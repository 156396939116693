import React, {useEffect} from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import {hasEditPermissions, hasPermissions, isAdmin, isAuthorized, isTokenExpired} from '../../shared/utils/utilities'
import {getCurrentUserOwners} from '../../redux/user/actions'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import { DEVIATION_READ } from '../../shared/utils/constants'
const CustomerRestrictedRoutes = () => {
  const dispatch = useDispatch()
  const userOwners = useSelector((state: RootState) => state.user.owners)
  useEffect(() => {
    if (!hasEditPermissions()) {
      dispatch(getCurrentUserOwners())
    }
  }, [])

  if (isTokenExpired()) return <Navigate to='/home' />
  else if (isAdmin()) return <Outlet />
  else if (hasEditPermissions() || hasPermissions([DEVIATION_READ])) return <Outlet />
  else if (userOwners.length > 0) return <Navigate to={`/owner/${userOwners[0].id}`} />
  else return <Navigate to='/start' />
}
export default CustomerRestrictedRoutes
