
import { Dispatch } from "redux";
import { ICurrency } from "../meter/interfaces";
import { ActionTypes } from "./types";
import currencyService from "../../services/currencyService";

export const getCurrencyByName = (name: string) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.FETCH_CURRENCY_LOADING})
    try {
        const currencies = await currencyService.getCurrencies()
        const currency = currencies.find((x: ICurrency) => x.name.toLowerCase() === name.toLowerCase())
        return dispatch({type: ActionTypes.FETCH_CURRENCY_SUCCESS, payload: currency})
    }
    catch(error) {
        return dispatch({type: ActionTypes.FETCH_CURRENCY_FAILED, payload: error})
    }
}