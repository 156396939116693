import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import React, {useEffect, useState} from 'react'

export interface MessageDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  message: string
}

const MessageDialog: React.FC<MessageDialogProps> = (props: MessageDialogProps) => {
  return (
    <div>
      <Dialog open={props.open} onClose={() => props.setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default MessageDialog
