import {Store, createStore, applyMiddleware, compose} from 'redux'
import {composeWithDevTools} from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import {RootState, RootReducer} from './rootReducer'

const initialState: any = {}

const configureStore = (initState: RootState): Store<RootState> => {
  const middleware = [thunk]
  const composeEnhancers = composeWithDevTools || compose
  const enhancers = composeEnhancers(applyMiddleware(...middleware))
  const store = createStore(RootReducer, initState, enhancers)
  return store
}

export default configureStore(initialState)
