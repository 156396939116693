import {IMeterCostCurrency} from '../redux/meter/interfaces'
import saveupClient, {handleResponse} from '../clients/saveupClient'
import {IDeviationType, IDeviationValueType} from '../redux/counter/interfaces'
async function getAllMeterTypes() {
  const allAddress = await saveupClient
    .get(`meter`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return allAddress
}
async function getMeterType(id: number) {
  const meterTypes = await saveupClient.get(`meter/${id}`).then(handleResponse)
  return meterTypes
}
async function getDeviationTypeForMeter(meterTypeId: number) {
  return await saveupClient.get<IDeviationType>(`meter/${meterTypeId}/deviation/type`).then(handleResponse)
}
async function getDeviationTypes() {
  return await saveupClient.get<IDeviationType>(`counter/deviation/type`).then(handleResponse)
}
async function getDeviationValueTypes() {
  return await saveupClient.get<IDeviationValueType[]>(`counter/deviation/value/type`).then(handleResponse)
}

async function getMeterCosts(id: number) {
  return await saveupClient.get<IMeterCostCurrency[]>(`meter/${id}/cost`).then(handleResponse)
}
async function getEnergyZoneMeterCosts(meterId: number, energyZoneId: number) {
  return await saveupClient.get<IMeterCostCurrency[]>(`meter/${meterId}/energyzone/${energyZoneId}/cost`).then(handleResponse)
}
async function createMeterType(name: string, maxValue: number, multiple: number, unitId: number, decimals: number, isConsumption: boolean) {
  return await saveupClient.put('meter', {name, maxValue, multiple, unitId, decimals, isConsumption, insDate: new Date(), updDate: new Date()}).then(handleResponse)
}
async function createMeterCost(meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
  return await saveupClient.post(`meter/cost`, {meterId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
}

async function createEnergyZoneMeterCost(meterId: number, energyZoneId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
  return await saveupClient
    .post<IMeterCostCurrency>(`meter/energyzone/cost`, {meterId, energyZoneId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()})
    .then(handleResponse)
}

async function updateMeterType(id: number, name: string, maxValue: number, multiple: number, unitId: number, decimals: number, isConsumption: boolean) {
  return await saveupClient.put('meter', {id, name, maxValue, multiple, unitId, decimals, isConsumption, insDate: new Date(), updDate: new Date()}).then(handleResponse)
}

async function updateMeterCost(id: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
  return await saveupClient
    .put<IMeterCostCurrency>(`meter/cost`, {id, meterId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()})
    .then(handleResponse)
}

async function updateEnergyZoneMeterCost(id: number, meterId: number, energyZoneId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
  return await saveupClient
    .put(`meter/energyzone/cost`, {id, meterId, energyZoneId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()})
    .then(handleResponse)
}

async function updateDeviationType(meterTypeId: number, deviationTypeId: number) {
  return await saveupClient.post('meter/deviation/type', {meterId: meterTypeId, deviationTypeId}).then(handleResponse)
}

async function deleteMeterType(id: number) {
  return await saveupClient.delete(`meter/${id}`).then(handleResponse)
}

async function deleteMeterCost(id: number) {
  return await saveupClient.delete(`meter/cost/${id}`).then(handleResponse)
}

async function deleteEnergyZoneMeterCost(id: number) {
  return await saveupClient.delete(`meter/energyzone/cost/${id}`).then(handleResponse)
}

async function getMeterEmissions(id: number) {
  const res = await saveupClient.get(`meter/${id}/emission`).then(handleResponse)
  return res
}

async function getMeterEmissionsByEmissionId(meterId: number, emissionId: number) {
  const res = await saveupClient.get(`meter/${meterId}/emission/${emissionId}`).then(handleResponse)
  return res
}


async function createMeterEmission(meterId: number, emissionId: number, value: number, startDate: Date, stopDate: Date) {
  return await saveupClient.post(`meter/emission`, {meterId, emissionId, value, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
}

async function updateMeterEmission(id: number, meterId: number, emissionId: number, value: number, startDate: Date, stopDate: Date) {
  return await saveupClient.put(`meter/emission`, {id, meterId, emissionId, value, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
}

async function deleteMeterEmission(id: number) {
  return await saveupClient.delete(`meter/emission/${id}`).then(handleResponse)
}

const meterTypeService = {
  getAllMeterTypes,
  getMeterType,
  getDeviationTypeForMeter,
  getDeviationValueTypes,
  getDeviationTypes,
  getMeterCosts,
  getEnergyZoneMeterCosts,
  getMeterEmissions,
  getMeterEmissionsByEmissionId,
  createMeterType,
  createMeterCost,
  createEnergyZoneMeterCost,
  createMeterEmission,
  updateMeterType,
  updateDeviationType,
  updateMeterCost,
  updateEnergyZoneMeterCost,
  updateMeterEmission,
  deleteMeterType,
  deleteMeterCost,
  deleteEnergyZoneMeterCost,
  deleteMeterEmission
}
export default meterTypeService
