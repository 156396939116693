import officeService from '../../services/officeService'
import { ActionTypes } from './types'
export const getOffices = () => {
    return async (dispatch: any) => {
        dispatch({ type: ActionTypes.GET_OFFICES_LOADING })
        try {
            const response = await officeService.getOffices()
            dispatch({ type: ActionTypes.GET_OFFICES_SUCCESS, payload: response })
        } catch (error) {
            dispatch({ type: ActionTypes.GET_OFFICES_FAILED, payload: error })
        }
    }
}
export const getOffice = (id: number) => {
    return async (dispatch: any) => {
        dispatch({ type: ActionTypes.GET_OFFICE_LOADING })
        try {
            const response = await officeService.getOffice(id)
            dispatch({ type: ActionTypes.GET_OFFICE_SUCCESS, payload: response })
        } catch (error) {
            dispatch({ type: ActionTypes.GET_OFFICE_FAILED, payload: error })
        }
    }
}
export const createOffice = (name: string, description: string) => {
    return async (dispatch: any) => {
        dispatch({ type: ActionTypes.CREATE_OFFICE_LOADING })
        try {
            const response = await officeService.createOffice(name, description)
            dispatch({ type: ActionTypes.CREATE_OFFICE_SUCCESS, payload: response })
        } catch (error) {
            dispatch({ type: ActionTypes.CREATE_OFFICE_FAILED, payload: error })
        }
    }
}
export const updateOffice = (id: number, name: string, description: string) => {
    return async (dispatch: any) => {
        dispatch({ type: ActionTypes.UPDATE_OFFICE_LOADING })
        try {
            const response = await officeService.updateOffice(id, name, description)
            dispatch({ type: ActionTypes.UPDATE_OFFICE_SUCCESS, payload: response })
        } catch (error) {
            dispatch({ type: ActionTypes.UPDATE_OFFICE_FAILED, payload: error })
        }
    }
}
export const deleteOffice = (id: number) => {
    return async (dispatch: any) => {
        dispatch({ type: ActionTypes.DELETE_OFFICE_LOADING })
        try {
            const response = await officeService.deleteOffice(id)
            dispatch({ type: ActionTypes.DELETE_OFFICE_SUCCESS, payload: id })
        } catch (error) {
            dispatch({ type: ActionTypes.DELETE_OFFICE_FAILED, payload: error })
        }
    }
}