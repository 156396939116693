import React, {useState} from 'react'
import {Box, Tab} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import MeasurePointDetails from './MeasurePointDetails'
import MeasurePointSummary from './MeasurePointSummary'
import { useParams } from 'react-router'
const MeasurePoint = () => {
  const [selectedTab, setSelectedTab] = useState('0')
  const {id} = useParams()
  const measurePointId = parseInt(id ?? '0')
  const onTabChange = (e: any, obj: any) => {
    setSelectedTab(obj)
  }

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box>
          <TabList onChange={onTabChange}>
            <Tab label='Förbrukning' value={'0'} />
            <Tab label='Redigera' value={'1'} />
          </TabList>
        </Box>
        <TabPanel value={'0'}>
          <MeasurePointSummary measurePointId={measurePointId}/>
        </TabPanel>
        <TabPanel value={'1'}>
          <MeasurePointDetails measurePointId={measurePointId} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
export default MeasurePoint
