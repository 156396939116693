import React from 'react'
import { Select, MenuItem, FormControl } from '@mui/material'
import { useState } from 'react'

interface Props {
    items: any[],
    label: string,
    onSelect: any,
    defaultItem: React.ReactElement
}

const renderItem = ({id, name}: any, index: number) => {    
    return (
        <MenuItem key={index} value={id}>{name}</MenuItem>
    )
}

const FilterSelector = (props: Props) => {        
    const { items, label, defaultItem } = props
    const [selected, setSelected] = useState(defaultItem.props.value)
    const onSelectedChanged = (event: any) =>  {              
        console.log(event.target.value)   
        setSelected(event.target.value)
        props.onSelect(event.target.value)       
    }
    if(!items) {
        return <div></div>
    }
    return (        
        <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select 
                sx={{flexGrow: 1, width: '250px', color: '#3E5060', margin: '5px'}}
                label={label}                
                className='type-select'
                value={selected}   
                onChange={onSelectedChanged}                          
            >
                {defaultItem}
                {items.map((t, i) => renderItem(t, i + 1))}
            </Select>
            </FormControl>
        </div>
    )
}
export default FilterSelector