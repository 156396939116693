import React from 'react'
import saveupClient, { handleResponse } from '../clients/saveupClient'
import { IOffice } from '../redux/estateAttribute/interfaces'

const officeService = {
    getOffice,
    getOffices,    
    createOffice,
    updateOffice,
    deleteOffice,
}

async function getOffices() {
    return await saveupClient.get<IOffice[]>('office').then(handleResponse)
}

async function getOffice(id: number) {
    return await saveupClient.get<IOffice>(`office/${id}`).then(handleResponse)
}

async function createOffice(name: string, description: string) {
    return await saveupClient.post<IOffice>('office', { name, description }).then(handleResponse)
}

async function updateOffice(id: number, name: string, description: string) {
    return await saveupClient.put<IOffice>(`office`, { id, name, description }).then(handleResponse)
}

async function deleteOffice(id: number) {
    return await saveupClient.delete(`office/${id}`).then(handleResponse)
}

export default officeService
