import {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {SidebarContext} from '../../../components/shared/SidebarContext'
import TopSectionMenu from '../../../components/shared/TopSectionMenu'
import {Button, Drawer, Hidden, IconButton, MenuItem} from '@mui/material'
import {SidebarWrapper} from './SidebarWrapperStyle'
import Tree from '../../../components/tree/Tree'
import SearchBar from '../../../components/search/SearchBar'
import {getFilter} from '../../../redux/filter/actions'
import FilterSelector from '../../../components/filter/FilterSelector'
import {RootState} from '../../../redux/rootReducer'
import {setExpandedNodes, setSelectedMeterType, setSelectedOffice} from '../../../redux/tree/treeActions'
import {hasEditPermissions} from '../../../shared/utils/utilities'
import {getDegreeDayCorrectedMeterTypes} from '../../../redux/configuration/actions'
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble'
function Sidebar() {
  const {sidebarToggle, toggleSidebar} = useContext(SidebarContext)
  const dispatch = useDispatch()
  const closeSidebar = () => toggleSidebar()
  const canEdit = hasEditPermissions()
  
  useEffect(() => {
    dispatch(getFilter())
  }, [dispatch])

  const onMeterTypeSelected = (item: any, index: number) => {
    dispatch(setSelectedMeterType(item))
  }

  const onOfficeSelected = (item: any, index: number) => {
    dispatch(setSelectedOffice(item))
  }

  const handleClearNodes = () => {
    dispatch(setExpandedNodes([]))
  }

  const types = useSelector((state: RootState) => state.filter.types ?? [])
  const offices = useSelector((state: RootState) => state.filter.offices ?? [])
  return (
    <>
      <Hidden lgDown={true}>
        <SidebarWrapper>
          {canEdit && <TopSectionMenu />}
          <SearchBar />
          <FilterSelector
            items={offices}
            label='Kontor'
            defaultItem={
              <MenuItem key='Alla kontor' value='Alla kontor'>
                Alla kontor
              </MenuItem>
            }
            onSelect={onOfficeSelected}
          />
          <FilterSelector
            items={types}
            label='Mätartyp'
            defaultItem={
              <MenuItem key='Alla mätartyper' value={0}>
                Alla mätartyper
              </MenuItem>
            }
            onSelect={onMeterTypeSelected}
          />
          <IconButton aria-label='close' color='inherit' size='small' title='Stäng alla noder' onClick={handleClearNodes}>
            <UnfoldLessDoubleIcon fontSize='inherit' />
          </IconButton>
          <Tree />
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp={true}>
        <Drawer anchor='left' open={sidebarToggle} onClose={closeSidebar} variant='temporary' elevation={9}>
          <SidebarWrapper>
            {canEdit && <TopSectionMenu />}
            <SearchBar />
            <FilterSelector
              items={offices}
              label='Kontor'
              defaultItem={
                <MenuItem key='Alla kontor' value='Alla kontor'>
                  Alla kontor
                </MenuItem>
              }
              onSelect={onOfficeSelected}
            />
            <FilterSelector
              items={types}
              label='Mätartyp'
              defaultItem={
                <MenuItem key='Alla mätartyper' value={0}>
                  Alla mätartyper
                </MenuItem>
              }
              onSelect={onMeterTypeSelected}
            />
            <IconButton aria-label='close' color='inherit' size='small' title='Stäng alla noder' onClick={handleClearNodes}>
              <UnfoldLessDoubleIcon fontSize='inherit' />
            </IconButton>
            <Tree />
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  )
}

export default Sidebar
