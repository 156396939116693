import {Reducer} from 'redux'
import {ActionTypes} from './addressTypes'
import { IAddressState } from './interfaces'

export const initialState: IAddressState = {
  isLoading: false,
  selectedAddress: undefined,
  errorMessage: undefined,
  meterTypes: [],
  consumptions: [],
  readingLogs: [],
  consumptionLogs: [],
  measurePoints: {
    isLoading: false,
    measurePoints: [],
    errorMessage: '' 
  }
}

export const addressReducer: Reducer<IAddressState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.GET_ADDRESS_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.GET_ADDRESS_OK: {
      return {...state, isLoading: false, selectedAddress: payload}
    }
    case ActionTypes.GET_ADDRESS_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.CREATE_ADDRESS_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.CREATE_ADDRESS_OK: {
      return {...state, isLoading: false, selectedAddress: payload}
    }
    case ActionTypes.CREATE_ADDRESS_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.UPDATE_ADDRESS_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.UPDATE_ADDRESS_OK: {
      return {...state, isLoading: false, selectedAddress: payload}
    }
    case ActionTypes.UPDATE_ADDRESS_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.FETCH_ADDRESS_METER_TYPES_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ADDRESS_METER_TYPES_SUCCESS: {
      return {...state, isLoading: false, meterTypes: payload}
    }
    case ActionTypes.FETCH_ADDRESS_METER_TYPES_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOADING: {
      return { ...state, isLoading: true }
    }
    case ActionTypes.FETCH_ADDRESS_CONSUMPTION_SUCCESS: {
      return { ...state, isLoading: false, consumptions: payload }
    }
    case ActionTypes.FETCH_ADDRESS_CONSUMPTION_ERROR: {
      return { ...state, isLoading: false, errorMessage: payload.response.status }
    }
    case ActionTypes.FETCH_ADDRESS_READING_LOGS_LOADING: {
      return { ...state, isLoading: true }
    }
    case ActionTypes.FETCH_ADDRESS_READING_LOGS_SUCCESS: {
      return { ...state, isLoading: false, readingLogs: payload }
    }
    case ActionTypes.FETCH_ADDRESS_READING_LOGS_FAILED: {
      return { ...state, isLoading: false, errorMessage: payload.response.status }
    }
    case ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOGS_LOADING: {
      return { ...state, isLoading: true }
    }
    case ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOGS_SUCCESS: {
      return { ...state, isLoading: false, consumptionLogs: payload }
    }
    case ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOGS_FAILED: {
      return { ...state, isLoading: false, errorMessage: payload.response.status }
    }
    case ActionTypes.FETCH_MEASUREPOINTS_BY_ADDRESS_ID_LOADING: {
      return {...state, measurePoints: {...state.measurePoints, measurePoints: [], isLoading: true}}
    }
    case ActionTypes.FETCH_MEASUREPOINTS_BY_ADDRESS_ID_SUCCESS: {
      return {...state, measurePoints: {...state.measurePoints, isLoading: false, measurePoints: payload}}
    }
    case ActionTypes.FETCH_MEASUREPOINTS_BY_ADDRESS_ID_FAILED: {
      return {...state, measurePoints: {...state.measurePoints, isLoading: false, errorMessage: payload.response.status}}
    }
    default: {
      return state
    }
  }
}