import {Reducer} from 'redux'
import {ActionTypes} from './types'
import {IConfigurationState} from './interfaces'

const initialConfigurationState: IConfigurationState = {
  degreeDayCorrectedMeterTypeIds: [],
  meterTypesWithEnergyZone: [],
  showVolume: false
}

export const configurationReducer: Reducer<IConfigurationState> = (state = initialConfigurationState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_DEGREEDAY_CORRECTED_METER_TYPES_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_DEGREEDAY_CORRECTED_METER_TYPES_SUCCESS:
      return {...state, isLoading: false, degreeDayCorrectedMeterTypeIds: payload}
    case ActionTypes.FETCH_DEGREEDAY_CORRECTED_METER_TYPES_FAILED:
      return {...state, isLoading: false}
    case ActionTypes.FETCH_ENERGY_ZONE_METER_TYPES_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_ENERGY_ZONE_METER_TYPES_SUCCESS:
      return {...state, isLoading: false, meterTypesWithEnergyZone: payload}
    case ActionTypes.FETCH_ENERGY_ZONE_METER_TYPES_FAILED:
      return {...state, isLoading: false}
      case ActionTypes.GET_SHOW_VOLUME:
        return {...state, showVolume: payload}
    default:
      return state
  }
}
