import React, {useEffect, useState} from 'react'
import {Office} from '../../../redux/estateAttribute/interfaces'
import {UpdateOfficeContainerProps, UpdateOfficeForm} from './interfaces'
import {useNavigate, useParams} from 'react-router'
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {UpdateOfficeState} from '../../../redux/office/interfaces'
import {RootState} from '../../../redux/rootReducer'
import {getOffice, updateOffice} from '../../../redux/office/actions'
import UpdateOffice from './UpdateOffice'
import {stat} from 'fs'

const defaultOffice: Office = {
  id: 0,
  name: '',
  description: '',
  insDate: new Date(),
  updDate: new Date(),
}

const UpdateOfficeContainer = (props: UpdateOfficeContainerProps) => {
  const {id} = useParams()
  const officeId: number = id ? parseInt(id) : 0
  const dispatch = useDispatch()
  const methods = useForm<UpdateOfficeForm>({defaultValues: defaultOffice})
  const {handleSubmit} = useForm<UpdateOfficeForm>()
  const {setValue, reset} = methods
  const navigate = useNavigate()

  const officeById: Office | undefined = useSelector((state: RootState) => state.office.office.office)
  const updateOfficeState: UpdateOfficeState = useSelector((state: RootState) => state.office.updateOffice)

  useEffect(() => {
    dispatch(getOffice(officeId))
  }, [officeId])

  useEffect(() => {
    if (officeById) reset(officeById)
  }, [officeById])

  const onSaveOffice: SubmitHandler<UpdateOfficeForm> = (data: UpdateOfficeForm) => {
    dispatch(updateOffice(data.id, data.name, data.description))
    navigate('/office')
  }

  return officeById ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSaveOffice)}>
        <UpdateOffice />
      </form>
    </FormProvider>
  ) : (
    <div>Loading...</div>
  )
}
export default UpdateOfficeContainer
