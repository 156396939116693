import {combineReducers, Reducer} from 'redux'
import treeReducer from './tree/treeReducer'
import {homeReducer} from './home/homeReducer'
import {ILogState} from './home/homeInterfaces'
import {ITreeState} from './tree//treeInterfaces'
import {IOwnerState} from './owner/ownerInterfaces'
import ownerReducer from './owner/ownerReducer'
import { IAreaState } from './area/Interfaces.ts'
import { AreaReducer } from './area/reducers'
import {EstateState} from './estate/Interfaces'
import {EstateReducer} from './estate/reducers'
import {addressEstateState} from './address/interfaces'
import {IAddressState} from './address/interfaces'
import {addressReducer} from './address/addressReducer'
import { IUnitState} from './unit/unitInterfaces'
import { unitReducer } from './unit/unitReducer'
import { filterReducer } from './filter/reducers';

import { measurePointReducer} from './Measurepoint/measurepointReducer'
import { IMeasurePointState} from './Measurepoint/measurepointInterfaces'
import { FilterState } from './filter/interfaces'
import { counterReducer } from './counter/reducer'
import { ICounterState, ICounterValueState } from './counter/interfaces'
import { ICaseState } from './case/interfaces'
import { caseReducer } from './case/reducer'
import { IMeterTypeState } from './meter/interfaces'
import meterTypeReducer from './meter/reducers'
import { IUserState } from './user/interfaces'
import { userReducer } from './user/reducers'
import { IReportState } from './report/interfaces'
import { ILoginState } from './login/loginInterfaces'
import reportReducer from './report/reducers'
import loginReducer from './login/loginReducer'
import { counterValueReducer } from './counterValue/reducer'
import { ICurrencyState } from './currency/interfaces'
import { currencyReducer } from './currency/reducer'
import { IEnergyZoneState } from './energyzone/interfaces'
import { energyZoneReducer } from './energyzone/reducer'
import { IEmissionState } from './emission/interfaces'
import { emissionReducer } from './emission/reducer'
import { IConfigurationState } from './configuration/interfaces'
import { configurationReducer } from './configuration/reducer'
import { IPDFState } from './pdf/interfaces'
import pdfReducer from './pdf/reducer'
import { IExternalSystemState } from './externalSystem/interfaces'
import { externalSystemReducer } from './externalSystem/reducer'
import { OfficeState } from './office/interfaces'
import { officeReducer } from './office/reducer'
import { counterPeriodConsumptionReducer } from './counterPeriodConsumption/reducer'
import { CounterPeriodConsumptionState } from './counterPeriodConsumption/interfaces'
import { NodeState } from './node/interfaces'
import { nodeReducer } from './node/reducer'

export interface RootState {
  home: ILogState,
  tree: ITreeState,
  owner: IOwnerState,
  area: IAreaState,
  estate: EstateState,
  address: IAddressState,
  unit: IUnitState,
  measurepoint: IMeasurePointState,
  filter: FilterState,
  counter: ICounterState,
  case: ICaseState,
  meter: IMeterTypeState,
  user: IUserState,
  report: IReportState,
  login: ILoginState,
  counterValue: ICounterValueState,
  counterPeriodConsumption: CounterPeriodConsumptionState
  currency: ICurrencyState,
  energyZone: IEnergyZoneState,
  emission: IEmissionState,
  configuration: IConfigurationState,
  externalSystem: IExternalSystemState
  pdf: IPDFState
  office: OfficeState
  node: NodeState
}
const appReducer = combineReducers({
  home: homeReducer,
  tree: treeReducer,
  area: AreaReducer,
  owner: ownerReducer,
  estate: EstateReducer,
  address: addressReducer,  
  unit: unitReducer,
  measurepoint: measurePointReducer,
  filter: filterReducer,
  counter: counterReducer,
  case: caseReducer,
  meter: meterTypeReducer,
  user: userReducer,
  report: reportReducer,
  login: loginReducer,
  counterValue: counterValueReducer,
  counterPeriodConsumption: counterPeriodConsumptionReducer,
  currency: currencyReducer,
  energyZone: energyZoneReducer,
  emission: emissionReducer,
  configuration: configurationReducer,
  pdf: pdfReducer,
  externalSystem: externalSystemReducer,
  office: officeReducer,
  node: nodeReducer
})
export const RootReducer: Reducer = (state: RootState, action) => {
  if(action.type === "LOGOUT") {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}