import { Dispatch } from "redux"
import { ActionTypes } from "../estate/types"
import { IEstateMeterEmission } from "./interfaces"
import estateMeterEmissionService from "../../services/estateMeterEmissionService"

export const getEstateMeterEmissions = (estateId: number, meterId: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.GET_ESTATE_METER_EMISSION_LOADING})
    try {
      const meterCosts: IEstateMeterEmission[] = await estateMeterEmissionService.getEstateMeterEmissions(estateId, meterId)
      return dispatch({type: ActionTypes.GET_ESTATE_METER_EMISSION_SUCCESS, payload: meterCosts})
    } catch (error) {
      return dispatch({type: ActionTypes.GET_ESTATE_METER_EMISSION_FAILED, payload: error})
    }
  }
  export const createEstateMeterEmission = (estateId: number, emissionId: number, meterId: number, value: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.CREATE_ESTATE_METER_EMISSION_LOADING})
    try {
      const createdMeterEmission: IEstateMeterEmission = await estateMeterEmissionService.createEstateMeterEmission(estateId, emissionId ,meterId, value, startDate, stopDate)
      return dispatch({type: ActionTypes.CREATE_ESTATE_METER_EMISSION_SUCCESS, payload: createdMeterEmission})
    } catch (error) {
      return dispatch({type: ActionTypes.CREATE_ESTATE_METER_EMISSION_FAILED, payload: error})
    }
  }
  export const updateEstateMeterEmission = (id: number, estateId: number, emissionId: number, meterId: number, value: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.UPDATE_ESTATE_METER_EMISSION_LOADING})
    try {        
      const updatedMeterEmission: IEstateMeterEmission = await estateMeterEmissionService.updateEstateMeterEmission(id, estateId, emissionId ,meterId, value, startDate, stopDate)
      return dispatch({type: ActionTypes.UPDATE_ESTATE_METER_EMISSION_SUCCESS, payload: updatedMeterEmission})
    } catch (error) {
      return dispatch({type: ActionTypes.UPDATE_ESTATE_METER_EMISSION_FAILED, payload: error})
    }
  }
  export const deleteEstateMeterEmission = (id: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.DELETE_ESTATE_METER_EMISSION_LOADING})
    try {
      await estateMeterEmissionService.deleteEstateMeterEmission(id)
      return dispatch({type: ActionTypes.DELETE_ESTATE_METER_EMISSION_SUCCESS, payload: id})
    } catch (error) {
      return dispatch({type: ActionTypes.DELETE_ESTATE_METER_EMISSION_FAILED, payload: error})
    }
  }