import {Cancel, Save} from '@mui/icons-material'
import {Button} from '@mui/material'
import React from 'react'
import {hasEditPermissions} from '../../../shared/utils/utilities'
import {IDialogOKCancelButtonsProps} from './interfaces'
const DialogOKCancelButtons = (props: IDialogOKCancelButtonsProps) => {
  return (
    <React.Fragment>
      <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={!props.isAuthorized} onClick={() => props.onConfirmClick()}>
        Spara
      </Button>
      <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={() => props.onCancelClick()}>
        Avbryt
      </Button>
    </React.Fragment>
  )
}
export default DialogOKCancelButtons
