import {IConsumptionNodeDto, IConsumptionGraphGroupedProps, IConsumptionYearData } from '../../../redux/consumption/interfaces'
import ConsumptionNodeDto from '../../../redux/consumption/ConsumptionNodeDto'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import {IMeterType} from '../../../redux/meter/interfaces'
import {ATEMP, CONSUMPTION, COST, CUBIC_METERS_SWE, EMISSION, LOA, MWH, OWNER, READING, WATER_SWE} from '../../../shared/utils/constants'
import {getUnitById} from '../../../redux/unit/unitActions'
import {RootState} from '../../../redux/rootReducer'
import {IAxesLabels} from '../table/interfaces'
import {getDesc, getUnit, getAnnotations } from './consumptionGraphHelper'
import annotationPlugin from 'chartjs-plugin-annotation';
import { getApproxCostString, getApproxEmissionString, getCostString, getEmissionString } from './costEmissionHelper'
import { isNullOrWhiteSpace } from '../../../shared/utils/utilities'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin)

const labels = ['test1', 'test2', 'test3']
export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => 3000),
      backgroundColor: 'rgb(255, 99, 132)',
      stack: 'Stack 0',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => 3000),
      backgroundColor: 'rgb(75, 192, 192)',
      stack: 'Stack 0',
    },
    {
      label: 'Dataset 3',
      data: labels.map(() => 3000),
      backgroundColor: 'rgb(53, 162, 235)',
      stack: 'Stack 1',
    },
  ],
}

const barColors = ['#C30009', '#00791C', '#0067FF', '#9D5900']

const axesLabels = {
  'CONSUMPTION': 'Förbrukning',
  'READING': 'Avläsning',
  'COST': 'Kostnad',
  'EMISSION': 'Utsläpp',
  'ATEMP': 'ATemp',
  'LOA': 'LOA',
} as IAxesLabels

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
const ConsumptionGraphGrouped = (props: IConsumptionGraphGroupedProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (selectedUnit === undefined || selectedUnit.id !== props.meterType.unitId) {
      dispatch(getUnitById(props.meterType.unitId))
    }
  }, [props.meterType.id])
  const selectedUnit = useSelector((state: RootState) => state.unit.selectedUnit)
  const showVolume = useSelector((state: RootState) => state.configuration.showVolume)
  const getYearlyConsumptionData = (consumptions: ConsumptionNodeDto[], year: number) => {
    var consumptionsByYear: number[] = []

    for (let i = 1; i <= 12; i++) {
      var monthlyConsumption = consumptions.filter((x) => x.year == year && x.month == i).map((x) => x)[0]
      if (monthlyConsumption) {
        if (!props.category || props.category === CONSUMPTION) consumptionsByYear.push(monthlyConsumption.value)
        else if (props.category === COST) consumptionsByYear.push(monthlyConsumption.cost)
        else if (props.category === EMISSION) consumptionsByYear.push(monthlyConsumption.emissionValue)
        else if (props.category === ATEMP && selectedUnit !== undefined) consumptionsByYear.push((!showVolume && props.meterType.name === WATER_SWE) || selectedUnit?.comment === MWH ? monthlyConsumption.aTempMultiplied : monthlyConsumption.aTemp)
        else if (props.category === LOA && selectedUnit !== undefined) consumptionsByYear.push((!showVolume && props.meterType.name === WATER_SWE) || selectedUnit?.comment === MWH ? monthlyConsumption.loaMultiplied : monthlyConsumption.loa)
      } else {
        consumptionsByYear.push(0)
      }
    }

    let yearData: IConsumptionYearData = {
      label: `${year}`,
      backgroundColor: '#000000',
      data: consumptionsByYear,
      fill: true,
    }
    return yearData
  }
  const getConsumptionYearData = (consumptions: ConsumptionNodeDto[], startDate: Date) => {
    var years = consumptions
      .filter((x) => x.year >= startDate.getFullYear())
      .map((x) => x.year)
      .filter((v, i, arr) => arr.indexOf(v) === i)
    var consumptionsByYear: IConsumptionYearData[] = []
    years.forEach((year) => {
      consumptionsByYear.push(getYearlyConsumptionData(consumptions, year))
    })
    return {
      months: months,
      data: consumptionsByYear,
    }
  }

  let groupedData = getConsumptionYearData(props.data, props.startDate)

  let labels = months.map((x) => x).filter((v, i, arr) => arr.indexOf(v) === i)
  groupedData.data.forEach((year, index) => {
    if (barColors[index] !== undefined) {
      year.backgroundColor = barColors[index]
    }
  })

  const chartData = {
    datasets: groupedData.data.map((x: IConsumptionYearData) => {
      return {
        label: x.label,
        data: x.data,
        backgroundColor: x.backgroundColor,
        fill: x.fill,
      }
    }),
    labels: labels,
  }

  const renderCostEmissionInfo = (context: any) => {
    if(props.costEmissionData === undefined || props.data?.length === 0 || !props.category) return ''
    if(props.data[0].type === OWNER) return ''
    const currentContext = context[0]
    var years = props.data
      .filter((x) => x.year >= props.startDate.getFullYear())
      .map((x) => x.year)
      .filter((v, i, arr) => arr.indexOf(v) === i)
    const year = years[currentContext.datasetIndex]
    if (props.category === COST) return getCostString(props.costEmissionData, props.meterType.id, year, currentContext.dataIndex)
    else if (props.category === EMISSION) return getEmissionString(props.costEmissionData, props.meterType.id, year, currentContext.datasetIndex)
    return ''
  }

  return (
    <Bar
      data={chartData}
      options={{
        animation: {duration: 0},
        scales: {
          y: {title: {display: true, text: `${getDesc(props.category, props.meterType)} (${getUnit(props.category, props.meterType, props.unit, props.currency, props.emission)})`}},
          x: {title: {display: true, text: 'Period'}},
        },
        plugins: {
          annotation: {
            annotations: getAnnotations(props.deviations, true),
          },
          tooltip: {
            callbacks: {
              footer: renderCostEmissionInfo,              
            }
          },
        },     
      }}
    />
  )
}
export default ConsumptionGraphGrouped
