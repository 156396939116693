import React, {useEffect, useState} from 'react'
import {ICounterDeviationDetailsProps} from './interfaces'
import {Grid} from '@mui/material'
import {DEFAULT_SWE, DEVIATION_TYPE_EFFICIENCY, DEVIATION_TYPE_LEVEL, DEVIATION_SWE} from '../../../shared/language/swe'
import SecTextField from '../../shared/Inputs/SecTextField'
import {hasEditPermissions, hasPermissions, htmlDecode, isNullOrWhiteSpace} from '../../../shared/utils/utilities'
import {MAX_TEMP, MIN_TEMP, NODE_WRITE, NOT_SET} from '../../../shared/utils/constants'
import {ICounterDeviation, IDeviationType, IDeviationValue, IDeviationValueType} from '../../../redux/counter/interfaces'
import {useDispatch} from 'react-redux'

const getDefaultDeviationValue = (deviationType: IDeviationType) => {
  if (deviationType === undefined) return 10
  const deviationTypeName = deviationType.name.toLocaleLowerCase()
  if (deviationTypeName === DEFAULT_SWE.toLocaleLowerCase()) return 10
  else if (deviationTypeName === DEVIATION_TYPE_EFFICIENCY.toLocaleLowerCase()) {
    return 50
  } else return 10
}

const emptyDeviationValue: ICounterDeviation = {
  id: 0,
  counterId: 0,
  deviation: 10,
  insDate: new Date(),
  updDate: new Date(),
  deviationValues: [],
}
const CounterDeviationDetails = (props: ICounterDeviationDetailsProps) => {
  const dispatch = useDispatch()
  const [defaultDeviation, setDefaultDeviation] = useState<ICounterDeviation>(emptyDeviationValue)
  const [deviation, setDeviation] = useState<ICounterDeviation | undefined>()
  const [value, setValue] = useState<number>(props.deviation.deviation)
  const [canEdit, setCanEdit] = useState<boolean>(false)

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
    const defaultDeviation = defineDefaultDeviation(props.meterDeviationType)
    setDefaultDeviation(defaultDeviation)
  }, [])

  useEffect(() => {
    if (props.deviation) {
      setDeviation(props.deviation)
    } else {
      setDeviation(undefined)
    }
  }, [props.deviation])

  useEffect(() => {
    if (deviation) {
      setValue(deviation.deviation)
      props.onChange(deviation)
    }
  }, [deviation])


  const defineDefaultDeviation = (deviationType: IDeviationType | undefined) => {
    if (!deviationType) return defaultDeviation
    let subValues: IDeviationValue[] = []
    if (deviationType.valueTypes?.length > 0) {
      subValues = deviationType.valueTypes.map((x: IDeviationValueType) => ({
        id: 0,
        counterDeviationId: 0,
        deviationValueTypeId: x.id,
        value: x.defaultValue,
        insDate: new Date(),
        updDate: new Date(),
        deviationValueType: x,
      }))
    }
    return {
      id: 0,
      counterId: props.counterId,
      deviation: props.meterDeviationType ? getDefaultDeviationValue(props.meterDeviationType) : 10,
      insDate: new Date(),
      updDate: new Date(),
      deviationValues: subValues,
    }
  }

  const onDeviationValueChange = (deviationTypeOrValueName: string, deviationValue: number) => {
    if (isNullOrWhiteSpace(deviationTypeOrValueName)) return
    else if ([DEFAULT_SWE, DEVIATION_TYPE_EFFICIENCY, DEVIATION_TYPE_LEVEL].map((x) => x.toLocaleLowerCase()).indexOf(deviationTypeOrValueName.toLocaleLowerCase()) !== -1) {
      setDeviation((deviation) =>
        deviation
          ? {...deviation, counterId: props.counterId, deviation: deviationValue}
          : {
              id: 0,
              counterId: props.counterId,
              deviation: deviationValue,
              insDate: new Date(),
              updDate: new Date(),
              deviationValues: [],
            }
      )
    } else {
      if (deviation && deviation?.counterId > 0) {
        let currentDeviationValueType = props.meterDeviationType?.valueTypes.find((x) => x.name === deviationTypeOrValueName)
        let index = deviation.deviationValues.findIndex((x) => x.deviationValueTypeId === currentDeviationValueType?.id)

        if (index >= 0) {
          const modifiedDeviationsValues = [...deviation.deviationValues]
          modifiedDeviationsValues[index].value = deviationValue
          setDeviation(
            deviation
              ? {...deviation, deviationValues: modifiedDeviationsValues}
              : {
                  id: 0,
                  counterId: props.counterId,
                  deviation: 0,
                  insDate: new Date(),
                  updDate: new Date(),
                  deviationValues: modifiedDeviationsValues,
                }
          )
        } else {
          const values: IDeviationValue[] = []
          const newDeviationValue = {
            id: 0,
            counterDeviationId: deviation.id,
            deviationValueTypeId: currentDeviationValueType ? currentDeviationValueType.id : 0,
            value: deviationValue,
            insDate: new Date(),
            updDate: new Date(),
            deviationValueType: currentDeviationValueType,
          }
          values.push(newDeviationValue)

          const unassignedValuesTypes = props.meterDeviationType?.valueTypes.filter((x) => x !== undefined && x.id !== newDeviationValue.deviationValueTypeId)
          const placeholderValues: IDeviationValue[] =
            unassignedValuesTypes?.forEach((valueType: IDeviationValueType) => {
              values.push({
                id: 0,
                counterDeviationId: 0,
                deviationValueTypeId: valueType.id,
                value: valueType.name.toLocaleLowerCase() === deviationTypeOrValueName.toLocaleLowerCase() ? deviationValue : valueType.defaultValue,
                insDate: new Date(),
                updDate: new Date(),
                deviationValueType: valueType,
              })
            }) ?? []
          values.concat(placeholderValues)

          const modifiedDeviation = {...deviation, deviationValues: values}
          setDeviation(modifiedDeviation)
        }
      } else {
        let values: IDeviationValue[] = []
        props.meterDeviationType?.valueTypes?.forEach((valueType: IDeviationValueType) => {
          values.push({
            id: 0,
            counterDeviationId: 0,
            deviationValueTypeId: valueType.id,
            value: valueType.name.toLocaleLowerCase() === deviationTypeOrValueName.toLocaleLowerCase() ? deviationValue : valueType.defaultValue,
            insDate: new Date(),
            updDate: new Date(),
            deviationValueType: valueType,
          })
        })

        const newDeviation: ICounterDeviation = {...emptyDeviationValue, counterId: props.counterId, deviationValues: values}
        setDeviation(newDeviation)
      }
    }
  }

  const renderDeviation = () => {
    if (props.meterDeviationType?.name.toLocaleLowerCase() === DEFAULT_SWE.toLocaleLowerCase()) {
      return (
        <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
          <Grid item xs={3}>
            <SecTextField
              type='number'
              value={value}
              fullWidth
              inputProps={{maxLength: 40}}
              disabled={!canEdit}
              label='Normalavvikelse (%)'
              InputProps={{
                readOnly: !canEdit,
              }}
              onChange={(e: any) => onDeviationValueChange(props.meterDeviationType ? props.meterDeviationType.name : '', e.target.value)}
            />
          </Grid>
        </Grid>
      )
    } else if (props.meterDeviationType?.name.toLocaleLowerCase() === DEVIATION_TYPE_EFFICIENCY.toLocaleLowerCase()) {
      return (
        <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
          <Grid item xs={3}>
            <SecTextField
              type='number'
              value={value}
              fullWidth
              inputProps={{maxLength: 40}}
              disabled={!canEdit}
              label={props.meterDeviationType.name}
              InputProps={{
                readOnly: !canEdit,
              }}
              onChange={(e: any) => onDeviationValueChange(props.meterDeviationType ? props.meterDeviationType.name : '', e.target.value)}
            />
          </Grid>
        </Grid>
      )
    } else if (props.meterDeviationType?.name.toLocaleLowerCase() === DEVIATION_TYPE_LEVEL.toLocaleLowerCase()) {
      return (
        <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
          <Grid item xs={3}>
            <SecTextField
              type='number'
              value={value}
              fullWidth
              inputProps={{maxLength: 40}}
              disabled={!canEdit}
              label={props.meterDeviationType.name}
              InputProps={{
                readOnly: !canEdit,
              }}
              onChange={(e: any) => onDeviationValueChange(props.meterDeviationType ? props.meterDeviationType.name : '', e.target.value)}
            />
          </Grid>
        </Grid>
      )
    } else {
      if (deviation && deviation.counterId > 0 && deviation.deviationValues?.length > 0) {
        let currentDeviationValues = deviation?.deviationValues

        const sortedDeviationValues = currentDeviationValues.sort((a: IDeviationValue, b: IDeviationValue) => {
          if (a.deviationValueType?.name === MAX_TEMP && b.deviationValueType?.name === MIN_TEMP) return 1
          if (a.deviationValueType?.name === MIN_TEMP && b.deviationValueType?.name === MAX_TEMP) return -1
          return 0
        })

        return (
          <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
            {sortedDeviationValues.map((value: IDeviationValue, index: number) => renderDeviationValue(value, index))}
          </Grid>
        )
      } else if (props.meterDeviationType?.valueTypes) {
        const sortedValueTypes = props.meterDeviationType?.valueTypes.sort((a: IDeviationValueType, b: IDeviationValueType) => {
          if (a.name === MAX_TEMP && b.name === MIN_TEMP) return 1
          if (a.name === MIN_TEMP && b.name === MAX_TEMP) return -1
          return 0
        })
        return (
          <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
            {sortedValueTypes.map((valueType: IDeviationValueType, index: number) => renderDefaultDeviationValue(valueType, index))}
          </Grid>
        )
      } else {
        return (
          <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
            <Grid item xs={3}>
              <SecTextField
                type='number'
                value={0}
                fullWidth
                inputProps={{maxLength: 40}}
                disabled={true}
                label='Okänd avvikelsetyp'
                InputProps={{
                  readOnly: !canEdit,
                }}
              />
            </Grid>
          </Grid>
        )
      }
    }
  }

  const renderDeviationValue = (value: IDeviationValue, index: number) => {
    const deviationValue = deviation?.deviationValues.find((x: IDeviationValue) => x.id === value.id)
    const defaultDeviationValue = props.meterDeviationType?.valueTypes.find((x) => x.id === deviationValue?.deviationValueTypeId)
    return (
      <Grid item xs={2} key={index}>
        <SecTextField
          type='number'
          value={deviationValue ? value.value : defaultDeviationValue?.defaultValue}
          fullWidth
          inputProps={{maxLength: 40}}
          disabled={!canEdit}
          label={htmlDecode(defaultDeviationValue ? defaultDeviationValue.friendlyName : NOT_SET)}
          onChange={(e: any) => {
            if (value.deviationValueType) onDeviationValueChange(value.deviationValueType.name, e.target.value)
          }}
        />
      </Grid>
    )
  }

  const renderDefaultDeviationValue = (valueType: IDeviationValueType, index: number) => {
    return (
      <Grid item xs={2} key={index}>
        <SecTextField
          type='number'
          value={valueType.defaultValue}
          fullWidth
          inputProps={{maxLength: 40}}
          disabled={!canEdit}
          label={htmlDecode(valueType.friendlyName)}
          onChange={(e: any) => {
            onDeviationValueChange(valueType.name, e.target.value)
          }}
        />
      </Grid>
    )
  }

  return (
    <div>
      <Grid container spacing={5}>
        {renderDeviation()}
      </Grid>
    </div>
  )
}
export default CounterDeviationDetails
