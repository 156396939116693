import saveupClient, {handleResponse} from '../clients/saveupClient'
import {ICounterPeriodConsumption, ICounterPeriodConsumptionListItem, ICounterPeriodConsumptionLog} from '../redux/counterPeriodConsumption/interfaces'

async function getCounterPeriodConsumptionLog(id: number) {
  return await saveupClient.get<ICounterPeriodConsumptionLog>(`v2/counter/consumption/period/log/${id}`).then(handleResponse)
}
async function getCounterPeriodConsumptionListItems(counterId: number) {
  return await saveupClient.get<ICounterPeriodConsumptionListItem[]>(`counter/${counterId}/consumption/period/list`).then(handleResponse)
}
async function createCounterPeriodConsumptionLog(counterPeriodConsumptionId: number, text: string, tagName: string) {
  return await saveupClient.post<ICounterPeriodConsumptionLog>(`v2/counter/consumption/period/log`, { counterPeriodConsumptionId: counterPeriodConsumptionId, text: text, tagName: tagName}).then(handleResponse)
}
async function updateCounterPeriodConsumptionLog(counterPeriodConsumptionLogId: number, text: string, tagName: string) {
  return await saveupClient.put<ICounterPeriodConsumptionLog>(`v2/counter/consumption/period/log`, {id: counterPeriodConsumptionLogId, text: text, tagName: tagName}).then(handleResponse)
}
async function getPeriodicConsumption(counterId: number, year: number, month: number) {
  return await saveupClient
    .get<ICounterPeriodConsumption>(`consumption/period?counterId=${counterId}&year=${year}&month=${month}`)
    .then((response) => (response && response.data ? response.data : null))
    .catch((error) => console.log(error))
}
async function getAllPeriodicConsumption(counterId: number) {
  return await saveupClient.get<ICounterPeriodConsumption>(`counter/${counterId}/consumption/period`).then(handleResponse)
}
async function isConsumptionBasedOnReading(counterId: number, year: number, month: number) {
  return await saveupClient
    .get<boolean>(`counter/value/exists/period?counterId=${counterId}&year=${year}&month=${month}`)
    .then(handleResponse)
    .catch((error) => console.log(error))
}
async function savePeriodConsumption(counterId: number, value: number, year: number, month: number) {
  let consumption = {counterId, value, year, month}
  return await saveupClient
    .post<ICounterPeriodConsumption>(`consumption/period`, consumption)
    .then(handleResponse)
    .catch((error) => console.log(error))
}
async function deletePeriodConsumption(id: number) {
  return await saveupClient.delete(`consumption/period/${id}`).then(handleResponse)
}
async function updateConsumption(id: number, counterId: number, value: number, year: number, month: number) {
  let consumption = {id, counterId, value, year, month, priority: false}
  return await saveupClient.put<ICounterPeriodConsumption>(`consumption/period`, consumption).then(handleResponse)
}

const counterPeriodConsumptionService = {
  getPeriodicConsumption,
  getAllPeriodicConsumption,
  getCounterPeriodConsumptionLog,
  getCounterPeriodConsumptionListItems,
  createCounterPeriodConsumptionLog,
  updateCounterPeriodConsumptionLog,
  savePeriodConsumption,
  deletePeriodConsumption,
  isConsumptionBasedOnReading,
  updateConsumption,
}
export default counterPeriodConsumptionService
