import {Button} from '@mui/material'
import {hasEditPermissions, hasPermissions} from '../../../shared/utils/utilities'
import React from 'react'
import { NODE_WRITE } from '../../../shared/utils/constants'
import { SecButtonProps } from './interfaces'
const SecButton : React.FC<SecButtonProps> = (props: any) => {
  const isAuthenticated = hasPermissions([NODE_WRITE])

  const onSecButtonClick = (e: any) => {
    if (isAuthenticated && props.onClick !== undefined) props.onClick(e)
  }

  return (
    <Button disabled={!isAuthenticated} onClick={onSecButtonClick} {...props}>
      {props.children}
    </Button>
  )
}
export default SecButton
