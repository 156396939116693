import React, {useEffect, useState} from 'react'
import { IMeterEmissionListProps } from './interfaces'
import { Card, CardContent, CardHeader, Divider, IconButton, styled } from '@mui/material'
import { DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { Edit } from '@mui/icons-material'
import {isAdmin} from '../../../shared/utils/utilities'
const StripedDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
  }))
const MeterEmissionList = (props: IMeterEmissionListProps) => {
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  useEffect(() => {    
    setHasAdminRights(isAdmin())
  }, [])
    const columns: GridColDef[] = [
        {field: 'id', headerName: 'Id', flex: 1},
        {field: 'value', headerName: 'Värde', flex: 1, editable: true},
        {
          field: 'emission',
          headerName: 'Utsläpp',
          flex: 1,
          editable: false,
          renderCell: (params: any) => {
            return params.value.name
          },
        },
        {field: 'startDate', headerName: 'Från', flex: 1, editable: true},
        {field: 'stopDate', headerName: 'Till', flex: 1, editable: true},
        {
          width: 10,
          field: 'actions',
          headerName: 'Actions',
          renderHeader: (params: GridColumnHeaderParams) => <div></div>,
          renderCell: (params: GridRenderCellParams<string>) => (
            hasAdminRights ? (
            <div>
              <IconButton onClick={() => {props.onMeterEmissionEditClick(params.row)}}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => props.onMeterEmissionDeleteClick(params.row)}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </div>
            ) : ''
          ),
          flex: 1,
        },
      ]      
      return (
        <div>
          <Card>
            <CardHeader title={`Registrerade utsläpp`} />
            <Divider />
            <CardContent>
              <StripedDataGrid pageSize={50} rows={props.meterEmissions} columns={columns} autoHeight />
            </CardContent>
          </Card>
        </div>
      )
}
export default MeterEmissionList