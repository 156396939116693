import React from 'react'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField} from '@mui/material'
import {Save, Cancel, RunningWithErrors} from '@mui/icons-material'
import {Controller, useForm} from 'react-hook-form'
import {ICounterPeriodConsumptionInputDialogForm, ICounterPeriodConsumptionInputDialogProps} from '../../redux/counterPeriodConsumption/interfaces'
import {hasEditPermissions, hasPermissions} from '../../shared/utils/utilities'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getCounterPeriodConsumptionLog, createCounterPeriodConsumptionLog, updateCounterPeriodConsumptionLog} from '../../redux/counterPeriodConsumption/actions'
import {RootState} from '../../redux/rootReducer'
import {head} from 'lodash'
import {NODE_WRITE} from '../../shared/utils/constants'
import CreateConsumptionDeviationDialog from './consumptions/CreatePeriodConsumptionDeviationDialog'
import {ICounterPeriodConsumptionDeviationForm} from './consumptions/interfaces'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const CounterPeriodConsumptionInputDialog = (props: ICounterPeriodConsumptionInputDialogProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<ICounterPeriodConsumptionInputDialogForm>({
    defaultValues: {
      id: props.counterPeriodConsumptionListItem ? props.counterPeriodConsumptionListItem.id : 0,
      value: props.counterPeriodConsumptionListItem ? props.counterPeriodConsumptionListItem.value : 0,
      year: props.counterPeriodConsumptionListItem && props.counterPeriodConsumptionListItem.year > 0 ? props.counterPeriodConsumptionListItem.year : new Date().getFullYear(),
      month: props.counterPeriodConsumptionListItem ? props.counterPeriodConsumptionListItem.month : new Date().getMonth() + 1,
    },
  })
  const dispatch = useDispatch()
  const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false)
  const [createConsumptionDeviationDialogOpen, setCreateConsumptionDeviationDialogOpen] = useState<boolean>(false)
  const [resetAlertOpen, setResetAlertOpen] = useState<boolean>(false)
  const [pendingValue, setPendingValue] = useState<ICounterPeriodConsumptionInputDialogProps | undefined>()
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const renderDeviationDialog = () => {
    if (!props.counterPeriodConsumptionListItem) return ''

    // Get the first CounterPeriodConsumptionLog from the CounterPeriodCOnsumptionListItem
    let log = head(props.counterPeriodConsumptionListItem.counterPeriodConsumptionLogs)

    return (
      <CreateConsumptionDeviationDialog
        id={log ? log.id : 0}
        open={createConsumptionDeviationDialogOpen}
        counterPeriodConsumptionId={props.counterPeriodConsumptionListItem.id}
        onClose={() => setCreateConsumptionDeviationDialogOpen(false)}
        onConfirm={(e: any) => onDeviationConfirmClick(e)}
      />
    )
  }

  const onDeviationConfirmClick = (data: ICounterPeriodConsumptionDeviationForm) => {
    if (!canEdit) return
    if (data.counterPeriodConsumptionId && data.counterPeriodConsumptionId == props.counterPeriodConsumptionListItem?.id) {
      if (data.id === 0 && props.counterPeriodConsumptionListItem && data.counterPeriodConsumptionId == data.counterPeriodConsumptionId) {
        dispatch(createCounterPeriodConsumptionLog(data.counterPeriodConsumptionId, data.text, data.tag))
      } else if (data.id > 0 && props.counterPeriodConsumptionListItem && data.counterPeriodConsumptionId == props.counterPeriodConsumptionListItem?.id) {
        dispatch(updateCounterPeriodConsumptionLog(data.id, data.text, data.tag))
      }
    }
    setCreateConsumptionDeviationDialogOpen(false)
  }

  let log = useSelector((state: RootState) => head(props.counterPeriodConsumptionListItem?.counterPeriodConsumptionLogs))
  const createdLog = useSelector((state: RootState) => state.counterValue.createdLog)

  useEffect(() => {
    setPendingValue(undefined)
    if (props.counterPeriodConsumptionListItem) {
      dispatch(getCounterPeriodConsumptionLog(props.counterPeriodConsumptionListItem.id))
    }
    reset({
      id: props.counterPeriodConsumptionListItem ? props.counterPeriodConsumptionListItem.id : 0,
      value: props.counterPeriodConsumptionListItem ? props.counterPeriodConsumptionListItem.value : 0,
      year: props.counterPeriodConsumptionListItem && props.counterPeriodConsumptionListItem.year > 0 ? props.counterPeriodConsumptionListItem.year : new Date().getFullYear(),
      month: props.counterPeriodConsumptionListItem ? props.counterPeriodConsumptionListItem.month : new Date().getMonth() + 1,
    })
  }, [props.counterPeriodConsumptionListItem])

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [canEdit])

  useEffect(() => {
    if (createdLog) {
      dispatch(getCounterPeriodConsumptionLog(createdLog.id))
    }
  }, [createdLog])

  const onSubmit = (data: ICounterPeriodConsumptionInputDialogForm) => {
    let input: ICounterPeriodConsumptionInputDialogForm = {
      id: props.counterPeriodConsumptionListItem ? props.counterPeriodConsumptionListItem.id : 0,
      value: data.value,
      year: data.year,
      month: data.month,
    }
    props.onConfirm(input)
  }

  return (
    <div>
      <form>
        <Dialog sx={{style}} open={props.open}>
          <DialogTitle>Redigera förbrukning</DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                <Grid xs={12} item>
                  <Controller
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        id='value'
                        label='Förbrukning'
                        type='number'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                    name='value'
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                <Grid xs={6} item>
                  <Controller
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        id='year'
                        label='År'
                        type='number'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                    name='year'
                    control={control}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        id='month'
                        label='Månad'
                        type='number'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...field}
                        fullWidth
                        error={!!errors.month}
                      />
                    )}
                    rules={{
                      min: 1,
                      max: 12,
                    }}
                    name='month'
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={!canEdit} onClick={handleSubmit(onSubmit)}>
              Spara
            </Button>
            <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
              Avbryt
            </Button>
            {log || (createdLog && createdLog.id === props.counterPeriodConsumptionListItem?.id) ? (
              <Button
                sx={{mt: {xs: 2, md: 0}}}
                variant='contained'
                color='primary'
                startIcon={<RunningWithErrors fontSize='small' />}
                disabled={!canEdit}
                onClick={() => setCreateConsumptionDeviationDialogOpen(true)}>
                Uppdatera avvikelse
              </Button>
            ) : (
              // <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={!canEdit} onClick={() => setResetDialogOpen(true)}>
              //   Spara mätaråterställning
              // </Button>
              <Button
                sx={{mt: {xs: 2, md: 0}}}
                variant='contained'
                color='primary'
                startIcon={<RunningWithErrors fontSize='small' />}
                disabled={!canEdit}
                onClick={() => setCreateConsumptionDeviationDialogOpen(true)}>
                Skapa avvikelse
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </form>
      <Grid container direction='row' alignItems='stretch'>
        <Grid item xs={12}>
          {renderDeviationDialog()}
        </Grid>
      </Grid>
    </div>
  )
}
export default CounterPeriodConsumptionInputDialog
