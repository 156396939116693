import { Dispatch } from "redux"
import energyZoneService from "../../services/energyZoneService"
import { IEnergyZone } from "./interfaces"
import { ActionTypes } from "./types"

export const getEnergyZones = () => async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_ENERGY_ZONES_LOADING})
      let zones : IEnergyZone[] = await energyZoneService.getEnergyZones()
      return dispatch({type: ActionTypes.FETCH_ENERGY_ZONES_SUCCESS, payload: zones})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_ENERGY_ZONES_FAILED, payload: error})
    }
  }