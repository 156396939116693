import {IOwnerState} from './ownerInterfaces'
import {Reducer} from 'redux'
import {ActionTypes} from './ownerTypes'
import {selectedGridRowsCountSelector} from '@mui/x-data-grid'
export const initialState: IOwnerState = {
  isLoading: false,
  owners: [],
  selectedOwner: undefined,
  errorMessage: undefined
}

const ownerReducer: Reducer<IOwnerState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_ALL_OWNERS_OK:
      return {...state, isLoading: false, owners: payload}
    case ActionTypes.CLEAR_SELECTED_OWNER:
      return {...state, isLoading: false, selectedOwner: undefined}
    case ActionTypes.GET_SELECTED_OWNER_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_SELECTED_OWNER_SUCCESS:
      return {...state, isLoading: false, selectedOwner: {...state.selectedOwner, owner: payload, Pdf: []}}
    case ActionTypes.GET_SELECTED_OWNER_ERROR:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.CREATE_OWNER_OK:
      return {...state, isLoading: false, selectedOwner: {...state.selectedOwner, owner: payload}}
    case ActionTypes.EDIT_OWNER_OK:
      return {...state, isLoading: false, selectedOwner: {...state.selectedOwner, owner: payload}}
    case ActionTypes.DELETE_OWNER_OK:
      return {...state, isLoading: false}
    case ActionTypes.GET_OWNER_ESTATES_SUCCESS:
      return {...state, isLoading: false, selectedOwner: {...state.selectedOwner, estates: payload}}
    case ActionTypes.FETCH_OWNER_METER_TYPES_LOADING:
      return {...state}
    case ActionTypes.FETCH_OWNER_METER_TYPES_SUCCESS:
      return {...state, selectedOwner: {...state.selectedOwner, meterTypes: payload}}
    case ActionTypes.FETCH_OWNER_METER_TYPES_ERROR:
      return {...state, errorMessage: payload}
    case ActionTypes.FETCH_OWNER_CONSUMPTION_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_OWNER_CONSUMPTION_SUCCESS:
      return {...state, isLoading: false, selectedOwner: {...state.selectedOwner, consumptions: payload}}
    case ActionTypes.FETCH_OWNER_CONSUMPTION_ERROR:
      return {...state, isLoading: false, errorMessage: payload}
    default:
      return state
  }
}
export default ownerReducer
