export enum ActionTypes {
  FETCH_AREA_LOADING = 'FETCH_AREA_LOADING',
  FETCH_AREA_SUCCESS = 'FETCH_AREA_SUCCES',
  FETCH_AREA_FAILED = 'FETCH_AREA_FAILED',
  FETCH_AREAS_LOADING = 'FETCH_AREAS_LOADING',
  FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCES',
  FETCH_AREAS_FAILED = 'FETCH_AREAS_FAILED',
  FETCH_AREABYID_LOADING = 'FETCH_AREABYID_LOADING',
  FETCH_AREABYID_SUCCESS = 'FETCH_AREABYID_SUCCES',
  FETCH_AREABYID_FAILED = 'FETCH_AREABYID_FAILED',
  DELETE_AREA_LOADING = 'DELETE_AREA_LOADING',
  DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCES',
  DELETE_AREA_FAILED = 'DELETE_AREA_FAILED',
  CREATE_AREA_LOADING = 'CREATE_AREA_LOADING',
  CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCES',
  CREATE_AREA_FAILED = 'CREATE_AREA_FAILED',
  EDIT_AREA_LOADING = 'EDIT_AREA_LOADING',
  EDIT_AREA_SUCCESS = 'EDIT_AREA_SUCCES',
  EDIT_AREA_FAILED = 'EDIT_AREA_FAILED',
  FETCH_AREA_ENERGY_ZONE_LOADING = 'FETCH_AREA_ENERGY_ZONE_LOADING',
  FETCH_AREA_ENERGY_ZONE_SUCCESS = 'FETCH_AREA_ENERGY_ZONE_SUCCES',
  FETCH_AREA_ENERGY_ZONE_FAILED = 'FETCH_AREA_ENERGY_ZONE_FAILED',
  CREATE_AREA_ENERGY_ZONE_LOADING = 'CREATE_AREA_ENERGY_ZONE_LOADING',
  CREATE_AREA_ENERGY_ZONE_SUCCESS = 'CREATE_AREA_ENERGY_ZONE_SUCCES',
  CREATE_AREA_ENERGY_ZONE_FAILED = 'CREATE_AREA_ENERGY_ZONE_FAILED',
  UPDATE_AREA_ENERGY_ZONE_LOADING = 'UPDATE_AREA_ENERGY_ZONE_LOADING',
  UPDATE_AREA_ENERGY_ZONE_SUCCESS = 'UPDATE_AREA_ENERGY_ZONE_SUCCES',
  UPDATE_AREA_ENERGY_ZONE_FAILED = 'UPDATE_AREA_ENERGY_ZONE_FAILED',
  DELETE_AREA_ENERGY_ZONE_LOADING = 'DELETE_AREA_ENERGY_ZONE_LOADING',
  DELETE_AREA_ENERGY_ZONE_SUCCESS = 'DELETE_AREA_ENERGY_ZONE_SUCCES',
  DELETE_AREA_ENERGY_ZONE_FAILED = 'DELETE_AREA_ENERGY_ZONE_FAILED',
}
