export enum ActionTypes {
    SEARCH_USERS_LOADING = "SEARCH_USERS_LOADING",
    SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS",
    SEARCH_USERS_FAILED = "SEARCH_USERS_FAILED",
    GET_USERS_LOADING = "GET_USERS_LOADING",
    GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
    GET_USERS_FAILED = "GET_USERS_FAILED",
    GET_CURRENT_USER_OWNERS_LOADING = "GET_CURRENT_USER_OWNERS_LOADING",
    GET_CURRENT_USER_OWNERS_SUCCESS = "GET_CURRENT_USER_OWNERS_SUCCESS",
    GET_CURRENT_USER_OWNERS_FAILED = "GET_CURRENT_USER_OWNERS_FAILED",
    GET_USER_ROLES_LOADING = "GET_USER_ROLES_LOADING",
    GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS",
    GET_USER_ROLES_FAILED = "GET_USER_ROLES_FAILED",
    GET_USER_BY_ID_LOADING = "GET_USER_BY_ID_LOADING",
    GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS",
    GET_USER_BY_ID_FAILED = "GET_USER_BY_ID_FAILED",
    CREATE_USER_LOADING = "CREATE_USER_LOADING",
    CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
    CREATE_USER_FAILED = "CREATE_USER_FAILED",
    DELETE_USER_LOADING = "DELETE_USER_LOADING",
    DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
    DELETE_USER_FAILED = "DELETE_USER_FAILED",
    UPDATE_USER_LOADING = "UPDATE_USER_LOADING",
    UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILED = "UPDATE_USER_FAILED",
    UPDATE_USER_PASSWORD_LOADING = "UPDATE_USER_PASSWORD_LOADING",
    UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS",
    UPDATE_USER_PASSWORD_FAILED = "UPDATE_USER_PASSWORD_FAILED",
    GET_USER_ATTRIBUTES_LOADING = "GET_USER_ATTRIBUTES_LOADING",
    GET_USER_ATTRIBUTES_SUCCESS = "GET_USER_ATTRIBUTES_SUCCESS",
    GET_USER_ATTRIBUTES_FAILED = "GET_USER_ATTRIBUTES_FAILED",        
    DISABLE_USER_LOADING = "DISABLE_USER_LOADING",
    DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS", 
    DISABLE_USER_FAILED = "DISABLE_USER_FAILED",
}