import React, {useState, useEffect} from 'react'
import {Box, Divider, Grid, Tab} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import OwnerDetails from './OwnerDetails'
import OwnerEstates from './OwnerEstates'
import {Container} from '@mui/material'
import {Outlet, useParams} from 'react-router-dom'
import OwnerSummary from './OwnerSummary'
import {getEstatesByOwnerId} from '../../redux/owner/ownerActions'
import { getMeterTypesAtOwner} from '../../redux/owner/ownerActions'

import {useDispatch, useSelector} from 'react-redux'
import {getOwnerById} from '../../redux/owner/ownerActions'
const Owner = () => {
  const [selectedTab, setSelectedTab] = useState('consumption')
  const {id} = useParams()
  const ownerId: number = id ? parseInt(id) : 0

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOwnerById(ownerId))
    dispatch(getEstatesByOwnerId(ownerId))
    dispatch(getMeterTypesAtOwner(ownerId))
  }, [dispatch, ownerId])

  const onTabChange = (e: any, obj: any) => {
    setSelectedTab(obj)
  }

  return (
    <>
      <Container fixed>
        <Box>
          <TabContext value={selectedTab}>
            <Box>
              <TabList onChange={onTabChange}>
                <Tab label='Förbrukning' value={'consumption'} />
                <Tab label='Redigera' value={'edit'} />
              </TabList>
            </Box>
            <TabPanel value={'consumption'}>
              <OwnerSummary ownerId={ownerId} />
            </TabPanel>
            <TabPanel value='edit'>
              <Grid container>
                <Grid item xs={12} container justifyContent='flex-start' direction='column' spacing={5}>
                  <Grid>
                    <OwnerDetails id={ownerId} />
                  </Grid>
                  <Grid sx={{marginTop: '10px'}}>
                    <OwnerEstates id={ownerId} />
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
      <Outlet />
    </>
  )
}
export default Owner
