import {Cancel, Save} from '@mui/icons-material'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import React, { useEffect } from 'react'
import {Controller, useForm} from 'react-hook-form'
import {ICounterValueResetDialogProps, ICounterValueResetForm} from '../../../../redux/counterValue/interfaces'
import {ICounterValue, ICounterValueListItem} from '../../../../redux/counter/interfaces'
import {ResetType} from '../../../../shared/enums/ResetType'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const CounterValueResetDialog = (props: ICounterValueResetDialogProps) => {
  let defaultResetType = props.counterValueListItem.counterResets.length > 0 ?(props.counterValueListItem.counterResets[0].changed ? ResetType.CHANGED : props.counterValueListItem.counterResets[0].turned ? ResetType.TURNED : ResetType.NOT_SET) : ResetType.NOT_SET
  const {register, control, handleSubmit, reset} = useForm<ICounterValueResetForm>(
    props.counterValueListItem.counterResets.length > 0
      ? {
          defaultValues: {
            counterValueId: props.counterValueListItem.id,
            date: props.counterValueListItem.date,
            lastValue: props.counterValueListItem.counterResets[0].lastValue,
            startValue: props.counterValueListItem.counterResets[0].startValue,
            resetType: defaultResetType,
          },
        }
      : {defaultValues: {counterValueId: 0, date: new Date(), lastValue: 0, startValue: 0 , resetType: ResetType.NOT_SET}}
  )
  useEffect(() => {
    defaultResetType = props.counterValueListItem.counterResets.length > 0 ?(props.counterValueListItem.counterResets[0].changed ? ResetType.CHANGED : props.counterValueListItem.counterResets[0].turned ? ResetType.TURNED : ResetType.NOT_SET) : ResetType.NOT_SET
    const item =   props.counterValueListItem.counterResets.length ? {
      counterValueId: props.counterValueListItem.id,
      date: props.counterValueListItem.date,
      lastValue: props.counterValueListItem.counterResets[0].lastValue,
      startValue: props.counterValueListItem.counterResets[0].startValue,
      resetType: defaultResetType
    } : {counterValueId: 0, date: new Date(), lastValue: 0, startValue: 0 , resetType: ResetType.NOT_SET}

    reset(item)
  }, [props.counterValueListItem])
  const onSubmit = (data: ICounterValueResetForm) => {
    props.onConfirm({...data, counterValueId: props.counterValueListItem?.id})
  }
  return (
    <form>
      <Dialog sx={{style}} open={props.open}>
        <DialogTitle>Mätaråterställning</DialogTitle>
        <DialogContent>
          <Grid>
            <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
              <Grid item xs={6}>
                <TextField
                  id='counterValueId'
                  label='Avläsnings-Id'
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={props.counterValueListItem.id}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='date'
                  label='Datum'
                  type='text'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value={props.counterValueListItem.date}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
              <Grid item xs={6}>
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      id='lastValue'
                      label='Slutvärde'
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                  name='lastValue'
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      id='startValue'
                      label='Startvärde'
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                  name='startValue'
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
              <Grid xs={12} item>
                <FormControl fullWidth>
                  <InputLabel>Välj avvikelse</InputLabel>
                  <Controller
                    render={({field}) => (
                      <Select inputProps={{maxLength: 40}} fullWidth defaultValue={defaultResetType} {...field}>
                        <MenuItem value={ResetType.NOT_SET} key='NOT_SET'>
                          Ej specificerad
                        </MenuItem>
                        <MenuItem value={ResetType.TURNED} key='TURNED'>
                          Slagit runt
                        </MenuItem>
                        <MenuItem value={ResetType.CHANGED} key='CHANGED'>
                          Utbytt
                        </MenuItem>
                      </Select>
                    )}
                    name='resetType'
                    control={control}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} onClick={handleSubmit(onSubmit)}>
            Spara
          </Button>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
export default CounterValueResetDialog
