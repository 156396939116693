import React, {useEffect, useState} from 'react'
import {IEstateMeterCostProps} from './interfaces'
import {Container, Grid} from '@mui/material'
import ResponseAlert from '../../../components/alert/ResponseAlert'
import AddButton from '../../shared/buttons/AddButton'
import {IEstateMeterCost} from '../../../redux/estateMeterCost/interface'
import {SaveResponse} from '../../../shared/enums/SaveResponse'
import {ICurrency, IMeterType} from '../../../redux/meter/interfaces'
import {RootState} from '../../../redux/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import {CURRENCY_SEK, NODE_WRITE, NOT_SET} from '../../../shared/utils/constants'
import {orderBy} from 'lodash'
import EstateMeterCostInputDialog from './estateMeterCostInputDialog/EstateMeterCostInputDialog'
import {IEstateMeterCostInputForm} from './estateMeterCostInputDialog/interface'
import {hasEditPermissions, hasPermissions} from '../../../shared/utils/utilities'
import {createEstateMeterCost, deleteEstateMeterCost, getEstateMeterCosts, updateEstateMeterCost} from '../../../redux/estateMeterCost/actions'
import {getCurrencyByName} from '../../../redux/currency/actions'
import {getMeterTypesAtEstate} from '../../../redux/estate/actions'
import EstateMeterCostTabContext from './EstateMeterCostTabContext'
import {getAllUnits} from '../../../redux/unit/unitActions'
import {IUnit} from '../../../redux/unit/unitInterfaces'
import MessageDialog from '../../../components/shared/alerts/MessageDialog'
import { isOverlapping } from '../../../components/consumption/graph/costEmissionHelper'

const EstateMeterCost = (props: IEstateMeterCostProps) => {
  const dispatch = useDispatch()

  const [selectedRow, setSelectedRow] = useState<IEstateMeterCost | undefined>()
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)
  const [saveResponse, setSaveResponse] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('0')
  const [canEdit, setCanEdit] = useState<boolean>(false)  
  const [alreadyExistsDialogOpen, setAlreadyExistsDialogOpen] = useState<boolean>(false)

  const meterTypes = useSelector((state: RootState) => state.estate.meterTypes ?? [])
  const units = useSelector((State: RootState) => State.unit.units ?? [])
  let costs = useSelector((state: RootState) => {
    const sorted = orderBy(state.estate.estateMeterCosts, ['startDate', 'stopDate'], ['asc'])
    return sorted
  })
  const defaultCurrency: ICurrency | undefined = useSelector((state: RootState) => state.currency.selectedCurrency)
  const savedMeterCost = useSelector((state: RootState) => state.estate.selectedEstateMeterCost)
  const deletedEstateMeterCost = useSelector((state: RootState) => state.estate.deletedEstateMeterCost)
  const selectedEstateMeterCost = useSelector((state: RootState) => state.estate.selectedEstateMeterCost)

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [])

  useEffect(() => {
    if (selectedEstateMeterCost && selectedTab !== '' && parseInt(selectedTab) !== 0) {
      dispatch(getEstateMeterCosts(props.estateId, parseInt(selectedTab)))
    }
  }, [selectedEstateMeterCost])

  useEffect(() => {
    if (deletedEstateMeterCost > 0 && selectedTab !== '' && parseInt(selectedTab) !== 0) {
      dispatch(getEstateMeterCosts(props.estateId, parseInt(selectedTab)))
    }
  }, [deletedEstateMeterCost])

  useEffect(() => {
    dispatch(getCurrencyByName(CURRENCY_SEK))
    dispatch(getMeterTypesAtEstate(props.estateId))
    if (units.length === 0) {
      dispatch(getAllUnits())
    }
  }, [props.estateId])

  useEffect(() => {
    if ((!selectedTab || selectedTab === '0') && meterTypes?.length > 0 && meterTypes?.length > 0) {
      setSelectedTab(meterTypes[0].id.toString())
    }
  }, [meterTypes])

  useEffect(() => {
    if (selectedTab !== '' && parseInt(selectedTab) !== 0) dispatch(getEstateMeterCosts(props.estateId, parseInt(selectedTab)))
  }, [props.estateId, selectedTab])

  const onHandleSaveNewMeterCostClick = (event: React.MouseEventHandler<HTMLButtonElement>) => {
    setSelectedRow(undefined)
    setSaveDialogOpen(true)
  }

  const onEstateMeterCostEditClick = (cost: IEstateMeterCost) => {
    setSelectedRow(cost)
    setSaveDialogOpen(true)
  }

  const onEstateMeterCostDeleteClick = (cost: IEstateMeterCost) => {
    dispatch(deleteEstateMeterCost(cost.id))
  }

  const onValueConfirmClick = (data: IEstateMeterCostInputForm) => {
    if (!canEdit) return
     
    if(isOverlapping(data.id, data.startDate, data.stopDate, costs)) {      
      setAlreadyExistsDialogOpen(true)
      return
    }

    if (data.id > 0) {
      dispatch(updateEstateMeterCost(data.id, props.estateId, parseInt(selectedTab), data.currencyId, data.cost, data.startDate, data.stopDate))
    } else {
      dispatch(createEstateMeterCost(props.estateId, parseInt(selectedTab), defaultCurrency ? defaultCurrency.id : 0, data.cost, data.startDate, data.stopDate))
    }
    setSaveDialogOpen(false)
    setSaveResponse(SaveResponse.PENDING)
  }

  const renderInputDialog = () => {
    if (defaultCurrency) {
      const currentMeter = meterTypes.find((x: IMeterType) => x.id === parseInt(selectedTab))
      const unit: IUnit | undefined = units.find((x: IUnit) => x.id === currentMeter?.unitId)
      return (
        <EstateMeterCostInputDialog
          defaultCurrency={defaultCurrency}
          open={saveDialogOpen}
          item={selectedRow}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={(e: IEstateMeterCostInputForm) => onValueConfirmClick(e)}
          unit={unit}
        />
      )
    }
    return ''
  }

  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <ResponseAlert
            response={saveResponse}
            open={alertOpen}
            successMessage={`Prisuppgiften ${savedMeterCost ? savedMeterCost.cost : NOT_SET} registerades.`}
            failureMessage='Det gick inte att registera prisuppgiften.'
          />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <AddButton onClick={onHandleSaveNewMeterCostClick} id='addMeterCost' label='Ny prisuppgift' isAuthorized={canEdit} />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <EstateMeterCostTabContext
            estateId={props.estateId}
            meterTypes={meterTypes}
            units={units}
            estateMeterCosts={costs}
            onEstateMeterCostEditClick={onEstateMeterCostEditClick}
            onEstateMeterCostDeleteClick={onEstateMeterCostDeleteClick}
            onTabChange={(value: string) => setSelectedTab(value)}
          />
        </Grid>
      </Grid>
      {renderInputDialog()}
      <MessageDialog open={alreadyExistsDialogOpen} setOpen={(value) => setAlreadyExistsDialogOpen(value)} title='Prisuppgift finns redan' message='Det finns redan en prisuppgift registrerad för denna fastighet inom valt tidsspann.' />
    </Container>
  )
}
export default EstateMeterCost
