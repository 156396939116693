import { IEnergyZone } from "../redux/energyzone/interfaces";
import saveupClient, { handleResponse } from "../clients/saveupClient";
async function getEnergyZones() {
    const zones = await saveupClient.get<IEnergyZone[]>(`energyzone`).then(handleResponse)
    return zones
}

const energyZoneService = {
    getEnergyZones
  }
  
  export default energyZoneService
  