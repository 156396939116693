import {Dispatch} from 'redux'
import areaService from '../../services/areaService'
import {ActionTypes} from './types'
import Area from './Interfaces.ts'
import {orderBy} from 'lodash'

export const getAllArea = () => {
  return async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.FETCH_AREAS_LOADING})
    try {
      let data: Area[] = await areaService.getAreas()
      data = orderBy(data, ['name'], ['asc'])
      return dispatch({type: ActionTypes.FETCH_AREAS_SUCCESS, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_AREAS_FAILED, payload: error})
    }
  }
}
export const getAreaById = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_AREA_LOADING})
      const area = await areaService.getAreaById(id)
      return dispatch({type: ActionTypes.FETCH_AREA_SUCCESS, payload: area})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_AREA_FAILED, payload: error})
    }
  }
}

export const deleteArea = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.DELETE_AREA_LOADING})
      const res = await areaService.deleteArea(id)
      return dispatch({type: ActionTypes.DELETE_AREA_SUCCESS, payload: res})
    } catch (error) {
      return dispatch({type: ActionTypes.DELETE_AREA_FAILED, payload: error})
    }
  }
}

export const createArea = (number: number, name: string, energyZoneId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.CREATE_AREA_LOADING})
    const area = await areaService.createArea(number, name)    
    if(area?.id > 0 && energyZoneId > 0) {
      const areaEnergyZone = await areaService.createAreaEnergyZone(area.id, energyZoneId)
    }
    return dispatch({type: ActionTypes.CREATE_AREA_SUCCESS, payload: area})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_AREA_FAILED, payload: error})
  }
}

export const editArea = (data: Area) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.EDIT_AREA_LOADING})
      const area = await areaService.editArea(data)
      return dispatch({type: ActionTypes.EDIT_AREA_SUCCESS, payload: area})
    } catch (error) {
      return dispatch({type: ActionTypes.EDIT_AREA_FAILED, payload: error})
    }
  }
}

export const getEnergyZoneForArea = (areaId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_AREA_ENERGY_ZONE_LOADING})
  try {
    const energyZone = await areaService.getEnergyZone(areaId)
    return dispatch({type: ActionTypes.FETCH_AREA_ENERGY_ZONE_SUCCESS, payload: energyZone})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_AREA_ENERGY_ZONE_FAILED, payload: error})
  }
}

export const createAreaEnergyZone = (areaId: number, energyZoneId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_AREA_ENERGY_ZONE_LOADING})
  try {
    const areaEnergyZone = await areaService.createAreaEnergyZone(areaId, energyZoneId)
    const energyZoneById = await areaService.getEnergyZone(areaEnergyZone.areaId)
    return dispatch({type: ActionTypes.CREATE_AREA_ENERGY_ZONE_SUCCESS, payload: energyZoneById})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_AREA_ENERGY_ZONE_FAILED, payload: error})
  }
}
export const updateAreaEnergyZone = (areaId: number, energyZoneId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_AREA_ENERGY_ZONE_LOADING})
  try {
    const areaEnergyZone = await areaService.updateAreaEnergyZone(areaId, energyZoneId)
    const energyZoneById = await areaService.getEnergyZone(areaEnergyZone.areaId)
    return dispatch({type: ActionTypes.UPDATE_AREA_ENERGY_ZONE_SUCCESS, payload: energyZoneById})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_AREA_ENERGY_ZONE_FAILED, payload: error})
  }
}
export const deleteAreaEnergyZone = (areaId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_AREA_ENERGY_ZONE_LOADING})
  try {
    await areaService.deleteAreaEnergyZone(areaId)
    return dispatch({type: ActionTypes.DELETE_AREA_ENERGY_ZONE_SUCCESS, payload: areaId})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_AREA_ENERGY_ZONE_FAILED, payload: error})
  }
}
