import React, {useEffect, useState} from 'react'
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {UncontrolledDropdownProps, IAttributeControlValue} from './interfaces'
const UncontrolledDropdown = (props: UncontrolledDropdownProps) => {
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    props.onChange(value)
  }, [value])

  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select inputProps={{maxLength: 40}} label={props.label} value={value} onChange={(e: any) => setValue(e.target.value)} disabled={props.disabled ?? false}>
        {props.values.map((value: any, index: number) => (
          <MenuItem value={value} key={index}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
export default UncontrolledDropdown
