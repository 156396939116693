import {IPDFState} from './interfaces'
import {Reducer} from 'redux'
import {ActionTypes} from './types'
import {selectedGridRowsCountSelector} from '@mui/x-data-grid'
export const initialState: IPDFState = {
  isLoading: false,
  errorMessage: undefined,
  pdfData: [],
  pdfTableStates: [],
}
const pdfReducer: Reducer<IPDFState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_PDF_CONSUMPTION_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_PDF_CONSUMPTION_SUCCESS:
      return {...state, isLoading: false, pdfData: payload}
    case ActionTypes.FETCH_PDF_CONSUMPTION_ERROR:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.PDF_FILE_CREATE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.PDF_FILE_CREATE_SUCCESS:
      return {...state, pdfData: undefined, isLoading: false}
    case ActionTypes.PDF_FILE_CREATE_ERROR:
      return {...state, pdfData: undefined, isLoading: false, errorMessage: payload}
    case ActionTypes.PDF_TABLE_STATE_CHANGE:
      return {...state, pdfTableStates: [...state.pdfTableStates, payload]}
    case ActionTypes.PDF_TABLE_STATE_CHANGE:
      return {...state, pdfTableStates: []}    
    default:
      return state
  }
}
export default pdfReducer
