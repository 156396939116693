import SaveAlert from '../shared/alerts/SaveAlert'
import LabeledSelect from '../shared/Inputs/LabeledSelect'
import SecButton from '../shared/Inputs/SecButton'
import {Cancel, Save} from '@mui/icons-material'
import {Button, Card, CardContent, CardHeader, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField} from '@mui/material'
import {createCounter} from '../../redux/counter/actions'
import {RootState} from '../../redux/rootReducer'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router'
import {Link} from 'react-router-dom'
interface ICreateCounterForm {
  measurePointId: number
  meterId: number
  name: string
  constant: number
  hidden: boolean
  counterNumber: string
}
const defaultCreateCounterForm = {
  measurePointId: 0,
  meterId: 0,
  name: '',
  constant: 1,
  hidden: false,
  counterNumber: '',
}

const CreateCounter = () => {
  const {id} = useParams()
  const measurePointId = id ? parseInt(id) : 0

  useEffect(() => {
    reset({
      measurePointId: measurePointId,
      meterId: 0,
      name: '',
      constant: 1,
      hidden: false,
      counterNumber: '',
    })
  }, [measurePointId])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [saveResult, setSaveResult] = useState<SaveResponse>(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const {control, handleSubmit, reset} = useForm<ICreateCounterForm>({defaultValues: defaultCreateCounterForm})
  const availableTypes = useSelector((state: RootState) => state.filter.types)
  const onSaveCounterClick = (form: ICreateCounterForm) => {
    dispatch(createCounter(measurePointId, form.meterId, form.name, form.counterNumber, form.constant, false)) //form.hidden))
    setSaveResult(SaveResponse.SUCCESS)
    setAlertOpen(true)
    navigate(`/measurepoint/${measurePointId}`)
  }

  const renderMeterTypeSelect = () => {
    return (
      <FormControl fullWidth>
        <InputLabel>Mätartyp</InputLabel>
        <Controller
          name='meterId'
          control={control}
          defaultValue={0}
          render={({field}) => (
            <Select fullWidth {...field}>
              <MenuItem value={0}>--- Välj mätartyp ---</MenuItem>
              {availableTypes.map((meterType, index) => (
                <MenuItem key={index} value={meterType.id}>
                  {meterType.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    )
  }

  return (
    <div>
      <Container maxWidth='lg'>
        <Card sx={{minWidth: 275}}>
          <CardHeader title='Ny mätpunkt' />
          <CardContent>
            <Grid>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                <Grid item xs={12}>
                  <SaveAlert
                    result={saveResult}
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    successMessage='Räkneverket har skapats.'
                    errorMessage='Misslyckades med att skapa räkneverket.'
                  />
                </Grid>
              </Grid>
              <Grid container direction='row' spacing={2} alignItems='stretch'>
                <Grid item xs={2}>
                  <Controller name='name' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Namn' fullWidth InputLabelProps={{shrink: true}} />} />
                </Grid>
                <Grid item xs={2}>
                  <Controller name='counterNumber' control={control} render={({field}) => <TextField {...field} id='outlined-required' label='Nummer' fullWidth InputLabelProps={{shrink: true}} />} />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name='constant'
                    control={control}
                    render={({field}) => <TextField {...field} type='number' required id='outlined-required' label='Multipliceras med' InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  {renderMeterTypeSelect()}
                </Grid>
                {/* <Grid item xs={3}>
                  <Controller
                    name='hidden'
                    control={control}
                    render={({field}) => <FormControlLabel sx={{marginTop: '10px'}} control={<Switch {...field} title='Avläses ej' />} label='Avläses ej' />}
                  />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid sx={{marginTop: '10px'}}>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={2}>
                    <SecButton sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<Save fontSize='small' />} onClick={handleSubmit(onSaveCounterClick)}>
                      Spara
                    </SecButton>
                    <Link to={`/measurepoint/${measurePointId}`}>
                      <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<Cancel fontSize='small' />}>
                        Avbryt
                      </Button>
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
export default CreateCounter
