import React from 'react'
import {IAxesLabels, IConsumptionTableCardHeaderTitleProps} from './table/interfaces'
import {CONSUMPTION, COST, EMISSION, READING, ATEMP, LOA} from '../../shared/utils/constants'
import { getUnitByValueCategory, getUnitDividedByAreaLabel } from '../../shared/utils/utilities'
const axesLabels = {
  'CONSUMPTION': 'Förbrukning',
  'READING': 'Avläsning',
  'COST': 'Kostnad',
  'EMISSION': 'Utsläpp',
  'ATEMP' : 'ATemp',
  'LOA' : 'LOA',
} as IAxesLabels

const ConsumptionTitle = (props: IConsumptionTableCardHeaderTitleProps) => {
  const getDesc = () => {
    if (!props.category) return ''
    switch (props.category) {
      case CONSUMPTION:
        return props.meter?.isConsumption ? axesLabels[CONSUMPTION] : axesLabels[READING]
      case COST:
        return axesLabels[COST]
      case EMISSION:
        return axesLabels[EMISSION]
      case READING:
        return axesLabels[READING]
      case ATEMP:
        return axesLabels[ATEMP]
      case LOA:
        return axesLabels[LOA]
      default:
        return ''
    }
  }

  const getUnit = () => {
    if ((props.category === CONSUMPTION || props.category === READING) && props.meter && props.unit) return props.unit.comment
    else if (props.category === COST && props.currency) return props.currency.name
    else if (props.category === EMISSION && props.emission) return props.emission.name === 'KG CO2' ? 'KG CO2e' : props.emission.name
    else if (props.category === ATEMP || props.category === LOA) return `${getUnitDividedByAreaLabel(props.unit?.comment, props.category)}`
    else return ''
  }
  return <React.Fragment>{`${getDesc()} (${getUnit()})`}</React.Fragment>
}
export default ConsumptionTitle
