
import { Typography } from '@mui/material';
import { DataGrid, GridRowsProp } from '@mui/x-data-grid';
import { getEstateAddresses } from '../../../redux/estate/actions';
import IAddress from '../../../redux/address/interfaces';
import { IAddressListProps } from './interfaces';
import { RootState } from '../../../redux/rootReducer';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
const AddressList = (props: IAddressListProps) => {
  const dispatch = useDispatch()
    useEffect(() => {
      if(props.estateId > 0) {
        dispatch(getEstateAddresses(props.estateId))
      }
    }, [props.estateId])

    const addresses: IAddress[] = useSelector((state: RootState) => state.estate.addresses)
    const columns = [
        { field: "id", headerName: "Id", flex: 1 },
        { field: "name", headerName: "Namn", flex: 1 },
        { field: "street", headerName: "Gata", flex: 1 },        
        { field: "number", headerName: "Nummer", flex: 1 },        
        { field: "city", headerName: "Stad", flex: 1 },                
        { field: "postalCode", headerName: "Postkod", flex: 1 },                
      ];

    
    const renderGrid = () => {
      
        if(addresses === undefined || addresses.length === 0)
        return <Typography variant='h5'>Inga adresser kunde hittas för vald fastighet!</Typography>
    
        const rows: GridRowsProp = addresses

        return (
        <DataGrid style={{ height: 400, width: "100%" }}
          rows={rows}
          columns={columns}
          pageSize={13}
          rowsPerPageOptions={[13]}          
          />
        )
        }
    return (
        <div>
             {renderGrid()}
        </div>
    )
}
export default AddressList