import {IConsumptionWithLog, ITableLog} from './interfaces'

export class ConsumptionWithLog implements IConsumptionWithLog {
  private _multiplier: number = 1
  private _aTemp: number
  private _loa: number

  private _estimateATemp: number
  private _estimateLoa: number

  id: number
  type: string
  year: number
  month: number
  value: number
  cost: number
  emissionValue: number
  alert: boolean
  message: string
  logs: ITableLog[]
  estimatedValue: number
  estimatedCost: number
  estimatedEmissionValue: number

  constructor(consumption: IConsumptionWithLog, multiplier?: number) {
    this.id = consumption.id
    this.type = consumption.type
    this.year = consumption.year
    this.month = consumption.month
    this.value = consumption.value
    this.cost = consumption.cost
    this.emissionValue = consumption.emissionValue
    this.alert = consumption.alert
    this.message = consumption.message
    this.logs = consumption.logs
    this._aTemp = consumption.aTemp
    this._loa = consumption.loa
    this.estimatedValue = consumption.estimatedValue
    this.estimatedCost = consumption.estimatedCost
    this._estimateATemp = consumption.estimatedATemp
    this._estimateLoa = consumption.estimatedLOA
    this.estimatedEmissionValue = consumption.estimatedEmissionValue
    if(multiplier) this._multiplier = multiplier
  }

  get loa(): number {
    return this._loa
  }

  get aTemp(): number {
    return this._aTemp
  }

  get loaMultiplied(): number {
    return this._loa * this._multiplier
  }

  get aTempMultiplied(): number {
    return this._aTemp * this._multiplier
  }

  get estimatedATemp(): number {
    return this._estimateATemp
  }

  get estimatedLOA(): number {
    return this._estimateLoa
  }

  get estimatedATempMultiplied(): number {
    return this._estimateATemp * this._multiplier
  }

  get estimatedLOAMultiplied(): number {
    return this._estimateLoa * this._multiplier
  }

  get multiplier() :number {
    return this._multiplier
  }
}
export default ConsumptionWithLog