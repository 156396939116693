import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {useEffect, useState} from 'react'
import {Controller, FieldValues, DeepPartial, useForm, Control, useFormContext} from 'react-hook-form'
import { EditUserForm } from '../../../redux/user/interfaces'

type ArbitraryFormValues = {
  fieldName: string
}

type ControlledCheckDropwDownProps = {
  label: string
  items: any[]
  name: string
  control: Control<any, any>
  defaultValue?: string
}

const ControlledCheckDropDown = (props: ControlledCheckDropwDownProps) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const { register, handleSubmit, watch } = useFormContext<EditUserForm>()

  const watchedValues = watch('owners')

  useEffect(() => { 
    if(watchedValues) {
      setSelectedItems(watchedValues)
    }
  }, [])


  const handleChange = (event: any) => {
    console.log(event.target)
    setSelectedItems(event.target.value)
  }

  const renderSelectedValues = (selected: any) => { 
    if(selected) {
      const labels = props.items.filter((x: any) => selected.includes(x.value)).map((x: any) => x.text)
      return labels.join(', ')
    }
  }

  return (
    <FormControl>
      <InputLabel>{props.label}</InputLabel>
      <Controller
        render={({field}) => (
          <Select {...register('owners')} multiple value={selectedItems} onChange={handleChange} renderValue={renderSelectedValues} sx={{width: '300px'}}>
            {props.items.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <Checkbox checked={selectedItems.includes(item.value)} />
                <ListItemText primary={item.text} />
              </MenuItem>
            ))}
          </Select>
        )}
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue}
      />
    </FormControl>
  )
}

export default ControlledCheckDropDown
