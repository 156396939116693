import { IEstateMeterCost } from "../redux/estateMeterCost/interface"
import saveupClient, { handleResponse } from "../clients/saveupClient"

async function getEstateMeterCosts(estateId: number, meterId: number)  {
    return await saveupClient.get<IEstateMeterCost[]>(`estate/${estateId}/meter/${meterId}/cost`).then(handleResponse)    
}
async function createEstateMeterCost(estateId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
    return await saveupClient.post<IEstateMeterCost>(`estate/meter/cost`, {estateId, meterId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
}
async function updateEstateMeterCost(id: number, estateId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
    return await saveupClient.put<IEstateMeterCost>(`estate/meter/cost`, {id, estateId, meterId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
} 
async function deleteEstateMeterCost(id: number) {
    return await saveupClient.delete(`estate/meter/cost/${id}`).then(handleResponse)
} 
const estateMeterCostService = {
    getEstateMeterCosts,
    createEstateMeterCost,
    updateEstateMeterCost,
    deleteEstateMeterCost
}
  
  export default estateMeterCostService
  