import meterTypeService from '../../services/meterTypeService'
import {ActionTypes} from './types'
import {Dispatch} from 'redux'
import {IMeterCostCurrency, IMeterEmission} from './interfaces'
import {DEFAULT_SWE} from '../../shared/language/swe'
import {IDeviationType, IDeviationValueType} from '../counter/interfaces'

export const getMeterTypeById = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.GET_METER_TYPE_LOADING})
    const meterType = await meterTypeService.getMeterType(id)
    return dispatch({type: ActionTypes.GET_METER_TYPE_SUCCESS, payload: meterType})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_METER_TYPE_FAILED, payload: error})
  }
}
export const getDeviationTypeForMeter = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_METER_DEVIATION_TYPE_LOADING})
  try {
    let deviationType : IDeviationType = await meterTypeService.getDeviationTypeForMeter(id)
    if (!deviationType) {
      const deviationTypes = await meterTypeService.getDeviationTypes()
      deviationType = deviationTypes.find((x: IDeviationType) => x.name.toUpperCase() === DEFAULT_SWE.toUpperCase())
    }

    let deviationValueTypes : IDeviationValueType[]= await meterTypeService.getDeviationValueTypes()
    let deviationValueTypeIds: number[] = deviationType.deviationTypeBindings.map(x => x.deviationValueTypeId)
    let deviationValueTypesForMeter = deviationValueTypes.filter((valueType: IDeviationValueType) => deviationValueTypeIds.some(id => valueType.id === id))
    return dispatch({type: ActionTypes.GET_METER_DEVIATION_TYPE_SUCCESS, payload: {...deviationType, valueTypes: deviationValueTypesForMeter }})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_METER_DEVIATION_TYPE_FAILED, payload: error})
  }
}
export const getDeviationTypes = () => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.GET_DEVIATION_TYPES_LOADING})
    const deviationTypes = await meterTypeService.getDeviationTypes()
    return dispatch({type: ActionTypes.GET_DEVIATION_TYPES_SUCCESS, payload: deviationTypes})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_DEVIATION_TYPES_FAILED, payload: error})
  }
}
export const getDeviationValueTypes = () => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.GET_DEVIATION_VALUE_TYPES_LOADING})
    const deviationValueTypes = await meterTypeService.getDeviationValueTypes()
    return dispatch({type: ActionTypes.GET_DEVIATION_VALUE_TYPES_SUCCESS, payload: deviationValueTypes})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_DEVIATION_VALUE_TYPES_FAILED, payload: error})
  }
}
export const deleteMeterType = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.DELETE_METER_TYPE_LOADING})
    const response = await meterTypeService.deleteMeterType(id)
    return dispatch({type: ActionTypes.DELETE_METER_TYPE_SUCCESS, payload: response})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_METER_TYPE_FAILED, payload: error})
  }
}
export const updateMeterType =
  (id: number, name: string, maxValue: number, multiple: number, unitId: number, decimals: number, isConsumption: boolean, deviationTypeId: number) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.UPDATE_METER_TYPE_LOADING})
      const updatedMeterType = await meterTypeService.updateMeterType(id, name, maxValue, multiple, unitId, decimals, isConsumption)
      const deviationType = await meterTypeService.updateDeviationType(id, deviationTypeId)
      return dispatch({type: ActionTypes.UPDATE_METER_TYPE_SUCCESS, payload: {...updatedMeterType, deviationTypeId: deviationType.id}})
    } catch (error) {
      return dispatch({type: ActionTypes.UPDATE_METER_TYPE_FAILED, payload: error})
    }
  }
export const getMeterCosts = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_METER_COST_LOADING})
  try {
    const meterCosts: IMeterCostCurrency[] = await meterTypeService.getMeterCosts(id)
    return dispatch({type: ActionTypes.GET_METER_COST_SUCCESS, payload: meterCosts})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_METER_COST_FAILED, payload: error})
  }
}
export const createMeterCost = (meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_METER_COST_LOADING})
  try {
    const createdMeterCost: IMeterCostCurrency = await meterTypeService.createMeterCost(meterId, currencyId, cost, startDate, stopDate)
    return dispatch({type: ActionTypes.CREATE_METER_COST_SUCCESS, payload: createdMeterCost})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_METER_COST_FAILED, payload: error})
  }
}
export const updateMeterCost = (id: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_METER_COST_LOADING})
  try {
    const updatedMeterCost: IMeterCostCurrency = await meterTypeService.updateMeterCost(id, meterId, currencyId, cost, startDate, stopDate)
    return dispatch({type: ActionTypes.UPDATE_METER_COST_SUCCESS, payload: updatedMeterCost})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_METER_COST_FAILED, payload: error})
  }
}
export const deleteMeterCost = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_METER_COST_LOADING})
  try {
    await meterTypeService.deleteMeterCost(id)
    return dispatch({type: ActionTypes.DELETE_METER_COST_SUCCESS, payload: id})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_METER_COST_FAILED, payload: error})
  }
}
export const getEnergyZoneMeterCosts = (meterId: number, energyZoneId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_METER_COST_LOADING})
  try {
    const meterCosts: IMeterCostCurrency[] = await meterTypeService.getEnergyZoneMeterCosts(meterId, energyZoneId)
    return dispatch({type: ActionTypes.GET_METER_COST_SUCCESS, payload: meterCosts})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_METER_COST_FAILED, payload: error})
  }
}
export const createEnergyZoneMeterCost = (meterId: number, energyZoneId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_METER_COST_LOADING})
  try {
    const createdMeterCost: IMeterCostCurrency = await meterTypeService.createEnergyZoneMeterCost(meterId, energyZoneId, currencyId, cost, startDate, stopDate)
    return dispatch({type: ActionTypes.CREATE_METER_COST_SUCCESS, payload: createdMeterCost})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_METER_COST_FAILED, payload: error})
  }
}
export const updateEnergyZoneMeterCost = (id: number, meterId: number, energyZoneId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_METER_COST_LOADING})
  try {        
    const updatedMeterCost: IMeterCostCurrency = await meterTypeService.updateEnergyZoneMeterCost(id, meterId, energyZoneId, currencyId, cost, startDate, stopDate)
    return dispatch({type: ActionTypes.UPDATE_METER_COST_SUCCESS, payload: updatedMeterCost})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_METER_COST_FAILED, payload: error})
  }
}
export const deleteEnergyZoneMeterCost = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_METER_COST_LOADING})
  try {
    await meterTypeService.deleteEnergyZoneMeterCost(id)
    return dispatch({type: ActionTypes.DELETE_METER_COST_SUCCESS, payload: id})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_METER_COST_FAILED, payload: error})
  }
}
export const getMeterEmissions = (meterId: number, emissionId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_METER_EMISSION_LOADING})
  try {
    const meterEmissions: IMeterEmission[] = await meterTypeService.getMeterEmissionsByEmissionId(meterId, emissionId)
    return dispatch({type: ActionTypes.GET_METER_EMISSION_SUCCESS, payload: meterEmissions})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_METER_EMISSION_FAILED, payload: error})
  }
}
export const createMeterEmission = (meterId: number, emissionId: number, value: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_METER_EMISSION_LOADING})
  try {
    const createdMeterEmission: IMeterEmission = await meterTypeService.createMeterEmission(meterId, emissionId, value, startDate, stopDate)
    return dispatch({type: ActionTypes.CREATE_METER_EMISSION_SUCCESS, payload: createdMeterEmission})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_METER_EMISSION_FAILED, payload: error})
  }
}
export const updateMeterEmission = (id: number, meterId: number, emissionId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_METER_EMISSION_LOADING})
  try {        
    const updatedMeterEmission: IMeterEmission = await meterTypeService.updateMeterEmission(id, meterId, emissionId, cost, startDate, stopDate)
    return dispatch({type: ActionTypes.UPDATE_METER_EMISSION_SUCCESS, payload: updatedMeterEmission})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_METER_EMISSION_FAILED, payload: error})
  }
}
export const deleteMeterEmission = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_METER_EMISSION_LOADING})
  try {
    await meterTypeService.deleteMeterEmission(id)
    return dispatch({type: ActionTypes.DELETE_METER_EMISSION_SUCCESS, payload: id})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_METER_EMISSION_FAILED, payload: error})
  }
}