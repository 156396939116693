import {useState, createContext, FC, ReactNode} from 'react'
import {CssBaseline, ThemeProvider} from '@mui/material'
import {themeCreator} from './base'
import {StylesProvider} from '@mui/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import svLocale from 'date-fns/locale/sv'
type Props = {children: ReactNode}
type ThemeContext = {themeName: string; setThemeName: (themeName: string) => void}

export const ThemeContext = createContext<ThemeContext>({} as ThemeContext)

const ThemeProviderWrapper: FC<Props> = ({children}): JSX.Element => {
  const curThemeName = localStorage.getItem('Theme') || 'DefaultsTheme'
  const [themeName, _setThemeName] = useState(curThemeName)
  const theme = themeCreator(themeName)
  // tslint:disable-next-line: no-shadowed-variable
  const setThemeName = (themeName: string): void => {
    localStorage.setItem('Theme', themeName)
    _setThemeName(themeName)
  }
  const serverTimezone = "Europe/Stockholm"
  const adapter = new AdapterDateFns()
  return (
    <StylesProvider injectFirst={true}>
      <ThemeContext.Provider value={{themeName, setThemeName}}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={svLocale}>{children}</LocalizationProvider>
          <CssBaseline />
        </ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  )
}

export default ThemeProviderWrapper
