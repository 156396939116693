import { OfficeMeterEmission } from "../redux/officeMeterEmission/interfaces"
import saveupClient, { handleResponse } from "../clients/saveupClient"

async function getOfficeMeterEmissions(officeId: number, meterId: number)  {
    return await saveupClient.get<OfficeMeterEmission[]>(`office/${officeId}/meter/${meterId}/emission`).then(handleResponse)    
}
async function createOfficeMeterEmission(officeId: number, meterId: number, currencyId: number, value: number, startDate: Date, stopDate: Date) {
    return await saveupClient.post<OfficeMeterEmission>(`office/meter/emission`, {officeId, meterId, currencyId, value, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
}
async function updateOfficeMeterEmission(id: number, officeId: number, meterId: number, currencyId: number, value: number, startDate: Date, stopDate: Date) {
    return await saveupClient.put<OfficeMeterEmission>(`office/meter/emission`, {id, officeId, meterId, currencyId, value, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
} 
async function deleteOfficeMeterEmission(id: number) {
    return await saveupClient.delete(`office/meter/emission/${id}`).then(handleResponse)
} 
const OfficeMeterEmissionService = {
    getOfficeMeterEmissions,
    createOfficeMeterEmission,
    updateOfficeMeterEmission,
    deleteOfficeMeterEmission
}
  
  export default OfficeMeterEmissionService
  