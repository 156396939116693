export enum ActionTypes {
  FETCH_COUNTER_METER_TYPE_LOADING = 'FETCH_COUNTER_METER_TYPE_LOADING',
  FETCH_COUNTER_METER_TYPE_SUCCESS = 'FETCH_COUNTER_METER_TYPE_SUCCES',
  FETCH_COUNTER_METER_TYPE_FAILED = 'FETCH_COUNTER_METER_TYPE_FAILED',
  FETCH_COUNTER_LOADING = 'FETCH_COUNTER_LOADING',
  FETCH_COUNTER_SUCCESS = 'FETCH_COUNTER_SUCCES',
  FETCH_COUNTER_FAILED = 'FETCH_COUNTER_FAILED',
  CREATE_COUNTER_LOADING = 'CREATE_COUNTER_LOADING',
  CREATE_COUNTER_SUCCESS = 'CREATE_COUNTER_SUCCES',
  CREATE_COUNTER_FAILED = 'CREATE_COUNTER_FAILED',
  UPDATE_COUNTER_LOADING = 'UPDATE_COUNTER_LOADING',
  UPDATE_COUNTER_SUCCESS = 'UPDATE_COUNTER_SUCCESS',
  UPDATE_COUNTER_FAILED = 'UPDATE_COUNTER_FAILED',
  DELETE_COUNTER_LOADING = 'DELETE_COUNTER_LOADING',
  DELETE_COUNTER_SUCCESS = 'DELETE_COUNTER_SUCCESS',
  DELETE_COUNTER_FAILED = 'DELETE_COUNTER_FAILED',
  FETCH_COUNTER_NUMBERS_LOADING = 'FETCH_COUNTER_NUMBERS_LOADING',
  FETCH_COUNTER_NUMBERS_SUCCESS = 'FETCH_COUNTER_NUMBERS_SUCCES',
  FETCH_COUNTER_NUMBERS_FAILED = 'FETCH_COUNTER_NUMBERS_FAILED',
  FETCH_COUNTER_IS_EXCLUDED_LOADING = 'FETCH_COUNTER_IS_EXCLUDED_LOADING',
  FETCH_COUNTER_IS_EXCLUDED_SUCCESS = 'FETCH_COUNTER_IS_EXCLUDED_SUCCES',
  FETCH_COUNTER_IS_EXCLUDED_FAILED = 'FETCH_COUNTER_IS_EXCLUDED_FAILED',
  FETCH_COUNTER_IS_MULTI_VALUE_LOADING = 'FETCH_COUNTER_IS_MULTI_VALUE_LOADING',
  FETCH_COUNTER_IS_MULTI_VALUE_SUCCESS = 'FETCH_COUNTER_IS_MULTI_VALUE_SUCCES',
  FETCH_COUNTER_IS_MULTI_VALUE_FAILED = 'FETCH_COUNTER_IS_MULTI_VALUE_FAILED',
  FETCH_COUNTER_CONSUMPTION_LOADING = 'FETCH_COUNTER_CONSUMPTION_LOADING',
  FETCH_COUNTER_CONSUMPTION_SUCCESS = 'FETCH_COUNTER_CONSUMPTION_SUCCES',
  FETCH_COUNTER_CONSUMPTION_FAILED = 'FETCH_COUNTER_CONSUMPTION_FAILED',
  FETCH_COUNTER_CONSUMPTION_COST_EMISSION_LOADING = 'FETCH_COUNTER_CONSUMPTION_COST_EMISSION_LOADING',
  FETCH_COUNTER_CONSUMPTION_COST_EMISSION_SUCCESS = 'FETCH_COUNTER_CONSUMPTION_COST_EMISSION_SUCCES',
  FETCH_COUNTER_CONSUMPTION_COST_EMISSION_FAILED = 'FETCH_COUNTER_CONSUMPTION_COST_EMISSION_FAILED',
  CREATE_COUNTER_DEVIATION_LOADING = 'CREATE_COUNTER_DEVIATION_LOADING',
  CREATE_COUNTER_DEVIATION_SUCCESS = 'CREATE_COUNTER_DEVIATION_SUCCESS',
  CREATE_COUNTER_DEVIATION_ERROR = 'CREATE_COUNTER_DEVIATION_ERROR',
  UPDATE_COUNTER_DEVIATION_LOADING = 'UPDATE_COUNTER_DEVIATION_LOADING',
  UPDATE_COUNTER_DEVIATION_SUCCESS = 'UPDATE_COUNTER_DEVIATION_SUCCESS',
  UPDATE_COUNTER_DEVIATION_ERROR = 'UPDATE_COUNTER_DEVIATION_ERROR',
  FETCH_COUNTER_INTEGRATIONS_LOADING = 'FETCH_COUNTER_INTEGRATIONS_LOADING',
  FETCH_COUNTER_INTEGRATIONS_SUCCESS = 'FETCH_COUNTER_INTEGRATIONS_SUCCESS',
  FETCH_COUNTER_INTEGRATIONS_ERROR = 'FETCH_COUNTER_INTEGRATIONS_ERROR',
  COUNTER_READINGS_LOADING = 'COUNTER_READINGS_LOADING',
  COUNTER_READINGS_SUCCESS = 'COUNTER_READINGS_SUCCESS',
  COUNTER_READINGS_ERROR = 'COUNTER_READINGS_ERROR',
  SAVE_COUNTER_VALUE_LOADING = 'SAVE_COUNTER_VALUE_LOADING',
  SAVE_COUNTER_VALUE_SUCCESS = 'SAVE_COUNTER_VALUE_SUCCESS',
  SAVE_COUNTER_VALUE_FAILED = 'SAVE_COUNTER_VALUE_FAILED',
  DELETE_COUNTER_VALUE_LOADING = 'DELETE_COUNTER_VALUE_LOADING',
  DELETE_COUNTER_VALUE_SUCCESS = 'DELETE_COUNTER_VALUE_SUCCESS',
  DELETE_COUNTER_VALUE_FAILED = 'DELETE_COUNTER_VALUE_FAILED',
  FETCH_COUNTER_READING_LOGS_LOADING = 'FETCH_COUNTER_READING_LOGS_LOADING',
  FETCH_COUNTER_READING_LOGS_SUCCESS = 'FETCH_COUNTER_READING_LOGS_SUCCESS',
  FETCH_COUNTER_READING_LOGS_FAILED = 'FETCH_COUNTER_READING_LOGS_FAILED',
  FETCH_COUNTER_CONSUMPTION_LOGS_LOADING = 'FETCH_COUNTER_CONSUMPTION_LOGS_LOADING',
  FETCH_COUNTER_CONSUMPTION_LOGS_SUCCESS = 'FETCH_COUNTER_CONSUMPTION_LOGS_SUCCESS',
  FETCH_COUNTER_CONSUMPTION_LOGS_FAILED = 'FETCH_COUNTER_CONSUMPTION_LOGS_FAILED',
  FETCH_COUNTER_NUMBER_LOADING = 'FETCH_COUNTER_NUMBER_LOADING',
  FETCH_COUNTER_NUMBER_SUCCESS = 'FETCH_COUNTER_NUMBER_SUCCES',
  FETCH_COUNTER_NUMBER_FAILED = 'FETCH_COUNTER_NUMBER_FAILED',
  FETCH_COUNTER_DISABLED_PERIODS_LOADING = 'FETCH_COUNTER_DISABLED_PERIODS_LOADING',
  FETCH_COUNTER_DISABLED_PERIODS_SUCCESS = 'FETCH_COUNTER_DISABLED_PERIODS_SUCCES',
  FETCH_COUNTER_DISABLED_PERIODS_FAILED = 'FETCH_COUNTER_DISABLED_PERIODS_FAILED',
  MODIFY_COUNTER_INTEGRATION = 'MODIFY_COUNTER_INTEGRATION',
  ADD_COUNTER_INTEGRATION = 'ADD_COUNTER_INTEGRATION',
  CREATE_COUNTER_INTEGRATION_LOADING = 'CREATE_COUNTER_INTEGRATION_LOADING',
  CREATE_COUNTER_INTEGRATION_SUCCESS = 'CREATE_COUNTER_INTEGRATION_SUCCES',
  CREATE_COUNTER_INTEGRATION_FAILED = 'CREATE_COUNTER_INTEGRATION_FAILED',
  UPDATE_COUNTER_INTEGRATION_LOADING = 'UPDATE_COUNTER_INTEGRATION_LOADING',
  UPDATE_COUNTER_INTEGRATION_SUCCESS = 'UPDATE_COUNTER_INTEGRATION_SUCCES',
  UPDATE_COUNTER_INTEGRATION_FAILED = 'UPDATE_COUNTER_INTEGRATION_FAILED',
  DELETE_COUNTER_INTEGRATION_LOADING = 'DELETE_COUNTER_INTEGRATION_LOADING',
  DELETE_COUNTER_INTEGRATION_SUCCESS = 'DELETE_COUNTER_INTEGRATION_SUCCESS',
  DELETE_COUNTER_INTEGRATION_FAILED = 'DELETE_COUNTER_INTEGRATION_FAILED',
  UPDATE_EXTERNAL_DATA_LOADING = 'UPDATE_EXTERNAL_DATA_LOADING',
  UPDATE_EXTERNAL_DATA_SUCCESS = 'UPDATE_EXTERNAL_DATA_SUCCESS',
  UPDATE_EXTERNAL_DATA_FAILED = 'UPDATE_EXTERNAL_DATA_FAILED',
  TOGGLE_INACTIVE_PERIODS = 'TOGGLE_INACTIVE_PERIODS',
  FETCH_COUNTER_INACTIVE_PERIODS_LOADING = 'FETCH_COUNTER_INACTIVE_PERIODS_LOADING',
  FETCH_COUNTER_INACTIVE_PERIODS_SUCCESS = 'FETCH_COUNTER_INACTIVE_PERIODS_SUCCESS',
  FETCH_COUNTER_INACTIVE_PERIODS_FAILED = 'FETCH_COUNTER_INACTIVE_PERIODS_FAILED',
  COUNTER_BREADCRUMB_LOADING = 'COUNTER_BREADCRUMB_LOADING',
  COUNTER_BREADCRUMB_SUCCESS = 'COUNTER_BREADCRUMB_SUCCESS',
  COUNTER_BREADCRUMB_FAILED = 'COUNTER_BREADCRUMB_FAILED',
  FETCH_COUNTER_DEVIATION_LOADING = 'FETCH_COUNTER_DEVIATION_LOADING',
  FETCH_COUNTER_DEVIATION_SUCCESS = 'FETCH_COUNTER_DEVIATION_SUCCESS',
  FETCH_COUNTER_DEVIATION_ERROR = 'FETCH_COUNTER_DEVIATION_ERROR',
  FETCH_COUNTER_DEVIATION_DICTIONARY_LOADING = 'FETCH_COUNTER_DEVIATION_DICTIONARY_LOADING',
  FETCH_COUNTER_DEVIATION_DICTIONARY_SUCCESS = 'FETCH_COUNTER_DEVIATION_DICTIONARY_SUCCESS',
  FETCH_COUNTER_DEVIATION_DICTIONARY_ERROR = 'FETCH_COUNTER_DEVIATION_DICTIONARY_ERROR',
  SET_MODIFIED_COUNTER_DEVIATION_LOADING = 'SET_MODIFIED_COUNTER_DEVIATION_LOADING',
  SET_MODIFIED_COUNTER_DEVIATION_SUCCESS = 'SET_MODIFIED_COUNTER_DEVIATION_SUCCESS',
  SET_MODIFIED_COUNTER_DEVIATION_FAILED = 'SET_MODIFIED_COUNTER_DEVIATION_FAILED',
  FETCH_COUNTER_ATTRIBUTE_CONTROLS_LOADING = 'FETCH_COUNTER_ATTRIBUTE_CONTROLS_LOADING',
  FETCH_COUNTER_ATTRIBUTE_CONTROLS_SUCCESS = 'FETCH_COUNTER_ATTRIBUTE_CONTROLS_SUCCESS',
  FETCH_COUNTER_ATTRIBUTE_CONTROLS_ERROR = 'FETCH_COUNTER_ATTRIBUTE_CONTROLS_ERROR',
  FETCH_COUNTER_ATTRIBUTES_LOADING = 'FETCH_COUNTER_ATTRIBUTES_LOADING',
  FETCH_COUNTER_ATTRIBUTES_SUCCESS = 'FETCH_COUNTER_ATTRIBUTES_SUCCESS',
  FETCH_COUNTER_ATTRIBUTES_ERROR = 'FETCH_COUNTER_ATTRIBUTES_ERROR',
}
