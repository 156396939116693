import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import store from './redux/store'
import App from './components/App'
import {SidebarProvider} from './components/shared/SidebarContext'
import ThemeProvider from './shared/theme/ThemeProvider'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <SidebarProvider>
        <Router>
          <Routes>
            <Route path='/*' element={<App />} />
          </Routes>
        </Router>
      </SidebarProvider>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root')
)
