import {Close} from '@mui/icons-material'
import {Alert, Collapse, IconButton} from '@mui/material'
import React, {useState} from 'react'
import {SaveResponse} from '../../shared/enums/SaveResponse'

export interface IResponseAlertProps {
  response: SaveResponse
  open: boolean
  successMessage: string
  failureMessage: string
}

const ResponseAlert = (props: IResponseAlertProps) => {
  const [alertOpen, setAlertOpen] = useState(props.open)

  const renderAlert = () => {
    let alert = <div></div>
    switch (props.response) {
      case SaveResponse.SUCCESS:
        alert = (
          <Alert
            severity='success'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            {props.successMessage}
          </Alert>
        )
        break
      case SaveResponse.FAIL:
        alert = (
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            {props.failureMessage}
          </Alert>
        )
        break
      default:
        break
    }
    return <Collapse in={alertOpen}>{alert}</Collapse>
  }
  return <div>{renderAlert()}</div>
}
export default ResponseAlert
