import {Dispatch} from 'redux'
import {ActionTypes} from './types'
import genericNodeService from '../../services/genericNodeService'

export const getNodeCostEmissionData = (id: number, type: string, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_NODE_COST_EMISSION_DATA_LOADING})
  try {
    const attributes = await genericNodeService.getNodeCostEmissionData(id, type, startDate, stopDate)
    return dispatch({type: ActionTypes.FETCH_NODE_COST_EMISSION_DATA_SUCCESS, payload: attributes})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_NODE_COST_EMISSION_DATA_ERROR, payload: error})
  }
}
