import React from 'react'
import { IControlledTextFieldProps } from './interfaces'
import { Grid, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
const ControlledTextField = (props: IControlledTextFieldProps) => {
    return (
        <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
        <Grid xs={12} item>
          <Controller
            render={({field, fieldState: {error}}) => (
              <TextField
                id={props.id}
                label={props.label}
                type={props.type}
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
                fullWidth
                disabled={props.disabled}
              />
            )}
            name={props.name}
            control={props.control}
          />
        </Grid>
      </Grid>
    )
}
export default ControlledTextField