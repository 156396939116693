import Area, { IAreaEnergyZone } from '../redux/area/Interfaces.ts'
import saveupClient, {handleResponse} from '../clients/saveupClient'
import { IEnergyZone } from '../redux/energyzone/interfaces.js'

const estateService = {
  getAreas,
  getAreaById,
  deleteArea,
  createArea,
  editArea,
  getEnergyZone,
  createAreaEnergyZone,
  updateAreaEnergyZone,
  deleteAreaEnergyZone
}

async function getAreas() {
  return await saveupClient.get<Area[]>(`/Area`).then(handleResponse)
}

async function getAreaById(id: number) {
  return await saveupClient.get<Area>(`Area/${id}`).then(handleResponse)  
}

async function deleteArea(id: number) {
  return await saveupClient.delete<Area>(`/Area/${id}`).then(handleResponse)  
}

async function createArea(number: number, name: string ) {
  return await saveupClient.post<Area>(`/Area`, {number, name}).then(handleResponse)
}

async function editArea(data: Area) {
  return await saveupClient.put<Area>(`/Area`, {
    'id': data.id,
    'number': data.number,
    'name': data.name,
    'insDate': data.insDate,
    'updDate': data.updDate,
  }).then(handleResponse)
}

async function getEnergyZone(areaId: number) {
  return await saveupClient.get<IEnergyZone>(`area/${areaId}/energyzone`).then(handleResponse)
}

async function createAreaEnergyZone(areaId: number, energyZoneId: number) {
  return await saveupClient.post<IAreaEnergyZone>(`area/energyzone`, { areaId, energyZoneId }).then(handleResponse)
}

async function updateAreaEnergyZone(areaId: number, energyZoneId: number) {
  return await saveupClient.put<IAreaEnergyZone>(`area/energyzone`, { areaId, energyZoneId }).then(handleResponse)
}

async function deleteAreaEnergyZone(areaId: number) {
  return await saveupClient.delete<IAreaEnergyZone>(`area/${areaId}/energyzone`).then(handleResponse)
}

export default estateService
