import {ICounterDeviationForm, ICounterValueForm, ICounterValueInputDialogProps, ICounterValueResetForm, ICounterValueSaveParameter} from '../../../../redux/counterValue/interfaces'
import {Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Grid, TextField} from '@mui/material'
import {Save, Cancel, RunningWithErrors} from '@mui/icons-material'
import {Controller, useForm} from 'react-hook-form'
import {ICounterValue} from '../../../../redux/counter/interfaces'
import {hasEditPermissions, hasPermissions} from '../../../../shared/utils/utilities'
import {DateTimePicker} from '@mui/x-date-pickers'
import {useEffect, useState} from 'react'
import CounterValueResetDialog from './CounterValueResetDialog'
import {useDispatch, useSelector} from 'react-redux'
import {getCounterValueLog, saveCounterReset, saveCounterValueLog} from '../../../../redux/counterValue/actions'
import {ResetType} from '../../../../shared/enums/ResetType'
import CreateDeviationDialog from './CreateDeviationDialog'
import {RootState} from '../../../../redux/rootReducer'
import {getCounterReadings} from '../../../../redux/counter/actions'
import {head} from 'lodash'
import AlertDialog from '../../../shared/alerts/AlertDialog'
import { NODE_WRITE } from '../../../../shared/utils/constants'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const CounterValueInputDialog = (props: ICounterValueInputDialogProps) => {
  const {register, control, handleSubmit, reset} = useForm<ICounterValueForm>({
    defaultValues: {
      counterValueId: props.counterValueListItem ? props.counterValueListItem.id : 0,
      value: props.counterValueListItem ? props.counterValueListItem.value : 0,
      date: props.counterValueListItem ? props.counterValueListItem.date : new Date(),
    },
  })
  const dispatch = useDispatch()
  const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false)
  const [createDeviationDialogOpen, setCreateDeviationDialogOpen] = useState<boolean>(false)
  const [resetAlertOpen, setResetAlertOpen] = useState<boolean>(false)
  const [pendingValue, setPendingValue] = useState<ICounterValueForm | undefined>()
  const [canEdit, setCanEdit] = useState<boolean>(false)

  useEffect(() => {
    setPendingValue(undefined)
    if (props.counterValueListItem) {
      dispatch(getCounterValueLog(props.counterValueListItem.id))
    }
    reset({value: props.counterValueListItem ? props.counterValueListItem.value : 0, date: props.counterValueListItem ? props.counterValueListItem.date : new Date()})
  }, [props.counterValueListItem])

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [])

  let log = useSelector((state: RootState) => (state.counterValue.currentLog ? state.counterValue.currentLog : head(props.counterValueListItem?.counterValueLogs)))
  const createdLog = useSelector((state: RootState) => state.counterValue.createdLog && state.counterValue.createdLog.counterValueId === props.counterValueListItem?.id ? state.counterValue.createdLog : undefined)
  const renderTitle = () => {
    return <Typography variant='h3'>{props.counterValueListItem?.id === 0 ? 'Ny avläsning' : (canEdit ? 'Redigera avläsning' : 'Avläsning')}</Typography>
  }

  const onSubmit = (data: ICounterValueForm) => {
    if(props.counterValueListItem?.hasReset) {
      setPendingValue(data)
      setResetAlertOpen(true)
    }
    else {
      props.onConfirm({...data, counterValueId: props.counterValueListItem?.id})
    }
  }
  const renderResetDialog = () => {
    if (!props.counterValueListItem) return ''
    return <CounterValueResetDialog open={resetDialogOpen} counterValueListItem={props.counterValueListItem} onClose={() => setResetDialogOpen(false)} onConfirm={(e: any) => onResetConfirmClick(e)} />
  }
  const renderDeviationDialog = () => {
    if (!props.counterValueListItem) return ''
    return (
      <CreateDeviationDialog
        open={createDeviationDialogOpen}
        counterValueId={props.counterValueListItem.id}
        onClose={() => setCreateDeviationDialogOpen(false)}
        onConfirm={(e: any) => onDeviationConfirmClick(e)}
      />
    )
  }
  const renderResetWarningDialog = () => {
    return (
      <AlertDialog
        open={resetAlertOpen}
        title='Varning'
        message='Det finns en registrerad mätaråterställning för detta värde. Vill du ändå skriva över?'
        confirmLabel='Ja'
        cancelLabel='Nej'
        onCancel={() => { 
          setResetAlertOpen(false)
        }}
        onConfirm={() => {
          setResetAlertOpen(false)
          if (pendingValue) props.onConfirm({...pendingValue, counterValueId: props.counterValueListItem?.id})
          else alert('Inmatat värde kunde inte sparas.')
        }}
      />
    )
  }
  const onResetConfirmClick = (data: ICounterValueResetForm) => {
    if (!canEdit) return
    if (data.counterValueId && data.counterValueId > 0) {
      dispatch(saveCounterReset(data.counterValueId, data.resetType === ResetType.CHANGED, data.resetType === ResetType.TURNED, data.lastValue, data.startValue))
    }
    setResetDialogOpen(false)
    props.onClose()
  }
  const onDeviationConfirmClick = (data: ICounterDeviationForm) => {
    if (!canEdit) return
    if (data.counterValueId && data.counterValueId > 0) {
      dispatch(saveCounterValueLog(data.counterValueId, data.text, data.tag))
    }
    setCreateDeviationDialogOpen(false)
  }

  useEffect(() => {
    if (createdLog && createdLog.counterValueId === props.counterValueListItem?.id) {
      dispatch(getCounterReadings(props.counterId))
    }
  }, [createdLog])
  return (
    <div>
      <form>
        <Dialog sx={{style}} open={props.open}>
          <DialogTitle>{renderTitle()}</DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                <Grid xs={12} item>
                  <Controller
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        id='value'
                        label='Avläsning'
                        type='number'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...field}
                        fullWidth
                        disabled={!canEdit}
                      />
                    )}
                    name='value'
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                <Grid xs={12} item>
                  <Controller
                    render={({field}) => <DateTimePicker label='Tidpunkt' {...field} renderInput={(params: any) => <TextField {...params} />} inputFormat='yyyy-MM-dd HH:mm:ss' disabled={!canEdit} />}
                    name='date'
                    control={control}
                    defaultValue={new Date()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {canEdit && (<Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={!canEdit} onClick={handleSubmit(onSubmit)}>
              Spara
            </Button>)}
            <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
              Avbryt
            </Button>
            {(log || (createdLog && createdLog.counterValueId === props.counterValueListItem?.id)) && canEdit ? (
              <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={!canEdit} onClick={() => setResetDialogOpen(true)}>
                Spara mätaråterställning
              </Button>
            ) : canEdit && (
              <Button
                sx={{mt: {xs: 2, md: 0}}}
                variant='contained'
                color='primary'
                startIcon={<RunningWithErrors fontSize='small' />}
                disabled={!canEdit}
                onClick={() => setCreateDeviationDialogOpen(true)}>
                Skapa avvikelse
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </form>
      <Grid container direction='row' alignItems='stretch'>
        <Grid item xs={12}>
          {renderResetDialog()}
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch'>
        <Grid item xs={12}>
          {renderDeviationDialog()}
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch'>
        <Grid item xs={12}>
          {renderResetWarningDialog()}
        </Grid>
      </Grid>
    </div>
  )
}
export default CounterValueInputDialog
