import {TabContext, TabList, TabPanel} from '@mui/lab'
import {Box, Tab} from '@mui/material'
import React, {useState} from 'react'
import {useParams} from 'react-router'
import UpdateOfficeContainer from './updateOffice/UpdateOfficeContainer'
import OfficeCost from './officeMeterCost/OfficeCost'
import OfficeEmission from './officeEmission/OfficeEmission'

const Office = () => {
  const {id} = useParams()
  const parsedId: number = id ? parseInt(id) : 0
  const [selectedTab, setSelectedTab] = useState('details')
  const onTabChange = (e: any, obj: any) => {
    setSelectedTab(obj)
  }
  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box>
          <TabList onChange={onTabChange}>
            <Tab label='Redigera' value={'details'} />
            <Tab label='Prisuppgifter' value={'cost'} />
            <Tab label='Utsläpp' value={'emission'} />
          </TabList>
        </Box>
        <TabPanel value={'details'}>
          <UpdateOfficeContainer officeId={parsedId} />
        </TabPanel>
        <TabPanel value={'cost'}>
          <OfficeCost officeId={parsedId} />
        </TabPanel>
        <TabPanel value={'emission'}>
          <OfficeEmission officeId={parsedId} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
export default Office
