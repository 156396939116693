import { FormControl, InputLabel, MenuItem, TextField } from '@mui/material'
import React from 'react'
import SecSelect from './SecSelect'

export interface ILabeledSelectProps {
    id: string
    className?: string
    defaultValue?: string,
    label: string,
    items?: any[],
    value?: any,
    components?: any,
    onChange?: any,
    defaultItem?: any
    canEdit?: boolean
    disabled?: boolean | undefined
}

export interface ILabeledSelectItem {
    value: string,    
    text: string
}

const LabeledSelect = (props: ILabeledSelectProps) => {

    const renderItem = ({value, text}: ILabeledSelectItem, index: number) => { 
        return (
            <MenuItem key={index} value={value}>{text}</MenuItem>
        )
    }
    return (
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel id={`${props.id}Label`} variant="outlined" htmlFor={props.id}>{props.label}</InputLabel>
            <SecSelect 
                sx={{flexGrow: 1, width: '270px', color: '#3E5060' }}                      
                id={props.id}
                labelId={`${props.id}Label`}
                label={props.label}
                className={props.className}   
                value={props.value}  
                onChange={props.onChange}
                canEdit={props.canEdit} 
                disabled={props.disabled}           
            >
                {props.items ? props.items.map((t, i) => renderItem(t, (i + 1) + (props.defaultItem ? 1 : 0))) : ""}
                {props.components}
            </SecSelect>
        </FormControl>
    )
}
export default LabeledSelect

