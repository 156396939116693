import {head, mergeWith} from 'lodash'
import {Reducer} from 'redux'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import {ICounter, ICounterIntegration, ICounterState, IInactivePeriod} from './interfaces'
import {ActionTypes} from './types'
import { current } from 'immer'
import { arrayOverwriteByIdentifier } from '../../shared/utils/utilities'

const defaultCounter: ICounter = {
  id: 0,
  measurePointId: 0,
  meterId: 0,
  name: '',
  constant: 0,
  insDate: new Date(),
  updDate: new Date(),
  turned: false,
  hidden: false,
}

const initialCounterState: ICounterState = {
  isLoading: false,
  type: {
    id: 0,
    name: '',
    maxValue: 0,
    multiple: 0,
    unitId: 0,
    decimals: 0,
    insDate: new Date(),
    updDate: new Date(),
    isConsumption: false,
    canDegreeDayAdjust: false
  },
  counter: defaultCounter,
  consumption: [],
  deviation: undefined,
  modifiedDeviation: undefined,
  deviations: {},
  integrations: [],
  integrationImportResult: [],
  periodConsumptions: [],
  readings: [],
  savedReading: null,
  readingLogs: [],
  consumptionLogs: [],
  numbers: [],
  inactivePeriods: [],
  lastActionType: '',
  errorMessage: '',
  deletedConsumption: 0,
  isMultiValue: false,
  isExcluded: false,
  attributeControls: [],
  counterAttributes: []
}
export const counterReducer: Reducer<ICounterState> = (state = initialCounterState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_COUNTER_METER_TYPE_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_METER_TYPE_SUCCESS: {
      return {...state, isLoading: false, type: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_METER_TYPE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_SUCCESS: {
      return {...state, isLoading: false, counter: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.CREATE_COUNTER_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.CREATE_COUNTER_SUCCESS: {
      return {...state, isLoading: false, counter: payload.counter, numbers: payload.counterNumbers, lastActionType: type}
    }
    case ActionTypes.CREATE_COUNTER_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.UPDATE_COUNTER_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.UPDATE_COUNTER_SUCCESS: {      
      let modifiedAttributes = arrayOverwriteByIdentifier([...state.counterAttributes], payload.counterAttributes, 'attributeId')      
      return {
        ...state,
        isLoading: false,
        counter: payload.counter,
        integrations: payload.integrations,
        disabledPeriods: payload.disabledPeriods,
        lastActionType: type,
        counterAttributes: modifiedAttributes
      }
    }
    case ActionTypes.UPDATE_COUNTER_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_SUCCESS: {
      return {...state, isLoading: false, counter: defaultCounter, numbers: [], lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_SUCCESS: {
      return {...state, isLoading: false, consumption: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_COST_EMISSION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_COST_EMISSION_SUCCESS: {
      return {...state, isLoading: false, consumption: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_COST_EMISSION_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_DEVIATION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_DEVIATION_SUCCESS: {
      return {...state, isLoading: false, deviation: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_DEVIATION_ERROR: {
      return {...state, isLoading: false, deviation: undefined, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_DEVIATION_DICTIONARY_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_DEVIATION_DICTIONARY_SUCCESS: {
      return {...state, isLoading: false, deviations: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_DEVIATION_DICTIONARY_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_IS_EXCLUDED_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_IS_EXCLUDED_SUCCESS: {
      return {...state, isLoading: false, isExcluded: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_IS_EXCLUDED_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_IS_MULTI_VALUE_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_IS_MULTI_VALUE_SUCCESS: {
      return {...state, isLoading: false, isMultiValue: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_IS_MULTI_VALUE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_NUMBERS_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_NUMBERS_SUCCESS: {
      return {...state, isLoading: false, numbers: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_NUMBERS_FAILED: {
      return {...state, isLoading: false, errorMessage: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_INTEGRATIONS_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_COUNTER_INTEGRATIONS_SUCCESS: {
      return {...state, isLoading: false, integrations: payload}
    }
    case ActionTypes.FETCH_COUNTER_INTEGRATIONS_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.COUNTER_READINGS_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.COUNTER_READINGS_SUCCESS: {
      return {...state, isLoading: false, readings: payload, lastActionType: type}
    }
    case ActionTypes.COUNTER_READINGS_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.SAVE_COUNTER_VALUE_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.SAVE_COUNTER_VALUE_SUCCESS: {
      return {...state, isLoading: false, savedReading: payload, lastActionType: type}
    }
    case ActionTypes.SAVE_COUNTER_VALUE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_VALUE_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_VALUE_SUCCESS: {
      const filteredReadings = state.readings.filter((x) => x.id !== payload)
      return {...state, isLoading: false, readings: filteredReadings, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_VALUE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_READING_LOGS_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_READING_LOGS_SUCCESS: {
      return {...state, isLoading: false, readingLogs: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_READING_LOGS_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_LOGS_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_LOGS_SUCCESS: {
      return {...state, isLoading: false, consumptionLogs: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_CONSUMPTION_LOGS_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.MODIFY_COUNTER_INTEGRATION: {      
      let integrationToModify = state.integrations.find(
        (x: ICounterIntegration) => x.id === payload.id && (x.externalId !== payload.externalId || x.externalSystemId !== payload.externalSystemId || x.integrationType !== payload.integrationType)
      )
      if (integrationToModify) {        
        const modifiedIntegrations = state.integrations.filter((x) => x.id !== integrationToModify?.id)
        modifiedIntegrations.push(payload)
        return {...state, isLoading: false, integrations: modifiedIntegrations, lastActionType: type}
      }
      return state
    }
    case ActionTypes.ADD_COUNTER_INTEGRATION: {
      let modifiedIntegrations = [...state.integrations, payload]
      return {...state, isLoading: false, integrations: modifiedIntegrations, lastActionType: type}
    }
    case ActionTypes.CREATE_COUNTER_INTEGRATION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.CREATE_COUNTER_INTEGRATION_SUCCESS: {          
      const integrations = state.integrations.some(x => x.id !== payload.id) ? [...state.integrations, payload] : state.integrations
      return {...state, isLoading: false, integrations: integrations, lastActionType: type}
    }
    case ActionTypes.CREATE_COUNTER_INTEGRATION_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_INTEGRATION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_INTEGRATION_SUCCESS: {            
      return {...state, isLoading: false, integrations: state.integrations.filter(x => x.id !== payload), lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_INTEGRATION_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.UPDATE_EXTERNAL_DATA_LOADING: {
      return {...state, lastActionType: type}
    }
    case ActionTypes.UPDATE_EXTERNAL_DATA_SUCCESS: {      
      return {...state, integrationImportResult: payload, lastActionType: type}
    }
    case ActionTypes.UPDATE_EXTERNAL_DATA_FAILED: {
      return {...state, errorMessage: payload, lastActionType: type}
    }
    case ActionTypes.TOGGLE_INACTIVE_PERIODS: {
      let modified = [...state.inactivePeriods]
      let period = modified.find((m) => m.period === payload.period)
      if (period) period.isInactive = payload.isInactive
      return {...state, inactivePeriods: modified, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_INACTIVE_PERIODS_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_INACTIVE_PERIODS_SUCCESS: {
      return {...state, isLoading: false, inactivePeriods: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_INACTIVE_PERIODS_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.SET_MODIFIED_COUNTER_DEVIATION_SUCCESS: {            
      return { ...state, deviation: payload }
    }
    case ActionTypes.UPDATE_COUNTER_DEVIATION_LOADING: {
      return {...state, lastActionType: type}
    }
    case ActionTypes.UPDATE_COUNTER_DEVIATION_SUCCESS: {      
      return {...state, deviation: payload, lastActionType: type}
    }
    case ActionTypes.UPDATE_COUNTER_DEVIATION_ERROR: {
      return {...state, errorMessage: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_ATTRIBUTE_CONTROLS_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_ATTRIBUTE_CONTROLS_SUCCESS: {
      return {...state, isLoading: false, attributeControls: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_ATTRIBUTE_CONTROLS_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_ATTRIBUTES_LOADING: {
      return {...state, isLoading: true, counterAttributes: [], lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_ATTRIBUTES_SUCCESS: {
      return {...state, isLoading: false, counterAttributes: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_ATTRIBUTES_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    default: {
      return state
    }
  }
}
