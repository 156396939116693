import {NavLink as RouterLink} from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import {Link, Breadcrumbs, Typography} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { RootState } from '../../redux/rootReducer'
import { getPath } from '../../redux/tree/treeActions'
import { isNullOrWhiteSpace } from '../../shared/utils/utilities'
import { IBreadCrumbPath } from '../../redux/tree/treeInterfaces'

const PreBreadcrumbs = () => {
  //const [type, setType] = useState<string>("")
  //const [id, setId] = useState<string>("")
  
  const dispatch = useDispatch()
  const breadcrumbs = useBreadcrumbs()
  const pathnames = document.location.pathname.split('/').filter(x => x)

  const nodeType = pathnames.length === 2 ? pathnames[0] : ""
  const id = pathnames.length === 2 ? pathnames[1] : ""
  
  const treeNodePath = useSelector((state: RootState) => state.tree.path)
  useEffect(() => {    
    dispatch(getPath(id, nodeType))
  }, [id, nodeType])

  function renderPath(path: IBreadCrumbPath[]) {    
     return path.map((node: IBreadCrumbPath, index: number) => {
      const isLast = index === path.length - 1
      if(isLast) {
        return (
          <Typography variant='h5' key={index}>{node.label}</Typography>
        )
      }
      else {
        return (          
          <Link component={RouterLink} underline='hover' sx={{display: 'flex', alignItems: 'center'}} color='inherit' to={`${node.nodeType}/${node.id}`} key={node.id}>
            {node.label}
          </Link>
          )
      }
     })
  }
  return (
    <div role='presentation'>
      <Breadcrumbs aria-label='breadcrumb'>
        {treeNodePath !== undefined && treeNodePath.length > 0 && treeNodePath.find(x => x.nodeType === nodeType.toUpperCase())? renderPath(treeNodePath) : breadcrumbs.map(({match, breadcrumb, key}, index) =>{
          const isLast = index === pathnames.length
          if(isLast) {
            return (
              <Typography variant='h5' key={index}>{breadcrumb}</Typography>
            )
          }
          else {
          return (          
          <Link component={RouterLink} underline='hover' sx={{display: 'flex', alignItems: 'center'}} color='inherit' to={match.pathname} key={key}>
            {breadcrumb}
          </Link>
          )
          }
          })}
      </Breadcrumbs>
    </div>
  )
}

export default PreBreadcrumbs
