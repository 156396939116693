import { IConsumption, IConsumptionNodeDto } from '../redux/consumption/interfaces'
import { ICounter, IDisabledPeriodParameter } from '../redux/counter/interfaces'
import { ITableLog } from '../redux/counterValue/interfaces'
import { IMeasurePoint, IMeasurePointNumber, IMeasurePointPathDto, MeasurePointSearchResultDto } from '../redux/Measurepoint/measurepointInterfaces'
import { IMeterType } from '../redux/meter/interfaces'
import saveupClient, { handleResponse } from '../clients/saveupClient'
import { IBreadCrumbPath } from '../redux/tree/treeInterfaces'
import { isNullOrWhiteSpace } from '../shared/utils/utilities'
import { OWNER, ESTATE, ADDRESS, MEASUREPOINT } from '../shared/utils/constants'

async function getMeasurePointByAddressId(addressId: number) {
  return await saveupClient.get<IMeasurePoint>(`address/${addressId}/MeasurePoint`).then(handleResponse)
}
async function createMeasurePoint(addressId: number, name: string, description: string, hidden: boolean) {
  return await saveupClient.post<IMeasurePoint>(`measurepoint`, { addressId, name, description, hidden }).then(handleResponse)
}
async function updateMeasurePoint(id: number, addressId: number, name: string, description: string, hidden: boolean) {
  return await saveupClient.put<IMeasurePoint>(`measurepoint`, { id, addressId, name, description, hidden }).then(handleResponse)
}

async function deleteMeasurePioint(id: number) {
  return await saveupClient.delete(`measurepoint/${id}`).then(handleResponse)  
  }

async function getMeasurePointById(id: number) {
  return await saveupClient.get<IMeasurePoint>(`measurepoint/${id}`).then(handleResponse)  
}

async function getMeasurePointNumber(id: number) {
  return await saveupClient.get<string[]>(`measurepoint/${id}/number`).then(handleResponse)  
}

async function getMeasurePointDisabledPeriods(id: number) {
  return await saveupClient.get<IDisabledPeriodParameter>(`measurepoint/${id}/period/disabled`)
  .then(handleResponse)  
}


async function saveMeasurePointNumber(measurePointId: number, number: string) {
  return await saveupClient.post<IMeasurePointNumber>(`measurepoint/number`, { measurePointId, number} ).then(handleResponse)  
}

async function saveDisabledPeriods(parameter: IDisabledPeriodParameter) {
  return await saveupClient.post<IDisabledPeriodParameter>("measurepoint/period/disabled", parameter).then(handleResponse)
}

async function getMeterTypesAtMeasurePoint(estateId: number) {
  return await saveupClient.get<IMeterType[]>(`measurepoint/${estateId}/meter/type`).then(handleResponse)
}

async function getConsumption(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {            
  return await saveupClient.get<IConsumption>(`Consumption/Summary?id=${id}&type=MEASUREPOINT&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}`)
  .then(handleResponse) 
}

async function getConsumptionCostEmission(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {  
  return await saveupClient
  .get<IConsumptionNodeDto>(`consumption/cost?id=${id}&type=MEASUREPOINT&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&round=true`)
  .then(handleResponse)
}

/**
 * Fetch all monthly consumptions, cost and emissions in given date span.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getNodeConsumption(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=MEASUREPOINT&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&round=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span with estimates.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getNodeConsumptionAndEstimate(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=MEASUREPOINT&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&estimate=true&round=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}

async function getReadingTableLogs(estateId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient.get<ITableLog>(`measurepoint/counter/value/log/period/meter?estateId=${estateId}&meterTypeId=${meterTypeId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`)
  .then(handleResponse)  
}

async function getConsumptionTableLogs(estateId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient.get<ITableLog>(`measurepoint/counter/consumption/log/period/meter?estateId=${estateId}&meterTypeId=${meterTypeId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`)
  .then(handleResponse)  
}
async function deleteMeasurePointNumber(measurePointId: number, number: string) {
  return await saveupClient.delete(`measurepoint/number?measurePointId=${measurePointId}&number=${number}`)
}
async function deleteDisabledPeriods(id: number) {  
  return await saveupClient.delete(`measurepoint/${id}/period/disabled`)
}
async function getPath(id: number) {
  return await saveupClient.get<IMeasurePointPathDto>(`measurepoint/${id}/path`).then(handleResponse)
}
async function getBreadCrumbPath(id: number) {
  let paths: IBreadCrumbPath[] = []
  const dto: IMeasurePointPathDto = await getPath(id)
  paths.push({
    id: dto.address.estate.owner.id,
    label: isNullOrWhiteSpace(dto.address.estate.owner.name) ? dto.address.estate.owner.id.toString() : dto.address.estate.owner.name,
    nodeType: OWNER
  })
  paths.push({
    id: dto.address.estate.id,
    label: `${isNullOrWhiteSpace(dto.address.estate.name) ? dto.address.estate.id.toString() : dto.address.estate.name}${
      isNullOrWhiteSpace(dto.address.estate.rekylId) ? '(' + dto.address.estate.rekylId + ')' : ''
    }`,
    nodeType: ESTATE
  })
  paths.push({id: dto.address.id, label: isNullOrWhiteSpace(dto.address.name) ? dto.address.id.toString() : dto.address.name, nodeType: ADDRESS})
  paths.push({
    id: dto.id,
    label: isNullOrWhiteSpace(dto.number)
      ? isNullOrWhiteSpace(dto.name)
        ? dto.id.toString()
        : dto.name
      : dto.number.toString(),
    nodeType: MEASUREPOINT
  })
  return paths;
}

async function searchMeasurePoint(keyword: string) {
  const result = await saveupClient.get<MeasurePointSearchResultDto[]>(`/measurepoint/search?keyword=${keyword}`).then(handleResponse)
  return result
}
async function getCountersByMeasurePointId(id: number) {
  return await saveupClient.get<ICounter[]>(`measurepoint/${id}/counter`).then(handleResponse)  
}

const measurePointService = {
  getMeasurePointByAddressId,  
  getMeasurePointById,
  getMeterTypesAtMeasurePoint,
  getReadingTableLogs,
  getConsumptionTableLogs,
  getConsumption,
  getConsumptionCostEmission,
  getNodeConsumption,
  getNodeConsumptionAndEstimate,
  getMeasurePointNumber,
  getMeasurePointDisabledPeriods,
  getPath,
  getBreadCrumbPath,
  getCountersByMeasurePointId,
  createMeasurePoint,
  updateMeasurePoint,
  deleteMeasurePioint,
  deleteMeasurePointNumber,
  deleteDisabledPeriods,
  saveMeasurePointNumber,
  saveDisabledPeriods,
  searchMeasurePoint
}
export default measurePointService
