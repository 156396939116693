import React from 'react'
import {ICaseTooltip} from './interfaces'
import {ITableLog} from '../../../redux/counterValue/interfaces'
import {htmlDecode} from '../../../shared/utils/utilities'
const CaseTooltip = (props: ICaseTooltip) => {
  if(!props.logs || props.logs.length === 0) return <div></div>

  const getCaseStateDescription = (log: ITableLog) => {
    if(log.caseState === 'CLOSED') return 'Avslutad'
    else if(log.caseState === 'NEW') return 'Ny'
    else return 'Pågående'
  }


  let messages = props.logs.map(
    (l) => (
      'Mätare: ' +
      (l.counterNumber ?? l.counterName) +
      '\n Meddelande: ' +
      htmlDecode(l.message) +
      '\n Ärende: ' +
      (l.hasCase ? `Ja (${getCaseStateDescription(l)})` : 'Nej')
    )
  ).join('\n\n')
  return <div style={{whiteSpace: 'pre-line'}}>{messages}</div>
}
export default CaseTooltip
