import {IEstate} from '../redux/estate/Interfaces'
import {IMeterType} from '../redux/meter/interfaces'
import {IConsumptionNodeDto} from '../redux/consumption/interfaces'
import estateService from './estateService'
// import {jsPDF} from 'jspdf'
import * as htmlToImage from 'html-to-image'
import ConsumptionNodePDF from '../redux/pdf/ConsumptionNodePDF'
import {castUnit, parseStopDateMonthly} from '../shared/utils/utilities'
import React from 'react'
import ReactDOM from 'react-dom'
import { CreateOptions, PDFDocument, PageSizes } from 'pdf-lib'
import meterTypeService from './meterTypeService'
import { IConsumptionNodePDF } from '../redux/pdf/interfaces'
import ConsumptionNodeDto from '../redux/consumption/ConsumptionNodeDto'
import { IUnit } from '../redux/unit/unitInterfaces'

async function getOwnerSummaryPdf(estates: IEstate[], meterTypes: IMeterType[], startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, units: IUnit[]) {
  // console.log(estates, meterTypes, startDate, stopDate, useDegreeDayCorrection)
  const parsedStopDate = parseStopDateMonthly(stopDate)
  
  const bufferYears = stopDate.getFullYear() === startDate.getFullYear() ? 2 : 1;
  const parsedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDay())
  parsedStartDate.setFullYear(parsedStartDate.getFullYear() - bufferYears)
  
  let ownerPdfData: IConsumptionNodePDF[] = []
  await Promise.all(
    estates.map(async (estate: IEstate) => {
      let meterTypesAtEstate: IMeterType[] = await estateService.getMeterTypesAtEstate(estate.id)
      const filteredMeterTypes = meterTypesAtEstate.filter(x => meterTypes.some(y => x.id === y.id))
      return await Promise.all(
        filteredMeterTypes.map(async (meter: IMeterType) => {
          const selectedUnit = units.find((x) => x.id === meter.unitId)
          const unitOverride = castUnit(selectedUnit)
          const consumptions: ConsumptionNodeDto[] = await estateService.getNodeConsumption(estate.id, meter.id, parsedStartDate, parsedStopDate, useDegreeDayCorrection, unitOverride)

          // Get items in consumptions that have a year property earlier than the year of the startDate. These items will be hidden and are only used for calculation purposes.
          const parsedConsumptions : ConsumptionNodeDto[] = consumptions.map((x: ConsumptionNodeDto) => new ConsumptionNodeDto({...x, loa: x.loa, aTemp: x.aTemp, estimatedLOA: x.estimatedLOA, estimatedATemp: x.estimatedATemp, hidden: x.year < startDate.getFullYear()}))
          
          

          if(parsedConsumptions.some(x => x.value !== 0)) {
            ownerPdfData.push({
              meterType: meter,
              estate: estate,
              consumptions: parsedConsumptions,
            })
          }
        })
      )
    })
  )
  return ownerPdfData
}

async function getEstateSummaryPdf(estate: IEstate, meterTypes: IMeterType[], startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, unit?: IUnit) {
  const parsedStopDate = parseStopDateMonthly(stopDate)
  
  const bufferYears = stopDate.getFullYear() === startDate.getFullYear() ? 2 : 1;
  const parsedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDay())
  parsedStartDate.setFullYear(parsedStartDate.getFullYear() - bufferYears)

  let meterTypesAtEstate: IMeterType[] = await estateService.getMeterTypesAtEstate(estate.id)
  const filteredMeterTypes = meterTypesAtEstate.filter(x => meterTypes.some(y => x.id === y.id))
  let estatePdfData: IConsumptionNodePDF[] = []
  await Promise.all(
    filteredMeterTypes.map(async (meter: IMeterType) => {
      const consumptions: ConsumptionNodeDto[] = await estateService.getNodeConsumption(estate.id, meter.id, parsedStartDate, parsedStopDate, useDegreeDayCorrection, castUnit(unit))

      // Get items in consumptions that have a year property earlier than the year of the startDate. These items will be hidden and are only used for calculation purposes.
      const parsedConsumptions : ConsumptionNodeDto[] = consumptions.map((x: ConsumptionNodeDto) => new ConsumptionNodeDto({...x, loa: x.loa, aTemp: x.aTemp, estimatedLOA: x.estimatedLOA, estimatedATemp: x.estimatedATemp, hidden: x.year < startDate.getFullYear()}))


      // If there is no registered consumption whatsover for this node during given period, we skip it.
      if(parsedConsumptions.some(x => x.value !== 0)) {
        estatePdfData.push({
          meterType: meter,
          estate: estate,
          consumptions: parsedConsumptions
        })
      }
    })
  )
  return estatePdfData
}


async function exportChartsByContainerReference(fileName: string, container: React.RefObject<HTMLDivElement>) {
  const doc : PDFDocument = await PDFDocument.create()
  const elements = document.getElementsByClassName('custom-chart')
  if (container.current !== null) {
    container.current.style.display = 'block'
    await createPdfDocument({doc, elements})
    const pdfBytes = await doc.save()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([pdfBytes], {type: 'appplication/pdf'}))
    link.download = fileName
    link.click()
    container.current.style.display = 'none'
  }
}

async function createPdfDocument({doc, elements}: {doc: PDFDocument; elements: HTMLCollectionOf<Element>}) {
  const padding = 10
  const marginTop = 20
  let top = marginTop
  for (let i = -1; i < elements.length; i++) {
    const el = i === -1 && elements.length > 0 ? (elements.item(0) as HTMLElement) : (elements.item(i) as HTMLElement)
    if(!el) continue
    const imgData = await htmlToImage.toPng(el) // , { quality: 0.1, backgroundColor: 'transparent' })

    let elHeight = el.offsetHeight
    let elWidth = el.offsetWidth

    const page = doc.addPage(PageSizes.A4)
    const pageWidth = page.getWidth()
    const pageHeight = page.getHeight()

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth
      elHeight = elHeight * ratio - padding * 2
      elWidth = elWidth * ratio - padding * 2
    }

    const pngImage = await doc.embedPng(imgData)
    const pngDims = pngImage.scale(0.4)
    page.drawImage(pngImage, {      
      x: pageWidth / 2 - pngDims.width / 2,
      y: pageHeight / 2 - pngDims.height / 2 ,
      width: pngDims.width,
      height: pngDims.height      
    })

    // if (top + elHeight > pageHeight) {
    //   doc.addPage()
    //   top = marginTop
    // }      
    
    //doc.addImage(imgData, 'JPEG', padding, top, elWidth, elHeight, undefined, 'FAST')
    //doc.addImage(imgData, 'PNG', padding, top, elWidth, elHeight, undefined, 'FAST')
    //top += elHeight + marginTop
  }
  // doc.deletePage(1)
}


const pdfService = {
  getEstateSummaryPdf,
  getOwnerSummaryPdf,
  exportChartsByContainerReference,
}

export default pdfService
