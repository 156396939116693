import {Cancel, Download} from '@mui/icons-material'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import {Box, Grid, Modal, TextField, Typography, Dialog, FormControlLabel, Switch, Button, Divider, DialogActions, DialogContent, DialogTitle, LinearProgress} from '@mui/material'
import {IReportModalProps} from '../../redux/report/interfaces'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {parseStopDateYearly} from '../../shared/utils/utilities'
import {PDF} from '../../shared/utils/constants'
const ReportModal = (props: IReportModalProps) => {
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0))
  const [stopDate, setStopDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0))
  const [useDegreeDayCorrection, setUseDegreeDayCorrection] = useState(true)
  const [useOfficeFilter, setUseOfficeFilter] = useState(false)
  const [isWorking, setIsWorking] = useState<boolean>(false)
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  useEffect(() => {
    if (props.reportType === PDF) {
      setStartDate(new Date(new Date().getFullYear() - 2, 0, 1, 0, 0, 0))
      setStopDate(new Date(new Date().getFullYear(), 11, 31, 0, 0, 0))
    } else {
      setStartDate(new Date(new Date().getFullYear(), 0, 1, 0, 0, 0))
      setStopDate(new Date(new Date().getFullYear(), 11, 31, 0, 0, 0))
    }
  }, [props.reportType])

  const download =  async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsWorking(true)
    await props.actions.run(startDate, stopDate, useDegreeDayCorrection, useOfficeFilter)
    setIsWorking(false)
  }

  return (
    <Dialog open={props.open} sx={{style}} arlia-labeledby='moda-modal-title' aria-describedby='modal-modal-description'>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Grid container direction='row' alignItems='stretch' spacing={1} sx={{marginTop: '10px'}}>
          <Grid item xs={4}>
            <DesktopDatePicker
              views={['year', 'month']}
              label='Start'
              mask='____-__'
              inputFormat='yyyy-MM'
              value={startDate}
              onChange={(date: Date | null) => {
                if (date) setStartDate(date)
              }}
              openTo='month'
              renderInput={(params: any) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={4}>
            <DesktopDatePicker
              views={['year', 'month']}
              label='Stopp'
              mask='____-__'
              inputFormat='yyyy-MM'
              value={stopDate}
              onChange={(date: Date | null) => {
                if (date) setStopDate(date)
              }}
              openTo='month'
              renderInput={(params: any) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='stretch' spacing={1} sx={{marginTop: '10px'}}>
          {props.children}
        </Grid>
        <Grid container direction='row' alignItems='stretch' spacing={1}>
          <Grid item xs={5}>
            <FormControlLabel
              control={<Switch checked={useDegreeDayCorrection} onChange={() => setUseDegreeDayCorrection(useDegreeDayCorrection ? false : true)} title='Använd graddagsjustering' />}
              label='Använd graddagsjustering'
            />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
              control={<Switch checked={useOfficeFilter} onChange={() => setUseOfficeFilter(useOfficeFilter ? false : true)} title='Använd kontorsfilter' />}
              label='Använd kontorsfilter'
            />
          </Grid>
        </Grid>
        {props.isLoading || isWorking ? (
          <Grid container direction='row' alignItems='stretch' spacing={1} sx={{marginTop: '10px'}}>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Download fontSize='small' />} onClick={download}>
          OK
        </Button>
        <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={() => props.onClose()}>
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ReportModal
