import {DataGrid} from '@mui/x-data-grid'
import {IMeasurePoint} from '../../redux/Measurepoint/measurepointInterfaces'
import React from 'react'
import {AddressMeasurePointsProps} from './interfaces'
const AddressMeasurePoints: React.FC<AddressMeasurePointsProps> = (props: AddressMeasurePointsProps) => {
  const columns = [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'name', headerName: 'Namn', flex: 1},
  ]
  const renderGrid = () => {
    if(props.isLoading) return <div>Laddar mätpunkter för vald address...</div>
    if (props.measurePoints === undefined || props.measurePoints.length === 0) return <div>Inga mätpunkter kunde hittas för vald address!</div>
    return <DataGrid style={{height: 800, width: '100%'}} rows={props.measurePoints} columns={columns} pageSize={13} rowsPerPageOptions={[13]} />
  }
  return <div>{renderGrid()}</div>
}
export default AddressMeasurePoints
