import {IConsumption, IConsumptionGraphPlainProps, IConsumptionYearData, IDeviationDataSet, IReadingDeviationDataSet, IReadingYearData} from '../../redux/consumption/interfaces'
import React from 'react'
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js'
import {Bar, Line} from 'react-chartjs-2'
import {uniq} from 'lodash'
import annotationPlugin from 'chartjs-plugin-annotation'
import {getAnnotations} from '../consumption/graph/consumptionGraphHelper'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Avläsning',
    },
  },
}

const ReadingGraphLine = (props: IConsumptionGraphPlainProps) => {
  const fullYearData: (number | null)[] = []
  let labels: string[] = []
  uniq(props.data.map((x) => x.year)).forEach((year) => {
    for (let month = 1; month <= 12; month++) {
      labels.push(year + '-' + month)
      const data = props.data.find((x) => x.year === year && x.month === month)
      fullYearData.push(data ? data.value : null)
    }
  })

  let mainDataSet: IReadingYearData[] = [
    {
      label: 'Avläsning',
      data: fullYearData,
      backgroundColor: 'rgba(195, 0, 9, 0.8)',
      fill: props.meterType.isConsumption,
      spanGaps: true,
    },
  ]

  let deviationDatasets: IReadingDeviationDataSet[] = []
  let vvTempDevitiationMax: number[] = []
  let vvTempDevitiationMin: number[] = []
  let heaterDeviationMin = []
  const values = props.data.map((x) => x.value)
  if (props.meterType.name.toLowerCase() === 'värmeväxlare') {
    if (props.deviations) {
      heaterDeviationMin.push(props.deviations['Effektivitet'])
    }
    let deviationDataset: IReadingDeviationDataSet = {
      label: 'Min',
      data: heaterDeviationMin,
      borderColor: 'rgba(195, 0, 9, 0.2)',
      backgroundColor: 'rgba(195, 0, 9, 0.2)',
      fill: props.meterType.isConsumption === true ? true : false,
      type: 'line',
      pointRadius: 0,
      spanGaps: true,
    }
    //mainDataSet.push(deviationDataset)
  } else if (props.meterType.name.toLowerCase() === 'temp, vv') {
    if (props.deviations) {
      vvTempDevitiationMax.push(props.deviations['MAX_TEMP'])
      vvTempDevitiationMin.push(props.deviations['MIN_TEMP'])
    }
    deviationDatasets = [
      {
        label: 'Max',
        data: vvTempDevitiationMax,
        borderColor: 'rgba(195, 0, 9, 0.2)',
        backgroundColor: 'rgba(195, 0, 9, 0.2)',
        fill: props.meterType.isConsumption,
        type: 'line',
        pointRadius: 0,
        spanGaps: true,
      },
      {
        label: 'Min',
        data: vvTempDevitiationMin,
        borderColor: 'rgba(195, 0, 9, 0.2)',
        backgroundColor: 'rgba(195, 0, 9, 0.2)',
        fill: props.meterType.isConsumption,
        type: 'line',
        pointRadius: 0,
        spanGaps: true,
      },
    ]
    //mainDataSet.push(...deviationDatasets)
  }
  const chartData = {
    datasets: mainDataSet,
    labels: labels,
  }
  return (
    <Line
      options={{
        animation: {duration: 0},
        plugins: {
          annotation: {
            annotations: getAnnotations(props.deviations, true),
          },
        },
      }}
      data={chartData}
    />
  )
}
export default ReadingGraphLine
