import React, {useEffect, useState} from 'react'
import {Office} from '../../../redux/estateAttribute/interfaces'
import {CreateOfficeForm} from './interfaces'
import {useNavigate} from 'react-router'
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {CreateOfficeState} from '../../../redux/office/interfaces'
import {RootState} from '../../../redux/rootReducer'
import {createOffice} from '../../../redux/office/actions'
import CreateOffice from './CreateOffice'

const defaultOffice: Office = {
  id: 0,
  name: '',
  description: '',
  insDate: new Date(),
  updDate: new Date(),
}

const CreateOfficeContainer = () => {
  const dispatch = useDispatch()
  const methods = useForm<CreateOfficeForm>({defaultValues: defaultOffice})
  const {handleSubmit} = useForm<CreateOfficeForm>()
  const {setValue, reset} = methods
  const navigate = useNavigate()

  const [office, setOffice] = useState<Office>(defaultOffice)

  const createOfficeState: CreateOfficeState = useSelector((state: RootState) => state.office.createOffice)

  const onSaveOffice: SubmitHandler<CreateOfficeForm> = (data: CreateOfficeForm) => {
    dispatch(createOffice(data.name, data.description))
    navigate('/office')
  }

  return office ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSaveOffice)}>
        <CreateOffice />
      </form>
    </FormProvider>
  ) : (
    <div>Loading...</div>
  )
}
export default CreateOfficeContainer
