import React, {useEffect, useState} from 'react'
import Report from './Report'
import {useDispatch, useSelector} from 'react-redux'
import {getAllReports} from '../../redux/report/actions'
import {RootState} from '../../redux/rootReducer'
import {CustomReportParameter, CustomReportValueParameter, CustomReportValueParameterFilter, IReportDefinition, PropertyTransform, ReportPreview} from '../../redux/report/interfaces'
import {applyTransformations, generateCsvFile, jsonToCsv} from '../../shared/utils/utilities'
import reportService from '../../services/reportService'
import {NOT_SET, OFFICE, ALL} from '../../shared/utils/constants'

const useReports = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllReports())
  }, [dispatch])
  return useSelector((state: RootState) => state.report.reportList.reports)
}

const useSelectedOffice = () => {
  return useSelector((state: RootState) => state.tree.selectedOffice)
}

const ReportsContainer = () => {
  const reports = useReports()
  const selectedOffice = useSelectedOffice()

  const [isReportLoading, setIsReportLoading] = useState(false)
  const [numberOfReportItems, setNumberOfReportItems] = useState(0)
  const [progress, setProgress] = useState(0)

  const getFilterString = (office: string) => {
    switch (office) {
      case 'Alla':
      case 'Alla kontor':
        return 'ALL' // Assume 'ALL' is a constant defined somewhere
      case 'Ej angivet':
        return 'NOT_SET' // Assume 'NOT_SET' is a constant defined somewhere
      default:
        return office
    }
  }

  const initiateReportGeneration = async (report: IReportDefinition, preview: ReportPreview, customReportParameter: CustomReportParameter, startDate: Date, stopDate: Date) => {

    setNumberOfReportItems(preview.uniqueObjectIds.length)
    setProgress(preview.uniqueObjectIds.length / 10)
    setIsReportLoading(true)
    const results = await processReportInBatches(preview.uniqueObjectIds, customReportParameter)
    if (results.length > 0) {
      saveReportAsCsv(report, results, startDate, stopDate)
    }
    setIsReportLoading(false)
    setProgress(0)
  }

  const processReportInBatches = async (objectIds: number[], customReportParameter: CustomReportParameter) => {
    const batchSize = 20
    let results : any[] = []

    for (let i = 0; i < objectIds.length; i += batchSize) {
      const slice = objectIds.slice(i, i + batchSize)
      const valueParameters: CustomReportValueParameter[] = slice.map((id) => ({name: 'estate', value: id.toString()}))
      const parameter: CustomReportParameter = {...customReportParameter, parameters: [...customReportParameter.parameters, ...valueParameters]}      
     
      const sliceResult = await reportService.runReport(parameter)
      setProgress((prev) => prev + slice.length)
      results.push(...sliceResult)      
    }
    return results
  }

  const saveReportAsCsv = (report: IReportDefinition, results: any[], startDate: Date, stopDate: Date) => {
    const fileName = generateFileName(report.title, startDate, stopDate)
    const csvContent = jsonToCsv(applyTransformations(results, report.propertyTransforms), report.columnHeaders)
    generateCsvFile(csvContent, fileName)
  }

  const generateFileName = (title: string, startDate: Date, stopDate: Date) => {
    const datePortion = `${startDate.getFullYear()}-${startDate.getMonth() + 1}_${stopDate.getFullYear()}-${stopDate.getMonth() + 1}`
    const timePortion = new Date().toLocaleString().substring(0, 10)
    return `${title.toLowerCase()} ${datePortion}_${timePortion}.csv`
  }

  const runReport = async (selectedReport: IReportDefinition, useOfficeFilter: boolean, startDate: Date, stopDate: Date) => {
    if (!selectedReport || selectedReport.id === 0) return
    const officeFilter = getFilterString(selectedOffice)
    const customReportParameter: CustomReportParameter = {
      name: selectedReport.name,
      parameters: [
        {name: 'fromYear', value: startDate.getFullYear().toString()},
        {name: 'fromMonth', value: (startDate.getMonth() + 1).toString()},
        {name: 'toYear', value: stopDate.getFullYear().toString()},
        {name: 'toMonth', value: (stopDate.getMonth() + 1).toString()},
      ],
      filter: {key: 'OFFICE', value: useOfficeFilter ? officeFilter : 'ALL'},
    }
    if (selectedReport.segmented) {
      const preview = await reportService.getPreview(customReportParameter)
      if (preview) {
        await initiateReportGeneration(selectedReport, preview, customReportParameter, startDate, stopDate)
      }
    } else {
      if (selectedReport === undefined || selectedReport.id === 0) return
      setIsReportLoading(true)
      setProgress(0)
      const result = await reportService.runReport(customReportParameter)
      if (result) {
        const csvContent = jsonToCsv(applyTransformations(result, selectedReport.propertyTransforms), selectedReport.columnHeaders)
        const fileName = `${selectedReport.title.toLocaleLowerCase()} ${startDate.getFullYear()}-${startDate.getMonth() + 1}_${stopDate.getFullYear()}-${stopDate.getMonth() + 1}_${new Date()
          .toLocaleString()
          .substring(0, 10)}.csv`
        generateCsvFile(csvContent, fileName)
        setIsReportLoading(false)
      }
    }
  }

  return <Report reports={reports} onRunReportClick={runReport} isReportLoading={isReportLoading} numberOfReportItems={numberOfReportItems} progress={progress} />
}

export default ReportsContainer
