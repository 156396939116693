import { Outlet, Navigate } from 'react-router-dom'
import { isAuthorized, isTokenExpired } from '../../shared/utils/utilities'
/**
 * Render routed components as outlet.
 * If user is unauthenticated or token has expired - redirect to login.
 * @returns React.Component
 */
const PrivateRoutes = () => {
  return !isTokenExpired() && isAuthorized() ? <Outlet /> : <Navigate to="/login" />
}
export default PrivateRoutes