export enum ActionTypes {
    FETCH_PDF_CONSUMPTION_LOADING = 'FETCH_PDF_CONSUMPTION_LOADING',
    FETCH_PDF_CONSUMPTION_SUCCESS = 'FETCH_PDF_CONSUMPTION_SUCCESS',
    FETCH_PDF_CONSUMPTION_ERROR = 'FETCH_PDF_CONSUMPTION_ERROR',
    RESET_PDF_CONSUMPTION_LOADING = 'RESET_PDF_CONSUMPTION_LOADING',
    RESET_PDF_CONSUMPTION_SUCCESS = 'RESET_PDF_CONSUMPTION_SUCCESS',
    RESET_PDF_CONSUMPTION_ERROR = 'RESET_PDF_CONSUMPTION_ERROR',
    PDF_FILE_CREATE_LOADING = "PDF_FILE_CREATE_LOADING",
    PDF_FILE_CREATE_SUCCESS = "PDF_FILE_CREATE_SUCCESS",
    PDF_FILE_CREATE_ERROR = "PDF_FILE_CREATE_ERROR",
    PDF_TABLE_STATE_CHANGE = "PDF_TABLE_STATE_CHANGE",
    CLEAR_PDF_TABLE_STATE = "CLEAR_PDF_TABLE_STATE"
}