import React from 'react'
import {ConsumptionOverviewListingProps} from './interfaces'
import { getUnitByValueCategory, getUnitDividedByAreaLabel } from '../../../shared/utils/utilities'
const ConsumptionOverviewListing: React.FC<ConsumptionOverviewListingProps> = (props: ConsumptionOverviewListingProps) => {
  const renderUnit = () => {
    if (props.unitName) return props.unitName
    return getUnitDividedByAreaLabel(props.unit?.comment ?? "", props.category)
  }
  const renderLabel = () => {
    if (props.label) return props.label
    else return `${props.meterType.name} (${renderUnit()})`
  }

  return (
    <div style={{flex: 1}}>
      <h3 style={{margin: '0'}}>{renderLabel()}:</h3>
      <ul style={{listStyleType: 'none', paddingLeft: '0', margin: '8px 0'}}>
        {props.years.map((year) => {
          const yearSum = props.data.filter((x) => x.year === year).reduce((acc, x) => acc + x.actual, 0)
          const yearEstimate = props.data.filter((x) => x.year === year).reduce((acc, x) => acc + x.estimate, 0)
          return (
            <li key={year} style={{marginBottom: '4px'}}>
              <strong>{year}: </strong> {yearSum.toFixed(2)} <em>[{yearEstimate.toFixed(2)}]</em> {renderUnit()}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default ConsumptionOverviewListing
