import React from 'react'
import EstateMeterCostList from './EstateMeterCostList'
import { IEstateMeterCostTabProps } from './interfaces'
import { Box } from '@mui/material'
const EstateMeterCostTab = (props: IEstateMeterCostTabProps) => {
    return (
      <Box sx={{'marginTop': '10px'}}>
        <EstateMeterCostList
        estateMeterCosts={props.values}
        onEstateMeterCostEditClick={props.onEstateMeterCostEditClick}
        onEstateMeterCostDeleteClick={props.onEstateMeterCostDeleteClick}
      />
      </Box>
    )
}
export default EstateMeterCostTab