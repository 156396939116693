import {ActionTypes} from './types'
import {Dispatch} from 'redux'
import counterValueService from '../../services/counterValueService'
import {IValues} from './interfaces'
import { toSWEISOString } from '../../shared/utils/utilities'
import { DateTime } from 'luxon'
export const saveCounterValue = (counterId: number, value: number, date: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_LOADING})
  try {
    const dateTime: DateTime = DateTime.fromJSDate(date).setZone('Europe/Berlin')
    const result = await counterValueService.saveCounterValue({counterId, value, date: dateTime, override: true})
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_FAILED, payload: error})
  }
}
export const updateCounterValue = (counterValueId: number, value: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_LOADING})
  try {
    const result = await counterValueService.updateCounterValue({id: counterValueId, value: value})
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_FAILED, payload: error})
  }
}
export const updateCounterValues = (counterValueId: number, fromAir: number, offAir: number, outAir: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_LOADING})
  try {
    const result = await counterValueService.updateCounterValues({
      id: counterValueId,
      values: [
        {type: 'FromAir', value: fromAir},
        {type: 'OffAir', value: offAir},
        {type: 'OutAir', value: outAir},
      ],
    })
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_FAILED, payload: error})
  }
}
export const saveCounterValues = (counterId: number, fromAir: number, offAir: number, outAir: number, date: Date) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_LOADING})
  const values: IValues[] = [
    {value: fromAir, type: 'FromAir'},
    {value: offAir, type: 'OffAir'},
    {value: outAir, type: 'OutAir'},
  ]
  try {
    const dateTime: DateTime = DateTime.fromJSDate(date).setZone('Europe/Berlin')
    const result = await counterValueService.saveCounterValues({counterId, values, date: dateTime, override: true})
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_FAILED, payload: error})
  }
}
export const deleteCounterValue = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.SAVE_COUNTER_VALUE_LOADING})
  try {
    const result = await counterValueService.deleteCounterValue(id)
    return dispatch({type: ActionTypes.DELETE_COUNTER_VALUE_SUCCESS, payload: id})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_COUNTER_VALUE_FAILED, payload: error})
  }
}
export const saveCounterReset = (counterValueId: number, turned: boolean, changed: boolean, lastValue: number, startValue: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionTypes.SAVE_COUNTER_RESET_LOADING })
  try {
    const result = await counterValueService.saveCounterReset(counterValueId, turned, changed, lastValue, startValue)
    return dispatch({ type: ActionTypes.SAVE_COUNTER_RESET_SUCCESS, payload: result })
  }
  catch (error) {
    return dispatch( { type: ActionTypes.SAVE_COUNTER_RESET_FAILED, payload: error} )
  }
}
export const saveCounterValueLog = (counterValueId: number, text: string, tag: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionTypes.SAVE_COUNTER_VALUE_LOG_LOADING })
  try {
    const result = await counterValueService.saveCounterValueLog(counterValueId, text, tag)
    return dispatch({ type: ActionTypes.SAVE_COUNTER_VALUE_LOG_SUCCESS, payload: result })
  }
  catch (error) {
    return dispatch( { type: ActionTypes.SAVE_COUNTER_VALUE_LOG_FAILED, payload: error} )
  }
}
export const getCounterValueLog = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_COUNTER_VALUE_LOG_LOADING})
  try {
      const readings = await counterValueService.getCounterValueLog(id)        
      return dispatch({type: ActionTypes.GET_COUNTER_VALUE_LOG_SUCCESS, payload: readings})
  }
  catch(error) {
      return dispatch({type: ActionTypes.GET_COUNTER_VALUE_LOG_FAILED, payload: error})
  }
}
export const getCounteReset = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_COUNTER_RESET_LOADING})
  try {
      const readings = await counterValueService.getCounterReset(id)        
      return dispatch({type: ActionTypes.GET_COUNTER_RESET_SUCCESS, payload: readings})
  }
  catch(error) {
      return dispatch({type: ActionTypes.GET_COUNTER_RESET_FAILED, payload: error})
  }
}