import SecButton from '../shared/Inputs/SecButton'
import SecTextField from '../shared/Inputs/SecTextField'
import PageTitleWrapper from '../shared/PageTitleWrapper'
import {Add, CloudCircle, Delete, Save, SettingsInputAntenna} from '@mui/icons-material'
import {Button, Card, CardContent, CardHeader, Container, Divider, FormControlLabel, Grid, Switch, TextField, Typography} from '@mui/material'
import {IOwnerDetailsProps} from '../../redux/estate/Interfaces'
import {deleteOwner, editOwner, getOwnerById} from '../../redux/owner/ownerActions'
import {IOwner, IUpdateOwnerForm} from '../../redux/owner/ownerInterfaces'
import {RootState} from '../../redux/rootReducer'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import SaveAlert from '../shared/alerts/SaveAlert'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import {hasEditPermissions, hasPermissions, isAdmin} from '../../shared/utils/utilities'
import ConfirmDialog from '../shared/dialogs/ConfirmDialog'
import { NODE_WRITE } from '../../shared/utils/constants'

const OwnerDetails = (props: IOwnerDetailsProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const owner = useSelector((state: RootState) => state.owner.selectedOwner?.owner)

  useEffect(() => {
    dispatch(getOwnerById(props.id))
    //console.log(` OwnerDetails  ${owner?.name}  ${props.id}`)
  }, [props.id])

  const [name, setName] = useState<string>(owner ? owner.name : '')
  const [comment, setComment] = useState<string>(owner ? owner.comment : '')
  const [hidden, setHidden] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const canEdit = hasPermissions([NODE_WRITE])

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() => {
    if (owner) {
      setName(owner.name)
      setComment(owner.comment)
      setHidden(owner.hidden)
    }
  }, [owner])
  const onSaveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(editOwner(props.id, name, comment, hidden))
    setSaveResult(SaveResponse.SUCCESS)
    setAlertOpen(true)
  }
  const onDeleteOwnerClick = (e: React.MouseEvent<HTMLButtonElement>) => setDeleteDialogOpen(true)
  const onConfirmDialogClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(deleteOwner(props.id))
    setDeleteDialogOpen(false)
    navigate('/Home')
  }

  const renderModifyButtons = () => {
    return canEdit ? (
      <Grid item xs={12} container justifyContent='flex-start' direction='row'>
        <Grid item xs={1}>
          <SecButton startIcon={<Save />} variant='contained' onClick={onSaveClick} sx={{minWidth: '80px'}}>
            Spara
          </SecButton>
        </Grid>
        {hasAdminRights && (
          <Grid item xs={2}>
            <Link to={`Estate/Create`}>
              <SecButton startIcon={<Add />} variant='contained' sx={{marginLeft: '10px', minWidth: '80px'}}>
                Ny fastighet
              </SecButton>
            </Link>
          </Grid>
        )}
        <Grid item xs={6} sx={{marginLeft: '10px'}}>
          <a href='https://app.metry.io/id/oauth/authorize?client_id=44bc8621f3fb2b0a176b523175b2cfc53849b3fb3ad26664bb51403ac34a&redirect_uri=http://localhost:3000&grant_type=authorization_code&response_type=code&scope=basic'>
            <SecButton startIcon={<CloudCircle />} variant='contained' sx={{minWidth: '80px'}}>
              Anslut till Metry
            </SecButton>
          </a>
        </Grid>
        {hasAdminRights && (
          <Grid item xl={3} sx={{marginLeft: 'auto'}}>
            <SecButton startIcon={<Delete />} variant='contained' onClick={onDeleteOwnerClick} color='error' sx={{float: 'right'}}>
              Radera fastighetsägare
            </SecButton>
          </Grid>
        )}
      </Grid>
    ) : (
      ''
    )
  }

  const renderOwnerInputs = () => {
    if (owner === undefined) return <div>Vald fastighetsägare kunde inte hittas!</div>
    return (
      <Grid container spacing={5}>
        <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
          <Grid item xs={12}>
            <SaveAlert
              result={saveResult}
              open={alertOpen}
              onClose={() => setAlertOpen(false)}
              successMessage='Fastighetsägaren har uppdaterats.'
              errorMessage='Misslyckades med att uppdatera fastighetsägaren.'
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
          <Grid item xs={2}>
            <SecTextField value={owner.id} type='number' fullWidth disabled={true} InputProps={{readOnly: true}} InputLabelProps={{shrink: true}} label='Id' />
          </Grid>
          <Grid item xs={3}>
            <SecTextField
              fullWidth
              inputProps={{maxLength: 40}}
              InputLabelProps={{shrink: true}}
              value={name}
              label='Namn'
              onChange={(e: React.KeyboardEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel control={<Switch checked={hidden} onChange={() => setHidden(!hidden)} title='Använd kontorsfilter' disabled={!canEdit} />} label='Avläses ej' />
          </Grid>
          <Grid item xs={4}>
            <SecTextField
              fullWidth
              inputProps={{maxLength: 40}}
              value={comment}
              InputLabelProps={{shrink: true}}
              label='comment'
              multiline
              onChange={(e: React.KeyboardEvent<HTMLInputElement>) => setComment(e.currentTarget.value)}
            />
          </Grid>
        </Grid>
        {renderModifyButtons()}
      </Grid>
    )
  }

  return (
    <div>
      <PageTitleWrapper>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h3' component='h3' gutterBottom></Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </PageTitleWrapper>
      <Container maxWidth='lg'>
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <Card component='form' noValidate autoComplete='off'>
              <CardHeader title='Egenskaper' />
              <Divider />
              <CardContent>{renderOwnerInputs()}</CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <ConfirmDialog
        open={deleteDialogOpen}
        title='Radera fastighetsägare'
        content={`Är du säker på att du vill radera fastighetsägaren ${name}?`}
        onClose={(e) => setDeleteDialogOpen(false)}
        onConfirm={onConfirmDialogClick}
      />
    </div>
  )
}
export default OwnerDetails
