import React, {useState, useEffect} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Stack, TextField, Typography} from '@mui/material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {ArrowBack} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import {UpdateOfficeForm} from './interfaces'
import {isAdmin} from '../../../shared/utils/utilities'
const UpdateOffice: React.FC = () => {
  const methods = useFormContext<UpdateOfficeForm>()
  const [showOwners, setShowOwners] = useState<boolean>(false)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const {setValue} = useFormContext<UpdateOfficeForm>()
  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])
  // Render and return a div form with all the necessary elements in the EditUserForm
  return (
    <Grid sx={{margin: '20px'}} justifyContent='center' alignItems='stretch'>
      <Container maxWidth='lg'>
        <Card>
          <CardHeader title='Uppdatera kontor' />
          <CardContent>
            <Grid container spacing={5}>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                <Grid item xs={3}>
                  <Controller name='id' control={methods.control} render={({field}) => <TextField disabled fullWidth label='Id' {...field} />} />
                </Grid>
                <Grid item xs={3}>
                  <Controller name='name' control={methods.control} render={({field}) => <TextField fullWidth label='Namn' {...field} />} />
                </Grid>
                <Grid item xs={3}>
                  <Controller name='description' control={methods.control} render={({field}) => <TextField fullWidth label='Beskrivning' {...field} />} />
                </Grid>
              </Grid>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                <Grid item xs={3}>
                  <Controller name='insDate' control={methods.control} render={({field}) => <TextField disabled fullWidth label='Skapades' {...field} />} />
                </Grid>
                <Grid item xs={3}>
                  <Controller name='updDate' control={methods.control} render={({field}) => <TextField disabled fullWidth label='Uppdaterades' {...field} />} />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid item xs={12}>
              <Stack direction='row' spacing={2}>
                {hasAdminRights && (
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<SaveOutlinedIcon fontSize='small' />}>
                    Spara
                  </Button>
                )}
                <Link to='/office'>
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='secondary' startIcon={<ArrowBack fontSize='small' />}>
                    Tillbaka
                  </Button>
                </Link>
              </Stack>
            </Grid>
          </CardActions>
        </Card>
      </Container>
    </Grid>
  )
}
export default UpdateOffice
