import CounterIntegration from '../../components/counter/CounterIntegration'
import {IConsumption, IConsumptionNodeDto} from '../../redux/consumption/interfaces'
import {ICounterIntegrationDto, IDisabledPeriodParameter, IInactivePeriod, IPeriodParameter} from '../../redux/counter/interfaces'
import {ITableLog} from '../../redux/counterValue/interfaces'
import {IMeterType} from '../../redux/meter/interfaces'
import {IDeviationValue} from '../../redux/counter/interfaces'
import {IAuth} from '../../redux/login/loginInterfaces'
import {Role} from '../../shared/enums/Role'
import {CounterIntegrationType} from '../enums/CounterIntegrationType'
import {ResetType} from '../enums/ResetType'
import {IEstate} from '../../redux/estate/Interfaces'
import {ISelectItem} from '../../components/shared/dialogs/SearchSelectDialog'
import userService from '../../services/userService'
import attributeService from '../../services/attributeService'
import jwt_decode from 'jwt-decode'
import IAttribute from '../../redux/attribute/interfaces'
import {UserAttribute} from '../../redux/user/interfaces'
import {ATEMP, EMISSION_KG_CO2e, KWH, LITER, LOA, M3, MWH, M_EXP_2, M_EXP_3, NOT_SET, USER} from './constants'
import {NodeCostEmissionDataDto} from '../../redux/node/interfaces'
import {PropertyTransform} from '../../redux/report/interfaces'
import {IUnit} from '../../redux/unit/unitInterfaces'
import ConsumptionNodeDto from '../../redux/consumption/ConsumptionNodeDto'

const defaultInactivePeriods: IInactivePeriod[] = [
  {period: 1, isInactive: false, label: 'Jan'},
  {period: 2, isInactive: false, label: 'Feb'},
  {period: 3, isInactive: false, label: 'Mar'},
  {period: 4, isInactive: false, label: 'Apr'},
  {period: 5, isInactive: false, label: 'Maj'},
  {period: 6, isInactive: false, label: 'Jun'},
  {period: 7, isInactive: false, label: 'Jul'},
  {period: 8, isInactive: false, label: 'Aug'},
  {period: 9, isInactive: false, label: 'Sep'},
  {period: 10, isInactive: false, label: 'Okt'},
  {period: 11, isInactive: false, label: 'Nov'},
  {period: 12, isInactive: false, label: 'Dec'},
]

const defaultMonths: string[] = ['Jan', 'Feb', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
/**
 * Verifies provided string is not undefined, null or empty.
 * @param str the string to verify
 * @returns boolean
 */
export function isNullOrWhiteSpace(str: string | null | undefined) {
  return str === undefined || str === null || str.trim().length === 0
}
/**
 * Verifies whether user has permissions to edit properties.
 * @returns boolean
 */
export function hasEditPermissions() {
  const authData: string | null = localStorage.getItem('auth')
  if (isNullOrWhiteSpace(authData)) return false
  const auth: IAuth = JSON.parse(authData ?? '')
  try {
    const decoded: any = jwt_decode(auth.token)
    let role: Role = Role[decoded.role as keyof typeof Role]
    return role > Role.Customer
  } catch (error) {}
  return false
}

export function hasPermissions(permissions: string[]) {
  const authData: string | null = localStorage.getItem('auth')
  if (isNullOrWhiteSpace(authData)) return false
  const auth: IAuth = JSON.parse(authData ?? '')
  try {
    const decoded: any = jwt_decode(auth.token)

    let role: Role = Role[decoded.role as keyof typeof Role]
    if (role > Role.Customer) return true

    let permissionsInClaims: string[] = []
    if (typeof decoded.Permission === 'string') permissionsInClaims = [decoded.Permission]
    else permissionsInClaims = decoded.Permission
    return permissions.every((item) => permissionsInClaims && permissionsInClaims.includes(item))
  } catch (error) {}
  return true
}

export function htmlDecode(str: string) {
  var element = document.createElement('textarea')

  const decode = (str: string) => {
    if (str && typeof str === 'string') {
      str = str.replace(/</g, '&lt;')
      str = str.replace(/>/g, '&gt;')
      element.innerHTML = str
      str = element.textContent ?? ''
      element.textContent = ''
    }
    return str
  }
  return decode(str)
}

export function isInPeriod(startDate: Date, stopDate: Date, currDate: Date) {
  let start = new Date(startDate.getFullYear(), startDate.getMonth(), 15)
  let stop = new Date(stopDate.getFullYear() + (stopDate.getMonth() === 11 ? 1 : 0), stopDate.getMonth() === 11 ? 0 : stopDate.getMonth() + 1, 14)
  return new Date(currDate) >= start && new Date(currDate) <= stop
}
export function logIsInConsumptionPeriod(consumption: IConsumption, log: ITableLog) {
  const logDate = new Date(log.date)
  if (log.isConsumption) {
    return consumption.year === logDate.getFullYear() && consumption.month === logDate.getMonth() + 1
  } else {
    if (logDate.getDate() < 15) {
      if (logDate.getMonth() === 0) {
        return consumption.year === logDate.getFullYear() - 1 && consumption.month === 12
      } else {
        return consumption.year === logDate.getFullYear() && consumption.month === logDate.getMonth()
      }
    } else {
      return consumption.year === logDate.getFullYear() && consumption.month === logDate.getMonth() + 1
    }
  }
}

export function htmlDecodeIfExists(text: string | undefined) {
  return text && isNullOrWhiteSpace(text) ? htmlDecode(text) : ''
}
export function isNumeric(number: string) {
  return !isNaN(parseFloat(number)) && isFinite(parseFloat(number))
}
function getDecimalSeparator(locale: string) {
  const tryNumber = 1.1
  return tryNumber.toLocaleString(locale).substring(1, 2)
}
export function jsonToCsv(json: any, headers: string[], uselocale = true) {
  const parseArrayRow = (row: any) => {
    let lastValue = ''
    for (let cell = 0; cell < headers.length; cell++) {
      var value = row[cell] === null ? '' : row[cell].toString()
      if (row[cell] instanceof Date) {
        value = row[cell].toLocaleString()
      }
      let result = value.replace(/"/g, '""')
      if (result.search(/"(|,|\n)/g) >= 0) {
        result = '"' + result + '"'
      }
      if (cell > 0) {
        lastValue += ';'
      }
      lastValue += result
    }
    return lastValue + '\n'
  }
  const parseJsonRow = (row: any) => {
    let lastValue = ''
    var index = 0
    for (var cell in row) {
      if (row.hasOwnProperty(cell)) {
        var value = row[cell] === null ? '' : row[cell].toString()
        if (row[cell] instanceof Date) {
          value = row[cell].toLocaleString()
        }
        if (isNumeric(value) && uselocale) {
          var decimalSeparator = getDecimalSeparator('sv-SE')
          value = value.replace('.', decimalSeparator)
        }
        let result = value.replace(/"/g, '""').replace(/[\n\r]/g, ' ')
        if (result.search(/"(|,|\n)/g) >= 0) {
          result = '"' + result + '"'
        }
        if (index > 0) {
          lastValue += ';'
        }
        lastValue += result
      }
      index++
    }
    return lastValue + '\n'
  }
  let csv = ''
  csv += parseArrayRow(headers)
  for (var i = 0; i < json.length; i++) {
    csv += parseJsonRow(json[i])
  }
  return csv
}
export function generateCsvFile(csv: string, filename: string) {
  const file = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {type: 'text/csv;charset=utf-8'})

  const a = document.createElement('a')
  if (a.download !== undefined) {
    const url = URL.createObjectURL(file)
    a.setAttribute('href', url)
    a.setAttribute('download', filename)
    a.style.visibility = 'hidden'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
export function generateTextFile(txt: string, filename: string) {
  var text = txt.trim()
  const file = new Blob([text], {type: 'text/plain;charset=utf-8'})

  const a = document.createElement('a')
  if (a.download !== undefined) {
    const url = URL.createObjectURL(file)
    a.setAttribute('href', url)
    a.setAttribute('download', filename)
    a.style.visibility = 'hidden'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
export function parseStopDate(stopDate: Date) {
  return stopDate.getMonth() < 11 ? new Date(stopDate.getFullYear(), stopDate.getMonth(), 1) : new Date(stopDate.getFullYear() + 1, 0, 1)
}
export function parseStopDateYearly(stopDate: Date) {
  return new Date(stopDate.getFullYear(), 11, 31)
}
export function parseStopDateMonthly(stopDate: Date) {
  return new Date(stopDate.getFullYear(), stopDate.getMonth(), 31)
}
export function getCurrentPeriodAsDate() {
  const period = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0)
  if (new Date().getDate() < 15) {
    if (period.getMonth() === 0) return new Date(new Date().getFullYear() - 1, 11, 1, 0, 0, 0)
    else return new Date(new Date().getFullYear(), period.getMonth() - 1, 1, 0, 0, 0)
  } else return period
}
export function setMeterTypeOrder(meterTypes: IMeterType[] | undefined) {
  if (!meterTypes) return meterTypes
  const prioTypes = ['fjärrvärme, energi', 'fjärrvärme, flöde', 'fjärrkyla, energi', 'fjärrkyla, flöde']
  const sortedTypes: IMeterType[] = meterTypes
    .map((type: IMeterType) => {
      let sortOrder = prioTypes.indexOf(type.name.toLocaleLowerCase())
      if (sortOrder === -1) sortOrder = prioTypes.length + 1
      return {...type, sortOrder: sortOrder}
    })
    .sort((a, b) => {
      if (a.sortOrder > b.sortOrder) return 1
      else if (a.sortOrder < b.sortOrder) return -1
      else return 0
    })
  return sortedTypes
}
export function sortSelectItems(items: ISelectItem[]) {
  if (!items) return items
  return items.sort((a, b) => {
    const nameA = a.text.trim().toLowerCase()
    const nameB = b.text.trim().toLocaleLowerCase()
    if (nameA > nameB) return 1
    else if (nameA < nameB) return -1
    else return 0
  })
}
export function getIntegrationType(integration: ICounterIntegrationDto) {
  if (integration.isExport && integration.isImport) return CounterIntegrationType.Both
  else if (integration.isExport) return CounterIntegrationType.Export
  else if (integration.isImport) return CounterIntegrationType.Import
  else return CounterIntegrationType.None
}
export function convertToInactivePeriod(parameter: IDisabledPeriodParameter | undefined) {
  const x = defaultInactivePeriods.map((d: IInactivePeriod) => {
    const disabledPeriod = parameter?.periods.find((p: IPeriodParameter) => new Date(p.startDate).getMonth() + 1 === d.period)
    if (disabledPeriod === undefined || disabledPeriod === null) {
      d.isInactive = false
      return d
    }
    const modifiedInactivePeriod: IInactivePeriod = {
      period: d.period,
      label: d.label,
      isInactive: disabledPeriod !== undefined && disabledPeriod !== null && disabledPeriod.recurring,
    }
    return modifiedInactivePeriod
  })
  return x
  //Array.from({ length: 11 }, (x, y) => y +1)
}
export function getMonthLabel(month: number) {
  return defaultMonths.length > month - 1 ? defaultMonths[month - 1] : ''
}
export function parseResetType(turned: number, changed: number) {
  if (turned) return ResetType.TURNED
  else if (changed) return ResetType.CHANGED
  else return ResetType.NOT_SET
}
/**
 *  Verify token has not expired
 * @returns boolean
 */
export function isTokenExpired() {
  const authData: string | null = localStorage.getItem('auth')
  if (authData) {
    const auth: IAuth = JSON.parse(authData ?? '')
    if (auth) {
      try {
        const decoded: any = jwt_decode(auth.token)
        const expirationTimeStamp: number = decoded.exp * 1000 - 60000
        const expirationDate = new Date(expirationTimeStamp)
        return new Date() >= expirationDate
      } catch (error) {}
    }
  }
  return false
}
/**
 * Verify user is authorized to access the application
 * @returns boolean
 */
export function isAuthorized() {
  const authData: string | null = localStorage.getItem('auth')
  if (authData) {
    const auth: IAuth = JSON.parse(authData)
    if (auth) {
      try {
        const decoded: any = jwt_decode(auth.token)
        let role: Role = Role[decoded.role as keyof typeof Role]
        return role >= Role.Customer
      } catch (error) {}
    }
  }
  return false
}

export function isAdmin() {
  const authData: string | null = localStorage.getItem('auth')
  if (authData) {
    const auth: IAuth = JSON.parse(authData)
    if (auth) {
      try {
        const decoded: any = jwt_decode(auth.token)
        let role: Role = Role[decoded.role as keyof typeof Role]
        return role >= Role.Administrators
      } catch (error) {}
    }
  }
  return false
}

const isInRole = (role: Role) => {
  const authData: string | null = localStorage.getItem('auth')
  if (authData) {
    const auth: IAuth = JSON.parse(authData)
    if (auth) {
      try {
        const decoded: any = jwt_decode(auth.token)
        let userRole: Role = Role[decoded.role as keyof typeof Role]
        return userRole >= role
      } catch (error) {}
    }
  }
  return false
}

export function arrayOverwriteByIdentifier(arr1: any[], arr2: any[], identifier: string) {
  const arr = arr1.map((i1: any) => {
    const i3 = arr2.find((i2: any) => i1[identifier] === i2[identifier])
    return i3 ? i3 : i1
  })
  return arr
}

export function getCounterDisplayName(data: any) {
  if (!isNullOrWhiteSpace(data.counterName) && !isNullOrWhiteSpace(data.counterNumber)) return data.counterName + ' (' + data.counterNumber + ')'
  else if (!isNullOrWhiteSpace(data.counterName)) return data.counterName
  else if (!isNullOrWhiteSpace(data.counterNumber)) return data.counterNumber
  else return data.counterId
}

export function getMeasurePointDisplayName(data: any) {
  if (!isNullOrWhiteSpace(data.measurePointName) && !isNullOrWhiteSpace(data.measurePointNumber)) return data.measurePointName + ' (' + data.measurePointNumber + ')'
  else if (!isNullOrWhiteSpace(data.measurePointName)) return data.measurePointName
  else if (!isNullOrWhiteSpace(data.measurePointNumber)) return data.measurePointNumber
  else return data.measurePointId
}

export function applyTransformations(data: any[], options: PropertyTransform[] | undefined) {
  if (options === undefined) return data

  return data.map((item: any) => {
    const transformedItem = {...item}
    options.forEach((option: PropertyTransform) => {
      const propertyName = Object.keys(item)[option.propertyIndex]
      transformedItem[propertyName] = option.doTransform(item[propertyName])
    })
    return transformedItem
  })
}

export function propsAreEqual(left: any, right: any) {
  if (left === undefined || right === undefined) return false
  if (left === null || right === null) return false

  for (var propName in Object.getOwnPropertyNames(left)) {
    if (left.hasOwnProperty(propName) !== right.hasOwnProperty(propName)) {
      continue
    } else if (typeof left[propName] !== typeof right[propName]) {
      return false
    }
    if (left[propName] !== right[propName]) {
      return false
    }
  }
  return true
}

export function getUnitDividedByAreaLabel(unitName: string | undefined, valueCategory?: string) {
  if (unitName && valueCategory) {
    if (valueCategory.toLocaleLowerCase() === LOA.toLocaleLowerCase()) {
      return getUnitByValueCategory(unitName, valueCategory) + '/' + M_EXP_2
    } else if (valueCategory.toLocaleLowerCase() === ATEMP.toLocaleLowerCase()) {
      return getUnitByValueCategory(unitName, valueCategory) + '/' + M_EXP_2
    }
  }
  return unitName
}

export const shouldHideGraphs = () => JSON.parse(localStorage.getItem('localSettings') ?? '{}')['hideGraphs'] ?? false
export const showVolume = () => JSON.parse(localStorage.getItem('localSettings') ?? '{}')['showVolume'] ?? false
export function castUnit(unit: IUnit | undefined) {
  if (unit === undefined) return undefined

  if (!showVolume() && unit.comment.toLocaleLowerCase() === M3.toLocaleLowerCase()) return LITER
  else if (!showVolume() && unit.comment.toLocaleLowerCase() === M_EXP_3.toLocaleLowerCase()) return LITER
  else if (unit.comment.toLocaleLowerCase() === MWH.toLocaleLowerCase()) return KWH
  
  return undefined
}

export function getUnitByValueCategory(unitName: string | undefined, valueCategory?: string) {
  if (unitName === undefined) return NOT_SET
  if (valueCategory === undefined) return unitName
  if (unitName.toLocaleLowerCase() === MWH.toLocaleLowerCase()) {
    if (valueCategory.toLocaleLowerCase() === LOA.toLocaleLowerCase()) return KWH
    if (valueCategory.toLocaleLowerCase() === ATEMP.toLocaleLowerCase()) return KWH
  } else if (unitName.toLocaleLowerCase() === M3.toLocaleLowerCase()) {
    if (showVolume()) {
      return M_EXP_3
    } else return LITER
  }
  return unitName
}

export function getFactorByUnit(unit?: IUnit, valueCategory?: string) {
  if (unit && valueCategory) {
    if (valueCategory.toLocaleLowerCase() === LOA.toLocaleLowerCase()) {
      if (unit.comment.toLocaleLowerCase() === MWH.toLocaleLowerCase() && getUnitByValueCategory(unit.comment, valueCategory) === KWH) return 1000
      else if (unit.comment.toLocaleLowerCase() === M3.toLocaleLowerCase() && getUnitByValueCategory(unit.comment, valueCategory) === LITER) return 1000
    } else if (valueCategory.toLocaleLowerCase() === ATEMP.toLocaleLowerCase()) {
      if (unit.comment.toLocaleLowerCase() === MWH.toLocaleLowerCase() && getUnitByValueCategory(unit.comment, valueCategory) === KWH) return 1000
      else if (unit.comment.toLocaleLowerCase() === M3.toLocaleLowerCase() && getUnitByValueCategory(unit.comment, valueCategory) === LITER) return 1000
    }
  }
  return 1
}

export function processConsumptions(consumptions: IConsumptionNodeDto[], unit?: IUnit, valueCategory?: string) {
  return consumptions.map((consumption: IConsumptionNodeDto) => new ConsumptionNodeDto({...consumption}, 1))
}
export function toSWEISOString(date: Date) {
  // Format the date to ISO string without milliseconds
  const isoString = date.toISOString().split('.')[0]

  // Append the GMT+1 timezone offset
  const timezoneString = '+01:00'

  return isoString + timezoneString
}

export const getOfficeFilterString = (office: string) => {
  switch (office) {
    case 'Alla':
    case 'Alla kontor':
      return 'ALL' // Assume 'ALL' is a constant defined somewhere
    case 'Ej angivet':
      return 'NOT_SET' // Assume 'NOT_SET' is a constant defined somewhere
    default:
      return office
  }
}
