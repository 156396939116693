import {Dispatch} from 'redux'
import counterPeriodConsumptionService from '../../services/counterPeriodConsumptionService'
import {ActionTypes} from './types'

export const getCounterPeriodConsumptionLog = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING})
  try {
    const log = await counterPeriodConsumptionService.getCounterPeriodConsumptionLog(id)
    return dispatch({type: ActionTypes.GET_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS, payload: log})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR, payload: error})
  }
}
export const getPeriodicConsumption = (counterId: number, year: number, month: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LOADING})
  try {
    const isConsumptionBasedOnReading = await counterPeriodConsumptionService.isConsumptionBasedOnReading(counterId, year, month)
    const consumption = await counterPeriodConsumptionService.getPeriodicConsumption(counterId, year, month)
    return dispatch({type: ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_SUCCESS, payload: {consumption, isConsumptionBasedOnReading}})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_ERROR, payload: error})
  }
}
export const getAllPeriodicConsumption = (counterId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_LOADING})
  try {
    const consumption = await counterPeriodConsumptionService.getAllPeriodicConsumption(counterId)
    return dispatch({type: ActionTypes.FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_SUCCESS, payload: consumption})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_ERROR, payload: error})
  }
}
export const getPeriodConsumptionListItems = (counterId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_LOADING})
  try {
    const items = await counterPeriodConsumptionService.getCounterPeriodConsumptionListItems(counterId)
    return dispatch({type: ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_SUCCESS, payload: items})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_ERROR, payload: error})
  }
}
export const createCounterPeriodConsumptionLog = (counterPeriodConsumptionId: number, text: string, tag: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING})
  try {
    const result = await counterPeriodConsumptionService.createCounterPeriodConsumptionLog(counterPeriodConsumptionId, text, tag)
    return dispatch({type: ActionTypes.CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR, payload: error})
  }
}
export const updateCounterPeriodConsumptionLog = (counterPeriodConsumptionId: number, text: string, tag: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING})
  try {
    const result = await counterPeriodConsumptionService.updateCounterPeriodConsumptionLog(counterPeriodConsumptionId, text, tag)
    return dispatch({type: ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR, payload: error})
  }
}
export const savePeriodicConsumption = (counterId: number, value: number, year: number, month: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.SAVE_COUNTER_PERIOD_CONSUMPTION_LOADING})
  try {
    const saveConsumptionResult = await counterPeriodConsumptionService.savePeriodConsumption(counterId, value, year, month)
    return dispatch({type: ActionTypes.SAVE_COUNTER_PERIOD_CONSUMPTION_SUCCESS, payload: saveConsumptionResult})
  } catch (error) {
    return dispatch({type: ActionTypes.SAVE_COUNTER_PERIOD_CONSUMPTION_ERROR, payload: error})
  }
}
export const deletePeriodicConsumption = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_LOADING})
  try {
    await counterPeriodConsumptionService.deletePeriodConsumption(id)
    return dispatch({type: ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_SUCCESS, payload: id})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_ERROR, payload: error})
  }
}
export const updateCounterPeriodConsumption = (id: number, counterId: number, value: number, year: number, month: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOADING})
  try {
    const saveConsumptionResult = await counterPeriodConsumptionService.updateConsumption(id, counterId, value, year, month)
    return dispatch({type: ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_SUCCESS, payload: saveConsumptionResult})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_ERROR, payload: error})
  }
}
