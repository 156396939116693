import { Select } from '@mui/material'
import { IAuth } from '../../../redux/login/loginInterfaces'
import { hasEditPermissions, hasPermissions } from '../../../shared/utils/utilities'
import React from 'react'
import { NODE_WRITE } from '../../../shared/utils/constants'

const SecSelect = (props: any) => {
    const isAuthenticated = hasPermissions([NODE_WRITE])
    return (
        <Select sx={props.sx} id={props.id} labelId={props.labelId} label={props.label} className={props.className} value={props.value} onChange={props.onChange} disabled={(props.disabled ?? false) || !props.canEdit && !isAuthenticated}>
            {props.children}
        </Select>              
    )
}
export default SecSelect