import React, {useEffect, useState} from 'react'
import {Box, Tab} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import MeterDetails from './MeterDetails'
import MeterCost from './MeterCost'
import MeterEmission from './meterEmission/MeterEmission'
import {RootState} from '../../redux/rootReducer'
const Meter = () => {
  const {id} = useParams()
  const parsedId = id ? parseInt(id) : 0
  const [selectedTab, setSelectedTab] = useState('details')
  const dispatch = useDispatch()
  const onTabChange = (e: any, obj: any) => {
    setSelectedTab(obj)
  }

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box>
          <TabList onChange={onTabChange}>
            <Tab label='Redigera' value={'details'} />
            <Tab label='Prisuppgifter' value={'cost'} />
            <Tab label='Utsläpp' value={'emission'} />
          </TabList>
        </Box>
        <TabPanel value={'details'}>
          <MeterDetails meterId={parsedId} />
        </TabPanel>        
          <TabPanel value={'cost'}>
            <MeterCost meterId={parsedId} />
          </TabPanel>
        <TabPanel value={'emission'}>
          <MeterEmission meterId={parsedId} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
export default Meter
