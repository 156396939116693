import {Add, CheckBox, OpenInNew, Summarize} from '@mui/icons-material'
import {IconButton} from '@mui/material'
import React from 'react'
import {ITableLog} from '../../../redux/counterValue/interfaces'
import {ICaseIconButtonProps} from './interfaces'
const CaseIcon = (props: ICaseIconButtonProps) => {
  if (!props.isSummarized) {
    const openButtons = props.logs
      .filter((log) => log.caseState === 'OPEN' || log.caseState === 'NEW')
      .map((log, index) => (
        <IconButton onClick={(e: any) => props.onClick(log)} key={index}>
          <OpenInNew fontSize='small' />
        </IconButton>
      ))

    const closedButtons = props.logs
      .filter((log) => log.caseState === 'CLOSED')
      .map((log, index) => (
        <IconButton onClick={(e: any) => props.onClick(log)} key={index}>
          <CheckBox fontSize='small' />
        </IconButton>
      ))

    const addButtons = props.logs
      .filter((log) => log.hasCase === false)
      .map((log, index) => (
        <IconButton onClick={(e: any) => props.onClick(log)} key={index}>
          <Add fontSize='small' />
        </IconButton>
      ))

    return (
      <div style={{float: 'right'}}>
        {openButtons}
        {closedButtons}
        {addButtons}
      </div>
    )
  } else {
    return (
      <IconButton>
        <Summarize fontSize='small' />
      </IconButton>
    )
  }
}

export default CaseIcon
