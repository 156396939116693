import {Box, IconButton} from '@mui/material'
import {styled} from '@mui/material/styles'

export const HeaderWrapper = styled(Box)(
  ({theme}) => `
        height:${theme.header.height};
        border-bottom:  ${theme.header.borderColor} ;
        background: ${theme.header.background};
        position: fixed;
        justify-content: space-between;
        width: 100%;
}
`
)

export const IconButtonStyle = styled(IconButton)(
  ({theme}) => `
    width: 60px;
    height: 60px;
`
)
