import Footer from '../shared/Footer'
import PageTitleWrapper from '../shared/PageTitleWrapper'
import {Container, Grid} from '@mui/material'
import {Fragment} from 'react'
import NotificationsTab from './NotificationsTab'
import PageHeader from './PageHeader'

export default function Settings(): React.ReactElement {
  return (
    <Fragment>
      <PageTitleWrapper>
        <PageHeader user={'test'} />
      </PageTitleWrapper>
      <Container maxWidth='lg'>
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <NotificationsTab />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Fragment>
  )
}
