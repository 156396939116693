import {Dispatch} from 'redux'
import {ActionTypes} from './types'
import {ActionTypes as TreeActionTypes} from '../tree/treeTypes'
import meterTypeService from '../../services/meterTypeService'
import counterService from '../../services/counterService'
import externalSystemService from '../../services/externalSystemService'
import {ICounterAttribute, ICounterIntegration, ICounterNumber, IImportResultDto, IInactivePeriod, IsCounterExcluded, ICounterAttributeParameter} from './interfaces'
import {castUnit, convertToInactivePeriod, isNullOrWhiteSpace, processConsumptions} from '../../shared/utils/utilities'
import configurationService from '../../services/configurationService'
import {ICounterDeviation, IDeviationType, IDeviationValue, IDeviationValueType} from '../counter/interfaces'
import {COUNTER, EXTERNAL_IMPORT_FAILED, HEAT_EXCHANGER} from '../../shared/utils/constants'
import IAttribute, { IAttributeControl } from '../attribute/interfaces'
import attributeService from '../../services/attributeService'
import axios from 'axios'
import { IUnit } from '../unit/unitInterfaces'
export const getCounter = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_LOADING})
  try {
    const counter = await counterService.getCounter(id)
    return dispatch({type: ActionTypes.FETCH_COUNTER_SUCCESS, payload: counter})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_FAILED, payload: error})
  }
}

export const getIsCounterMultiValue = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_IS_MULTI_VALUE_FAILED})
  try {
    const isMultiValue = await counterService.getIsCounterMultiValue(id)
    return dispatch({type: ActionTypes.FETCH_COUNTER_IS_MULTI_VALUE_SUCCESS, payload: isMultiValue})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_IS_MULTI_VALUE_FAILED, payload: error})
  }
}

export const getCounterNumbers = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_NUMBERS_FAILED})
  try {
    const numbers = await counterService.getCounterNumbers(id)
    return dispatch({type: ActionTypes.FETCH_COUNTER_NUMBERS_SUCCESS, payload: numbers})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_NUMBERS_FAILED, payload: error})
  }
}

export const getIsCounterExcluded = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_IS_EXCLUDED_LOADING})
  try {
    const excluded: IsCounterExcluded = await counterService.getCounterExcluded(id)
    const isExcluded = excluded.isExcludedFromAddress && excluded.isExcludedFromEstate && excluded.isExcludedFromMeasurePoint && excluded.isExcludedFromOwner
    return dispatch({type: ActionTypes.FETCH_COUNTER_IS_EXCLUDED_SUCCESS, payload: isExcluded})
  } 
  // Check if the error is an Axios error with status cdoe 404, if so, the counter is not excluded
  catch(error) {
    if(axios.isAxiosError(error)) {
      if (error.response && error.response.status === 404) {
        return dispatch({type: ActionTypes.FETCH_COUNTER_IS_EXCLUDED_SUCCESS, payload: false})
      }
    }
    return dispatch({type: ActionTypes.FETCH_COUNTER_IS_EXCLUDED_FAILED, payload: error})
  }
}

export const getInactivePeriods = (counterId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_INACTIVE_PERIODS_LOADING})
  try {
    const disabledPeriodDto = await counterService.getCounterDisabledPeriods(counterId)
    const inactivePeriods = convertToInactivePeriod(disabledPeriodDto)
    return dispatch({type: ActionTypes.FETCH_COUNTER_INACTIVE_PERIODS_SUCCESS, payload: inactivePeriods})
  } catch (error) {
    // If the error is an Axios error with status code 404, the counter has no inactive periods
    if(axios.isAxiosError(error)) {
      if (error.response && error.response.status === 404) {
        return dispatch({type: ActionTypes.FETCH_COUNTER_INACTIVE_PERIODS_SUCCESS, payload: convertToInactivePeriod(undefined)})
      }
    }
    return dispatch({type: ActionTypes.FETCH_COUNTER_INACTIVE_PERIODS_FAILED, payload: error})
  }
}

export const createCounter = (measurePointId: number, meterId: number, name: string, number: string, constant: number, hidden: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.CREATE_COUNTER_LOADING})
    const createdCounter = await counterService.createCounter(measurePointId, meterId, name, constant, hidden)
    let createdCounterNumbers: ICounterNumber[] = []
    if (createdCounter && number) createdCounterNumbers = await counterService.saveCounterNumber(createdCounter.id, number)    
    dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
    return dispatch({type: ActionTypes.CREATE_COUNTER_SUCCESS, payload: {...createdCounter, number: createdCounterNumbers.join(',')}})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_COUNTER_FAILED, payload: error})
  }
}

export const updateCounter =
  (
    id: number,
    measurePointId: number,
    meterId: number,
    name: string,
    turned: boolean,
    constant: number,
    hidden: boolean,
    shouldBeExcluded: boolean,
    counterNumbers: string,
    integrations?: ICounterIntegration[],
    attributes?: ICounterAttributeParameter[],
    disabledPeriods?: IInactivePeriod[],
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.UPDATE_COUNTER_LOADING})
    try {
      const updatedCounter = await counterService.updateCounter(id, measurePointId, meterId, name, turned, constant, hidden)

      const updatedCounterNumber = await counterService.saveCounterNumber(id, counterNumbers)      
      let modifiedIntegrations: ICounterIntegration[] = []
      if (integrations) {
        const createdIntegrations = await Promise.all(
          integrations
            .filter((integration: ICounterIntegration) => integration.id <= 0)
            .map(async (integration: ICounterIntegration) => {
              return await counterService.createCounterIntegration(integration.counterId, integration.externalSystemId, integration.integrationType, integration.externalId)
            })
        )
        const updatedIntegrations = await Promise.all(
          integrations
            .filter((integration: ICounterIntegration) => integration.id > 0)
            .map(async (integration: ICounterIntegration) => {
              return await counterService.updateCounterIntegration(integration.id, integration.counterId, integration.externalSystemId, integration.integrationType, integration.externalId)
            })
        )
        modifiedIntegrations = await counterService.getCounterIntegrations(id)
      }
      let inactivePeriods: IInactivePeriod[] | undefined = []
      if (disabledPeriods && disabledPeriods.length > 0) {
        await counterService.deleteDisabledPeriods(id)
        const disabledPeriodsResult = await counterService.saveDisabledPeriods(id, disabledPeriods)
        inactivePeriods = convertToInactivePeriod(disabledPeriodsResult)
      }

      const excludedResult = await counterService.saveCounterConsumtionExcluded(id, shouldBeExcluded)
      const isCurrentlyExcluded = excludedResult.isExcludedFromAddress && excludedResult.isExcludedFromEstate && excludedResult.isExcludedFromMeasurePoint && excludedResult.isExcludedFromOwner

      const modifiedAttributes = []
      if (attributes) {
        for (let i = 0; i < attributes.length; i++) {
          const attribute = await counterService.createOrUpdateCounterAttribute(id, attributes[i].attributeId, attributes[i].value)
          modifiedAttributes.push(attribute)
        }
      }
      

      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({
        type: ActionTypes.UPDATE_COUNTER_SUCCESS,
        payload: {counter: updatedCounter, integrations: modifiedIntegrations, disabledPeriods: inactivePeriods, isExcluded: isCurrentlyExcluded, counterAttributes: modifiedAttributes},
      })
    } catch (error) {
      console.log(error)
      return dispatch({type: ActionTypes.UPDATE_COUNTER_FAILED, payload: error})
    }
  }

export const createCounterIntegration = (counterId: number, externalSystemId: number, integration: number, externalId: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_COUNTER_INTEGRATION_LOADING})
  try {
    const createdIntegration = await counterService.createCounterIntegration(counterId, externalSystemId, integration, externalId)
    return dispatch({type: ActionTypes.CREATE_COUNTER_INTEGRATION_SUCCESS, payload: createdIntegration})
  } catch (error) {
    console.log(error)
    return dispatch({type: ActionTypes.CREATE_COUNTER_INTEGRATION_FAILED, payload: error})
  }
}

export const getMeterType = (meterTypeId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_METER_TYPE_LOADING})
  try {
    let meter = await meterTypeService.getMeterType(meterTypeId)
    const degreeDayCorrectedMeterTypeIds = await configurationService.getDegreeDayCorrectedMeterTypes()
    meter = {...meter, canDegreeDayAdjust: degreeDayCorrectedMeterTypeIds.some((x: number) => x === meter.id)}
    return dispatch({type: ActionTypes.FETCH_COUNTER_METER_TYPE_SUCCESS, payload: meter})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_METER_TYPE_FAILED, payload: error})
  }
}
export const getConsumption = (counterId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_LOADING})
  try {
    const consumptions = await counterService.getConsumption(counterId, startDate, stopDate, useDegreeDayCorrection)
    return dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_SUCCESS, payload: consumptions})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_FAILED, payload: error})
  }
}

export const getNodeConsumption = (counterId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, shouldEstimate?: boolean, unit?: IUnit, category?: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_LOADING})
  try {
    const consumptions = shouldEstimate
      ? await counterService.getNodeConsumptionAndEstimate(counterId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
      : await counterService.getNodeConsumption(counterId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
    return dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_SUCCESS, payload: processConsumptions(consumptions, unit, category)})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_FAILED, payload: error})
  }
}

export const getCounterIntegrations = (counterId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_INTEGRATIONS_LOADING})
  try {
    const integrations = await counterService.getCounterIntegrations(counterId)    
    return dispatch({type: ActionTypes.FETCH_COUNTER_INTEGRATIONS_SUCCESS, payload: integrations})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_INTEGRATIONS_ERROR, payload: error})
  }
}
export const getCounterReadings = (counterId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.COUNTER_READINGS_LOADING})
  try {
    const readings = await counterService.getCounterValueListItems(counterId)
    return dispatch({type: ActionTypes.COUNTER_READINGS_SUCCESS, payload: readings})
  } catch (error) {
    return dispatch({type: ActionTypes.COUNTER_READINGS_ERROR, payload: error})
  }
}
export const getReadingTableLogs = (counterId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_READING_LOGS_LOADING})
  try {
    const data = await counterService.getReadingTableLogs(counterId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_COUNTER_READING_LOGS_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_READING_LOGS_FAILED, payload: error})
  }
}
export const getConsumptionTableLogs = (counterId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_LOGS_LOADING})
  try {
    const data = await counterService.getConsumptionTableLogs(counterId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_LOGS_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_CONSUMPTION_LOGS_FAILED, payload: error})
  }
}
export const modifyIntegration = (integration: ICounterIntegration) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.MODIFY_COUNTER_INTEGRATION, payload: integration})
}
export const addNewIntegration = (integration: ICounterIntegration) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.ADD_COUNTER_INTEGRATION, payload: integration})
}
export const deleteIntegration = (integration: ICounterIntegration) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_COUNTER_INTEGRATION_LOADING})
  if (integration.id > 0) {
    try {
      await counterService.deleteCounterIntegration(integration.id)
      return dispatch({type: ActionTypes.DELETE_COUNTER_INTEGRATION_SUCCESS, payload: integration.id})
    } catch (error) {
      return dispatch({type: ActionTypes.DELETE_COUNTER_INTEGRATION_FAILED, payload: error})
    }
  } else {
    return dispatch({type: ActionTypes.DELETE_COUNTER_INTEGRATION_SUCCESS, payload: integration.id})
  }
}
export const updateExternalData = (counterId: number, externalSystem: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_EXTERNAL_DATA_LOADING})
  try {
    let result: IImportResultDto[] | undefined = undefined
    if (externalSystem.toUpperCase() === 'MESTRO') result = await counterService.updateExternalMestroData(counterId)
    else if (externalSystem.toUpperCase() === 'METRY') result = await counterService.updateExternalMetryData(counterId)
    if (result && result.length > 0) return dispatch({type: ActionTypes.UPDATE_EXTERNAL_DATA_SUCCESS, payload: result})
    else return dispatch({type: ActionTypes.UPDATE_EXTERNAL_DATA_FAILED, payload: EXTERNAL_IMPORT_FAILED})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_EXTERNAL_DATA_FAILED, payload: error})
  }
}
export const deleteCounter = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_COUNTER_LOADING})
  try {
    const result = await counterService.deleteCounter(id)
    dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
    return dispatch({type: ActionTypes.DELETE_COUNTER_SUCCESS, payload: result})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_COUNTER_FAILED, payload: error})
  }
}

export const toggleInactivePeriods = (isInactive: boolean, period: number) => async (dispatch: Dispatch) => {
  return dispatch({type: ActionTypes.TOGGLE_INACTIVE_PERIODS, payload: {isInactive, period}})
}

export const getCounterDeviation = (counterId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_DEVIATION_LOADING})
  try {
    const deviation = await counterService.getCounterDeviation(counterId)    
    return dispatch({type: ActionTypes.FETCH_COUNTER_DEVIATION_SUCCESS, payload: deviation})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_DEVIATION_ERROR, payload: error})
  }
}

export const getCounterDeviationDictionary = (counterId: number, meterId: number, isConsumption: boolean) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_DEVIATION_DICTIONARY_LOADING})
  try {
    const deviations = await counterService.getCounterDeviationDictionary(counterId, meterId, isConsumption)
    return dispatch({type: ActionTypes.FETCH_COUNTER_DEVIATION_DICTIONARY_SUCCESS, payload: deviations})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_DEVIATION_DICTIONARY_ERROR, payload: error})
  }
}

export const setUpdatedCounterDeviation = (deviation: ICounterDeviation) => async (dispatch: Dispatch) => dispatch({type: ActionTypes.SET_MODIFIED_COUNTER_DEVIATION_SUCCESS, payload: deviation})
export const updateCounterDeviation = (deviation: ICounterDeviation) => async (dispatch: Dispatch) => {  
  dispatch({type: ActionTypes.UPDATE_COUNTER_DEVIATION_LOADING})  
  let modifiedDeviation: ICounterDeviation | undefined = undefined
  try {
    if (deviation.id > 0) modifiedDeviation = await counterService.updateCounterDeviation(deviation.id, deviation.counterId, deviation.deviation)
    else modifiedDeviation = await counterService.createCounterDeviation(deviation.counterId, deviation.deviation)
    let modifiedSubValues: IDeviationValue[] = []
    if (modifiedDeviation && deviation.deviationValues.length > 0) {
      for (let i = 0; i < deviation.deviationValues.length; i++) {
        let deviationValue = deviation.deviationValues[i]
        if (deviationValue.id > 0) modifiedSubValues.push(await counterService.updateDeviationSubValue(deviationValue.id, modifiedDeviation.id, deviationValue.deviationValueTypeId, deviationValue.value))
        else modifiedSubValues.push(await counterService.createCounterDeviationSubValue(modifiedDeviation.id, deviationValue.deviationValueTypeId, deviationValue.value))
      }      
      modifiedDeviation.deviationValues = modifiedSubValues
    }
    return dispatch({type: ActionTypes.UPDATE_COUNTER_DEVIATION_SUCCESS, payload: modifiedDeviation})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_COUNTER_DEVIATION_ERROR, payload: error})
  }
}
export const getCounterAttributeControls = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_ATTRIBUTE_CONTROLS_LOADING})
  try {
    const attributes : IAttribute[] = await attributeService.getAttributes()
    const counterAttributes : IAttribute[] = attributes?.filter(x => x.class === COUNTER || x.class === HEAT_EXCHANGER)
    let attributeControls: IAttributeControl[] = [] 
    for(let i = 0; i < counterAttributes.length; i++) {
      const attribute = counterAttributes[i]      
      const controls : IAttributeControl[] = await attributeService.getAttributeControls(attribute.id)
      if(controls) {        
        attributeControls = [...attributeControls, ...controls]
      }
    }
    return dispatch({type: ActionTypes.FETCH_COUNTER_ATTRIBUTE_CONTROLS_SUCCESS, payload: attributeControls})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_ATTRIBUTE_CONTROLS_ERROR, payload: error})
  }
}
export const getCounterAttributes = (counterId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_COUNTER_ATTRIBUTES_LOADING})
  try {
    const attributes = await counterService.getCounterAttributes(counterId)
    return dispatch({type: ActionTypes.FETCH_COUNTER_ATTRIBUTES_SUCCESS, payload: attributes})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_COUNTER_ATTRIBUTES_ERROR, payload: error})
  }
}