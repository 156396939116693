import {FormControlLabel, Switch} from '@mui/material'
import {hasEditPermissions, hasPermissions} from '../../../shared/utils/utilities'
import React, { useEffect, useState } from 'react'
import {NODE_WRITE} from '../../../shared/utils/constants'
const SecSwitch = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  useEffect(() => {
    setIsAuthenticated(hasPermissions([NODE_WRITE]))
  }, [])

  return <FormControlLabel control={React.cloneElement(props.children, {disabled: !isAuthenticated})} {...props} />
}
export default SecSwitch
