import { TextField } from '@mui/material'
import { hasEditPermissions, hasPermissions } from '../../../shared/utils/utilities'
import React from 'react'
import { NODE_WRITE } from '../../../shared/utils/constants'
const SecTextField = (props: any) => {
    const isAuthenticated = hasPermissions([NODE_WRITE])
    return (
        <TextField ref={props.innerRef} disabled={!isAuthenticated} {...props} />
    )
}
export default SecTextField

