export enum ActionTypes {
  FETCH_ALL_OWNERS_LOADING = 'FETCH_ALL_OWNERS_LOADING',
  FETCH_ALL_OWNERS_OK = 'FETCH_ALL_OWNERS_OK',
  FETCH_ALL_OWNERS_ERROR = 'FETCH_ALL_OWNERS_ERROR',
  CREATE_OWNER_LOADING = 'CREATE_OWNER_LOADING',
  CREATE_OWNER_OK = 'CREATE_OWNER_OK',
  CREATE_OWNER_ERROR = 'CREATE_OWNER_ERROR',
  EDIT_OWNER_LOADING = 'EDIT_OWNER_LOADING',
  EDIT_OWNER_OK = 'EDIT_OWNER_OK',
  EDIT_OWNER_ERROR = 'EDIT_OWNER_ERROR',
  GET_SELECTED_OWNER_LOADING = 'GET_SELECTED_OWNER_LOADING',
  GET_SELECTED_OWNER_SUCCESS = 'GET_SELECTED_OWNER_SUCCESS',
  GET_SELECTED_OWNER_ERROR = 'GET_SELECTED_OWNER_ERROR',
  DELETE_OWNER_LOADING = 'DELETE_OWNER_LOADING',
  DELETE_OWNER_OK = 'DELETE_OWNER_OK',
  DELETE_OWNER_ERROR = 'DELETE_OWNER_ERROR',
  GET_OWNER_ESTATES_LOADING = 'GET_OWNER_ESTATES_LOADING',
  GET_OWNER_ESTATES_SUCCESS = 'GET_OWNER_ESTATES_SUCCESS',
  GET_OWNER_ESTATES_ERROR = 'GET_OWNER_ESTATES_ERROR',
  FETCH_OWNER_METER_TYPES_LOADING = 'FETCH_OWNER_METER_TYPES_LOADING',
  FETCH_OWNER_METER_TYPES_SUCCESS = 'FETCH_OWNER_METER_TYPES_SUCCESS',
  FETCH_OWNER_METER_TYPES_ERROR = 'FETCH_OWNER_METER_TYPES_ERROR',
  FETCH_OWNER_CONSUMPTION_LOADING = 'FETCH_OWNER_CONSUMPTION_LOADING',
  FETCH_OWNER_CONSUMPTION_SUCCESS = 'FETCH_OWNER_CONSUMPTION_SUCCESS',
  FETCH_OWNER_CONSUMPTION_ERROR = 'FETCH_OWNER_CONSUMPTION_ERROR',
  CLEAR_SELECTED_OWNER = "CLEAR_SELECTED_OWNER"
}
