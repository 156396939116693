import { getUnitByValueCategory, getUnitDividedByAreaLabel } from '../../../shared/utils/utilities'
import {IEmission} from '../../../redux/emission/interfaces'
import {ICurrency, IMeterType} from '../../../redux/meter/interfaces'
import {IUnit} from '../../../redux/unit/unitInterfaces'
import {CONSUMPTION, COST, EMISSION, READING, ATEMP, LOA, M_EXP_2} from '../../../shared/utils/constants'
import {IAxesLabels} from '../table/interfaces'
const axesLabels = {
  'CONSUMPTION': 'Förbrukning',
  'READING': 'Avläsning',
  'COST': 'Kostnad',
  'EMISSION': 'Utsläpp',
  'ATEMP': 'ATemp',
  'LOA': 'LOA',
} as IAxesLabels
export const getDesc = (category: string | undefined, meter: IMeterType) => {
  if (!category) return ''
  switch (category) {
    case CONSUMPTION:
      return meter?.isConsumption ? axesLabels[CONSUMPTION] : axesLabels[READING]
    case COST:
      return axesLabels[COST]
    case EMISSION:
      return axesLabels[EMISSION]
    case ATEMP:
      return axesLabels[ATEMP]
    case LOA:
      return axesLabels[LOA]
    default:
      return ''
  }
}

export const getSelectedCategoryValueLabel = (category: string | undefined, meter: IMeterType | undefined) => {
  switch (category) {
    case CONSUMPTION:
      if (meter?.name === 'Värmeväxlare') return 'Effektivitet'
      else if (meter?.name === 'Pejling') return 'Mått'
      else if (meter?.name === 'Vattentemperatur' || meter?.name === 'Temperatur') return 'Temperatur'
      return meter?.isConsumption ? 'Förbrukning' : 'Avläsning'
    case COST:
      return 'Kostnad'
    case EMISSION:
      return 'Utsläpp'
    case ATEMP:
      return `ATemp (${M_EXP_2})`
    case LOA:
      return `LOA (${M_EXP_2})`
    default:
      return 'Förbrukning'
  }
}

export const getLabelDesc = (label: string) => {
  if (!label) return ''
  const labelText = label.toLocaleLowerCase()
  switch (labelText) {
    case 'standard':
      return 'Avvikelsegräns'
    case 'min_temp':
      return 'Min (\u00b0C)'
    case 'max_temp':
      return 'Max (\u00b0C)'
    case 'effektivitet':
      return 'Min Eff. (%)'
    default:
      return label
  }
}

export const getUnit = (category: string | undefined, meter: IMeterType, unit: IUnit | undefined, currency: ICurrency | undefined, emission: IEmission | undefined) => {
  if ((category === CONSUMPTION || category === READING) && meter && unit) return unit.comment
  else if (category === COST && currency) return currency.name
  else if (category === EMISSION && emission) return emission.name === 'KG CO2' ? 'KG CO2e' : emission.name
  else if (category === ATEMP || category === LOA) return `${getUnitDividedByAreaLabel(unit?.comment, category)}`
  else return ''
}

export const getAnnotations = (deviations?: {[key: string]: number} | undefined | null, showLabels?: boolean) =>
  deviations
    ? Object.entries(deviations).filter(([key]) => key.toLocaleLowerCase() !== 'standard').map(([key, value]) => ({
        id: key,
        yMin: value,
        yMax: value,
        backgroundColor: 'rgba(195, 0, 9, 0.2)',
        borderColor: 'rgba(195, 0, 9, 0.2)',
        borderWidth: 2,
        borderDash: [6, 6],
        label: {
          display: showLabels,
          borderWidth: 0,
          borderColor: 'rgba(195, 0, 9, 0.2)',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          content: getLabelDesc(`${key}`),
          font: {
            size: 10,
          },
          type: 'label',
          position: 'end',
        },
      }))
    : []
