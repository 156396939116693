export enum ActionTypes {
  GET_ALL_LOGS_SUCCESS = 'GET_ALL_LOGS_SUCCESS',
  GET_ALL_LOGS_FAILED = 'GET_ALL_LOGS_FAILED',
  GET_ALL_LOGS_LOADING = 'GET_ALL_LOGS_LOADING',
  GET_DEVIATIONS_SUCCESS = 'GET_DEVIATIONS_SUCCESS',
  GET_DEVIATIONS_FAILED = 'GET_DEVIATIONS_FAILED',
  GET_DEVIATIONS_LOADING = 'GET_DEVIATIONS_LOADING',
  GET_DEVIATION_FILTER_STATE_SUCCESS = 'GET_DEVIATION_FILTER_STATE_SUCCESS',
  GET_DEVIATION_FILTER_STATE_FAILED = 'GET_DEVIATION_FILTER_STATE_FAILED',
  GET_DEVIATION_FILTER_STATE_LOADING = 'GET_DEVIATION_FILTER_STATE_LOADING',
  UPDATE_LOG_TAGS_LOADING = 'UPDATE_LOG_TAGS_LOADING',
  UPDATE_LOG_TAGS_SUCCESS = 'UPDATE_LOG_TAGS_SUCCESS',
  UPDATE_LOG_TAGS_FAILED = 'UPDATE_LOG_TAGS_FAILED'
}
