export enum ActionTypes {
    GET_METER_TYPE_LOADING = "GET_METER_TYPE_LOADING",
    GET_METER_TYPE_SUCCESS = "GET_METER_TYPE_SUCCESS",
    GET_METER_TYPE_FAILED = "GET_METER_TYPE_FAILED",
    GET_DEVIATION_TYPES_LOADING = "GET_DEVIATION_TYPES_LOADING",
    GET_DEVIATION_TYPES_SUCCESS = "GET_DEVIATION_TYPES_SUCCESS",
    GET_DEVIATION_TYPES_FAILED = "GET_DEVIATION_TYPES_FAILED",
    GET_DEVIATION_VALUE_TYPES_LOADING = "GET_DEVIATION_VALUE_TYPES_LOADING",
    GET_DEVIATION_VALUE_TYPES_SUCCESS = "GET_DEVIATION_VALUE_TYPES_SUCCESS",
    GET_DEVIATION_VALUE_TYPES_FAILED = "GET_DEVIATION_VALUE_TYPES_FAILED",
    GET_DEVIATION_TYPE_BY_ID_LOADING = "GET_DEVIATION_TYPE_BY_ID_LOADING",
    GET_DEVIATION_TYPE_BY_ID_SUCCESS = "GET_DEVIATION_TYPE_BY_ID_SUCCESS",
    GET_DEVIATION_TYPE_BY_ID_FAILED = "GET_DEVIATION_TYPE_BY_ID_FAILED",
    GET_METER_DEVIATION_TYPE_LOADING = "GET_METER_DEVIATION_TYPE_LOADING",
    GET_METER_DEVIATION_TYPE_SUCCESS = "GET_METER_DEVIATION_TYPE_SUCCESS",
    GET_METER_DEVIATION_TYPE_FAILED = "GET_METER_DEVIATION_TYPE_FAILED",
    GET_METER_COST_LOADING = "GET_METER_COST_LOADING",
    GET_METER_COST_SUCCESS = "GET_METER_COST_SUCCESS",
    GET_METER_COST_FAILED = "GET_METER_COST_FAILED",
    UPDATE_METER_TYPE_LOADING = "UPDATE_METER_TYPE_LOADING",
    UPDATE_METER_TYPE_SUCCESS = "UPDATE_METER_TYPE_SUCCESS",
    UPDATE_METER_TYPE_FAILED = "UPDATE_METER_TYPE_FAILED",
    DELETE_METER_TYPE_LOADING = "DELETE_METER_TYPE_LOADING",
    DELETE_METER_TYPE_SUCCESS = "DELETE_METER_TYPE_SUCCESS",
    DELETE_METER_TYPE_FAILED = "DELETE_METER_TYPE_FAILED",
    CREATE_METER_COST_LOADING = "CREATE_METER_COST_LOADING",
    CREATE_METER_COST_SUCCESS = "CREATE_METER_COST_SUCCESS",
    CREATE_METER_COST_FAILED = "CREATE_METER_COST_FAILED",
    UPDATE_METER_COST_LOADING = "UPDATE_METER_COST_LOADING",
    UPDATE_METER_COST_SUCCESS = "UPDATE_METER_COST_SUCCESS",
    UPDATE_METER_COST_FAILED = "UPDATE_METER_COST_FAILED",
    DELETE_METER_COST_LOADING = "DELETE_METER_COST_LOADING",
    DELETE_METER_COST_SUCCESS = "DELETE_METER_COST_SUCCESS",
    DELETE_METER_COST_FAILED = "DELETE_METER_COST_FAILED",
    GET_METER_EMISSION_LOADING = "GET_METER_EMISSION_LOADING",
    GET_METER_EMISSION_SUCCESS = "GET_METER_EMISSION_SUCCESS",
    GET_METER_EMISSION_FAILED = "GET_METER_EMISSION_FAILED",
    CREATE_METER_EMISSION_LOADING = "CREATE_METER_EMISSION_LOADING",
    CREATE_METER_EMISSION_SUCCESS = "CREATE_METER_EMISSION_SUCCESS",
    CREATE_METER_EMISSION_FAILED = "CREATE_METER_EMISSION_FAILED",
    UPDATE_METER_EMISSION_LOADING = "UPDATE_METER_EMISSION_LOADING",
    UPDATE_METER_EMISSION_SUCCESS = "UPDATE_METER_EMISSION_SUCCESS",
    UPDATE_METER_EMISSION_FAILED = "UPDATE_METER_EMISSION_FAILED",
    DELETE_METER_EMISSION_LOADING = "DELETE_METER_EMISSION_LOADING",
    DELETE_METER_EMISSION_SUCCESS = "DELETE_METER_EMISSION_SUCCESS",
    DELETE_METER_EMISSION_FAILED = "DELETE_METER_EMISSION_FAILED"
}