import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Tab, Typography} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import CounterDetails from './CounterDetails'
import CounterSummary from './CounterSummary'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {getCounter, getCounterNumbers, getInactivePeriods, getIsCounterExcluded, getIsCounterMultiValue} from '../../redux/counter/actions'
import {RootState} from '../../redux/rootReducer'
import CounterReadings from './readings/CounterReadings'
import CounterPeriodConsumptions from './CounterPeriodConsumptions'
import {HelpOutline} from '@mui/icons-material'

const Counter = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState('0')

  const onTabChange = (e: any, obj: any) => {
    setSelectedTab(obj)
  }

  useEffect(() => {
    const parsedId = id ? parseInt(id) : 0
    dispatch(getCounter(parsedId))
    dispatch(getIsCounterExcluded(parsedId))
  }, [id])

  const counter = useSelector((state: RootState) => state.counter.counter)

  if (!counter || counter.id === 0)
    return (
      <div style={{textAlign: 'center'}}>
        <HelpOutline sx={{fontSize: '256px'}} />
        <Typography variant='h5'>Räkneverket kunde inte hittas.</Typography>
      </div>
    )

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box>
          <TabList onChange={onTabChange}>
            <Tab label='Förbrukning' value={'0'} />
            <Tab label='Avläsningar' value={'1'} />
            <Tab label='Förbrukningsinmatningar' value={'2'} />
            <Tab label='Redigera' value={'3'} />
          </TabList>
        </Box>
        <TabPanel value={'0'}>
          <CounterSummary counter={counter} />
        </TabPanel>
        <TabPanel value={'1'}>
          <CounterReadings counter={counter} />
        </TabPanel>
        <TabPanel value={'2'}>
          <CounterPeriodConsumptions counter={counter} />
        </TabPanel>
        <TabPanel value={'3'}>
          <CounterDetails counter={counter} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
export default Counter
