import {Dispatch} from 'redux'
import emissionService from '../../services/emissionService'
import {IEmission} from './interfaces'
import {ActionTypes} from './types'

export const getEmissions = () => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.FETCH_EMISSIONS_LOADING})
    let emissions: IEmission[] = await emissionService.getEmissions()
    return dispatch({type: ActionTypes.FETCH_EMISSIONS_SUCCESS, payload: emissions})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_EMISSIONS_FAILED, payload: error})
  }
}
export const getEmissionByName = (name: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.FETCH_EMISSION_BY_NAME_LOADING})
    let emissions: IEmission[] = await emissionService.getEmissions()
    let emission: IEmission | undefined = emissions.find(x => x.name.toLowerCase().trim() === name.toLocaleLowerCase().trim())
    return dispatch({type: ActionTypes.FETCH_EMISSION_BY_NAME_SUCCESS, payload: emission})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_EMISSION_BY_NAME_FAILED, payload: error})
  }
}
