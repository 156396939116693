import CaseModal from '../../case/CaseModal'
import {Card, CardContent, CardHeader, Container, Divider, Grid, IconButton, Tooltip} from '@mui/material'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {ICaseModalProps} from '../../../redux/case/interfaces'
import {IConsumptionNodeDto, IConsumptionTableProps} from '../../../redux/consumption/interfaces'
import ConsumptionNodeDto from '../../../redux/consumption/ConsumptionNodeDto'
import {IConsumptionWithLog, ITableLog} from '../../../redux/counterValue/interfaces'
import {htmlDecode, logIsInConsumptionPeriod} from '../../../shared/utils/utilities'
import {uniq} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getUnitById} from '../../../redux/unit/unitActions'
import {RootState} from '../../../redux/rootReducer'
import ConsumptionTableCell from './ConsumptionTableCell'
import {getFormattedData} from './consumptionTableHelper'
import ConsumptionTitle from '../ConsumptionTitle'
import {getCurrencyByName} from '../../../redux/currency/actions'
import {CURRENCY_SEK, EMISSION_KG_CO2} from '../../../shared/utils/constants'
import {getEmissionByName} from '../../../redux/emission/actions'
import ConsumptionWithLog from '../../../redux/counterValue/ConsumptionWithLog'

const ConsumptionTable = (props: IConsumptionTableProps) => {
  const dispatch = useDispatch()
  const selectedUnit = useSelector((state: RootState) => state.unit.selectedUnit)
  const selectedEmission = useSelector((state: RootState) => state.emission.selectedEmission)
  const selectedCurrency = useSelector((state: RootState) => state.currency.selectedCurrency)
  const showVolume = useSelector((state: RootState) => state.configuration.showVolume)
  const [rows, setRows] = useState<{[key: string]: string | ConsumptionWithLog}[]>([])
  useEffect(() => {
    if (selectedUnit === undefined || selectedUnit.id !== props.meterType.unitId) {
      dispatch(getUnitById(props.meterType.unitId))
      if (!selectedEmission) dispatch(getCurrencyByName(CURRENCY_SEK))
      if (!selectedCurrency) dispatch(getEmissionByName(EMISSION_KG_CO2))
    }
  }, [props.meterType.id])

  const combineLogs = (consumptions: ConsumptionNodeDto[], logs: ITableLog[]) => {
    const combined: ConsumptionWithLog[] = consumptions.map((c) => {
      const cLogs = logs.filter((l) => {
        return logIsInConsumptionPeriod(c, l)
      })
      return new ConsumptionWithLog({...c, loa: c.loa, aTemp: c.aTemp, estimatedLOA: c.estimatedLOA, estimatedATemp: c.estimatedATemp, logs: cLogs}, c.multiplier)
    })
    return combined
  }

  useEffect(() => {
    if(selectedUnit !== undefined && selectedUnit.id === props.meterType.unitId) {
      const combinedRows: ConsumptionWithLog[] = combineLogs(props.data, props.logs ?? [])
      setRows(getFormattedData(combinedRows, fromYear, props.category, props.meterType, selectedUnit, props.shouldEstimate, showVolume))
    }
  }, [selectedUnit, props.category, props.data, props.logs])

  const fromYear = props.startDate.getFullYear()
  const columns: GridColDef[] = []
  const handleOnClick = (row: any) => {
    console.log(row)
  }
  const onCaseModalConfirm = (logId: number, isArchived: boolean, caseStatus: string) => {
    setCaseModalProps({...caseModalProps, open: false})
  }
  const onCaseModalCancel = () => setCaseModalProps({...caseModalProps, open: false})
  const defaultCaseModalProps: ICaseModalProps = {
    id: 0,
    logMessage: '',
    isArchived: false,
    isReading: false,
    open: false,
    category: 'OTHER',
    type: 'OTHER',
    status: 'OPEN',
    onConfirm: onCaseModalConfirm,
    onClose: onCaseModalCancel,
  }
  const [caseModalProps, setCaseModalProps] = useState<ICaseModalProps>(defaultCaseModalProps)

  const onLogClick = (log: ITableLog | undefined) => {
    if (!log) return
    setCaseModalProps({
      id: log.id,
      logMessage: htmlDecode(log.message),
      isArchived: false,
      isReading: !log.isConsumption,
      open: true,
      category: log.tag,
      type: log.category,
      status: log.caseState,
      onConfirm: onCaseModalConfirm,
      onClose: onCaseModalCancel,
    })
  }

  columns.push({field: 'month', headerName: 'Månad', flex: 1})
  columns.push(
    ...uniq(
      props.data
        .filter((x) => x.year >= fromYear)
        .map((x) => x.year)
        .sort()
    ).map((year) => ({
      field: year.toString(),
      flex: 1,
      renderCell: (params: any) => {
        const value: ConsumptionWithLog | string = params.row[year.toString()] //params.getValue(params.id, year.toString())
        if (!value) return 0
        if (typeof value === 'string' || value instanceof String) return value
        else return <ConsumptionTableCell data={value} nodeType={props.data?.length > 0 ? props.data[0].type : ''} category={props.category} year={year} onTooltipButtonClick={onLogClick} shouldEstimate={props.shouldEstimate} meterType={props.meterType} />
      },
    }))
  )
  columns.push({field: 'diffPeriod', headerName: 'Diff/Period', flex: 1})
  columns.push({field: 'diffYear', headerName: 'Diff/rullande 12 månader', flex: 1})
  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<ConsumptionTitle category={props.category} meter={props.meterType} unit={selectedUnit} currency={selectedCurrency} emission={selectedEmission} />} style={{'textAlign': 'center'}} />
            <CardContent>
              <DataGrid rows={rows} columns={columns} autoHeight onRowDoubleClick={(param: any) => handleOnClick(param.row)} />
              <Divider />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CaseModal {...caseModalProps} />
    </Container>
  )
}
export default ConsumptionTable
