import React from 'react'
import { FormAttributeControlPanelProps } from './interfaces'
import {DROPDOWN, UNDEFINED_SWE} from '../../../shared/utils/constants'
import {IAttributeControl, INodeAttribute, IAttributeControlValue} from '../../../redux/attribute/interfaces'
import {Grid} from '@mui/material'
import ControlledTextField from '../../../components/shared/Inputs/ControlledTextField'
import ControlledDropDown from '../../../components/shared/Inputs/ControlledDropDown'
import { FieldValues } from 'react-hook-form'
const FormAttributeControlPanel = <T extends FieldValues>(props: FormAttributeControlPanelProps<T>) => {  

  const renderAttribute = (control: IAttributeControl) => {
    if(!control.attribute) return <React.Fragment />
    const valuesToRender = control.attributeControlValues.map((x: IAttributeControlValue) => x.value)
    switch (control.controlType.name) {
      case DROPDOWN:      
        return (
            <ControlledDropDown
            control={props.useFormReturn.control}
            key={control.label}
            name={control.attribute.name.toLocaleLowerCase()}
            label={control.label}
            items={valuesToRender.map((x: string, index: number) => ({value: x, text: x, key: index}))}
            disabled={props.disabled}
          />
        )
      default:
        return <ControlledTextField control={props.useFormReturn.control} key={control.label} id={control.attribute.name.toLocaleLowerCase()} name={control.attribute.name.toLocaleLowerCase()} label={control.label} type='text' disabled={props.disabled} />
    }
  }

  return (
    <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
      <Grid item xs={2}>
        {props.attributeControls.map((control: IAttributeControl) => renderAttribute(control))}
      </Grid>
    </Grid>
  )
}
export default FormAttributeControlPanel
