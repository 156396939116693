
import { Dispatch } from "redux";
import configurationService from "../../services/configurationService";
import { ActionTypes } from "./types";
import { IConfiguration } from "./interfaces";
import { isNullOrWhiteSpace, showVolume } from "../../shared/utils/utilities";

export const getDegreeDayCorrectedMeterTypes = () => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.FETCH_DEGREEDAY_CORRECTED_METER_TYPES_LOADING})
    try {
        const meterTypes = await configurationService.getDegreeDayCorrectedMeterTypes()
        return dispatch({type: ActionTypes.FETCH_DEGREEDAY_CORRECTED_METER_TYPES_SUCCESS, payload: meterTypes })
    }
    catch(error) {
        return dispatch({type: ActionTypes.FETCH_DEGREEDAY_CORRECTED_METER_TYPES_FAILED, payload: error})
    }
}
export const getMeterTypesWithEnergyZone = () => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.FETCH_ENERGY_ZONE_METER_TYPES_LOADING})
    try {
        const meterTypes: IConfiguration= await configurationService.getMeterTypesWithEnergyZone()
        const meterTypeIds = meterTypes.values.split(',').filter((x: string) => !isNullOrWhiteSpace(x)).map((x: string) => parseInt(x))
        return dispatch({type: ActionTypes.FETCH_ENERGY_ZONE_METER_TYPES_SUCCESS, payload: meterTypeIds })
    }
    catch(error) {
        return dispatch({type: ActionTypes.FETCH_ENERGY_ZONE_METER_TYPES_FAILED, payload: error})
    }
}
export const getShouldShowVolume = () => async (dispatch: Dispatch) => dispatch({type: ActionTypes.GET_SHOW_VOLUME, payload: showVolume()})
