import {ICasePostProps, IPost} from '../../redux/case/interfaces'
import React from 'react'
import {styled, createTheme, ThemeProvider} from '@mui/system'

const CasePost = (props: {body: string, fullName: string, updDate: Date, isSaved: boolean}) => {
  const author = props.isSaved ? `${props.fullName} - ${props.updDate}`: "(Ej sparad ännu)"
  return (
    <div
      className='bubble'
      style={{
        border: '0.5px solid gray',
        borderRadius: '10px',
        margin: '5px',
        padding: '10px',
        display: 'inline-block',
        backgroundColor: '#ffffe6',
        width: "100%"
      }}>
      {props.body}
      <p style={{textAlign: 'right', fontSizeAdjust: 'small', fontStyle: 'italic'}}>
        {author}
      </p>
    </div>
  )
}
export default CasePost
