import React, {FC, useEffect, useState} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {Navigate, useNavigate} from 'react-router-dom'
import {ILogin} from '../../redux/login/loginInterfaces'
import userService from '../../services/userService'
import {Button, TextField} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {login} from '../../redux/login/loginActions'
import {RootState} from '../../redux/rootReducer'
import ConsumptionTable from '../consumption/table/ConsumptionTable'
import './Login.scss'
const LoginForm: FC = (): any => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {register, handleSubmit} = useForm<ILogin>()
  const [attempts, setAttempts] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const onSubmit: SubmitHandler<ILogin> = async (data) => {
    setIsDisabled(true)
    dispatch(login(data))
    setTimeout(async () => {
      setAttempts(attempts + 1)
      setIsDisabled(false)
    }, attempts * 1000)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField margin='normal' required fullWidth label='Användarnamn' {...register('username', {required: true})} />
      <TextField margin='normal' required fullWidth label='Lösenord' type='password' {...register('password', {required: true})} />
      <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}} disabled={isDisabled} style={{backgroundColor: '#00d170'}}>
        Logga in
      </Button>
    </form>
  )
}
export default LoginForm
