import Footer from '../shared/Footer'
import {Edit} from '@mui/icons-material'
import {Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton} from '@mui/material'
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams} from '@mui/x-data-grid'
import {deleteMeterType} from '../../redux/meter/actions'
import {RootState} from '../../redux/rootReducer'
import {getAllUnits} from '../../redux/unit/unitActions'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {ActionTypes} from '../../redux/meter/types'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import {hasEditPermissions, isAdmin} from '../../shared/utils/utilities'
interface IMeterRow {
  id: number
  name: string
  maxValue: number
  multiple: number
  unitId: number
  decimals: number
  isConsumption: boolean
  insDate: Date
  updDate: Date
  unit: string
}
const MeterList = () => {
  const dispatch = useDispatch()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [deleteResultDialogOpen, setDeleteResultDialogOpen] = useState<boolean>(false)
  const [deleteResultMessage, setDeleteResultMessage] = useState<string>('')
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)

  const [meterTypeToDelete, setMeterTypeToDelete] = React.useState<IMeterRow>()
  const units = useSelector((state: RootState) => state.unit.units ?? [])
  const meterTypes = useSelector((state: RootState) => state.filter.types)
  const deleteResult = useSelector((state: RootState) => state.meter.errorMessage)

  useEffect(() => {
    dispatch(getAllUnits())
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() => {
    if (deleteResult === ActionTypes.DELETE_METER_TYPE_FAILED) {
      setDeleteResultMessage(`Det gick inte att ta bort mätartypen ${meterTypeToDelete ? meterTypeToDelete.name : 'okänd'}. Vänligen kontrollera att den inte redan används av mätare.`)
      setDeleteResultDialogOpen(true)
    }
  }, [deleteResult])

  const rows = meterTypes.map((type) => {
    const currentUnit = units.find((unit) => unit.id === type.unitId)
    return {
      id: type.id,
      name: type.name,
      multiple: type.multiple,
      maxValue: type.maxValue,
      unit: currentUnit?.name,
      decimals: type.decimals,
      isConsumption: type.isConsumption ? 'Ja' : 'Nej',
      updDate: type.updDate,
      insDate: type.insDate,
    }
  })

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'name', headerName: 'Namn', flex: 1},
    {field: 'multiple', headerName: 'Multipel', flex: 1},
    {field: 'maxValue', headerName: 'Namn', flex: 1},
    {field: 'unit', headerName: 'Enhet', flex: 1},
    {field: 'decimals', headerName: 'Antal decimaler', flex: 1},
    {field: 'isConsumption', headerName: 'Är förbrukningsmätare', flex: 1},
    {field: 'updDate', headerName: 'Ändrades', flex: 1},
    {field: 'insDate', headerName: 'Skapades', flex: 1},
    {
      width: 10,
      field: 'edit',
      headerName: 'Redigera',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <div>
              <Link to={`/meter/${params.row.id}`}>
                <IconButton>
                  <Edit fontSize='small' />
                </IconButton>
              </Link>
            {hasAdminRights ? (
              <IconButton onClick={() => onDeleteModalOpen(params.row)}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            ) : (
              ''
            )}
          </div>
        )
      },
      flex: 1,
    },
  ]

  const onDeleteModalOpen = (type: IMeterRow) => {
    if (type) {
      if (hasAdminRights) {
        setMeterTypeToDelete(type)
        setDeleteDialogOpen(true)
      } else {
        alert('Behörighet att utföra denna åtgärd saknas.')
      }
    }
  }

  const onMeterTypeDeleteClick = (e: any) => {
    if (meterTypeToDelete) {
      dispatch(deleteMeterType(meterTypeToDelete.id))
      setDeleteDialogOpen(false)
    }
  }

  return (
    <React.Fragment>
      <div>
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>{'Radera mätartyp'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>Borttagning av mätartyper är inaktiverat. Vill du radera denna mätartyp, vänligt kontakta support.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled autoFocus>
              Radera
            </Button>
            <Button onClick={() => setDeleteDialogOpen(false)}>Avbryt</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={deleteResultDialogOpen} onClose={() => setDeleteResultDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{deleteResultMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteResultDialogOpen(false)} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Container maxWidth='lg'>
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title='Alla mätartyper' />
              <Divider />
              <CardContent>
                <DataGrid pageSize={50} rows={rows} columns={columns} autoHeight />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  )
}
export default MeterList
