import React, {useRef, useState} from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {IReportDefinition} from '../../redux/report/interfaces'
import {useEffect} from 'react'
import {getAllReports} from '../../redux/report/actions'
import {Download} from '@mui/icons-material'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import ReportDetails from './ReportDetails'
import {ReportProps} from './interfaces'
import ReportLoading from './ReportLoading'

const defaultReportDefinition: IReportDefinition = {
  id: 0,
  name: '',
  title: '',
  description: '',
  columnHeaders: [],
  parameters: [],
}

const Report = (props: ReportProps) => {
  const [selectedReportName, setselectedReportName] = useState<string>(defaultReportDefinition.name)
  const [selectedReport, setSelectedReport] = useState<IReportDefinition>(defaultReportDefinition)

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0))
  const [stopDate, setStopDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0))
  const [useOfficeFilter, setUseOfficeFilter] = useState(false)

  useEffect(() => {
    if (props.reports?.length > 0) setselectedReportName(props.reports[0].name)
  }, [])

  const renderReport = (report: IReportDefinition, index: number) => {
    return (
      <MenuItem key={index} value={report.name} title={report.title}>
        {report.title}
      </MenuItem>
    )
  }

  useEffect(() => {
    setSelectedReport(props.reports.find((report) => report.name === selectedReportName) || defaultReportDefinition)
  }, [selectedReportName])

  const onselectedReportNameChanged = (e: SelectChangeEvent<string>, child: React.ReactNode) => {
    const reportByName = props.reports.find((report) => report.name === e.target.value)
    if (reportByName) setselectedReportName(reportByName.name)
  }

  const onDownloadButtonClick = async (e: any) => {
    const reportByName = props.reports.find((report) => report.name.toLocaleLowerCase() === selectedReportName.toLocaleLowerCase())
    reportByName && await props.onRunReportClick(reportByName, useOfficeFilter, startDate, stopDate)
  }

  return (
    <Container maxWidth='lg'>
      <Card>
        <CardHeader title='Alla graddagsorter' />
        <Divider />
        <CardContent>
          <Grid>
            <Grid container direction='row' alignItems='stretch' spacing={3}>
              <Grid xs={3} item>
                <InputLabel>Välj rapport:</InputLabel>
                {props.reports && (
                  <Select fullWidth label='Välj rapport:' name='reports' value={selectedReportName} onChange={onselectedReportNameChanged}>
                    {props.reports?.map((report: IReportDefinition, index: number) => renderReport(report, index))}
                  </Select>
                )}
              </Grid>
              <Grid item xs={2}>
                <InputLabel>Start:</InputLabel>
                <DesktopDatePicker
                  views={['year', 'month']}
                  mask='____-__'
                  inputFormat='yyyy-MM'
                  value={startDate}
                  onChange={(date: Date | null) => {
                    if (date) setStartDate(date)
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={2}>
                <InputLabel>Stopp:</InputLabel>
                <DesktopDatePicker
                  views={['year', 'month']}
                  mask='____-__'
                  inputFormat='yyyy-MM'
                  value={stopDate}
                  onChange={(date: Date | null) => {
                    if (date) setStopDate(date)
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </Grid>
              <Grid xs={2} item>
                <FormControlLabel
                  sx={{marginTop: '26px'}}
                  label='Kontorsfilter'
                  control={<Switch checked={useOfficeFilter} onChange={(e) => setUseOfficeFilter((prev) => !prev)} title='Kontorsfilter' />}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {selectedReport && (
        <div>
          <Divider />
          <Card>
            <CardContent>
              <Grid container direction='row' alignItems='stretch' spacing={3}>
                <Grid item xs={12}>
                  <ReportDetails report={selectedReport} />
                </Grid>
                <Grid container direction='row' alignItems='stretch' spacing={3}>
                  <Grid item xs={2} marginLeft={3}>
                    <Button startIcon={<Download />} variant='contained' onClick={onDownloadButtonClick} sx={{marginTop: '28px'}}>
                      Hämta
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='stretch' spacing={3} marginTop={3}>
                  <Grid item xs={12} marginLeft={3}>
                    <ReportLoading isLoading={props.isReportLoading} maxProgress={props.numberOfReportItems} progress={props.progress} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Divider />
        </div>
      )}
    </Container>
  )
}
export default Report
