import React from 'react'
import { IEstateMeterEmissionTabProps } from './interfaces'
import { Box } from '@mui/material'
import EstateMeterEmissionList from './EstateMeterEmissionList'
const EstateMeterEmissionTab = (props: IEstateMeterEmissionTabProps) => {
    return (
      <Box sx={{'marginTop': '10px'}}>
        <EstateMeterEmissionList
        estateMeterEmissions={props.values}
        onEstateMeterEmissionEditClick={props.onEstateMeterEmissionEditClick}
        onEstateMeterEmissionDeleteClick={props.onEstateMeterEmissionDeleteClick}
      />
      </Box>
    )
}
export default EstateMeterEmissionTab