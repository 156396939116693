import React from 'react'
import {ITab, IContainedTabListProps} from './interfaces'
import {TabList} from '@mui/lab'
import {Tab} from '@mui/material'
const ContainedTabList = (props: IContainedTabListProps) => {
  const renderTab = (tab: ITab) => {
    return <Tab label={tab.label} value={tab.value} key={tab.key} />
  }
  const renderTabList = () => {
    if (props.tabs.length > 0) {
      return <TabList onChange={props.onChange}>{props.tabs.map((tab) => renderTab(tab))}</TabList>
    } else return <div></div>
  }
  return <React.Fragment>{renderTabList()}</React.Fragment>
}
export default ContainedTabList
