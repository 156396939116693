import {SettingsRemote} from '@mui/icons-material'
import {Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, styled, alpha, InputBase} from '@mui/material'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import SearchBar from '../../search/SearchBar'
import {isNumeric} from '../../../shared/utils/utilities'
import SearchIcon from '@mui/icons-material/Search'
const Search = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))
export interface ISelectItem {
  id: number
  text: string
}
export interface ISelectDialogProps {
  open: boolean
  title: string
  defaultItemLabel: string
  text: string
  onSearch: (keyword: string) => Promise<ISelectItem[]>
  onConfirm: (value: number) => void
  onCancel: () => void
}

const SearchSelectDialog = (props: ISelectDialogProps) => {
  const defaultItem = {
    id: 0,
    text: props.defaultItemLabel,
  }
  const dispatch = useDispatch()
  const [value, setValue] = useState<number>(defaultItem.id)
  const [keyword, setKeyword] = useState<string>("")
  const [debouncedKeyword, setDebouncedKeyword] = useState<string>("")  
  const [filteredItems, setFilterdItems] = useState<ISelectItem[]>()
  useEffect(() => {
    const keywordTimer = setTimeout(() => {
      setDebouncedKeyword(keyword)
    }, 2000)
    return () => {
      clearTimeout(keywordTimer)
    }
  }, [keyword])


  useEffect(() => {
    const doSearch = async () => {
      const items = await props.onSearch(debouncedKeyword)
      setFilterdItems(items.filter(x => x.text.trim().toLowerCase().indexOf(debouncedKeyword.trim().toLowerCase()) !== -1))
    }
    doSearch()
  }, [debouncedKeyword])

  const onConfirm = () => {
    props.onConfirm(value)
  }

  const onSelectChange = (e: any) => {
    const targetValue: number = isNumeric(e.target.value) ? parseInt(e.target.value) : 0
    if (filteredItems && filteredItems.length > 0 && targetValue > 0) {
      setValue(targetValue)
    }
  }

  
  return (
    <div>
      <Dialog open={props.open} onClose={props.onCancel} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{props.text}</DialogContentText>
          <Grid>
            <Grid container direction='row'>
              <Grid item xl={12}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase fullWidth placeholder='Filtrera...' inputProps={{'aria-label': 'search'}} value={keyword} onChange={(e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)} />
                </Search>
              </Grid>
            </Grid>
            <Grid container direction='row'>
              <Grid item xl={12}>
                <Select value={value} onChange={onSelectChange} fullWidth>
                  <MenuItem key={0} value={defaultItem.id}>
                    {props.defaultItemLabel}
                  </MenuItem>
                  {filteredItems
                    ? filteredItems.map((item: ISelectItem, index: number) => (
                        <MenuItem key={index} value={item.id}>
                          {item.text}
                        </MenuItem>
                      ))
                    : ''}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm} autoFocus>
            OK
          </Button>
          <Button onClick={props.onCancel}>Avbryt</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default SearchSelectDialog
