import {Cancel, Save} from '@mui/icons-material'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import React, { useEffect } from 'react'
import {Controller, useForm} from 'react-hook-form'
import { ICounterValueDeviationDialogProps, ICounterValueResetDialogProps, ICounterValueResetForm} from '../../../redux/counterValue/interfaces'
import {DeviationType} from '../../../shared/enums/DeviationType'
import { ICounterConsumptionDeviationDialogProps, ICounterPeriodConsumptionDeviationForm } from './interfaces'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const CreatePeriodConsumptionDeviationDialog = (props: ICounterConsumptionDeviationDialogProps) => {
  let defaultResetType = DeviationType.OTHER

  const {register, control, handleSubmit, reset} = useForm<ICounterPeriodConsumptionDeviationForm>({defaultValues: { id: props.id, counterPeriodConsumptionId: props.counterPeriodConsumptionId, text: "" , tag: DeviationType.OTHER }} )
  useEffect(() => {
    reset({id: props.id, counterPeriodConsumptionId: props.counterPeriodConsumptionId, text: "" , tag: DeviationType.OTHER })
  }, [props.counterPeriodConsumptionId])

  const onSubmit = (data: ICounterPeriodConsumptionDeviationForm) => {
    props.onConfirm({...data })
  }
  return (
    <form>
      <Dialog sx={{style}} open={props.open}>
        <DialogTitle>Mätaråterställning</DialogTitle>
        <DialogContent>
          <Grid>
            <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
              <Grid item xs={12}>
                <TextField
                  id='counterConsumptionId'
                  label='Förbruknings-Id'
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={props.counterPeriodConsumptionId}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
              <Grid item xs={12}>
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      id='text'
                      label='Meddelande'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                  name='text'
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
              <Grid xs={12} item>
                <FormControl fullWidth>
                  <InputLabel>Välj avvikelse</InputLabel>
                  <Controller
                    render={({field}) => (
                      <Select inputProps={{maxLength: 40}} fullWidth defaultValue={defaultResetType} {...field}>
                        <MenuItem value={DeviationType.OTHER} key={DeviationType.OTHER}>
                          Avvikelse
                        </MenuItem>
                        <MenuItem value={DeviationType.RESET} key={DeviationType.RESET}>
                          Mätarbyte/Runtslagning
                        </MenuItem>
                      </Select>
                    )}
                    name='tag'
                    control={control}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} onClick={handleSubmit(onSubmit)}>
            Spara
          </Button>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
export default CreatePeriodConsumptionDeviationDialog
