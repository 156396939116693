export enum ActionTypes {
  GET_UNITS_LOADING = 'GET_UNITS_LOADING',
  GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS',
  GET_UNITS_FAILED = 'GET_UNITS_FAILED',
  GET_UNIT_LOADING = 'GET_UNIT_LOADING',
  GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS',
  GET_UNIT_FAILED = 'GET_UNIT_FAILED',
  CREATE_UNIT_LOADING = 'CREATE_UNIT_LOADING',
  CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS',
  CREATE_UNIT_FAILED = 'CREATE_UNIT_FAILED',
  EDIT_UNIT_LOADING = 'EDIT_UNIT_LOADING',
  EDIT_UNIT_SUCCESS = 'EDIT_UNIT_SUCCESS',
  EDIT_UNIT_FAILED = 'EDIT_UNIT_FAILED',
  DELETE_UNIT_LOADING = 'DELETE_UNIT_LOADING',
  DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS',
  DELETE_UNIT_FAILED = 'DELETE_UNIT_FAILED',
}
