import React, {useEffect, useState} from 'react'
import {OfficeMeterCostInputDialogProps, OfficeMeterCostInputForm} from './interfaces'
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material'
import {useForm} from 'react-hook-form'
import DialogOKCancelButtons from '../../../shared/dialogs/DialogOKCancelButtons'
import DialogStartStopDate from '../../../shared/dialogs/DialogStartStopDate'
import ControlledTextField from '../../../shared/Inputs/ControlledTextField'
import { isAdmin } from '../../../../shared/utils/utilities'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const OfficeMeterCostInputDialog = (props: OfficeMeterCostInputDialogProps) => {
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const {register, control, handleSubmit, reset} = useForm<OfficeMeterCostInputForm>({
    defaultValues: {
      id: props.item ? props.item.id : 0,
      cost: props.item ? props.item.cost : 0,
      meterId: props.meterId,
      currencyId: props.item ? props.item.currencyId : props.defaultCurrency.id,
      startDate: props.item ? props.item.startDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      stopDate: props.item ? props.item.stopDate : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    },
  })

  useEffect(() => {
    if (props.item) {
      reset({
        id: props.item ? props.item.id : 0,
        meterId: props.meterId,
        cost: props.item ? props.item.cost : 0,
        currencyId: props.item ? props.item.currencyId : props.defaultCurrency.id,
        startDate: props.item ? props.item.startDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        stopDate: props.item ? props.item.stopDate : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
      })
    }
  }, [props.item])

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  const renderTitle = () => {
    return <Typography variant='h3'>{props.item?.id === 0 ? 'Ny prisuppgift' : 'Redigera prisuppgift'}</Typography>
  }

  const onSubmit = (data: OfficeMeterCostInputForm) => {
    const cost = parseFloat(data.cost.toString())
    const meterId = data.meterId
    const startDate = new Date(data.startDate)
    const stopDate = new Date(data.stopDate)
    props.onConfirm({...data, id: props.item ? props.item.id : 0, meterId, cost, startDate, stopDate})
  }

  return (
    <div>
      <form>
        <Dialog sx={{style}} open={props.open}>
          <DialogTitle>{renderTitle()}</DialogTitle>
          <DialogContent>
            <Grid>
              <ControlledTextField control={control} id='cost' name='cost' label={`Pris (SEK / ${props.unit?.comment})`} type='number' />
              <DialogStartStopDate control={control} defaultValue={new Date()} views={['year', 'month']} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogOKCancelButtons onConfirmClick={handleSubmit(onSubmit)} onCancelClick={props.onClose} isAuthorized={hasAdminRights} />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  )
}
export default OfficeMeterCostInputDialog
