import { Add } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { ICounterIntegration, ICounterIntegrationCardProps } from '../../redux/counter/interfaces'
import { IExternalSystem } from '../../redux/externalSystem/interfaces'
import { hasEditPermissions, isAdmin } from '../../shared/utils/utilities'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CounterIntegration from './CounterIntegration'
import { addNewIntegration, updateExternalData } from '../../redux/counter/actions'
import { CounterIntegrationType } from '../../shared/enums/CounterIntegrationType'

const integrationOptions = [
    { value: "export", text: "Export"},
    { value: "import", text: "Import"}    
]

 
const CounterIntegrationList = (props: ICounterIntegrationCardProps) => {    
    const dispatch = useDispatch()
    const [newIntegrations, setNewIntegrations] = useState<ICounterIntegration[]>([])    
    const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
     
    useEffect(() => {
        setHasAdminRights(isAdmin())
    }, [])


    const importIntegration = props.integrations.find(x => x.id > 0 && x.integrationType === CounterIntegrationType.Import)
    const renderIntegration = (integration: ICounterIntegration, externalSystems: IExternalSystem[], index: number) => {
        return <CounterIntegration counterId={props.counter.id} integration={integration} externalSystems={externalSystems} index={index} key={index} />
    }

    const addIntegration = (e: any) => {
        const newIntegration : ICounterIntegration = { id: -(newIntegrations.length + 1), externalSystemId: 0, counterId: props.counter.id, integrationType: CounterIntegrationType.Export, externalId: "", insDate: new Date(), updDate: new Date() }
        dispatch(addNewIntegration(newIntegration))
    } 
    
    const renderAddButton = () => {        
        if(!hasAdminRights) {
            return <div></div>
        }
        return(
            <Grid container spacing={5}>
                <Grid item xs={12} container justifyContent="flex-start" direction="row" spacing={2}>
                    <Grid item xs={2}>
                        <Button variant='text' onClick={addIntegration}><Add />&nbsp;Lägg till</Button>                        
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderLoadExternalDataButton = () => {
        if(props.integrations?.filter(x => x.id > 0).length > 0) {
            return <Button id="loadExternalData" onClick={onLoadExternalDataClick} variant='contained'>Uppdatera extern data</Button>
        }
        return ''
    }

    const onLoadExternalDataClick = () => {
        if(importIntegration) {
            const externalSystem = props.externalSystems.find(sys => sys.id === importIntegration.externalSystemId)
            if(externalSystem) {
                dispatch(updateExternalData(importIntegration.counterId, externalSystem.name))
            }
        }
    }
    if(!props.externalSystems || props.externalSystems.length === 0) return <div></div>
    return (        
        <div>
            <div>            
                {props.integrations?.map((x, i) => renderIntegration(x, props.externalSystems, i))}
                {/* {newIntegrations?.map((x, i) => renderIntegration(x, externalSystems, i))}*/}
            </div>
            {renderAddButton()}
            {importIntegration ? renderLoadExternalDataButton() : ''}
        </div>
    )
}
export default CounterIntegrationList