import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {createMeasurePoint} from '../../redux/Measurepoint/measurepointActions'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import {Controller, useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import {CardHeader, Container, FormControlLabel, Grid, Stack, Switch} from '@mui/material'
import SaveAlert from '../shared/alerts/SaveAlert'
import SecButton from '../shared/Inputs/SecButton'
import {Cancel, Save} from '@mui/icons-material'

interface ICreateMeasurePointForm {
  name: string
  measurePointNumber: string,
  description: string
  hidden: boolean
}

const CreateMeasurePoint = () => {
  const {id} = useParams()
  const addressId = id ? parseInt(id) : 0
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [saveResult, setSaveResult] = useState<SaveResponse>(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const {control, handleSubmit, reset} = useForm<ICreateMeasurePointForm>()

  const onSaveMeasurePointClick = (form: ICreateMeasurePointForm) => {
    dispatch(createMeasurePoint(addressId, form.name, form.description, form.hidden, form.measurePointNumber))
    setSaveResult(SaveResponse.SUCCESS)
    setAlertOpen(true)
    navigate(`/address/${addressId}`)
  }

  return (
    <Container maxWidth='lg'>
      <Card sx={{minWidth: 275}}>
        <CardHeader title='Ny mätpunkt' />
        <CardContent>
          <Grid>
            <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
              <Grid item xs={12}>
                <SaveAlert result={saveResult} open={alertOpen} onClose={() => setAlertOpen(false)} successMessage='Mätpunkten har skapats.' errorMessage='Misslyckades med att skapa mätpunkten.' />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2} alignItems='stretch'>
              <Grid item xs={2}>
                <Controller name='name' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Namn' fullWidth />} />
              </Grid>
              <Grid item xs={2}>
                <Controller name='measurePointNumber' control={control} render={({field}) => <TextField {...field} id='outlined-required' label='Nummer' fullWidth />} />
              </Grid>
              <Grid item xs={3}>
                <Controller name='hidden' control={control} render={({field}) => <FormControlLabel sx={{marginTop: '10px'}} control={<Switch {...field} title='Avläses ej' />} label='Avläses ej' />} />
              </Grid>
            </Grid>
            <Grid container direction='row' sx={{marginTop: '20px'}} spacing={2} alignItems='stretch'>
              <Grid item xs={4}>
                <Controller
                  name='description'
                  control={control}
                  render={({field}) => <TextField {...field} required id='outlined-required' label='Beskrivning' fullWidth minRows={3} multiline={true} />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{marginTop: '10px'}}>
            <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
              <Grid item xs={12}>
                <Stack direction='row' spacing={2}>
                  <SecButton sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<Save fontSize='small' />} onClick={handleSubmit(onSaveMeasurePointClick)}>
                    Spara
                  </SecButton>
                  <Button onClick={() => navigate(`/owner/${id}`)} sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<Cancel fontSize='small' />}>
                    Avbryt
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}
export default CreateMeasurePoint