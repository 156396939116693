import React, {useEffect, useState} from 'react'
import {Box, Tab} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import EstateDetails from './details/EstateDetails'
import EstateSummary from './summary/EstateSummary'
import { useParams } from 'react-router'
import EstateMeterCost from './estateMeterCost/EstateMeterCost'
import EstateMeterEmission from './estateMeterEmission/EstateMeterEmission'
const Estate = () => {
  const [selectedTab, setSelectedTab] = useState('0')
  const { id } = useParams()
  const estateId = parseInt(id ? id : '0')
  
  const onTabChange = (event: React.SyntheticEvent<Element, Event>, obj: any) => {
    setSelectedTab(obj)
  }
  
  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box>
          <TabList onChange={onTabChange}>
            <Tab label='Förbrukning' value={'0'} />
            <Tab label='Redigera' value={'1'} />
            <Tab label='Kostnader' value={'2'} />
            <Tab label='Utsläpp' value={'3'} />
          </TabList>
        </Box>
        <TabPanel value={'0'}>
          <EstateSummary estateId={estateId}/>
        </TabPanel>
        <TabPanel value={'1'}>
          <EstateDetails estateId={estateId}/>
        </TabPanel>
        <TabPanel value={'2'}>
          <EstateMeterCost estateId={estateId} />
        </TabPanel>
        <TabPanel value={'3'}>
          <EstateMeterEmission estateId={estateId} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
export default Estate
