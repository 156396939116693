import {ActionTypes} from './types'
import {Dispatch} from 'redux'
import caseService from '../../services/caseService'
import {ICase, ICaseCreateParameter, ICaseUpdateParameter, IPost, IPostCreateParameter} from './interfaces'
import logService from '../../services/logService'
export const getLogCase = (id: number, isArchived: boolean, isReading: boolean) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_CASE_LOADING})
  try {
    let logCases: ICase[] = []
    if (isArchived && isReading) logCases = await caseService.getArchivedReadingCaseByLogId(id)
    else if (!isArchived && isReading) logCases = await caseService.getReadingCaseByLogId(id)
    else if (isArchived && !isReading) logCases = await caseService.getArchivedConsumptionCaseByLogId(id)
    else if (!isArchived && !isReading) logCases = await caseService.getConsumptionCaseByLogId(id)
    return dispatch({type: ActionTypes.FETCH_CASE_SUCCESS, payload: logCases.length > 0 ? logCases[0] : undefined})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_CASE_FAILED, payload: error})
  }
}
export const getCaseStatuses = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_CASESTATUS_LOADING})
  try {
    let states = await caseService.getCaseStatus()
    return dispatch({type: ActionTypes.FETCH_CASESTATUS_SUCCESS, payload: states})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_CASESTATUS_FAILED, payload: error})
  }
}
export const getCaseTypes = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_CASETYPES_LOADING})
  try {    
    const types = [
      {id: 1, name: 'ALL'},
      {id: 2, name: 'OTHER'},
      {id: 3, name: 'RESET'},
      {id: 4, name: 'LOWER_CONSUMPTION'},
      {id: 5, name: 'EXTERNAL'},
    ]    
    return dispatch({type: ActionTypes.FETCH_CASETYPES_SUCCESS, payload: types})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_CASETYPES_FAILED, payload: error})
  }
}
export const createLogCase = (logCase: ICaseCreateParameter, isConsumption: boolean, isArchived: boolean) => async (dispatch: Dispatch) => {
  let savedCase = null
  dispatch({type: ActionTypes.CREATE_CASE_LOADING})
  try {
    if (isConsumption && isArchived) savedCase = await caseService.createArchivedConsumptionCase(logCase)
    else if (isConsumption && !isArchived) savedCase = await caseService.createConsumptionCase(logCase)
    else if (!isConsumption && isArchived) savedCase = await caseService.createArchivedCase(logCase)
    else savedCase = await caseService.createCase(logCase)
    return dispatch({type: ActionTypes.CREATE_CASE_SUCCESS, payload: savedCase})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_CASE_FAILED, payaload: error})
  }
}
export const updateLogCase = (logCase: ICaseUpdateParameter) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_CASE_LOADING})
  try {
    const updatedCase = await caseService.updateCase(logCase)
    return dispatch({type: ActionTypes.UPDATE_CASE_SUCCESS, payload: updatedCase})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_CASE_FAILED, payload: error})
  }
}
export const createPost = (caseId: number, posts: IPostCreateParameter[]) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_POSTS_LOADING})
  try {
    const postsToCreate = posts.map((p) => ({...p, caseId: caseId}))
    let createdPosts: IPost[] = []
    for (let post of postsToCreate) {
      const createdPost = await caseService.createPost(post)
      createdPosts.push(createdPost)
    }
    return dispatch({type: ActionTypes.CREATE_POSTS_SUCCESS, payload: createdPosts})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_POSTS_FAILED, payaload: error})
  }
}