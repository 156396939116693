export enum ActionTypes {
    GET_COUNTER_VALUES_RELOAD = "GET_COUNTER_VALUES_RELOAD",
    GET_COUNTER_VALUE_LOG_LOADING = "GET_COUNTER_VALUE_LOG_LOADING",
    GET_COUNTER_VALUE_LOG_SUCCESS = "GET_COUNTER_VALUE_LOG_SUCCESS",
    GET_COUNTER_VALUE_LOG_FAILED = "GET_COUNTER_VALUE_LOG_FAILED",
    GET_COUNTER_RESET_LOADING = "GET_COUNTER_RESET_LOADING",
    GET_COUNTER_RESET_SUCCESS = "GET_COUNTER_RESET_SUCCESS",
    GET_COUNTER_RESET_FAILED = "GET_COUNTER_RESET_FAILED",
    SAVE_COUNTER_VALUE_LOADING = 'SAVE_COUNTER_VALUE_LOADING',
    SAVE_COUNTER_VALUE_SUCCESS = 'SAVE_COUNTER_VALUE_SUCCESS',
    SAVE_COUNTER_VALUE_FAILED = 'SAVE_COUNTER_VALUE_FAILED',
    UPDATE_COUNTER_VALUE_LOADING = 'UPDATE_COUNTER_VALUE_LOADING',
    UPDATE_COUNTER_VALUE_SUCCESS = 'UPDATE_COUNTER_VALUE_SUCCESS',
    UPDATE_COUNTER_VALUE_FAILED = 'UPDATE_COUNTER_VALUE_FAILED',
    DELETE_COUNTER_VALUE_LOADING = 'DELETE_COUNTER_VALUE_LOADING',
    DELETE_COUNTER_VALUE_SUCCESS = 'DELETE_COUNTER_VALUE_SUCCESS',
    DELETE_COUNTER_VALUE_FAILED = 'DELETE_COUNTER_VALUE_FAILED',
    SAVE_COUNTER_RESET_LOADING = 'SAVE_COUNTER_RESET_LOADING',
    SAVE_COUNTER_RESET_SUCCESS = 'SAVE_COUNTER_RESET_SUCCESS',
    SAVE_COUNTER_RESET_FAILED = 'SAVE_COUNTER_RESET_FAILED',
    SAVE_COUNTER_VALUE_LOG_LOADING = 'SAVE_COUNTER_VALUE_LOG_LOADING',
    SAVE_COUNTER_VALUE_LOG_SUCCESS = 'SAVE_COUNTER_VALUE_LOG_SUCCESS',
    SAVE_COUNTER_VALUE_LOG_FAILED = 'SAVE_COUNTER_VALUE_LOG_FAILED',
}