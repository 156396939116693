import React, {useState} from 'react'
import {Box, Tab} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import AddressDetails from './AddressDetails'
import AddressSummary from './AddressSummary'
import { useParams } from 'react-router'
const Address = () => {
  const [selectedTab, setSelectedTab] = useState('0')
  const {id} = useParams()
  const addressId = id ? parseInt(id) : 0
  const onTabChange = (e: any, obj: any) => {
    setSelectedTab(obj)
  }

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box>
          <TabList onChange={onTabChange}>
            <Tab label='Förbrukning' value={'0'} />
            <Tab label='Redigera' value={'1'} />
          </TabList>
        </Box>
        <TabPanel value={'0'}>
          <AddressSummary addressId={addressId} />
        </TabPanel>
        <TabPanel value={'1'}>
          <AddressDetails addressId={addressId} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
export default Address
