import { Close } from "@mui/icons-material"
import { Alert, Collapse, IconButton } from "@mui/material"
import { SaveResponse } from "../../../shared/enums/SaveResponse"
import React from "react"

export interface ISaveAlertProps {
    result: SaveResponse    
    open: boolean,
    onClose: () => void
    successMessage?: string,
    errorMessage?: string
}
const SaveAlert = (props: ISaveAlertProps) => {
    const renderAlert = () => {
        let alert = <div></div>
        switch (props.result) {
          case SaveResponse.SUCCESS:
            alert = (
              <Alert
                severity='success'
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='small'
                    onClick={props.onClose}>
                    <Close fontSize='inherit' />
                  </IconButton>
                }
                sx={{mb: 2}}>
                {props.successMessage}
              </Alert>
            )
            break
          case SaveResponse.FAIL:
            alert = (
              <Alert
                severity='error'
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='small'
                    onClick={props.onClose}>
                    <Close fontSize='inherit' />
                  </IconButton>
                }
                sx={{mb: 2}}>
                {props.errorMessage}
              </Alert>
            )
            break
          default:
            break
        }
        return <Collapse in={props.open}>{alert}</Collapse>
      }
      return <div>{renderAlert()}</div>
    
}
export default SaveAlert