import React from 'react'
import {IConsumptionWithLog} from '../../../redux/counterValue/interfaces'
import {ATEMP, CONSUMPTION, COST, CUBIC_METERS_SWE, EMISSION, LOA, MWH, WATER_SWE} from '../../../shared/utils/constants'
import {head, orderBy, uniq, uniqBy} from 'lodash'
import {IMonthlyValue} from './interfaces'
import {getMonthLabel} from '../../../shared/utils/utilities'
import ConsumptionWithLog from '../../../redux/counterValue/ConsumptionWithLog'
import { IUnit } from '../../../redux/unit/unitInterfaces'
import { IMeterType } from '../../../redux/meter/interfaces'
export const getValue = (value: ConsumptionWithLog, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  if (!category || category === CONSUMPTION) return value.value
  else if (category === COST) return value.cost
  else if (category === EMISSION) return value.emissionValue
  else if (category === LOA) return (!showVolume && meterType.name === WATER_SWE) || unit.comment === MWH ? value.loaMultiplied : value.loa
  else if (category === ATEMP) return (!showVolume && meterType.name === WATER_SWE) || unit.comment === MWH ? value.aTempMultiplied : value.aTemp
  else return 0
}
export const getValueOrEstimate = (value: ConsumptionWithLog, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  if (!category || category === CONSUMPTION) return getBestValue(value.value, value.estimatedValue)
  else if (category === COST) return getBestValue(value.cost, value.estimatedCost)
  else if (category === EMISSION) return getBestValue(value.emissionValue, value.estimatedEmissionValue)
  else if (category === ATEMP) return (!showVolume && meterType.name === WATER_SWE) || unit.comment === MWH ? getBestValue(value.aTempMultiplied, value.estimatedATempMultiplied) : getBestValue(value.aTemp, value.estimatedATemp)
  else if (category === LOA) return (!showVolume && meterType.name === WATER_SWE) || unit.comment === MWH ? getBestValue(value.loaMultiplied, value.estimatedLOAMultiplied) : getBestValue(value.loa, value.estimatedLOA)
  else return 0
}
const getBestValue = (actual: number, estimate: number) => {
  if (actual === 0 && estimate !== undefined && estimate !== 0) return estimate
  else return actual
}
export const getMonthAcuSum = (consumptions: ConsumptionWithLog[], year: number, period: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  let currentYearsData = consumptions.filter((x: ConsumptionWithLog) => x.year === year && x.month <= period).map((x: ConsumptionWithLog) => getValue(x, category, meterType, unit, showVolume))
  var acuSum = currentYearsData.splice(0, period).reduce((x: number, y: number) => {
    return x + y
  }, 0)
  return acuSum.toFixed(2).replace('.', ',')
}
export const getAcuSumPercent = (currentYearAcuSum: number, previousYearAcuSum: number) => {
  let acuSumpercent = ((currentYearAcuSum - previousYearAcuSum) / previousYearAcuSum) * 100
  return acuSumpercent.toFixed(2).replace('.', ',').replace('Infinity', '∞') + '%'
}
export const getMonthAcuSumPercent = (consumptions: ConsumptionWithLog[], period: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  let lastConsumption = head(orderBy(consumptions, 'year', 'desc'))
  if (!lastConsumption) return 0
  let lastYear = lastConsumption.year
  let secondToLastValue = head(consumptions.filter((x: ConsumptionWithLog) => x.year === lastYear - 1 && x.month === period).map((x) => getValue(x, category, meterType, unit, showVolume)))
  let lastPeriodValue = head(consumptions.filter((x: ConsumptionWithLog) => x.year === lastYear && x.month === period).map((x) => getValue(x, category, meterType, unit, showVolume)))
  if (!lastPeriodValue || !secondToLastValue) return 0
  let acuSumpercent = ((lastPeriodValue - secondToLastValue) / secondToLastValue) * 100
  return acuSumpercent.toFixed(2).replace('.', ',').replace('Infinity', '∞') + '%'
}
export const getFormattedData = (data: ConsumptionWithLog[], fromYear: number, category: string, meterType: IMeterType, unit: IUnit, shouldEstimate: boolean, showVolume: boolean) => {
  let rows = []
  for (let month = 1; month <= 14; month++) {
    if (month <= 12) {
      const row = getTableRow(`${fromYear}-${month}`, data, month, fromYear, category, meterType, unit, showVolume)
      rows.push(row)
    } else if (month === 13) {
      const row = getTotalRow(`${fromYear}-${month}`, data, fromYear, category,  meterType, unit, shouldEstimate, showVolume)
      rows.push(row)
    } else if (month === 14) {
      const row = getAcuRow(`${fromYear}-${month}`, data, fromYear, month, category,  meterType, unit, showVolume)
      rows.push(row)
    }
  }
  return rows
}

export const getTableRow = (index: string, consumptions: ConsumptionWithLog[], month: number, fromYear: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  var consumptionsByMonth = consumptions
    .filter((x) => x.month === month && x.year >= fromYear)
    .sort((a: IConsumptionWithLog, b: IConsumptionWithLog) => {
      if (a.year > b.year) return 1
      else if (b.year > a.year) return -1
      else return 0
    })

  let fields: {[key: string]: ConsumptionWithLog | string} = {}
  fields['key'] = `${index}`
  fields['id'] = `${fromYear}-${month}`
  fields['month'] = getMonthLabel(month)
  if (consumptionsByMonth.length > 0) {
    const years = uniq(
      consumptionsByMonth
        .filter((x) => x.year >= fromYear)
        .map((x) => x.year)
        .sort()
    )
    const monthlyValues = getMonthlyValues(consumptionsByMonth, years, month)
    monthlyValues.forEach((value) => {
      if (value.value) fields[value.year.toString()] = value.value
    })
    fields['diffPeriod'] = getDiffLastMonth(consumptionsByMonth, month, category, meterType, unit, showVolume) + '%'
    fields['diffYear'] = getDiffLastYear(consumptions, month, category, meterType, unit, showVolume) + '%'
  } else {
    const distinctYears = uniq(consumptions.filter((x) => x.year >= fromYear).map((x) => x.year))
    distinctYears.forEach((year) => (fields[year.toString()] = ''))
    fields['diffPeriod'] = '0%'
    fields['diffYear'] = '0%'
  }
  return fields
}

export const getAcuRow = (index: string, consumptions: ConsumptionWithLog[], fromYear: number, month: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  var consumptions = consumptions
    .filter((x: ConsumptionWithLog) => x.year >= fromYear)
    .sort((a: ConsumptionWithLog, b: ConsumptionWithLog) => {
      if (a.year > b.year) return 1
      else if (b.year > a.year) return -1
      else return 0
    })

  var date = new Date()
  var currMonthNum = date.getMonth() + 1
  var currMonthDayNum = date.getDate()
  let currPeriod = currMonthDayNum < 15 ? currMonthNum - 1 : currMonthNum
  let fields: {[key: string]: string} = {}
  fields['key'] = `${index}`
  fields['id'] = `${fromYear}-acumulated`
  fields['month'] = 'Ackumulerad:'
  if (consumptions.length > 0) {
    const years = uniq(
      consumptions
        .filter((x) => x.year >= fromYear)
        .map((x) => x.year)
        .sort()
    )
    years.forEach((year: number) => {
      const monthlyValue = getMonthAcuSum(consumptions, year, currPeriod, category, meterType, unit, showVolume)
      fields[year.toString()] = monthlyValue
    })
    if (month === 14) {
      if (years.length > 2) {
        fields['diffPeriod'] = `${getAcuSumPercent(parseFloat(fields[years[years.length - 1]].replace(',', '.')), parseFloat(fields[years[years.length - 2]].replace(',', '.')))}`
      } else if (years.length == 2) {
        fields['diffPeriod'] = `${getAcuSumPercent(parseFloat(fields[years[1]].replace(',', '.')), parseFloat(fields[years[0]].replace(',', '.')))}`
      } else {
        fields['diffPeriod'] = ''
      }
    } else fields['diffPeriod'] = `${getMonthAcuSumPercent(consumptions, currPeriod, category, meterType, unit, showVolume)}`
  } else {
    const distinctYears = uniq(consumptions.filter((x) => x.year >= fromYear).map((x) => x.year))
    distinctYears.forEach((year) => (fields[year.toString()] = ''))
    fields['diffPeriod'] = ''
  }
  fields['diffYear'] = ''
  return fields
}

const getTotalColumnHeader = (category?: string | undefined) => {
  if (!category || category === CONSUMPTION) return 'Total årsförbrukning'
  else if (category === COST) return 'Total årskostnad'
  else if (category === EMISSION) return 'Totala utsläpp'
  else return 'Total årsförbrukning'
}

export const getTotalRow = (index: string, consumptions: ConsumptionWithLog[], fromYear: number, category: string, meterType: IMeterType, unit: IUnit, shouldEstimate: boolean, showVolume: boolean) => {
  var consumptions = consumptions
    .filter((x) => x.year >= fromYear)
    .sort((a: ConsumptionWithLog, b: ConsumptionWithLog) => {
      if (a.year > b.year) return 1
      else if (b.year > a.year) return -1
      else return 0
    })
  let fields: {[key: string]: string} = {}
  fields['key'] = `${index}`
  fields['id'] = `${fromYear}-total`
  fields['month'] = getTotalColumnHeader(category)
  if (consumptions.length > 0) {
    const years = uniq(
      consumptions
        .filter((x) => x.year >= fromYear)
        .map((x) => x.year)
        .sort()
    )
    years.forEach((year: number) => {
      const monthlyValue = getYearTotal(consumptions, year, category, meterType, unit, showVolume)
      if(shouldEstimate) {
        const monthlyEstimate = getYearEstimateTotal(consumptions, year, category, meterType, unit, showVolume)
        fields[year.toString()] = `${monthlyValue} (${monthlyEstimate})`
      }
      else {
        fields[year.toString()] = monthlyValue
      }
    })
  } else {
    const distinctYears = uniq(consumptions.filter((x) => x.year >= fromYear).map((x) => x.year))
    distinctYears.forEach((year) => (fields[year.toString()] = ''))
  }
  fields['diffPeriod'] = ''
  fields['diffYear'] = ''
  return fields
}
export const getYearTotal = (consumptions: ConsumptionWithLog[], year: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  let floatVal = function (i: any) {
    return typeof i === 'string' ? parseFloat(i.replace(/[\$,]/g, '.')) * 1 : typeof i === 'number' ? i : 0
  }
  let currentYearsData = consumptions.filter((x: ConsumptionWithLog) => x.year === year).map((x) => getValue(x, category, meterType, unit, showVolume))
  let totalSum = currentYearsData.reduce((x: number, y: number) => {
    return x + y
  }, 0)
  return totalSum.toFixed(2).replace('.', ',')
}
export const getYearEstimateTotal = (consumptions: ConsumptionWithLog[], year: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  let floatVal = function (i: any) {
    return typeof i === 'string' ? parseFloat(i.replace(/[\$,]/g, '.')) * 1 : typeof i === 'number' ? i : 0
  }
  let currentYearsData = consumptions.filter((x: ConsumptionWithLog) => x.year === year).map((x) => getValueOrEstimate(x, category, meterType, unit, showVolume))
  let totalSum = currentYearsData.reduce((x: number, y: number) => {
    return x + y
  }, 0)
  return totalSum.toFixed(2).replace('.', ',')
}

export const getMonthlyValues = (consumptions: ConsumptionWithLog[], years: number[], month: number) => {
  let values: IMonthlyValue[] = []
  years.forEach((year) => {
    const value = head(consumptions.filter((x: ConsumptionWithLog) => x.month === month && x.year === year))
    if (value) {
      values.push({month: month, year: year, value: value})
    } else {
      values.push({month: month, year: year, value: null})
    }
  })
  return values
}

export const getDiffLastMonth = (consumptions: ConsumptionWithLog[], month: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  if (consumptions && consumptions.length > 0) {
    const ordered = orderBy(consumptions, 'year', 'desc')
    const lastYear = head(ordered)?.year
    const lastObj = head(
      orderBy(
        consumptions.filter((x) => x.year == lastYear && x.month == month),
        'year',
        'desc'
      )
    )
    if (lastObj) {
      const secondToLastObj = head(consumptions.filter((x) => x.year == lastObj.year - 1 && x.month == lastObj.month))
      if (secondToLastObj) {
        let secondToLastValue = getValue(secondToLastObj, category, meterType, unit, showVolume)
        let lastValue = getValue(lastObj, category, meterType, unit, showVolume)
        if (secondToLastValue !== 0) {
          const diffPercent = +(((lastValue - secondToLastValue) / secondToLastValue) * 100).toFixed(2)
          return diffPercent
        } else if (lastValue != 0 && secondToLastValue == 0) {
          return 100
        } else {
          return 0
        }
      }
    }
  }
  return 0
}

export const getDiffLastYear = (consumptions: ConsumptionWithLog[], month: number, category: string, meterType: IMeterType, unit: IUnit, showVolume: boolean) => {
  if (consumptions.length > 0) {
    const currentYear = head(
      uniqBy(consumptions, 'year')
        .map((x: ConsumptionWithLog) => x.year)
        .sort((a: number, b: number) => {
          return b - a
        })
    )
    if (currentYear && currentYear > 0) {
      let previousYear = currentYear - 1
      let currentYearConsumption = consumptions
        .filter((x: ConsumptionWithLog) => new Date(x.year, x.month - 1, 1) > new Date(currentYear - 1, month - 1, 1) && new Date(x.year, x.month - 1, 1) <= new Date(currentYear, month - 1, 1))
        .reduce((sum: number, x: ConsumptionWithLog) => sum + getValue(x, category, meterType, unit, showVolume), 0)
      let previousYearConsumption = consumptions
        .filter((x) => new Date(x.year, x.month - 1, 1) > new Date(previousYear - 1, month - 1, 1) && new Date(x.year, x.month - 1, 1) <= new Date(previousYear, month - 1, 1))
        .reduce((sum: number, x: ConsumptionWithLog) => sum + getValue(x, category, meterType, unit, showVolume), 0)
      if (currentYearConsumption != 0) return +(((currentYearConsumption - previousYearConsumption) / previousYearConsumption) * 100).toFixed(2)
      else if (currentYearConsumption == 0 && previousYearConsumption != 0) return 100
      else return 0
    }
  }
  return 0
}
