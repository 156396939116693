import React from 'react'
import OfficeMeterCostList from './OfficeMeterCostList'
import { OfficeMeterCostTabProps } from './interfaces'
import { Box } from '@mui/material'
const OfficeMeterCostTab = (props: OfficeMeterCostTabProps) => {
    return (
      <Box sx={{'marginTop': '10px'}}>
        <OfficeMeterCostList
        officeMeterCosts={props.values}
        onOfficeMeterCostEditClick={props.onOfficeMeterCostEditClick}
        onOfficeMeterCostDeleteClick={props.onOfficeMeterCostDeleteClick}
      />
      </Box>
    )
}
export default OfficeMeterCostTab