import {styled} from '@mui/material/styles'
import {Box, Container} from '@mui/material'

export const LoginContainer = styled(Container)(
  ({theme}) =>
    `  position: inherit;
    justify-items: center;
    align-items: center;
    display: flex;
    height: 80vh;   
`
)

export const ImageLogo = styled(Box)(
  ({theme}) =>
    `    padding-bottom: 50px;
`
)

export const LoginBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
