import {Reducer} from 'redux'
import {ActionTypes} from './treeTypes'
import {ITreeState} from './treeInterfaces'

export const initialState: ITreeState = {
  isLoading: false,
  errorMessage: undefined,
  selectedMeterType: 0,
  selectedOffice: "",
  keyword: "",
  fullTree: [],
  filteredTree: [],
  updDate: null,
  userOwners: [],
  path: [],
  expandedNodes: []
}

export const treeReducer: Reducer<ITreeState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.GET_TREE_SUCCESS:
      return {...state, isLoading: true, fullTree: payload}
    case ActionTypes.GET_TREE_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.SET_SELECTED_METER_TYPE:
      return {...state, isLoading: false, selectedMeterType: payload }
    case ActionTypes.SET_SELECTED_OFFICE:
      return {...state, isLoading: false, selectedOffice: payload }
    case ActionTypes.SET_KEYWORD:
      return {...state, isLoading: false, keyword: payload}
    case ActionTypes.SET_FILTERED_NODES: 
      return { ...state, isLoading: false, filteredTree: payload }
    case ActionTypes.SET_TREE_MODIFIED:
      return { ...state, updDate: new Date() }
    case ActionTypes.FETCH_PATH_LOADING: 
      return { ...state, isLoading: true }
    case ActionTypes.FETCH_PATH_SUCCESS:      
      return { ...state, isLoading: false, path: payload }
    case ActionTypes.FETCH_PATH_FAILED: 
      return { ...state, isLoading: false, errorMessage: payload }
    case ActionTypes.SET_EXPANDED_NODES:
      return { ...state, expandedNodes: payload }
    default:
      return state
  }
}

export default treeReducer
