import {IUnit} from '../../../../redux/unit/unitInterfaces'
import {IOwnerPdfReportProps} from './interfaces'
import {IConsumptionNodePDF} from '../../../../redux/pdf/interfaces'
import EstatePdfReport from '../../../../components/estate/reports/pdf/EstatePdfReport'
import {CURRENCY_SEK, EMISSION_KG_CO2} from '../../../../shared/utils/constants'

const OwnerPdfReport = (props: IOwnerPdfReportProps) => {
  const renderEstateReport = (item: IConsumptionNodePDF, index: number) => {
    const unit = props.units.find((x) => x.id === item.meterType.unitId)
    if (unit) {
      if (item.consumptions && item.consumptions.length > 0) {
        const currency = props.currencies.find((x) => x.name.toLocaleLowerCase() === CURRENCY_SEK.toLocaleLowerCase())
        const emission = props.emissions.find((x) => x.name.toLocaleLowerCase() === EMISSION_KG_CO2.toLocaleLowerCase())
        return (
          <EstatePdfReport
            meterType={item.meterType}
            ownerName={props.ownerName}
            estate={item.estate}
            emission={emission}
            currency={currency}
            unit={unit}
            consumptions={item.consumptions}
            key={index}
            index={index}
            isUsingDegreeDayAdjustment={props.isUsingDegreeDayAdjustment}
            category={props.category}
          />
        )
      } else {
        const currency = props.currencies.find((x) => x.name.toLocaleLowerCase() === CURRENCY_SEK.toLocaleLowerCase())
        const emission = props.emissions.find((x) => x.name.toLocaleLowerCase() === EMISSION_KG_CO2.toLocaleLowerCase())
        return (
          <EstatePdfReport
            meterType={item.meterType}
            ownerName={props.ownerName}
            estate={item.estate}
            emission={emission}
            currency={currency}
            unit={unit}
            consumptions={[]}
            key={index}
            index={index}
            isUsingDegreeDayAdjustment={props.isUsingDegreeDayAdjustment}
            category={props.category}
          />
        )
      }
    }
    return <div></div>
  }

  return (
    <div style={{margin: '0', padding: '0', display: 'block'}}>
      {props.data
        .sort((x: IConsumptionNodePDF, y: IConsumptionNodePDF) => x.estate.name.localeCompare(y.estate.name))
        .map((obj: IConsumptionNodePDF, index: number) => {
          return (
            <div key={index}>
              {renderEstateReport(obj, index)}
              <div className='page-break' />
            </div>
          )
        })}
    </div>
  )
}
export default OwnerPdfReport
