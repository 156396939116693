import { Reducer } from "redux";
import { IEnergyZoneState } from "./interfaces";
import { ActionTypes } from "./types";

export const initialState: IEnergyZoneState = {
    isLoading: false,
    energyZones: [],
    errorMessage: undefined,
}

export const energyZoneReducer: Reducer<IEnergyZoneState> = (state = initialState, {type, payload}) => {
    switch (type) { 
        case ActionTypes.FETCH_ENERGY_ZONES_LOADING:
            return { ...state, isLoading: true}
        case ActionTypes.FETCH_ENERGY_ZONES_SUCCESS: 
            return { ...state, isLoading: false, energyZones: payload }
        case ActionTypes.FETCH_ENERGY_ZONES_FAILED:
            return { ...state, isLoading: false, errorMessage: payload}
        default:
            return state;
    }
}