import {ATEMP, CONSUMPTION, COST, CURRENCY_SEK, EMISSION, EMISSION_KG_CO2, LOA, MWH} from '../../../shared/utils/constants'
import {ConsumptionOverviewProps} from './interfaces'
import ConsumptionOverviewListing from './ConsumptionOverviewListing'
import {IConsumptionNodeDto} from '../../../redux/consumption/interfaces'
import {getUnitByValueCategory, getUnitDividedByAreaLabel, showVolume} from '../../../shared/utils/utilities'
import {useEffect, useState} from 'react'
import ConsumptionNodeDto from '../../../redux/consumption/ConsumptionNodeDto'
import {useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'

// Function component presenting a panel with total sum per year of all IConsumptionNodeDto in the array found in props.data where meterTypeId is equal to props.meterType.id.
const ConsumptionOverview = (props: ConsumptionOverviewProps) => {
  const fromYear = props.startDate.getFullYear()
  const toYear = props.stopDate.getFullYear()
  const years = Array.from({length: toYear - fromYear + 1}, (_, i) => fromYear + i)
  const shouldShowVolume = useSelector((state: RootState) => state.configuration.showVolume)
  const selectedUnit = useSelector((state: RootState) => state.unit.selectedUnit)

  if (selectedUnit === undefined) return <div></div>
  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#E3F2FD', padding: '8px', marginTop: '10px', borderRadius: '4px', border: '1px solid #000', fontSize: 'smaller'}}>
        <ConsumptionOverviewListing
          data={props.data.map((x: ConsumptionNodeDto) => ({year: x.year, month: x.month, actual: x.value, estimate: x.estimatedValue}))}
          meterType={props.meterType}
          unit={props.unit}
          years={years}
          category={CONSUMPTION}
        />
        <ConsumptionOverviewListing
          data={props.data.map((x: ConsumptionNodeDto) => ({year: x.year, month: x.month, actual: x.cost, estimate: x.estimatedCost}))}
          meterType={props.meterType}
          unit={props.unit}
          years={years}
          label={`Kostnad (${CURRENCY_SEK})`}
          unitName={CURRENCY_SEK}
          category={COST}
        />
        <ConsumptionOverviewListing
          data={props.data.map((x: ConsumptionNodeDto) => ({year: x.year, month: x.month, actual: x.emissionValue, estimate: x.estimatedEmissionValue}))}
          meterType={props.meterType}
          unit={props.unit}
          years={years}
          label={`Utsläpp (${EMISSION_KG_CO2})`}
          unitName={EMISSION_KG_CO2}
          category={EMISSION}
        />
        <ConsumptionOverviewListing
          data={props.data.map((x: ConsumptionNodeDto) => ({
            year: x.year,
            month: x.month,
            actual: !shouldShowVolume || selectedUnit.comment === MWH ? x.aTempMultiplied : x.aTemp,
            estimate: !shouldShowVolume || selectedUnit.comment === MWH ? x.estimatedATempMultiplied : x.estimatedATemp,
          }))}
          meterType={props.meterType}
          unit={props.unit}
          years={years}
          label={`Total ATemp (${getUnitDividedByAreaLabel(props.unit?.comment ?? '', ATEMP)})`}
          category={ATEMP}
        />
        <ConsumptionOverviewListing
          data={props.data.map((x: ConsumptionNodeDto) => ({
            year: x.year,
            month: x.month,
            actual: !shouldShowVolume || selectedUnit.comment === MWH ? x.loaMultiplied : x.loa,
            estimate: !shouldShowVolume || selectedUnit.comment === MWH ? x.estimatedLOAMultiplied : x.estimatedLOA,
          }))}
          meterType={props.meterType}
          unit={props.unit}
          years={years}
          label={`Total LOA (${getUnitDividedByAreaLabel(props.unit?.comment ?? '', LOA)})`}
          category={LOA}
        />
      </div>
      <div style={{margin: '0', fontStyle: 'italic', float: 'right'}}>Värden inom [ ] är estimat</div>
    </div>
  )
}
export default ConsumptionOverview
