import React from 'react'
import OfficeMeterEmissionList from './OfficeMeterEmissionList'
import { OfficeMeterEmissionTabProps } from './interfaces'
import { Box } from '@mui/material'
const OfficeMeterEmissionTab = (props: OfficeMeterEmissionTabProps) => {
    return (
      <Box sx={{'marginTop': '10px'}}>
        <OfficeMeterEmissionList
        officeMeterEmissions={props.values}
        onOfficeMeterEmissionEditClick={props.onOfficeMeterEmissionEditClick}
        onOfficeMeterEmissionDeleteClick={props.onOfficeMeterEmissionDeleteClick}
      />
      </Box>
    )
}
export default OfficeMeterEmissionTab