import { Dispatch } from 'redux'
import meterTypeService from '../../services/meterTypeService'
import { ActionTypes } from './types'
import estateService from '../../services/estateService'
import { GridFilterModel } from '@mui/x-data-grid'

export const getFilter  = () => async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_FILTER_LOADING})
      const types = await meterTypeService.getAllMeterTypes()                  
      const offices = await estateService.getOffices()
      const data = {types: types, offices: offices.sort((a, b) => a.name.localeCompare(b.name))}
      return dispatch({type: ActionTypes.FETCH_FILTER_SUCCESS, payload: data})
    } 
    catch (error) {
      return dispatch({type: ActionTypes.FETCH_FILTER_FAILED, payload: error})
    }
}
export const setSelectedConsumptionCategory = (category: string) => async (dispatch: Dispatch) => {
    return dispatch({ type: ActionTypes.SET_SELECTED_CONSUMPTION_CATEGORY_SUCCESS, payload: category })
}
export const setListColumnFilter = (listName: string, model: GridFilterModel) => async (dispatch: Dispatch) => dispatch({type: ActionTypes.SET_COLUMN_FILTER, payload: { listName: listName, model: model }})  
export const setListColumnSorting = (listName: string, columnName: string, order: 'asc' | 'desc') => async (dispatch: Dispatch) => dispatch({type: ActionTypes.SET_COLUMN_SORT, payload: { listName: listName, columnName: columnName, value: order }})  