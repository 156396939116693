import IAttribute from '../redux/attribute/interfaces'
import saveupClient, { handleResponse } from '../clients/saveupClient'
import { IConfiguration } from '../redux/configuration/interfaces'
import { COST_TARGET } from '../shared/utils/constants'

async function getDegreeDayCorrectedMeterTypes() {
    return await saveupClient.get<number[]>(`configuration/degreedaycorrectedmetertypes`)
    .then(handleResponse)
}
async function getMeterTypesWithEnergyZone() {
    return await saveupClient.get<IConfiguration>(`configuration?name=${COST_TARGET}`)
    .then(handleResponse)
}
const configurationService = {
    getDegreeDayCorrectedMeterTypes,
    getMeterTypesWithEnergyZone
}
export default configurationService