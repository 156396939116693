import React, {useEffect, useState} from 'react'
import {OfficeCostProps} from './interfaces'
import {Container, Grid} from '@mui/material'
import ResponseAlert from '../../../components/alert/ResponseAlert'
import AddButton from '../../shared/buttons/AddButton'
import {OfficeMeterCost} from '../../../redux/officeMeterCost/interfaces'
import {SaveResponse} from '../../../shared/enums/SaveResponse'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'
import {orderBy} from 'lodash'
import {ICurrency, IMeterType} from '../../../redux/meter/interfaces'
import {OfficeMeterCostInputForm} from './officeMeterCostInputDialog/interfaces'
import { isAdmin} from '../../../shared/utils/utilities'
import { IUnit } from '../../../redux/unit/unitInterfaces'
import OfficeMeterCostInputDialog from './officeMeterCostInputDialog/OfficeMeterCostInputDialog'
import { CURRENCY_SEK, NOT_SET } from '../../../shared/utils/constants'
import OfficeMeterCostTabContext from './OfficeMeterCostTabContext'
import { createOfficeMeterCost, deleteOfficeMeterCost, getOfficeMeterCosts, updateOfficeMeterCost } from '../../../redux/officeMeterCost/actions'
import { getCurrencyByName } from '../../../redux/currency/actions'
import { getMeterType } from '../../../redux/counter/actions'
import { getAllUnits } from '../../../redux/unit/unitActions'
import MessageDialog from '../../../components/shared/alerts/MessageDialog'
import { isOverlapping } from '../../../components/consumption/graph/costEmissionHelper'
const OfficeCost: React.FC<OfficeCostProps> = (props: OfficeCostProps) => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<OfficeMeterCost | undefined>()
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)
  const [saveResponse, setSaveResponse] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('0')
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const [alreadyExistsDialogOpen, setAlreadyExistsDialogOpen] = useState<boolean>(false)

  const meterTypes = useSelector((state: RootState) => state.filter.types ?? [])
  const units = useSelector((State: RootState) => State.unit.units ?? [])
  let costs = useSelector((state: RootState) => {
    const sorted : OfficeMeterCost[] = orderBy(state.office.officeMeterCosts.officeMeterCosts, ['startDate', 'stopDate'], ['asc'])
    return sorted
  })
  const defaultCurrency: ICurrency | undefined = useSelector((state: RootState) => state.currency.selectedCurrency)
  const deletedOfficeMeterCost = useSelector((state: RootState) => state.office.deleteOfficeMeterCost.id)
  const createdOfficeMeterCost = useSelector((state: RootState) => state.office.createOfficeMeterCost.officeMeterCost)
  const updatedOfficeMeterCost = useSelector((state: RootState) => state.office.updateOfficeMeterCost.officeMeterCost)
  
  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])
  
  useEffect(() => {
    dispatch(getCurrencyByName(CURRENCY_SEK))    
    if (units.length === 0) {
      dispatch(getAllUnits())
    }
  }, [props.officeId])

  useEffect(() => {
    if (meterTypes.length > 0) {
      const firstMeterType: IMeterType = meterTypes[0]
      setSelectedTab(`${firstMeterType.id}`)
    }
  }, [meterTypes])

  useEffect(() => {
    if (selectedTab !== '' && parseInt(selectedTab) !== 0) dispatch(getOfficeMeterCosts(props.officeId, parseInt(selectedTab)))
  }, [props.officeId, selectedTab])

  useEffect(() => {
    if (selectedTab !== '' && parseInt(selectedTab) !== 0) dispatch(getOfficeMeterCosts(props.officeId, parseInt(selectedTab)))
  }, [createdOfficeMeterCost, updatedOfficeMeterCost, deletedOfficeMeterCost ])

  const onHandleSaveNewMeterCostClick = (event: React.MouseEventHandler<HTMLButtonElement>) => {
    setSelectedRow(undefined)
    setSaveDialogOpen(true)
  }

  const onOfficeMeterCostEditClick = (cost: OfficeMeterCost) => {
    setSelectedRow(cost)
    setSaveDialogOpen(true)
  }

  const onOfficeMeterCostDeleteClick = (cost: OfficeMeterCost) => {
    dispatch(deleteOfficeMeterCost(cost.id))
  }

  const onValueConfirmClick = (data: OfficeMeterCostInputForm) => {
    if (!hasAdminRights) return

    if(isOverlapping(data.id, data.startDate, data.stopDate, costs)) {      
      setAlreadyExistsDialogOpen(true)
      return
    }

    if (data.id > 0) {
      dispatch(updateOfficeMeterCost(data.id, props.officeId, parseInt(selectedTab), data.currencyId, data.cost, data.startDate, data.stopDate))
    } else {
      dispatch(createOfficeMeterCost(props.officeId, parseInt(selectedTab), defaultCurrency ? defaultCurrency.id : 0, data.cost, data.startDate, data.stopDate))
    }
    setSaveDialogOpen(false)
    setSaveResponse(SaveResponse.PENDING)
  }
  
  const getCreatedOrUpdatedOfficeMeterCost = () => {
    if (createdOfficeMeterCost) {
      return `${createdOfficeMeterCost.id}`
    }
    if (updatedOfficeMeterCost) {
        return `${updatedOfficeMeterCost.id}`
    }
    return NOT_SET
  }

  const renderInputDialog = () => {
    if (defaultCurrency) {
      const currentMeter = meterTypes.find((x: IMeterType) => x.id === parseInt(selectedTab))
      const unit: IUnit | undefined = units.find((x: IUnit) => x.id === currentMeter?.unitId)
      return (
        <OfficeMeterCostInputDialog
          defaultCurrency={defaultCurrency}
          open={saveDialogOpen}
          item={selectedRow}
          meterId={parseInt(selectedTab)}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={(e: OfficeMeterCostInputForm) => onValueConfirmClick(e)}
          unit={unit}
        />
      )
    }
    return ''
  }
  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <ResponseAlert
            response={saveResponse}
            open={alertOpen}
            successMessage={`Prisuppgiften med Id: ${getCreatedOrUpdatedOfficeMeterCost()} registerades.`}
            failureMessage='Det gick inte att registera prisuppgiften.'
          />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <AddButton onClick={onHandleSaveNewMeterCostClick} id='addMeterCost' label='Ny prisuppgift' isAuthorized={hasAdminRights} />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <OfficeMeterCostTabContext
            officeId={props.officeId}
            meterTypes={meterTypes}
            units={units}
            officeMeterCosts={costs}
            onOfficeMeterCostEditClick={onOfficeMeterCostEditClick}
            onOfficeMeterCostDeleteClick={onOfficeMeterCostDeleteClick}
            onTabChange={(value: string) => setSelectedTab(value)}
          />
        </Grid>
      </Grid>
      {renderInputDialog()}
      <MessageDialog open={alreadyExistsDialogOpen} setOpen={(value) => setAlreadyExistsDialogOpen(value)} title='Prisuppgift finns redan' message='Det finns redan en prisuppgift registrerad för detta kontor inom valt tidsspann.' />
    </Container>
  )
}
export default OfficeCost