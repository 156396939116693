import ConsumptionTable from '../consumption/table/ConsumptionTable'
import ConsumptionView from '../consumption/ConsumptionView'
import TabPanel from '../shared/TabPanel'
import {UpdateOutlined} from '@mui/icons-material'
import {TabContext, TabList} from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material'
import {IConsumption, IConsumptionNodeDto} from '../../redux/consumption/interfaces'
import ConsumptionNodeDto from '../../redux/consumption/ConsumptionNodeDto'
import {getMeterType, getCounterDeviationDictionary, getReadingTableLogs, getConsumptionTableLogs, getNodeConsumption} from '../../redux/counter/actions'
import {ICounterSummaryProps} from '../../redux/counter/interfaces'
import {ITableLog} from '../../redux/counterValue/interfaces'
import {IMeterType} from '../../redux/meter/interfaces'
import {RootState} from '../../redux/rootReducer'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import {parseStopDateYearly} from '../../shared/utils/utilities'
import {CONSUMPTION, COST, COUNTER, EMISSION} from '../../shared/utils/constants'
import LabeledSelect from '../shared/Inputs/LabeledSelect'
import ConsumptionCostEmissionSelect from '../consumption/ConsumptionCostEmissionSelect'
import {getAllUnits} from '../../redux/unit/unitActions'
import ConsumptionOverview from '../consumption/overview/ConsumptionOverview'
import {IUnit} from '../../redux/unit/unitInterfaces'
import {getShouldShowVolume} from '../../redux/configuration/actions'
const getAnnotation = (key: string, value: number) => {
  return {
    type: 'line',
    scaleID: 'x',
    borderWidth: 1,
    borderColor: 'red',
    value: value,
  }
}

const CounterSummary = (props: ICounterSummaryProps) => {
  const dispatch = useDispatch()

  const [selectedConsumptionTab, setSelectedConsumptionTab] = useState('0')
  const [useDegreeDayCorrection, setUseDegreeDayCorrection] = useState(true)
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear() - 2, 0, 1, 0, 0, 0))
  const [stopDate, setStopDate] = useState(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59))
  const [lastLoadedStartDate, setLastLoadedStartDate] = useState(new Date(new Date().getFullYear() - 2, 0, 1, 0, 0, 0))
  const [lastLoadedStopDate, setLastLoadedStopDate] = useState(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59))
  const [canEstimate, setCanEstimate] = useState<boolean>(false)
  const [shouldEstimate, setShouldEstimate] = useState<boolean>(true)
  const [consumption, setConsumption] = useState<ConsumptionNodeDto[]>([])
  const [consumptionLogs, setConsumptionLogs] = useState<ITableLog[]>([])
  const [readingLogs, setReadingLogs] = useState<ITableLog[]>([])
  const [type, setType] = useState<IMeterType | undefined>(undefined)
  const onConsumptionTabChange = (e: any, obj: any) => {
    setSelectedConsumptionTab(obj)
  }

  const update = () => {
    if (type === undefined || type.id !== props.counter.meterId) return
    const unitByType = units.find((x: IUnit) => x.id === type.unitId)
    const parsedStopDate = parseStopDateYearly(stopDate)
    setLastLoadedStartDate(startDate)
    setLastLoadedStopDate(parsedStopDate)
    dispatch(getNodeConsumption(props.counter.id, startDate, parsedStopDate, type.canDegreeDayAdjust === true && useDegreeDayCorrection, shouldEstimate, unitByType, category))
  }

  const typeFromStore = useSelector((state: RootState) => state.counter.type)
  const consumptionFromStore = useSelector((state: RootState) => state.counter.consumption)
  const deviations = useSelector((state: RootState) => state.counter.deviations)
  const readingLogsFromStore = useSelector((state: RootState) => state.counter.readingLogs)
  const consumptionLogsFromStore = useSelector((state: RootState) => state.counter.consumptionLogs)
  const category = useSelector((state: RootState) => state.filter.consumptionCategory)
  const units = useSelector((state: RootState) => state.unit.units)

  useEffect(() => {
    setConsumption([])
    setConsumptionLogs([])
    setReadingLogs([])
    setType(undefined)
    dispatch(getMeterType(props.counter.meterId))
    dispatch(getAllUnits())
    dispatch(getShouldShowVolume())
  }, [props.counter.id])

  useEffect(() => {
    setType(typeFromStore)    
  }, [typeFromStore])

  useEffect(() => {
    setConsumption(consumptionFromStore)
  }, [consumptionFromStore])

  useEffect(() => {
    setConsumptionLogs(consumptionLogsFromStore)
  }, [consumptionLogsFromStore])

  useEffect(() => {
    setReadingLogs(readingLogsFromStore)
  }, [readingLogsFromStore])

  useEffect(() => {
    if (type === undefined || type.id !== props.counter.meterId) return
    let parsedStopDate = parseStopDateYearly(stopDate)
    setLastLoadedStartDate(startDate)
    setLastLoadedStopDate(parsedStopDate)
    setCanEstimate(type?.isConsumption ?? false)
    setShouldEstimate(type?.isConsumption ?? false)

    const unitByType = units.find((x: IUnit) => x.id === type.unitId)
    dispatch(getNodeConsumption(props.counter.id, startDate, parsedStopDate, type.canDegreeDayAdjust === true && useDegreeDayCorrection, type?.isConsumption ?? false, unitByType, category))
    dispatch(getReadingTableLogs(props.counter.id, startDate.getFullYear(), startDate.getMonth() + 1, parsedStopDate.getFullYear(), parsedStopDate.getMonth() + 1))
    dispatch(getConsumptionTableLogs(props.counter.id, startDate.getFullYear(), startDate.getMonth() + 1, parsedStopDate.getFullYear(), parsedStopDate.getMonth() + 1))
    dispatch(getCounterDeviationDictionary(props.counter.id, type.id, type.isConsumption))
  }, [type])

  const renderTabLabel = () => {
    if (type && units.length > 0) {
      const tabUnit = units.find((x) => x.id === type.unitId)
      return tabUnit ? `${type.name} (${tabUnit.comment})` : ''
    } else if (type) {
      return type.name
    } else {
      return ''
    }
  }

  const renderGraphs = (consumption: ConsumptionNodeDto[], logs: ITableLog[], type: IMeterType, deviations: {[key: string]: number}, startDate: Date, stopDate: Date, category: string) => {
    if (consumption) {
      return (
        <div>
          <ConsumptionView data={consumption} meterType={type} deviations={deviations} startDate={startDate} stopDate={stopDate} category={category} nodeType={COUNTER} />
          <Divider sx={{'marginTop': '10px', 'marginBottom': '10px'}} />
          <ConsumptionTable data={consumption} logs={logs} meterType={type} deviations={deviations} startDate={startDate} stopDate={stopDate} category={category} shouldEstimate={shouldEstimate} />
        </div>
      )
    } else {
      return (
        <div>
          <Box sx={{'margin': '10px'}}>
            <Grid container direction='row' alignItems='stretch' spacing={1}>
              <Grid item xs={12}>
                <Typography variant='h5'>Ingen data hittades för detta räkneverk!</Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      )
    }
  }

  if ((type && props.counter.meterId !== type.id) || consumption.find((x: IConsumption) => x.id !== props.counter.id)) {
    return (
      <Box sx={{'margin': '10px'}}>
        <Grid container direction='row' alignItems='stretch' spacing={1}>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='600px'>
              <CircularProgress sx={{color: '#223354'}} size={256} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
  return (
    <Container maxWidth='lg'>
      <Card>
        <CardContent>
          <TabContext value={selectedConsumptionTab}>
            <Box>
              <TabList onChange={onConsumptionTabChange}>
                <Tab label={renderTabLabel()} value={'0'} />
              </TabList>
            </Box>
            <Box sx={{'marginTop': '10px'}}>
              <Grid>
                <Grid container direction='row' alignItems='stretch' spacing={1}>
                  <Grid item xs={2}>
                    <DesktopDatePicker
                      label='Start'
                      views={['year']}
                      value={startDate}
                      onChange={(date: Date | null) => {
                        if (date) setStartDate(date)
                      }}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <DesktopDatePicker
                      label='Stopp'
                      views={['year']}
                      value={stopDate}
                      onChange={(date: Date | null) => {
                        if (date) setStopDate(date)
                      }}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ConsumptionCostEmissionSelect meterType={type} />
                  </Grid>
                  {type && type.canDegreeDayAdjust ? (
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={<Switch checked={useDegreeDayCorrection} onChange={() => setUseDegreeDayCorrection(useDegreeDayCorrection ? false : true)} title='Använd graddagsjustering' />}
                        label='Använd graddagsjustering'
                        sx={{'marginTop': '10px'}}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {canEstimate ? (
                    <Grid item xs={3}>
                      <FormControlLabel control={<Switch checked={shouldEstimate} onChange={() => setShouldEstimate((estimate) => !estimate)} title='Visa estimat' />} label='Visa estimat' />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={2} alignSelf='right'>
                    <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<UpdateOutlined fontSize='small' />} onClick={update}>
                      Uppdatera
                    </Button>
                  </Grid>
                </Grid>
                {shouldEstimate && type && units?.length > 0 && consumption ? (
                  <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={12}>
                      <ConsumptionOverview data={consumption} meterType={type} startDate={lastLoadedStartDate} stopDate={lastLoadedStopDate} category={category} unit={units.find((x) => x.id == type.unitId)} />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                {type !== undefined ? (
                  <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={12}>
                      {renderGraphs(consumption, readingLogs?.concat(consumptionLogs?.map((cl) => ({...cl, isConsumption: true}))), type, deviations, lastLoadedStartDate, lastLoadedStopDate, category)}
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Box>
          </TabContext>
        </CardContent>
      </Card>
    </Container>
  )
}
export default CounterSummary
