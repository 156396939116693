import {ActionTypes} from './types'
import {Dispatch} from 'redux'

export const isLoadingOwnerPdf = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_PDF_CONSUMPTION_LOADING})
  try {
    return dispatch({type: ActionTypes.FETCH_PDF_CONSUMPTION_SUCCESS, payload: undefined})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_PDF_CONSUMPTION_ERROR, payload: error})
  }
}
export const setPdfTableState = (key: string, isReady: boolean) => async (dispatch: Dispatch) => {
  return dispatch({ type: ActionTypes.PDF_TABLE_STATE_CHANGE, payload: { key, isReady }})
}
export const clearPdfTableState = () => async (dispatch: Dispatch) => {
  return dispatch({ type: ActionTypes.CLEAR_PDF_TABLE_STATE })
}