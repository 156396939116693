import {Reducer} from 'redux'
import {IUserState} from './interfaces'
import {ActionTypes} from './types'
import {Action} from 'history'

const initialState: IUserState = {
  isLoading: false,
  errorMessage: '',
  searchResult: [],
  owners: [],
  userList: {
    users: [],
    isLoading: false,
    errorMessage: '',
  },
  userRoles: {
    userRoles: [],
    isLoading: false,
    errorMessage: '',
  },
  userById: {
    user: undefined,
    isLoading: false,
    errorMessage: '',
  },
  createUser: {
    user: undefined,
    isLoading: false,
    errorMessage: '',
  },
  updateUser: {
    user: undefined,
    isLoading: false,
    errorMessage: '',
  },
  deleteUser: {
    id: 0,
    isLoading: false,
    errorMessage: '',
  },
  userAttributes: {
    userAttributes: [],
    isLoading: false,
    errorMessage: '',
  },
  userPasswordReset: {
    result: undefined,
    isLoading: false,
    errorMessage: '',
  },
  disableUser: {
    user: undefined,
    isLoading: false,
    errorMessage: '',
  },
}
export const userReducer: Reducer<IUserState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.SEARCH_USERS_FAILED:
      return {...state, isLoading: true}
    case ActionTypes.SEARCH_USERS_SUCCESS:
      return {...state, isLoading: false, searchResult: payload}
    case ActionTypes.SEARCH_USERS_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.GET_CURRENT_USER_OWNERS_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_CURRENT_USER_OWNERS_SUCCESS:
      return {...state, isLoading: false, owners: payload}
    case ActionTypes.GET_CURRENT_USER_OWNERS_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.GET_USERS_LOADING:
      return {...state, userList: {...state.userList, isLoading: true}}
    case ActionTypes.GET_USERS_SUCCESS:
      return {...state, userList: {...state.userList, isLoading: false, users: payload}, updateUser: {...state.updateUser, user: undefined, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_USERS_FAILED:
      return {...state, userList: {...state.userList, isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_USER_ROLES_LOADING:
      return {...state, userRoles: {...state.userRoles, isLoading: true}}
    case ActionTypes.GET_USER_ROLES_SUCCESS:
      return {...state, userRoles: {...state.userRoles, isLoading: false, userRoles: payload}}
    case ActionTypes.GET_USER_ROLES_FAILED:
      return {...state, userRoles: {...state.userRoles, isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_USER_BY_ID_LOADING:
      return {
        ...state,
        isLoading: true,
        userById: {...state.userById, user: undefined, isLoading: true,errorMessage: ''},
        updateUser: {...state.updateUser, user: undefined, isLoading: false, errorMessage: ''},
      }
    case ActionTypes.GET_USER_BY_ID_SUCCESS:
      return {...state, userById: {...state.userById, isLoading: false, user: payload}}
    case ActionTypes.GET_USER_BY_ID_FAILED:
      return {...state, userById: {...state.userById, isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_USER_ATTRIBUTES_LOADING:
      return {...state, userAttributes: {...state.userAttributes, isLoading: true}}
    case ActionTypes.GET_USER_ATTRIBUTES_SUCCESS:
      return {...state, userAttributes: {...state.userAttributes, isLoading: false, userAttributes: payload}}
    case ActionTypes.GET_USER_ATTRIBUTES_FAILED:
      return {...state, userAttributes: {...state.userAttributes, isLoading: false, errorMessage: payload}}
    case ActionTypes.CREATE_USER_LOADING:
      return {...state, createUser: {...state.createUser, isLoading: true, user: undefined}}
    case ActionTypes.CREATE_USER_SUCCESS:
      return {...state, createUser: {...state.createUser, isLoading: false, user: payload}}
    case ActionTypes.CREATE_USER_FAILED:
      return {...state, createUser: {...state.createUser, isLoading: false, errorMessage: payload, user: undefined}}
    case ActionTypes.UPDATE_USER_LOADING:
      return {...state, updateUser: {...state.updateUser, isLoading: true, user: undefined}}
    case ActionTypes.UPDATE_USER_SUCCESS:
      return {...state, updateUser: {...state.updateUser, isLoading: false, user: payload}}
    case ActionTypes.UPDATE_USER_FAILED:
      return {...state, updateUser: {...state.updateUser, isLoading: false, errorMessage: payload, user: undefined}}
    case ActionTypes.UPDATE_USER_PASSWORD_LOADING:
      return {...state, userPasswordReset: {...state.updateUser, isLoading: true, result: undefined}}
    case ActionTypes.UPDATE_USER_PASSWORD_SUCCESS:
      return {...state, userPasswordReset: {...state.updateUser, isLoading: false, result: payload}}
    case ActionTypes.UPDATE_USER_PASSWORD_FAILED:
      return {...state, userPasswordReset: {...state.updateUser, isLoading: false, errorMessage: payload, result: undefined}}
    case ActionTypes.DELETE_USER_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.DELETE_USER_SUCCESS:
      return {...state, isLoading: false, id: payload}
    case ActionTypes.DELETE_USER_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.DISABLE_USER_LOADING:
      return {...state, disableUser: {...state.disableUser, isLoading: true, user: undefined}}
    case ActionTypes.DISABLE_USER_SUCCESS:
      return {...state, disableUser: {...state.disableUser, isLoading: false, user: payload}}
    case ActionTypes.DISABLE_USER_FAILED:
      return {...state, disableUser: {...state.disableUser, isLoading: false, errorMessage: payload, user: undefined}}
    default:
      return state
  }
}
