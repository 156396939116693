import { DataGrid } from "@mui/x-data-grid";
import { getOwnerById, getEstatesByOwnerId } from "../../redux/owner/ownerActions";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../redux/rootReducer'
import { IOwnerEstatesProps } from "../../redux/owner/ownerInterfaces";
import { Card, CardContent, CardHeader, Container, Divider, Grid } from "@mui/material";
const columns = [
  {field: 'id', headerName: 'Id', flex: 1},
  {field: 'name', headerName: 'Namn', flex: 1},
  {field: 'category', headerName: 'Kategori', flex: 1},
]

const OwnerEstates = (props: IOwnerEstatesProps) => {
  const dispatch = useDispatch()
  const estates = useSelector((state: RootState) => (state.owner.selectedOwner?.estates ? state.owner.selectedOwner.estates : []))


  const [selectionModel, setSelectionModel] = React.useState([])

  const getOwner = (id: number) => dispatch(getOwnerById(id))

  const renderGrid = () => {
    if (estates === undefined || estates.length === 0) return <div>Inga fastigheter kunde hittas för vald fastighetsägare!</div>

    return (
      <DataGrid style={{ height: 800, width: "100%" }}
      rows={estates}
      columns={columns}
      pageSize={13}
      rowsPerPageOptions={[13]}  
      />
    )
  }
  return (
    <Container maxWidth='lg'>
      <Card>
        <CardHeader title='Fastigheter' />
        <Divider />
        <CardContent>{renderGrid()}</CardContent>
      </Card>
    </Container>
  )
}
export default OwnerEstates
