import React, { useEffect, useState } from 'react'
import { OfficeListProps } from './interfaces'
import {useSelector} from 'react-redux'
import { UserListItem} from '../../redux/user/interfaces'
import {Grid, IconButton, styled} from '@mui/material'
import { Delete, Edit, Restore} from '@mui/icons-material'
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams} from '@mui/x-data-grid'
import {Link} from 'react-router-dom'
import {isAdmin} from '../../shared/utils/utilities'
const OfficeList: React.FC<OfficeListProps> = (props: OfficeListProps) => {
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  useEffect(() => {    
    setHasAdminRights(isAdmin())
  }, [])

  const StyledDataGrid: any = styled(DataGrid)(({theme}) => ({
    '& .history': {
      color: 'gray',
    },
  }))

  const officeTableColumnHeaders: GridColDef[] = [
    {field: 'name', headerName: 'Namn', width: 200},
    {field: 'description', headerName: 'Beskrivning', flex: 1},    
    {field: 'updDate', headerName: 'Uppdaterades', width: 200},
    {field: 'insDate', headerName: 'Skapades', width: 200},
    {field: 'id', headerName: 'ID', width: 80},
    {
      width: 100,
      field: 'id_icons',
      headerName: 'Ikoner',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<boolean>) => {        
        return (
            <div style={{display: 'flex', gap: '10px'}}>
              <Link to={`/office/${params.row.id}/edit`}>
                <IconButton>
                  <Edit fontSize='small' />
                </IconButton>
              </Link>
              {hasAdminRights && <IconButton onClick={() => props.onDeleteClick(params.row.id, params.row.mail)}>
                <Delete fontSize='small' />
              </IconButton>}
            </div>
          )
      },
    },
  ]

  const renderOfficeList = () => {
    // Render a react-table from UserListItems, with sorting and filtering and a column containing icon buttons for edit and delete.
    return <StyledDataGrid pageSize={100} rows={props.offices} columns={officeTableColumnHeaders} autoHeight getRowHeight={() => 'auto'} style={{'width': '100%'}}  />
  }

  return <div>{props.isLoading ? <div>Loading...</div> : <div>{renderOfficeList()}</div>}</div>
}
export default OfficeList
