import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import {createAddress} from '../../redux/address/addressActions'
import {useDispatch} from 'react-redux'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import {Alert, CardHeader, Collapse, Container, Grid, IconButton, Stack} from '@mui/material'
import {Save as SaveIcon, Cancel, Save, Close} from '@mui/icons-material'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import {useEffect, useState} from 'react'
import SecButton from '../shared/Inputs/SecButton'
import SaveAlert from '../shared/alerts/SaveAlert'
import { SaveResponse } from '../../shared/enums/SaveResponse'

interface ICreateAddressForm {
  name: string
  street: string
  postalCode: string
  city: string
  number: string
  comment: string
  hidden: boolean
}

const defaultAddressForm: ICreateAddressForm= {
  name: '',
  street: '',
  postalCode: '',
  city: '',
  number: '',
  comment: '',
  hidden: false
}

export default function CreateAddress() {
  const {id} = useParams()
  const estateId = id ? parseInt(id) : 0
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {control, handleSubmit, reset} = useForm<ICreateAddressForm>({defaultValues: defaultAddressForm})
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState(false)
  const onSaveAddressClick = (form: ICreateAddressForm) => {
    dispatch(createAddress(form.name, estateId, form.street, form.postalCode, form.city, form.number, form.comment, form.hidden))
    setSaveResult(SaveResponse.SUCCESS)
    setAlertOpen(true)
    navigate(`/estate/${estateId}`)
  }

  return (
    <Container maxWidth='lg'>
      <Card sx={{minWidth: 275}}>
        <CardHeader title='Ny adress' />
        <CardContent>
          <Grid>
            <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
              <Grid item xs={12}>
                <SaveAlert result={saveResult} open={alertOpen} onClose={() => setAlertOpen(false)} successMessage='Addressen har skapats.' errorMessage='Misslyckades med att skapa addressen.' />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2} alignItems='stretch'>
              <Grid item xs={2}>
                <Controller name='name' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Namn' fullWidth />} />
              </Grid>
            </Grid>
            <Grid container direction='row' sx={{marginTop: '20px'}} spacing={2} alignItems='stretch'>
              <Grid item xs={3}>
                <Controller name='street' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Gata' fullWidth />} />
              </Grid>
              <Grid item xs={3}>
                <Controller name='number' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Nummer' fullWidth />} />
              </Grid>
            </Grid>
            <Grid container direction='row' sx={{marginTop: '20px'}} spacing={2} alignItems='stretch'>
              <Grid item xs={3}>
                <Controller name='city' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Stad' fullWidth />} />
              </Grid>
              <Grid item xs={3}>
                <Controller name='postalCode' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Postkod' fullWidth />} />
              </Grid>
            </Grid>
            <Grid container direction='row' sx={{marginTop: '20px'}} spacing={2} alignItems='stretch'>
              <Grid item xs={4}>
                <Controller name='comment' control={control} render={({field}) => <TextField {...field} required id='outlined-required' label='Kommentar' fullWidth minRows={3} multiline={true} />} />
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{marginTop: '10px'}}>
            <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
              <Grid item xs={12}>
                <Stack direction='row' spacing={2}>
                  <SecButton sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<Save fontSize='small' />} onClick={handleSubmit(onSaveAddressClick)}>
                    Spara
                  </SecButton>
                  <Button onClick={() => navigate(`/estate/${id}`)} sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<Cancel fontSize='small' />}>
                    Avbryt
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}
