import React, {useState} from 'react'
import {IEstateMeterEmissionTabContextProps} from './interfaces'
import {TabContext} from '@mui/lab'
import {Card, CardContent, Container} from '@mui/material'
import ContainedTabList from '../../shared/lists/ContainedTabList'
import {IMeterType} from '../../../redux/meter/interfaces'
import EstateMeterEmissionTab from './EstateMeterEmissionTab'
const EstateMeterEmissionTabContext = (props: IEstateMeterEmissionTabContextProps) => {
  const [selectedTab, setSelectedTab] = useState(props.meterTypes && props.meterTypes.length > 0 ? props.meterTypes[0].id.toString() : '')

  const onTabChange = (e: React.SyntheticEvent<Element, Event>, obj: any) => {
    setSelectedTab(obj.toString())
    props.onTabChange(obj.toString())
  }

  const renderTabLabel = (type: IMeterType) => {
    if (type && props.units.length > 0) {
      const tabUnit = props.units.find((x) => x.id === type.unitId)
      return tabUnit ? `${type.name} (${tabUnit.comment})` : ''
    } else if (type) {
      return type.name
    } else {
      return ''
    }
  }

  return (
    <Card>
      <CardContent>
        <TabContext value={selectedTab}>
          <ContainedTabList onChange={onTabChange} tabs={props.meterTypes.map((x: IMeterType) => ({key: x.id, label: renderTabLabel(x), value: x.id.toString()}))} />
          <EstateMeterEmissionTab values={props.estateMeterEmissions} onEstateMeterEmissionEditClick={props.onEstateMeterEmissionEditClick} onEstateMeterEmissionDeleteClick={props.onEstateMeterEmissionDeleteClick} />
        </TabContext>
      </CardContent>
    </Card>
  )
}
export default EstateMeterEmissionTabContext
