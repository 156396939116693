import {styled} from '@mui/material/styles'
import {ToggleButton} from '@mui/material'

const MenuBotton = styled(ToggleButton)(({theme}) => ({
  minHeight: 38,
  width: 43,
  borderRadius: 0,
  border: 0,
  color: theme.sidebar.menuBottonColor,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.primary.main,
  },
}))

export default MenuBotton
