import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'

export const DrawerLogo = styled(Box)(
  ({theme}) => ` 
      width: ${theme.sidebar.width};
      display: flex;
      justify-content: center;    
`
)
