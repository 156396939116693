import measurePointService from '../../services/measurepointService'
import {ActionTypes} from './measurepointTypes'
import {ActionTypes as TreeActionTypes} from '../tree/treeTypes'
import {Dispatch} from 'redux'
import {IDisabledPeriodParameter, IInactivePeriod, IPeriodParameter} from '../../redux/counter/interfaces'
import { castUnit, processConsumptions, setMeterTypeOrder } from '../../shared/utils/utilities'
import { IMeterType } from '../meter/interfaces'
import configurationService from '../../services/configurationService'
import {IMeasurePointNumber} from './measurepointInterfaces'
import { IUnit } from '../unit/unitInterfaces'

export const getMeasurePointById = (id: number) => {
  return async (dispatch: Dispatch) => {
    let measurePoint = {}
    try {
      dispatch({type: ActionTypes.GET_MEASUREPOINT_LOADING})
      measurePoint = await measurePointService.getMeasurePointById(id)
    } catch (error) {
      return dispatch({type: ActionTypes.GET_MEASUREPOINT_FAILED, payload: error})
    }
    let number: number[] = []
    try {
      number = await measurePointService.getMeasurePointNumber(id)
    } catch (error) {
      number = []
    }
    let disabledPeriods: number[] | undefined = []
    try {
      const periodParameter: IDisabledPeriodParameter = await measurePointService.getMeasurePointDisabledPeriods(id)
      disabledPeriods = periodParameter?.periods.map((x: IPeriodParameter) => {
        if (new Date(x.startDate).getMonth() > 11) {
          return 1
        } else {
          return new Date(x.startDate).getMonth() + 1
        }
      })
    } catch (error) {
      disabledPeriods = undefined
    }
    return dispatch({type: ActionTypes.GET_MEASUREPOINT_SUCCESS, payload: {...measurePoint, number: number?.join(','), inactivePeriods: disabledPeriods}})
  }
}
export const createMeasurePoint = (addressId: number, name: string, description: string, hidden: boolean, number?: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.CREATE_MEASUREPOINT_LOADING})
      const createdMeasurePoint = await measurePointService.createMeasurePoint(addressId, name, description, hidden)
      let createdMeasurePointNumber: IMeasurePointNumber | undefined = undefined
      if (createdMeasurePoint && number) createdMeasurePointNumber = await measurePointService.saveMeasurePointNumber(createdMeasurePoint.id, number)

      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({type: ActionTypes.CREATE_MEASUREPOINT_SUCCESS, payload: {...createdMeasurePoint, number: createdMeasurePointNumber?.number}})
    } catch (error) {
      return dispatch({type: ActionTypes.CREATE_MEASUREPOINT_FAILED, payload: error})
    }
  }
}

export const updateMeasurePoint = (id: number, addressId: number, name: string, description: string, hidden: boolean, number?: string | undefined, inactivePeriods?: IInactivePeriod[]) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.UPDATE_MEASUREPOINT_LOADING})
      const updatedMeasurePoint = await measurePointService.updateMeasurePoint(id, addressId, name, description, hidden)
      let updatedNumbers = []
      if (number) {
        const currentNumbers: string[] = await measurePointService.getMeasurePointNumber(id)
        const numbersToAdd = number.split(',')
        if (currentNumbers) {
          const numbersToRemove = currentNumbers.filter((current) => !numbersToAdd.some((toAdd) => toAdd.toLowerCase() === current.toLowerCase()))
          for (const numberToRemove of numbersToRemove) {
            await measurePointService.deleteMeasurePointNumber(id, numberToRemove)
          }
          const newNumbersToadd = numbersToAdd.filter((toAdd) => !currentNumbers.some((current) => toAdd.toLowerCase() === current.toLowerCase()))
          for (const numberToAdd of newNumbersToadd) {
            updatedNumbers.push(await measurePointService.saveMeasurePointNumber(id, numberToAdd))
          }
        } else {
          const numbersToAdd = number.split(',')
          for (const numberToAdd of numbersToAdd) {
            updatedNumbers.push(await measurePointService.saveMeasurePointNumber(id, numberToAdd))
          }
        }
      } else {
        const currentNumbers: string[] = await measurePointService.getMeasurePointNumber(id)
        for (const numberToRemove of currentNumbers) {
          await measurePointService.deleteMeasurePointNumber(id, numberToRemove)
        }
      }
      let modifiedInactivePeriodNumbers = []
      if (inactivePeriods && inactivePeriods.length > 0) {
        await measurePointService.deleteDisabledPeriods(id)
        let currYear = new Date().getFullYear()
        const currentDate = new Date()
        let disabledPeriodParameter = {
          id: id,
          periods: inactivePeriods
            .filter((x) => x.isInactive)
            .map((x: IInactivePeriod) => ({
              startDate: new Date(Date.UTC(currYear, x.period - 1, 15)),
              stopDate: new Date(Date.UTC(currYear, x.period, 14, 23, 59, 59)),
              recurring: true,
            })), // p.period + 1 > 12 ? new Date(Date.UTC(new Date().getFullYear() + 1, 0, 14, 23, 59, 59)) : new Date(Date.UTC(new Date().getFullYear(), p.period, 14, 23, 59, 59)),
        }
        const modifiedInactivePeriods = await measurePointService.saveDisabledPeriods(disabledPeriodParameter)
        modifiedInactivePeriodNumbers = modifiedInactivePeriods?.periods.map((x: IPeriodParameter) => new Date(x.stopDate).getMonth() + 1)
      }

      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({type: ActionTypes.UPDATE_MEASUREPOINT_SUCCESS, payload: {...updatedMeasurePoint, number: updatedNumbers, inactivePeriods: modifiedInactivePeriodNumbers}})
    } catch (error) {
      console.log(error)
      return dispatch({type: ActionTypes.UPDATE_MEASUREPOINT_FAILED, payload: error})
    }
  }
}

export const deleteMeasurePoint = (measurePointId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.DELETE_MEASUREPOINT_LOADING})
    const data = await measurePointService.deleteMeasurePioint(measurePointId)
    dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
    return dispatch({type: ActionTypes.DELETE_MEASUREPOINT_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_MEASUREPOINT_FAILED, payload: error})
  }
}

export const getMeterTypesAtMeasurePoint = (measurePointId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_MEASUREPOINT_METER_TYPES_LOADING})
  try {
    let meterTypes = await measurePointService.getMeterTypesAtMeasurePoint(measurePointId)
    const degreeDayCorrectedMeterTypeIds = await configurationService.getDegreeDayCorrectedMeterTypes()
    meterTypes = meterTypes.map((type: IMeterType) => ({...type, canDegreeDayAdjust: degreeDayCorrectedMeterTypeIds.some((x: number) => x === type.id)}))
    const sorted = setMeterTypeOrder(meterTypes)
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_METER_TYPES_SUCCESS, payload: sorted})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_METER_TYPES_FAILED, payload: error})
  }
}

export const getConsumption = (measurePointId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOADING})
  try {
    const consumptions = await measurePointService.getConsumption(measurePointId, meterTypeId, startDate, stopDate, useDegreeDayCorrection)
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_SUCCESS, payload: consumptions})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_FAILED, payload: error})
  }
}

export const getNodeConsumption = (addressId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, shouldEstimate?: boolean, unit?: IUnit, category?: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOADING})
  try {
    const consumptions = shouldEstimate
      ? await measurePointService.getNodeConsumptionAndEstimate(addressId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
      : await measurePointService.getNodeConsumption(addressId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_SUCCESS, payload: processConsumptions(consumptions, unit, category)})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_FAILED, payload: error})
  }
}

export const getReadingTableLogs = (measurePointId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_MEASUREPOINT_READING_LOGS_LOADING})
  try {
    const data = await measurePointService.getReadingTableLogs(measurePointId, meterTypeId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_READING_LOGS_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_READING_LOGS_FAILED, payload: error})
  }
}
export const getConsumptionTableLogs = (measurePointId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOGS_LOADING})
  try {
    const data = await measurePointService.getConsumptionTableLogs(measurePointId, meterTypeId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOGS_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOGS_FAILED, payload: error})
  }
}
export const getCountersByMeasurePointId = (measurePointId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_COUNTERS_BY_MEASUREPOINT_ID_LOADING})
      const data = await measurePointService.getCountersByMeasurePointId(measurePointId)
      return dispatch({type: ActionTypes.FETCH_COUNTERS_BY_MEASUREPOINT_ID_SUCCESS, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_COUNTERS_BY_MEASUREPOINT_ID_FAILED, payload: error})
    }
  }
}
