import {useEffect, ReactElement, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {getTree, loadUnfilteredTree, applyFilters, setExpandedNodes} from '../../redux/tree/treeActions'
import {Efficiency, Electricity, ElectricityHeat, CoolingEnergy, CoolingFlow, HeatingEnergy, HeatingFlow, Time, Oil, Temperature, Water} from '../shared/icons/gomorron'
import {Domain, House, PinDrop, Speed, ChevronRight, ExpandMore, Check, Recycling} from '@mui/icons-material'
import {TreeItem, TreeView} from '@mui/x-tree-view'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {cloneDeep} from 'lodash'
import useWindowDimensions from '../../shared/hooks/useWindowDimensions'
import {hasEditPermissions, isNumeric} from '../../shared/utils/utilities'
import {styled} from '@mui/material'
import {ITree} from '../../redux/tree/treeInterfaces'
import {TreeProps} from './interfaces'
const renderTypeIcon = (item: any, defaultValue = '') => {
  switch (item.type) {
    case 'ELECTRICITY':
      return <Electricity className='saveup-tree-icon' />
    case 'HEAT_EXCHANGER':
      return <Efficiency className='saveup-tree-icon' />
    case 'ELECTRICITY_HEAT':
      return <ElectricityHeat className='saveup-tree-icon' />
    case 'COOLING_ENERGY':
      return <CoolingEnergy className='saveup-tree-icon' />
    case 'COOLING_FLOW':
      return <CoolingFlow className='saveup-tree-icon' />
    case 'HEATING_ENERGY':
      return <HeatingEnergy className='saveup-tree-icon' />
    case 'HEATING_FLOW':
      return <HeatingFlow className='saveup-tree-icon' />
    case 'TIME':
      return <Time className='saveup-tree-icon' />
    case 'OIL':
      return <Oil className='saveup-tree-icon' />
    case 'WATER':
      return <Water className='saveup-tree-icon' />
    case 'TEMPERATURE':
      return <Temperature className='saveup-tree-icon' />
    case 'RECYCLING_ENERGY':
      return <Recycling />
    case 'RECYCLING_FLOW':
      return <Recycling />
    case 'OWNER':
      return <Domain />
    case 'ESTATE':
      return <House />
    case 'ADDRESS':
      return <PinDrop />
    case 'MEASUREPOINT':
      return <Speed />
    case 'COUNTER':
      return <Speed />
    default:
      return defaultValue
  }
}

const renderStateIcon = (data: any) => {
  return data.hasReading ? <Check sx={{fontSize: '16px', color: 'green'}} /> : ''
}

const renderIcons = (item: any) => {
  return item.data.hasReading ? (
    <div>
      <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'left', marginLeft: '5px'}}>
        {renderStateIcon(item)}
        {renderTypeIcon(item)}&nbsp;
      </span>
    </div>
  ) : (
    renderTypeIcon(item)
  )
}

const StyledTreeItem = styled(TreeItem)`
  .MuiTreeItem-label {

  }
`

const Tree = (props: TreeProps): ReactElement => {
  const dispatch = useDispatch()
  const dimensions = useWindowDimensions()
  const navigate = useNavigate()
  const {fullTree, isLoading, selectedOffice, selectedMeterType, keyword, updDate} = useSelector((state: RootState) => state.tree)
  const canEdit = hasEditPermissions()
  const location = useLocation()
  const filteredTree = useSelector((state: RootState) => state.tree.filteredTree)
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null)
  const [expanded, setExpanded] = useState<string[]>([])

  const expandedNodesFromState = useSelector((state: RootState) => state.tree.expandedNodes)
  
  useEffect(() => {
    setExpanded(expandedNodesFromState)
  }, [expandedNodesFromState])

  useEffect(() => {
    dispatch(getTree())
  }, [updDate])
  useEffect(() => {
    if (fullTree && (selectedOffice.length > 0 || selectedMeterType > 0 || keyword.length > 3 || !canEdit)) {
      dispatch(applyFilters(cloneDeep(fullTree), selectedOffice, selectedMeterType, keyword))
    } else if (fullTree) {
      dispatch(loadUnfilteredTree(fullTree))
    }
  }, [fullTree, selectedOffice, selectedMeterType, keyword])

  const expandParent = (node: any) => {
    if (node.parent) {
      node.expand = true
      expandParent(node.parent)
    }
    node.expand = true
  }

  const getNodes = (node: any) => (node.children ? node.children.map((child: any) => getNodes(child)).flat() : [node])

  const expand = (nodes: any) => nodes.forEach((node: any) => (node.defaultExpanded = true))

  
  const handleNodeToggled = (event: React.SyntheticEvent<Element, Event>, nodeIds: string[]) => {
    dispatch(setExpandedNodes(nodeIds))
  }

  const renderTreeItemLabel = ({data, text}: any) => {
    const {id, type} = data
    return (
      <Link to={`/${type}/${id}`} style={{textDecoration: 'none', color: 'inherit', whiteSpace: 'nowrap', textDecorationLine: data.hidden ? 'line-through' : 'none'}}>
        {renderStateIcon(data)}
        {text}
      </Link>
    )
  }

  const renderTreeItems = (treeItems: any) => {
    if (!treeItems) {
      return ''
    }

    const mappedItems = treeItems.map((treeItemData: any) => {
      let children = undefined
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = renderTreeItems(treeItemData.children)
      }
      return (
        <TreeItem
          key={`${treeItemData.type}_${treeItemData.id}`}
          nodeId={treeItemData.id}
          label={renderTreeItemLabel(treeItemData)}
          children={children}
          data-type={treeItemData.type}
          data-data={treeItemData.data}
          data-state={treeItemData.state}
          onClick={() => {
            navigate(`/${treeItemData.data.type}/${treeItemData.data.id}`)
          }}
          icon={renderTypeIcon(treeItemData)}
          id={`${treeItemData.id}`}
        />
      )
    })
    return mappedItems
  }

  const windowHeight = dimensions.height - 260
  const renderTree = () => {
    const nodes = renderTreeItems(filteredTree)
    return (
      <TreeView
        aria-label='Trädvy'
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        expanded={expanded}
        onNodeToggle={handleNodeToggled}
        sx={{height: windowHeight-30, flexGrow: 1, maxWidth: 400, overflow: 'scroll'}}>
        {nodes}
      </TreeView>
    )
  }

  return <div>{renderTree()}</div>
}
export default Tree
