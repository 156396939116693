import React, { useEffect, useState } from 'react'
import {IAttributeControlPanelProps} from './interfaces'
import {DROPDOWN, UNDEFINED_SWE} from '../../../shared/utils/constants'
import UncontrolledDropdown from './UncontrolledDropdown'
import {IAttributeControl, INodeAttribute, IAttributeControlValue} from '../../../redux/attribute/interfaces'
import UncontrolledTextfield from './UncontrolledTextfield'
import {Grid} from '@mui/material'
import {set} from 'lodash'
const AttributeControlPanel = (props: IAttributeControlPanelProps) => {  

  const handleOnChange = (attributeId: number, value: any) => {            
    props.onChange(attributeId, value)
  }

  const renderAttribute = (control: IAttributeControl) => {
    if(!control.attribute) return <React.Fragment />
    let nodeAttribute = props.values?.find((x: INodeAttribute) => x.attributeId === control.attributeId)
    const valuesToRender = control.attributeControlValues.map((x: IAttributeControlValue) => x.value)
    let value = ''
    if (!nodeAttribute) {
      if(props.ignoreDefaults) {         
        valuesToRender.push(UNDEFINED_SWE)
        value = UNDEFINED_SWE   
      }
      else {
        let attributeControlValue = control.attributeControlValues.find((x: IAttributeControlValue) => x.isDefault)
        value = attributeControlValue ? attributeControlValue.value : ''
      }
    } else {
      value = nodeAttribute.value      
    }
    switch (control.controlType.name) {
      case DROPDOWN:      
        return (
          <UncontrolledDropdown
            key={`${control.id}-${control.attribute.id}`}
            label={control.label}
            value={value}
            onChange={(e: any) => { 
              handleOnChange(control.attribute.id, e)
            }}
            values={valuesToRender}
            disabled={props.disabled}
          />
        )
      default:
        return <UncontrolledTextfield key={`${control.id}-${control.attribute.id}`} label={control.label} value={value} onChange={(e: any) => {
          handleOnChange(control.attribute.id, e.target.value)
        }} />
    }
  }

  return (
    <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
      <Grid item xs={2}>
        {props.attributeControls.map((control: IAttributeControl) => renderAttribute(control))}
      </Grid>
    </Grid>
  )
}
export default AttributeControlPanel
