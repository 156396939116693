import {Reducer} from 'redux'
import {ActionTypes} from './types'
import {OwnerEstateState, EstateState} from './Interfaces'

export const initialState: EstateState = {
  isLoading: false,
  isConsumptionLoading: false,
  estates: [],
  errorMessage: undefined,
  meterTypes: [],
  selectedEstate: undefined,
  consumptions: [],
  readingProperties: [],
  consumptionProperties: [],
  readers: [],
  addresses: [],
  estateMeterCosts: [],
  selectedEstateMeterCost: undefined,
  deletedEstateMeterCost: 0,
  estateMeterEmissions: [],
  selectedEstateMeterEmission: undefined,
  deletedEstateMeterEmission: 0,
  estateAttributeControls: {
    controls: [],
    isLoading: false,
    errorMessage: ''
  }
}
export const initiaOwnerEstatelState: OwnerEstateState = {
  isLoading: false,
  data: [],
  errorMessage: undefined,
}

export const EstateReducer: Reducer<EstateState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_ESTATE_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_SUCCESS: {
      return {...state, isLoading: false, data: payload}
    }
    case ActionTypes.FETCH_ESTATE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATES_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATES_SUCCESS: {
      return {...state, isLoading: false, estates: payload}
    }
    case ActionTypes.FETCH_ESTATES_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATE_BY_ID_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_BY_ID_SUCCESS: {
      return {...state, isLoading: false, selectedEstate: payload}
    }
    case ActionTypes.FETCH_ESTATE_BY_ID_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATE_METER_TYPES_SUCCESS: {
      return {...state, isLoading: false, meterTypes: payload}
    }
    case ActionTypes.FETCH_ESTATE_METER_TYPES_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATE_METER_TYPES_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_CONSUMPTION_SUCCESS: {
      return {...state, isConsumptionLoading: false, consumptions: payload}
    }
    case ActionTypes.FETCH_ESTATE_CONSUMPTION_ERROR: {
      return {...state, isConsumptionLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATE_CONSUMPTION_LOADING: {
      return {...state, isConsumptionLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_READING_PROPERTIES_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_READING_PROPERTIES_SUCCESS: {
      return {...state, isLoading: true, readingProperties: payload}
    }
    case ActionTypes.FETCH_ESTATE_READING_PROPERTIES_FAILED: {
      return {...state, isLoading: true, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATE_CONSUMPTION_PROPERTIES_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_CONSUMPTION_PROPERTIES_SUCCESS: {
      return {...state, isLoading: true, consumptionProperties: payload}
    }
    case ActionTypes.FETCH_ESTATE_CONSUMPTION_PROPERTIES_FAILED: {
      return {...state, isLoading: true, errorMessage: payload.status}
    }
    case ActionTypes.CREATE_ESTATE_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.CREATE_ESTATE_SUCCESS: {
      return {...state, isLoading: false, selectedEstate: payload}
    }
    case ActionTypes.CREATE_ESTATE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.UPDATE_ESTATE_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.UPDATE_ESTATE_SUCCESS: {
      return {...state, isLoading: false, selectedEstate: payload}
    }
    case ActionTypes.UPDATE_ESTATE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.DELETE_ESTATE_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.DELETE_ESTATE_SUCCESS: {
      return {...state, isLoading: false}
    }
    case ActionTypes.DELETE_ESTATE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATE_ATTRIBUTES_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_ATTRIBUTES_SUCCESS: {
      return {...state, isLoading: false, attributes: payload}
    }
    case ActionTypes.FETCH_ESTATE_ATTRIBUTES_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.FETCH_ESTATE_USERS_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_USERS_SUCCESS: {
      return {...state, isLoading: false, readers: payload}
    }
    case ActionTypes.FETCH_ESTATE_USERS_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.status}
    }
    case ActionTypes.CREATE_ESTATE_USER_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.CREATE_ESTATE_USER_SUCCESS: {
      return {...state, isLoading: false, readers: payload}
    }
    case ActionTypes.CREATE_ESTATE_USER_FAILED: {
      return {...state, isLoading: false}
    }
    case ActionTypes.DELETE_ESTATE_USER_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.DELETE_ESTATE_USER_SUCCESS: {
      return {...state, isLoading: false, readers: state.readers.filter((x) => x.id !== payload)}
    }
    case ActionTypes.DELETE_ESTATE_USER_FAILED: {
      return {...state, isLoading: false}
    }
    case ActionTypes.FETCH_ESTATE_ADDRESSES_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_ESTATE_ADDRESSES_OK: {
      return {...state, isLoading: false, addresses: payload}
    }
    case ActionTypes.FETCH_ESTATE_ADDRESSES_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.GET_ESTATE_METER_COST_LOADING: {
      return state
    }
    case ActionTypes.GET_ESTATE_METER_COST_SUCCESS: {
      return {...state, estateMeterCosts: payload}
    }
    case ActionTypes.GET_ESTATE_METER_COST_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.CREATE_ESTATE_METER_COST_LOADING: {
      return state
    }
    case ActionTypes.CREATE_ESTATE_METER_COST_SUCCESS: {
      return {...state, selectedEstateMeterCost: payload}
    }
    case ActionTypes.CREATE_ESTATE_METER_COST_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.UPDATE_ESTATE_METER_COST_LOADING: {
      return state
    }
    case ActionTypes.UPDATE_ESTATE_METER_COST_SUCCESS: {
      return {...state, selectedEstateMeterCost: payload}
    }
    case ActionTypes.UPDATE_ESTATE_METER_COST_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.DELETE_ESTATE_METER_COST_LOADING: {
      return state
    }
    case ActionTypes.DELETE_ESTATE_METER_COST_SUCCESS: {
      return {...state, deletedEstateMeterCost: payload}
    }
    case ActionTypes.DELETE_ESTATE_METER_COST_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.GET_ESTATE_METER_EMISSION_LOADING: {
      return state
    }
    case ActionTypes.GET_ESTATE_METER_EMISSION_SUCCESS: {
      return {...state, estateMeterEmissions: payload}
    }
    case ActionTypes.GET_ESTATE_METER_EMISSION_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.CREATE_ESTATE_METER_EMISSION_LOADING: {
      return state
    }
    case ActionTypes.CREATE_ESTATE_METER_EMISSION_SUCCESS: {
      return {...state, selectedEstateMeterEmission: payload}
    }
    case ActionTypes.CREATE_ESTATE_METER_EMISSION_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.UPDATE_ESTATE_METER_EMISSION_LOADING: {
      return state
    }
    case ActionTypes.UPDATE_ESTATE_METER_EMISSION_SUCCESS: {
      return {...state, selectedEstateMeterEmission: payload}
    }
    case ActionTypes.UPDATE_ESTATE_METER_EMISSION_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.DELETE_ESTATE_METER_EMISSION_LOADING: {
      return state
    }
    case ActionTypes.DELETE_ESTATE_METER_EMISSION_SUCCESS: {
      return {...state, deletedEstateMeterEmission: payload}
    }
    case ActionTypes.DELETE_ESTATE_METER_EMISSION_FAILED: {
      return {...state, errorMessage: payload}
    }
    case ActionTypes.FETCH_ESTATE_ATTRIBUTE_CONTROLS_LOADING: {
      return {...state, isLoading: true, estateAttributeControls: { controls: [], isLoading: false, errorMessage: '' }  }
    }
    case ActionTypes.FETCH_ESTATE_ATTRIBUTE_CONTROLS_SUCCESS: {
      return {...state, isLoading: false, estateAttributeControls: { controls: payload, isLoading: false, errorMessage: ''}}
    }
    case ActionTypes.FETCH_ESTATE_ATTRIBUTE_CONTROLS_FAILED: {
      return {...state, isLoading: false, estateAttributeControls: { controls: [], isLoading: false, errorMessage: payload}}
    }
    default: {
      return state
    }
  }
}

export const OwnerEstateReducer: Reducer<OwnerEstateState> = (state = initiaOwnerEstatelState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_OWNERESTATE_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_OWNERESTATE_SUCCESS: {
      return {...state, isLoading: false, data: payload}
    }
    case ActionTypes.FETCH_OWNERESTATE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    default: {
      return state
    }
  }
}
