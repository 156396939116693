import { IEstateMeterEmission } from "../redux/estateMeterEmission/interfaces"
import saveupClient, { handleResponse } from "../clients/saveupClient"

async function getEstateMeterEmissions(estateId: number, meterId: number)  {
    return await saveupClient.get<IEstateMeterEmission[]>(`estate/${estateId}/meter/${meterId}/emission`).then(handleResponse)    
}
async function createEstateMeterEmission(estateId: number, emissionId: number, meterId: number, value: number, startDate: Date, stopDate: Date) {
    return await saveupClient.post<IEstateMeterEmission>(`estate/meter/emission`, {estateId, meterId, emissionId, value, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
}
async function updateEstateMeterEmission(id: number, estateId: number, emissionId: number, meterId: number, value: number, startDate: Date, stopDate: Date) {
    return await saveupClient.put<IEstateMeterEmission>(`estate/meter/emission`, {id, estateId, emissionId, meterId, value, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
} 
async function deleteEstateMeterEmission(id: number) {
    return await saveupClient.delete(`estate/meter/emission/${id}`).then(handleResponse)
} 
const estateMeterEmissionService = {
    getEstateMeterEmissions,
    createEstateMeterEmission,
    updateEstateMeterEmission,
    deleteEstateMeterEmission
}
  
  export default estateMeterEmissionService
  