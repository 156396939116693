import {DefaultsTheme} from './schemes/AltTheme'
import {DarkTheme} from './schemes/DarkTheme'
import {Theme} from '@mui/material'

export function themeCreator(theme: string): Theme {
  return themeMap[theme]
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      shadows: {
        success: string
        error: string
        primary: string
        warning: string
      }

      alpha: {
        white: {
          5: string
          10: string
          30: string
          50: string
          70: string
          100: string
        }
        trueWhite: {
          5: string
          10: string
          30: string
          50: string
          70: string
          100: string
        }
        black: {
          5: string
          10: string
          30: string
          50: string
          70: string
          100: string
        }
      }
      secondary: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      primary: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      success: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      warning: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      error: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      info: {
        lighter: string
        light: string
        main: string
        dark: string
      }
    }
    general: {
      reactFrameworkColor: React.CSSProperties['color']
      borderRadiusSm: string
      borderRadius: string
      borderRadiusLg: string
      borderRadiusXl: string
    }
    header: {
      height: string
      background: string
      borderColor: string
    }
    sidebar: {
      width: string
      background: string
      borderColor: string
      menuBottonColor: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: {
      shadows: {
        success: string
        error: string
        primary: string
        warning: string
      }
      alpha: {
        white: {
          5: string
          10: string
          30: string
          50: string
          70: string
          100: string
        }
        trueWhite: {
          5: string
          10: string
          30: string
          50: string
          70: string
          100: string
        }
        black: {
          5: string
          10: string
          30: string
          50: string
          70: string
          100: string
        }
      }
      secondary: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      primary: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      success: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      warning: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      error: {
        lighter: string
        light: string
        main: string
        dark: string
      }
      info: {
        lighter: string
        light: string
        main: string
        dark: string
      }
    }
    header: {
      height: string
      background: React.CSSProperties['color']
      borderColor: React.CSSProperties['color']
    }
    sidebar: {
      width: string
      background: React.CSSProperties['color']
      borderColor: React.CSSProperties['color']
      menuBottonColor: React.CSSProperties['color']
    }
    general: {
      reactFrameworkColor: React.CSSProperties['color']
      borderRadiusSm: string
      borderRadius: string
      borderRadiusLg: string
      borderRadiusXl: string
    }
  }
}

const themeMap: {[key: string]: Theme} = {
  DarkTheme,
  DefaultsTheme,
}
