import {ActionTypes} from './types'
import {Reducer} from 'redux'
import {ICaseState} from './interfaces'

const initialCounterState: ICaseState = {
  selectedCase: undefined,
  caseStatuses: [],
  caseTypes: []
}
export const caseReducer: Reducer<ICaseState> = (state = initialCounterState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_CASE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_CASE_SUCCESS:
      return {...state, isLoading: false, selectedCase: payload}
    case ActionTypes.FETCH_CASE_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.FETCH_CASESTATUS_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_CASESTATUS_SUCCESS:
      return {...state, isLoading: false, caseStatuses: payload}
    case ActionTypes.FETCH_CASESTATUS_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.FETCH_CASETYPES_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_CASETYPES_SUCCESS:
      return {...state, isLoading: false, caseTypes: payload}
    case ActionTypes.FETCH_CASETYPES_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.CREATE_CASE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.CREATE_CASE_SUCCESS:
      return {...state, isLoading: false, selectedCase: payload}
    case ActionTypes.CREATE_CASE_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
      case ActionTypes.CREATE_POSTS_LOADING:
        return {...state, isLoading: true}
      case ActionTypes.CREATE_POSTS_SUCCESS:
        return {...state, isLoading: false, posts: payload}
      case ActionTypes.CREATE_POSTS_FAILED:
        return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.UPDATE_CASE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.UPDATE_CASE_SUCCESS:
      return {...state, isLoading: false, selectedCase: payload}
    case ActionTypes.UPDATE_CASE_FAILED:
      return {...state, isLoading: false, errorMessage: payload}      
    default:
      return state
  }
}
