import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {deleteAddress, getMeasurePointByAddressId, getaddressbyid, updateAddress} from '../../redux/address/addressActions'
import {RootState} from '../../redux/rootReducer'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import SecButton from '../shared/Inputs/SecButton'
import {Delete, Edit, Save} from '@mui/icons-material'
import AddressMeasurePoints from './AddressMeasurePoints'
import {IAddressDetailsProps} from '../../redux/address/interfaces'
import {Controller, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router'
import SaveAlert from '../shared/alerts/SaveAlert'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import {Link} from 'react-router-dom'
import {hasPermissions, isAdmin, isNullOrWhiteSpace, sortSelectItems} from '../../shared/utils/utilities'
import SearchSelectDialog, {ISelectItem} from '../shared/dialogs/SearchSelectDialog'
import {getEstate, getEstates} from '../../redux/estate/actions'
import {IEstate, ISelectedEstate} from '../../redux/estate/Interfaces'
import estateService from '../../services/estateService'
import {text} from 'stream/consumers'
import {NODE_WRITE} from '../../shared/utils/constants'
interface IUpdateAddressForm {
  name: string
  street: string
  postalCode: string
  city: string
  number: string
  comment: string
  hidden: boolean
}

const AddressDetails = (props: IAddressDetailsProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getaddressbyid(props.addressId))
  }, [props.addressId])
  const navigate = useNavigate()
  const address = useSelector((state: RootState) => state.address.selectedAddress)
  const addressMeasurePoints = useSelector((state: RootState) => state.address.measurePoints)  
  const estate = useSelector((state: RootState) => state.estate.selectedEstate)
  const [saveResult, setSaveResult] = useState<SaveResponse>(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [deleteAddressDialogOpen, setDeleteAddressDialogOpen] = useState<boolean>(false)
  const [selectEstateDialogOpen, setSelectEstateDialogOpen] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)

  useEffect(() => {
    if (address) {
      dispatch(getEstate(address.estateId))
      reset({
        name: address.name,
        street: address.street,
        postalCode: address.postalCode,
        city: address.city,
        number: address.number,
        comment: address.comment,
        hidden: address.hidden,
      })
    }
  }, [address])

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() => {    
    dispatch(getMeasurePointByAddressId(props.addressId))
  }, [props.addressId])

  const {control, handleSubmit, reset} = useForm<IUpdateAddressForm>({
    defaultValues: {
      name: address?.name,
      street: address?.street,
      postalCode: address?.postalCode,
      city: address?.city,
      number: address?.number,
      comment: address?.comment,
      hidden: false,
    },
  })

  const onSaveAddressClick = (form: IUpdateAddressForm) => {
    if (address) {
      const estateId = estate ? estate.id : address.estateId
      dispatch(updateAddress(address.id, form.name, estateId, form.street, form.postalCode, form.city, form.number, form.comment, form.hidden))
      setSaveResult(SaveResponse.SUCCESS)
      setAlertOpen(true)
    }
  }
  const onDeleteAddressClick = (e: any) => {
    if (address) {
      setDeleteAddressDialogOpen(false)
      dispatch(deleteAddress(address.id))
      navigate(`/estate/${address.estateId}`)
    }
  }
  const onChangeEstateClick = (e: any) => setSelectEstateDialogOpen(true)
  const onChangeEstateConfirmClick = (value: any) => {
    setSelectEstateDialogOpen(false)
    if (value) {
      dispatch(getEstate(value))
    }
  }
  const onEstateSearchPerformed = async (keyword: string) => {
    let result: ISelectItem[] = []
    if (keyword && keyword.trim().length >= 3) {
      let estates = await estateService.getEstatesByName(keyword)
      result = estates.map((x: IEstate) => {
        const item: ISelectItem = {id: x.id, text: x.name}
        return item
      })
      result = sortSelectItems(result)
    }
    return result
  }

  const renderEditButton = () =>
    canEdit && (
      <Grid item xs={10}>
        <SecButton startIcon={<Save />} variant='contained' onClick={handleSubmit(onSaveAddressClick)}>
          Spara
        </SecButton>
      </Grid>
    )

  const renderDeleteButton = () =>
    hasAdminRights && (
      <Grid item xs={2}>
        <SecButton startIcon={<Delete />} variant='contained' onClick={(e: React.MouseEvent<HTMLButtonElement>) => setDeleteAddressDialogOpen(true)} color='error' sx={{float: 'right'}}>
          Radera
        </SecButton>
      </Grid>
    )

  const renderModifyButtons = () => (
    <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
      {renderEditButton()}
      {renderDeleteButton()}
    </Grid>
  )

  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
        <Grid item xs={12}>
          <Card component='form' noValidate autoComplete='off'>
            <CardHeader title='Redigera address' />
            <Divider />
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                  <Grid item xs={12}>
                    <SaveAlert
                      result={saveResult}
                      open={alertOpen}
                      onClose={() => setAlertOpen(false)}
                      errorMessage='Det gick ej att uppdatera adressen.'
                      successMessage='Addressen har uppdaterats.'
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={4}>
                    <Controller name='name' control={control} render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} InputLabelProps={{shrink: true}} />} />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField fullWidth inputProps={{maxLength: 40}} disabled={true} InputProps={{readOnly: true}} label='Id' value={address?.id} InputLabelProps={{shrink: true}} />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={3}>
                    <Controller name='street' control={control} render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} label='Gata' InputLabelProps={{shrink: true}} />} />
                  </Grid>
                  <Grid item xs={2}>
                    <Controller name='number' control={control} render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} label='Nummer' InputLabelProps={{shrink: true}} />} />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name='postalCode'
                      control={control}
                      render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} label='Postkod' InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller name='city' control={control} render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} label='Stad' InputLabelProps={{shrink: true}} />} />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={4}>
                    <Controller
                      name='comment'
                      control={control}
                      render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} label='Kommentar' multiline={true} minRows={3} InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  {hasAdminRights && (
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        inputProps={{maxLength: 40}}
                        label='Fastighet'
                        value={estate?.name}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <SecButton startIcon={<Edit />} onClick={onChangeEstateClick}>
                              Ändra
                            </SecButton>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <FormControlLabel
                      sx={{marginTop: '10px'}}
                      control={
                        <Controller
                          name='hidden'
                          control={control}
                          render={({field}) => {
                            return <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Avläses ej' disabled={!canEdit} />
                          }}
                        />
                      }
                      label='Avläses ej'
                    />
                  </Grid>
                </Grid>
                {renderModifyButtons()}
              </Grid>
            </CardContent>
          </Card>
          <Card component='form' noValidate autoComplete='off' sx={{marginTop: '20px'}}>
            <CardHeader title='Mätpunkter' />
            <Divider />
            <CardContent>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>                
                  <Grid item xs={12}>
                    <AddressMeasurePoints isLoading={addressMeasurePoints.isLoading} errorMessage={addressMeasurePoints.errorMessage} measurePoints={addressMeasurePoints.measurePoints} />
                  </Grid>                
                {hasAdminRights ? (
                  <Grid item xs={12}>
                    <Link to={`measurepoint/create`}>
                      <SecButton startIcon={<Save />} variant='contained'>
                        Ny mätpunkt
                      </SecButton>
                    </Link>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div>
        <Dialog open={deleteAddressDialogOpen} onClose={() => setDeleteAddressDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>{'Radera adress'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Vill du radera adressen {isNullOrWhiteSpace(address?.name) ? `${address?.name}(${address?.id})` : `med ID: ${address?.id}`}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onDeleteAddressClick} autoFocus>
              Radera
            </Button>
            <Button onClick={() => setDeleteAddressDialogOpen(false)}>Avbryt</Button>
          </DialogActions>
        </Dialog>
      </div>
      <SearchSelectDialog
        open={selectEstateDialogOpen}
        title='Flytta address'
        defaultItemLabel='-- Välj fastighet --'
        text='Välj den fastighet till vilken du vill flytta addressen.'
        onConfirm={onChangeEstateConfirmClick}
        onCancel={() => setSelectEstateDialogOpen(false)}
        onSearch={onEstateSearchPerformed}
      />
    </Container>
  )
}
export default AddressDetails
