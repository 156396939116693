import {Reducer} from 'redux'
import {ActionTypes} from './types'
import {IAreaState, AreaByIdState} from './Interfaces.ts'

export const initialState: IAreaState = {
  areas: [],
  isLoading: false,
  area: undefined,
  energyZone: undefined,
  errorMessage: undefined,
  lastAction: ''
}

export const AreaReducer: Reducer<IAreaState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_AREAS_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_AREAS_SUCCESS: {
      return {...state, isLoading: false, areas: payload}
    }
    case ActionTypes.FETCH_AREAS_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.FETCH_AREA_LOADING: {
      return {...state, isLoading: true, lastAction: ActionTypes.FETCH_AREA_LOADING}
    }
    case ActionTypes.FETCH_AREA_SUCCESS: {      
      return {...state, isLoading: false, area: payload, lastAction: ActionTypes.FETCH_AREA_SUCCESS}
    }
    case ActionTypes.FETCH_AREA_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status, lastAction: ActionTypes.FETCH_AREA_FAILED}
    }
    case ActionTypes.CREATE_AREA_LOADING: {
      return {...state, isLoading: true, lastAction: ActionTypes.CREATE_AREA_LOADING}
    }
    case ActionTypes.CREATE_AREA_SUCCESS: {
      return {...state, isLoading: false, area: payload, lastAction: ActionTypes.CREATE_AREA_SUCCESS}
    }
    case ActionTypes.CREATE_AREA_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status, lastAction: ActionTypes.CREATE_AREA_FAILED}
    }
    case ActionTypes.EDIT_AREA_LOADING: {
      return {...state, isLoading: true, lastAction: ActionTypes.EDIT_AREA_LOADING}
    }
    case ActionTypes.EDIT_AREA_SUCCESS: {
      return {...state, isLoading: false, area: payload, lastAction: ActionTypes.EDIT_AREA_SUCCESS}
    }
    case ActionTypes.EDIT_AREA_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status, lastAction: ActionTypes.EDIT_AREA_FAILED}
    }
    case ActionTypes.DELETE_AREA_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.DELETE_AREA_SUCCESS: {
      return {...state, isLoading: false }
    }
    case ActionTypes.DELETE_AREA_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.FETCH_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_AREA_ENERGY_ZONE_SUCCESS: {
      return {...state, isLoading: false, energyZone: payload}
    }
    case ActionTypes.FETCH_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.CREATE_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: true}
    }
    case ActionTypes.CREATE_AREA_ENERGY_ZONE_SUCCESS: {
      return {...state, isLoading: false, energyZone: payload}
    }
    case ActionTypes.CREATE_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.UPDATE_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: true}
    }
    case ActionTypes.UPDATE_AREA_ENERGY_ZONE_SUCCESS: {
      return {...state, isLoading: false, energyZone: payload}
    }
    case ActionTypes.UPDATE_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    case ActionTypes.DELETE_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: true}
    }
    case ActionTypes.DELETE_AREA_ENERGY_ZONE_SUCCESS: {
      return {...state, isLoading: false }
    }
    case ActionTypes.DELETE_AREA_ENERGY_ZONE_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.response.status}
    }
    default: {
      return state
    }
  }
}