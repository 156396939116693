import {CustomReportParameter, CustomReportValueParameter, IReportDefinition, IReportDefinitionParameter, ReportPreview} from '../redux/report/interfaces'
import saveupClient, {handleError, handleResponse} from '../clients/saveupClient'
import { applyTransformations, generateCsvFile, isNullOrWhiteSpace, jsonToCsv } from '../shared/utils/utilities'
import { M_EXP_2 } from '../shared/utils/constants'

const defaultParameters: IReportDefinitionParameter[] = [
  {name: 'fromYear', title: 'Från år', value: new Date().getFullYear().toString()},
  {name: 'fromMonth', title: 'Från period', value: new Date().getFullYear().toString()},
  {name: 'toYear', title: 'Till år', value: new Date().getFullYear().toString()},
  {name: 'toMonth', title: 'Till period', value: new Date().getFullYear().toString()},
]

async function getReports() {
  const reports: IReportDefinition[] = [
    {
      id: 1,
      name: 'COUNTERS_WITHOUT_READING',
      title: 'Ej avlästa mätare',
      description: 'Rapport över vilka fastigheter, adresser, mätpunkter och mätare som ej fått avläsning registrerad inom vald avläsningsperiod.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'MätarId'],
      parameters: defaultParameters,
    },

    {
      id: 2,
      name: 'COUNTERS_WITH_DECREASING_READING',
      title: 'Mätare med negativ förbrukning inom period',
      description: 'Rapport över mätare vilka har en negativ förbrukning inom givet periodsintervall.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'MätarId', 'Avvikelsekategori'],
      parameters: defaultParameters,
      propertyTransforms: [ { propertyIndex: 10, doTransform: (value: any) => isNullOrWhiteSpace(value) ? '' : value === "RESET" ? 'Mätarbyte / Rundslagning' : 'Avvikelse' } ]
    },

    {
      id: 3,
      name: 'CONSUMPTION_ATEMP_LOA_DEVIATIONS',
      title: 'Avvikelse gentemot föregående år (OBS: Tar lång tid)',
      description: 'Rapport, per räkneverk och period, med förbrukning, föregående års förbrukning, LOA, ATemp samt avvikelse.',
      columnHeaders: [
        'Fastighetsägare',
        'Fastighet',
        'Adress',
        'Mätpunkt',
        'Mätare',
        'Mätarnummer',
        'Typ',
        'Enhet',
        'Kontor',
        'År',
        'Månad',
        'Avläsning',
        'Föregående års förbrukning',
        'Förbrukning',
        "Föregående års ATemp",
        "ATemp",
        "Föregående års LOA",
        "LOA",
        'Har avvikelse',
        'Avvikelse förbrukning',
        'Avvikelse ATemp',
        'Avvikelse LOA',        
        'Tillåten avvikelse',
        'Graddagsjusterad',
        'MätarId',
      ],
      parameters: defaultParameters,
    },

    {
      id: 4,
      name: 'DEVIATIONS',
      title: 'Tillåtna avvikelsevärden',
      description: 'Rapport över samtliga inmatade avvikelsevärden',
      columnHeaders: ['Tillåten avikelse', 'Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'MätarId'],
      parameters: defaultParameters,
    },

    {
      id: 5,
      name: 'DEVIATIONS_CASE_OPEN',
      title: 'Pågående avvikelseärenden',
      description: 'Rapport över samtliga pågående avvikelseärenden',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'Datum', 'Ärende', 'Beskrivning', 'MätarId'],
      parameters: defaultParameters,
    },

    {
      id: 6,
      name: 'MESTRO_COUNTERS',
      title: 'Externt kopplade mätare',
      description: 'Rapport över samtliga externt ansluta mätare.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'Integrationstyp', 'System', 'Externt Id', 'Mestro Nod-Id', 'MätarId'],
      parameters: defaultParameters,
    },
    {
      id: 7,
      name: 'COUNTERS_ALL',
      title: 'Alla mätare',
      description: 'Rapport över alla tillgängliga mätare i SaveUp.',
      columnHeaders: ['Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Mätare', 'Mätarnummer', 'Typ', 'Enhet', 'Kontor', 'Avläses', 'Inaktiva perioder', 'MätarId'],
      parameters: defaultParameters,
    },
    {
      id: 8,
      name: 'HEATEXCHANGER_EFFICIENCY',
      title: 'Värmeväxlare och effektivitetsgrad',
      description: 'Rapport över värmeväxlare och uppmätt effektivitetsgrad.',
      columnHeaders: [
        'Fastighetsägare',
        'Fastighet',
        'Adress',
        'Mätpunkt',
        'Värmeväxlare',
        'Nummer',
        'Tidpunkt',
        'Typ',
        'Effektivitetsgrad (%)',
        'Gränsvärde (%)',
        'Har avvikelse',
        'Meddelande',
        'Status',
        'Beskrivning',
        'Extra kommentar',
        'DB-ID',
      ],
      parameters: defaultParameters,
    },
    {
      id: 9,
      name: 'ESTATE_AREAS_CONSUMPTION',
      title: 'Årsförbrukningar och fastighetsytor',
      description: 'Rapport över fastigheters årsförbrukningar samt LOA och ATemp beräknat på fastighetsyta.',
      columnHeaders: [
        'Fastighetsägare',
        'Fastighet',
        'Fastighetskategori',
        'Mätartyp',
        'År',
        'Aktuell årsförbrukning',
        'Estimerad årsförbrukning',
        'Enhet',
        `LOA ${M_EXP_2}`,
        'LOA Aktuell',
        'LOA Estimerad',
        'LOA Enhet',
        `ATemp ${M_EXP_2}`,
        'ATemp Aktuell',
        'ATemp Estimerad',
        'ATemp enhet'
      ],
      parameters: defaultParameters,
      segmented: true,
      type: 'estates'
    },
  ]
  return reports
}

async function runReport(reportParameter: CustomReportParameter) {    
  return await saveupClient.post('report', reportParameter, {timeout: 360000}).then(response => {  
    return response?.data
  })
}

async function getPreview(reportParameter: CustomReportParameter) {
  return await saveupClient.post<ReportPreview>('report/preview', reportParameter).then(response => {
    return response?.data
  })
}

const saveReportAsCsv = (report: IReportDefinition, results: any[], startDate: Date, stopDate: Date) => {
  const fileName = generateFileName(report.title, startDate, stopDate)
  const csvContent = jsonToCsv(applyTransformations(results, report.propertyTransforms), report.columnHeaders)
  generateCsvFile(csvContent, fileName)
}

const generateFileName = (title: string, startDate: Date, stopDate: Date) => {
  const datePortion = `${startDate.getFullYear()}-${startDate.getMonth() + 1}_${stopDate.getFullYear()}-${stopDate.getMonth() + 1}`
  const timePortion = new Date().toLocaleString().substring(0, 10)
  return `${title.toLowerCase()} ${datePortion}_${timePortion}.csv`
}

const processReportInBatches = async (objectIds: number[], customReportParameter: CustomReportParameter) => {
  const batchSize = 20
  let results : any[] = []

  for (let i = 0; i < objectIds.length; i += batchSize) {
    const slice = objectIds.slice(i, i + batchSize)
    const valueParameters: CustomReportValueParameter[] = slice.map((id) => ({name: 'estate', value: id.toString()}))
    const parameter: CustomReportParameter = {...customReportParameter, parameters: [...customReportParameter.parameters, ...valueParameters]}      
   
    const sliceResult = await reportService.runReport(parameter)
    results.push(...sliceResult)      
  }
  return results
}

const generateBatchReports = async (report: IReportDefinition, preview: ReportPreview, customReportParameter: CustomReportParameter, startDate: Date, stopDate: Date) => {
  const results = await processReportInBatches(preview.uniqueObjectIds, customReportParameter)
  if (results.length > 0) {
    saveReportAsCsv(report, results, startDate, stopDate)
  }
}


const reportService = {
    getReports,
    runReport,
    getPreview,
    saveReportAsCsv,
    generateBatchReports
}
export default reportService