import {Cancel, RunningWithErrors, Save} from '@mui/icons-material'
import {DateTimePicker} from '@mui/x-date-pickers'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from '@mui/material'
import {ICounterDeviationForm, ICounterValueForm, ICounterValueResetForm, ICounterValuesForm, ICounterValuesInputDialogProps} from '../../../../redux/counterValue/interfaces'
import {hasEditPermissions, hasPermissions} from '../../../../shared/utils/utilities'
import {first, head} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import CounterValueResetDialog from './CounterValueResetDialog'
import {SaveResponse} from '../../../../shared/enums/SaveResponse'
import {useDispatch, useSelector} from 'react-redux'
import {ResetType} from '../../../../shared/enums/ResetType'
import {saveCounterReset, saveCounterValueLog} from '../../../../redux/counterValue/actions'
import CounterValueDeviationDialog from './CreateDeviationDialog'
import AlertDialog from '../../../shared/alerts/AlertDialog'
import {RootState} from '../../../../redux/rootReducer'
import {NODE_WRITE} from '../../../../shared/utils/constants'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const fromAirStyle = {
  backgroundColor: 'rgb(255,188,0,0.2)',
  color: '#333',
}
const offAirStyle = {
  backgroundColor: 'rgba(136,59,0,0.2)',
  color: '#333',
}
const outAirStyle = {
  backgroundColor: 'rgba(0,93,189,0.2)',
  color: '#333',
}

const CounterValuesInputDialog = (props: ICounterValuesInputDialogProps) => {
  const defaultValues = props.counterValueListItem
  ? {
      fromAirValue: first(props.counterValueListItem.subValues.filter((x) => x.subValueType.name === 'FromAir'))?.value ?? 0,
      offAirValue: first(props.counterValueListItem.subValues.filter((x) => x.subValueType.name === 'OffAir'))?.value ?? 0,
      outAirValue: first(props.counterValueListItem.subValues.filter((x) => x.subValueType.name === 'OutAir'))?.value ?? 0,
      date: props.counterValueListItem.date,
    }
  : {
      fromAirValue: 0,
      offAirValue: 0,
      outAirValue: 0,
      date: new Date(),
    }
  const dispatch = useDispatch()
  const {register, control, handleSubmit, reset} = useForm<ICounterValuesForm>({defaultValues: defaultValues})

  const [calculatedValue, setCalculatedValue] = useState('')
  const [fromAir, setFromAir] = useState(defaultValues.fromAirValue)
  const [offAir, setOffAir] = useState(defaultValues.offAirValue)
  const [outAir, setOutAir] = useState(defaultValues.outAirValue)
  const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false)
  const [createDeviationDialogOpen, setCreateDeviationDialogOpen] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)

  let log = useSelector((state: RootState) => (state.counterValue.currentLog ? state.counterValue.currentLog : first(props.counterValueListItem?.counterValueLogs)))
  const createdLog = useSelector((state: RootState) =>
    state.counterValue.createdLog && state.counterValue.createdLog.counterValueId === props.counterValueListItem?.id ? state.counterValue.createdLog : undefined
  )

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [])

  useEffect(() => {
    if (fromAir - outAir === 0) return
    setCalculatedValue((((fromAir - offAir) / (fromAir - outAir)) * 100).toFixed(2))
  }, [fromAir, offAir, outAir])

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    }
    setCalculatedValue(props.counterValueListItem?.value.toString() ?? '')
  }, [props.counterValueListItem])



  const renderTitle = () => {
    return <Typography variant='h3'>{props.counterValueListItem?.id === 0 ? 'Ny avläsning' : (canEdit ? 'Redigera avläsning' : 'Avläsning')}</Typography>
  }

  const renderDeviationDialog = () => {
    if (!props.counterValueListItem) return ''
    return (
      <CounterValueDeviationDialog
        open={createDeviationDialogOpen}
        counterValueId={props.counterValueListItem.id}
        onClose={() => setCreateDeviationDialogOpen(false)}
        onConfirm={(e: any) => onDeviationConfirmClick(e)}
      />
    )
  }

  const onSubmit = (data: ICounterValuesForm) => {    
    props.onConfirm({...data, counterValueId: props.counterValueListItem?.id})
  }

  const onDeviationConfirmClick = (data: ICounterDeviationForm) => {
    if (!canEdit) return
    if (data.counterValueId && data.counterValueId > 0) {
      dispatch(saveCounterValueLog(data.counterValueId, data.text, data.tag))
    }
    setCreateDeviationDialogOpen(false)
  }
  return (
    <div>
      <Grid>
        <Grid container direction='row' alignItems='stretch'>
          <Grid item xs={12}>
            <form>
              <Dialog sx={{style}} open={props.open}>
                <DialogTitle>{renderTitle()}</DialogTitle>
                <DialogContent>
                  <Grid container direction='column' spacing={2}>
                    <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                      <Grid xs={12} item>
                        <Controller
                          render={({field, fieldState: {error}}) => (
                            <TextField
                              id='fromAirValue'
                              label='Från-luft'
                              type='number'
                              style={fromAirStyle}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                              onChange={(e) => {
                                setFromAir(parseFloat(e.target.value))
                                field.onChange(e.target.value)
                              }}
                              fullWidth
                              disabled={!canEdit}
                            />
                          )}
                          name='fromAirValue'
                          control={control}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                      <Grid xs={12} item>
                        <Controller
                          render={({field, fieldState: {error}}) => (
                            <TextField
                              id='offAirValue'
                              label='Av-luft'
                              type='number'
                              style={offAirStyle}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                              onChange={(e) => {
                                setOffAir(parseFloat(e.target.value))
                                field.onChange(e.target.value)
                              }}
                              fullWidth
                              disabled={!canEdit}
                            />
                          )}
                          name='offAirValue'
                          control={control}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                      <Grid xs={12} item>
                        <Controller
                          render={({field, fieldState: {error}}) => (
                            <TextField
                              id='outAirValue'
                              label='Ute-luft'
                              type='number'
                              style={outAirStyle}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                              onChange={(e) => {
                                setOutAir(parseFloat(e.target.value))
                                field.onChange(e.target.value)
                              }}
                              fullWidth
                              disabled={!canEdit}
                            />
                          )}
                          name='outAirValue'
                          control={control}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                      <Grid xs={12} item>
                        <TextField
                          id='value'
                          label='Mätarvärde'
                          type='number'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={calculatedValue}
                          fullWidth
                          disabled={!canEdit}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                      <Grid xs={12} item>
                        <Controller
                          render={({field}) => <DateTimePicker label='Tidpunkt' {...field} renderInput={(params: any) => <TextField {...params} />} inputFormat='yyyy-MM-dd HH:mm:ss' disabled={!canEdit} />}
                          name='date'
                          control={control}
                          defaultValue={new Date()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {canEdit && (<Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={!canEdit} onClick={handleSubmit(onSubmit)}>
                    Spara
                  </Button>)}
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
                    Avbryt
                  </Button>
                  {log || (createdLog && createdLog.counterValueId === props.counterValueListItem?.id) || !canEdit ? (
                    ''
                  ) : (
                    <Button
                      sx={{mt: {xs: 2, md: 0}}}
                      variant='contained'
                      color='primary'
                      startIcon={<RunningWithErrors fontSize='small' />}
                      disabled={!canEdit}
                      onClick={() => setCreateDeviationDialogOpen(true)}>
                      Skapa avvikelse
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch'>
        <Grid item xs={12}>
          {renderDeviationDialog()}
        </Grid>
      </Grid>
    </div>
  )
}
export default CounterValuesInputDialog
