import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {createOwner} from '../../redux/owner/ownerActions'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import {ArrowBack, Save} from '@mui/icons-material'
import {CardHeader, Container, Grid} from '@mui/material'
import {Link, useNavigate} from 'react-router-dom'
import {RootState} from '../../redux/rootReducer'

const CreateOwner = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [comment, setComment] = useState('')
  const navigate = useNavigate()
  const selectedOwner = useSelector((state: RootState) => state.owner.selectedOwner)
  const onSaveClick = () => {
    dispatch(createOwner(name, comment))
    if(selectedOwner)
      navigate(`/owner`)
  }

  return (
    <Container maxWidth='lg'>
    <Card sx={{minWidth: 275}}>
      <CardHeader title='Skapa ny fastighetsägare' />
      <CardContent>
        <Grid>
          <Grid container spacing={2} direction='row'>
            <Grid item xs={6}>
              <Typography variant='body1'>Namn:</Typography>
              <TextField required id='outlined-required' value={name} onChange={(e) => setName(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>Kommentar:</Typography>
              <TextField required id='outlined-required' value={comment} onChange={(e) => setComment(e.target.value)} fullWidth />
            </Grid>
          </Grid>          
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant='contained' onClick={() => onSaveClick()} color='success'>
          <Save />
          &nbsp;Spara
        </Button>
        <Link to='/owner'>
          <Button variant='contained' style={{marginLeft: '10px'}}>
            <ArrowBack />
            &nbsp;Tillbaka
          </Button>
        </Link>
      </CardActions>
    </Card>
    </Container>
  )
}
export default CreateOwner
