import {Reducer} from 'redux'
import {OfficeState} from './interfaces'
import {ActionTypes} from './types'

const initialState: OfficeState = {
  isLoading: false,
  errorMessage: '',
  offices: {
    offices: [],
    isLoading: false,
    errorMessage: '',
  },
  office: {
    office: undefined,
    isLoading: false,
    errorMessage: '',
  },
  createOffice: {
    office: undefined,
    isLoading: false,
    errorMessage: '',
  },
  updateOffice: {
    office: undefined,
    isLoading: false,
    errorMessage: '',
  },
  deleteOffice: {
    id: 0,
    isLoading: false,
    errorMessage: '',
  },
  officeMeterCosts: {
    officeMeterCosts: [],
    isLoading: false,
    errorMessage: '',
  },
  officeMeterCost: {
    officeMeterCost: undefined,
    isLoading: false,
    errorMessage: '',
  },
  createOfficeMeterCost: {
    officeMeterCost: undefined,
    isLoading: false,
    errorMessage: '',
  },
  updateOfficeMeterCost: {
    officeMeterCost: undefined,
    isLoading: false,
    errorMessage: '',
  },
  deleteOfficeMeterCost: {
    id: 0,
    isLoading: false,
    errorMessage: '',
  },
  officeMeterEmissions: {
    officeMeterEmissions: [],
    isLoading: false,
    errorMessage: '',
  },
  officeMeterEmission: {
    officeMeterEmission: undefined,
    isLoading: false,
    errorMessage: '',
  },
  createOfficeMeterEmission: {
    officeMeterEmission: undefined,
    isLoading: false,
    errorMessage: '',
  },
  updateOfficeMeterEmission: {
    officeMeterEmission: undefined,
    isLoading: false,
    errorMessage: '',
  },
  deleteOfficeMeterEmission: {
    id: 0,
    isLoading: false,
    errorMessage: '',
  },
}
export const officeReducer: Reducer<OfficeState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.GET_OFFICES_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_OFFICES_SUCCESS:
      return {...state, isLoading: false, offices: {offices: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_OFFICES_FAILED:
      return {...state, isLoading: false, offices: {offices: [], isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_OFFICE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_OFFICE_SUCCESS:
      return {...state, isLoading: false, office: {office: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_OFFICE_FAILED:
      return {...state, isLoading: false, office: {office: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.CREATE_OFFICE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.CREATE_OFFICE_SUCCESS:
      return {...state, isLoading: false, createOffice: {office: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.CREATE_OFFICE_FAILED:
      return {...state, isLoading: false, createOffice: {office: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.UPDATE_OFFICE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.UPDATE_OFFICE_SUCCESS:
      return {...state, isLoading: false, updateOffice: {office: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.UPDATE_OFFICE_FAILED:
      return {...state, isLoading: false, updateOffice: {office: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.DELETE_OFFICE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.DELETE_OFFICE_SUCCESS:
      return {...state, isLoading: false, deleteOffice: {id: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.DELETE_OFFICE_FAILED:
      return {...state, isLoading: false, deleteOffice: {id: 0, isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_OFFICE_METER_COSTS_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_OFFICE_METER_COSTS_SUCCESS:
      return {...state, isLoading: false, officeMeterCosts: {officeMeterCosts: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_OFFICE_METER_COSTS_FAILED:
      return {...state, isLoading: false, officeMeterCosts: {officeMeterCosts: [], isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_OFFICE_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_OFFICE_METER_COST_SUCCESS:
      return {...state, isLoading: false, officeMeterCost: {officeMeterCost: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_OFFICE_METER_COST_FAILED:
      return {...state, isLoading: false, officeMeterCost: {officeMeterCost: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.CREATE_OFFICE_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.CREATE_OFFICE_METER_COST_SUCCESS:
      return {...state, isLoading: false, createOfficeMeterCost: {officeMeterCost: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.CREATE_OFFICE_METER_COST_FAILED:
      return {...state, isLoading: false, createOfficeMeterCost: {officeMeterCost: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.UPDATE_OFFICE_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.UPDATE_OFFICE_METER_COST_SUCCESS:
      return {...state, isLoading: false, updateOfficeMeterCost: {officeMeterCost: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.UPDATE_OFFICE_METER_COST_FAILED:
      return {...state, isLoading: false, updateOfficeMeterCost: {officeMeterCost: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.DELETE_OFFICE_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.DELETE_OFFICE_METER_COST_SUCCESS:
      return {...state, isLoading: false, deleteOfficeMeterCost: {id: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.DELETE_OFFICE_METER_COST_FAILED:
      return {...state, isLoading: false, deleteOfficeMeterCost: {id: 0, isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_OFFICE_METER_EMISSIONS_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_OFFICE_METER_EMISSIONS_SUCCESS:
      return {...state, isLoading: false, officeMeterEmissions: {officeMeterEmissions: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_OFFICE_METER_EMISSIONS_FAILED:
      return {...state, isLoading: false, officeMeterEmissions: {officeMeterEmissions: [], isLoading: false, errorMessage: payload}}
    case ActionTypes.CREATE_OFFICE_METER_EMISSION_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.CREATE_OFFICE_METER_EMISSION_SUCCESS:
      return {...state, isLoading: false, createOfficeMeterEmission: {officeMeterEmission: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.CREATE_OFFICE_METER_EMISSION_FAILED:
      return {...state, isLoading: false, createOfficeMeterEmission: {officeMeterEmission: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.UPDATE_OFFICE_METER_EMISSION_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.UPDATE_OFFICE_METER_EMISSION_SUCCESS:
      return {...state, isLoading: false, updateOfficeMeterEmission: {officeMeterEmission: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.UPDATE_OFFICE_METER_EMISSION_FAILED:
      return {...state, isLoading: false, updateOfficeMeterEmission: {officeMeterEmission: undefined, isLoading: false, errorMessage: payload}}
    case ActionTypes.DELETE_OFFICE_METER_EMISSION_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.DELETE_OFFICE_METER_EMISSION_SUCCESS:
      return {...state, isLoading: false, deleteOfficeMeterEmission: {id: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.DELETE_OFFICE_METER_EMISSION_FAILED:
      return {...state, isLoading: false, deleteOfficeMeterEmission: {id: 0, isLoading: false, errorMessage: payload}}
    default:
      return state
  }
}
