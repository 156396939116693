import { ActionTypes } from "./types";
import { Dispatch } from "redux";
import reportService from "../../services/reportService";
import { CustomReportParameter } from "./interfaces";

export const getAllReports = () => async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.GET_REPORTS_LOADING })
    try {
        const reports = await reportService.getReports()
        return dispatch({ type: ActionTypes.GET_REPORTS_SUCCESS, payload: reports})
    }
    catch(error) {
        return dispatch( { type: ActionTypes.GET_REPORTS_FAILED, payload: error})
    }
}
export const runReport = (reportParameter: CustomReportParameter) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.RUN_REPORT_LOADING })
    try {
        const report = await reportService.runReport(reportParameter)
        return dispatch({ type: ActionTypes.RUN_REPORT_SUCCESS, payload: report})
    }
    catch(error) {
        return dispatch( { type: ActionTypes.RUN_REPORT_FAILED, payload: error})
    }
}
export const getPreview = (reportParameter: CustomReportParameter) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionTypes.GET_REPORT_PREVIEW_LOADING })
    try {
        const report = await reportService.getPreview(reportParameter)
        return dispatch({ type: ActionTypes.GET_REPORT_PREVIEW_SUCCESS, payload: report})
    }
    catch(error) {
        return dispatch( { type: ActionTypes.GET_REPORT_PREVIEW_FAILED, payload: error})
    }
}