import {Reducer} from 'redux'
import {IExternalSystemState} from './interfaces'
import {ActionTypes} from './types'

const initialExternalSystemState: IExternalSystemState = {
  isLoading: false,
  errorMessage: undefined,
  externalSystems: [],
}
export const externalSystemReducer: Reducer<IExternalSystemState> = (state = initialExternalSystemState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_EXTERNAL_SYSTEMS_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.FETCH_EXTERNAL_SYSTEMS_SUCCESS: {
      return {...state, isLoading: false, externalSystems: payload, systems: payload}
    }
    case ActionTypes.FETCH_EXTERNAL_SYSTEMS_ERROR: {
      return {...state, isLoading: false, errorMessage: payload}
    }
    default:
      return state
  }
}
