import React, {useEffect, useState} from 'react'
import {CreateUserState, EditUserForm, IUser, UserByIdState, UserListItem, UserRole, UserRoleState} from '../../../redux/user/interfaces'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'
import {UserDetailsContainerProps} from '../interfaces'
import {useNavigate, useParams} from 'react-router'
import {getUserById, getUserRoles, createUser} from '../../../redux/user/actions'
import {IOffice} from '../../../redux/estateAttribute/interfaces'
import CreateUser from './CreateUser'
import {FormProvider, SubmitHandler, useForm, useFormContext} from 'react-hook-form'
import {IOwner} from '../../../redux/owner/ownerInterfaces'
import {getOwners} from '../../../redux/owner/ownerActions'
import {ControlledDropDownItem} from '../../../components/shared/Inputs/interfaces'

const defaultUserListItem: UserListItem = {
  id: 0,
  fullName: '',
  mail: '',
  userRoleId: 0,
  role: '',
  insDate: new Date(),
  updDate: new Date(),
  isDisabled: false,
  office: '',
  owners: [],
  canEdit: false,
  canReadDeviations: false,
}
const CreateUserContainer = () => {
  const dispatch = useDispatch()
  const methods = useForm<EditUserForm>({
    defaultValues: {
      id: 0,
      fullName: '',
      mail: '',
      userRoleId: undefined,
      isDisabled: false,
      office: '',
      password: '',
      owners: [],
      canEdit: false,
      canReadDeviations: false,
    },
  })
  const {handleSubmit} = useForm<EditUserForm>()
  const {setValue, reset} = methods
  const navigate = useNavigate()

  const offices: IOffice[] = useSelector((state: RootState) => state.filter.offices)
  const userRoleState: UserRoleState = useSelector((state: RootState) => state.user.userRoles)
  const [userListItem, setUserListItem] = useState<UserListItem>(defaultUserListItem)
  const owners: IOwner[] = useSelector((state: RootState) => state.owner.owners)
  const createUserState: CreateUserState = useSelector((state: RootState) => state.user.createUser)

  useEffect(() => {
    dispatch(getUserRoles())
    dispatch(getOwners())
  }, [])

  const onSaveCounter: SubmitHandler<EditUserForm> = (data: EditUserForm) => {
    dispatch(
      createUser(
        data.mail,
        data.fullName,
        data.password,
        data.userRoleId,
        data.office,
        data.owners.map((x) => parseInt(x)),
        data.canEdit,
        data.canReadDeviations
      )
    )
    navigate('/user')
  }

  return userListItem && userRoleState.userRoles ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSaveCounter)}>
        <CreateUser
          roles={userRoleState.userRoles.sort((x: UserRole, y: UserRole) => x.name.localeCompare(y.name))}
          offices={offices}
          owners={owners.sort((x: IOwner, y: IOwner) => x.name.localeCompare(y.name))}
        />
      </form>
    </FormProvider>
  ) : (
    <div>Loading...</div>
  )
}
export default CreateUserContainer
