import { IExternalSystem } from '../redux/externalSystem/interfaces'
import saveupClient, { handleResponse } from '../clients/saveupClient'
async function getExternalSystems() {
  const externalSystems = await saveupClient
  .get<IExternalSystem>(`external/system`)
  .then(handleResponse)
  return externalSystems
}
const externalSystemService = {
    getExternalSystems
}
export default externalSystemService