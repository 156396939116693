import {IConsumption, IConsumptionGraphPlainProps, IConsumptionYearData, IDeviationDataSet} from '../../../redux/consumption/interfaces'
import {ATEMP, CONSUMPTION, COST, EMISSION, LOA, OWNER, READING} from '../../../shared/utils/constants'
import React, {useEffect} from 'react'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import {getUnitById} from '../../../redux/unit/unitActions'
import {RootState} from '../../../redux/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import {getAnnotations, getDesc, getUnit} from './consumptionGraphHelper'
import annotationPlugin from 'chartjs-plugin-annotation';
import { getCostString, getEmissionString } from './costEmissionHelper'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, annotationPlugin)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Förbrukning',
    },
  },
}

interface IAxesLabels {
  [index: string]: string
}
const axesLabels = {
  'CONSUMPTION': 'Förbrukning',
  'READING': 'Avläsning',
  'COST': 'Kostnad',
  'EMISSION': 'Utsläpp',
  'ATEMP': 'ATemp',
  'LOA': 'LOA',
} as IAxesLabels

const ConsumptionGraphPlain = (props: IConsumptionGraphPlainProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (selectedUnit === undefined || selectedUnit.id !== props.meterType.unitId) {
      dispatch(getUnitById(props.meterType.unitId))
    }
  }, [props.meterType.id])
  const selectedUnit = useSelector((state: RootState) => state.unit.selectedUnit)
  const showVolume = useSelector((state: RootState) => state.configuration.showVolume)
  let mainDataSet: IConsumptionYearData[] = [
    {
      label: 'Förbrukning',
      data: props.data.map((x) => {
        if (!props.category || props.category === CONSUMPTION) return x.value
        else if (props.category === COST) return x.cost
        else if (props.category === EMISSION) return x.emissionValue
        else if (props.category === ATEMP) return showVolume ? x.aTemp : x.aTempMultiplied
        else if (props.category === LOA) return showVolume ? x.loa : x.loaMultiplied
        return 0
      }),
      backgroundColor: 'rgba(195, 0, 9, 0.8)',
      fill: props.meterType.isConsumption,
    },
  ]
  let labels: string[] = []
  props.data.forEach((d) => {
    if (d.year && d.month) {
      labels.push(d.year + '-' + d.month)
    }
  })

  let deviationDatasets: IDeviationDataSet[] = []
  let vvTempDevitiationMax: number[] = []
  let vvTempDevitiationMin: number[] = []
  let heaterDeviationMin = []
  if (props.meterType.name.toLowerCase() === 'värmeväxlare') {
    if (props.deviations) {
      heaterDeviationMin.push(props.deviations['Effektivitet'])
    }
    let deviationDataset: IDeviationDataSet = {
      label: 'Min',
      data: heaterDeviationMin,
      borderColor: 'rgba(195, 0, 9, 0.2)',
      backgroundColor: 'rgba(195, 0, 9, 0.2)',
      fill: props.meterType.isConsumption === true ? true : false,
      type: 'line',
      pointRadius: 0,
    }
    //mainDataSet.push(deviationDataset)
  } else if (props.meterType.name.toLowerCase() === 'temp, vv') {
    if (props.deviations) {
      vvTempDevitiationMax.push(props.deviations['MAX_TEMP'])
      vvTempDevitiationMin.push(props.deviations['MIN_TEMP'])
    }
    deviationDatasets = [
      {
        label: 'Max',
        data: vvTempDevitiationMax,
        borderColor: 'rgba(195, 0, 9, 0.2)',
        backgroundColor: 'rgba(195, 0, 9, 0.2)',
        fill: props.meterType.isConsumption,
        type: 'line',
        pointRadius: 0,
      },
      {
        label: 'Min',
        data: vvTempDevitiationMin,
        borderColor: 'rgba(195, 0, 9, 0.2)',
        backgroundColor: 'rgba(195, 0, 9, 0.2)',
        fill: props.meterType.isConsumption,
        type: 'line',
        pointRadius: 0,
      },
    ]
    //mainDataSet.push(...deviationDatasets)
  }
  const chartData = {
    datasets: mainDataSet,
    labels: labels,
  }

  const renderCostEmissionInfo = (context: any) => {
    if(props.costEmissionData === undefined || props.data?.length === 0 || !props.category) return ''
    if(props.data[0].type === OWNER) return ''
    const currentContext = context[0]
    const year = parseInt(currentContext.label.split("-")[0])    
    if(props.category === COST) return getCostString(props.costEmissionData, props.meterType.id, year, currentContext.dataIndex)
    else if(props.category === EMISSION) return getEmissionString(props.costEmissionData, props.meterType.id, year, currentContext.dataIndex)
    return ''
  }

  return (
    <Bar
      data={chartData}
      options={{
        animation: {duration: 0},
        scales: {
          y: {title: {display: true, text: `${getDesc(props.category, props.meterType)} (${getUnit(props.category, props.meterType, props.unit, props.currency, props.emission)})`}},
          x: {title: {display: true, text: 'Period'}},
        },
        plugins: {
          annotation: {
            annotations: getAnnotations(props.deviations, true),
          },
          tooltip: {
            callbacks: {
              footer: renderCostEmissionInfo,              
            }
          },
        }
      }}
    />
  )
}
export default ConsumptionGraphPlain
