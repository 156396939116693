import React, {useEffect, useState} from 'react'
import Offices from './Offices'
import {useDispatch, useSelector} from 'react-redux'
import {IOffice, Office} from '../../redux/estateAttribute/interfaces'
import {RootState} from '../../redux/rootReducer'
import {deleteOffice, getOffices} from '../../redux/office/actions'

const OfficeContainer = () => {
  const dispatch = useDispatch()

  const offices: Office[] = useSelector((state: RootState) => state.office.offices.offices)
  const createdOffice: Office | undefined = useSelector((state: RootState) => state.office.createOffice.office)
  const updatedOffice: Office | undefined = useSelector((state: RootState) => state.office.updateOffice.office)
  const deletedOfficeId: number = useSelector((state: RootState) => state.office.deleteOffice.id)
  const [officeListItems, setOfficeListItems] = useState<Office[]>([])

  useEffect(() => {
    dispatch(getOffices())
  }, [])

  useEffect(() => {
    setOfficeListItems(offices)
  }, [offices])

  useEffect(() => {
    if (createdOffice && !officeListItems.some((x: Office) => x.id !== createdOffice.id)) {
      setOfficeListItems((prev: Office[]) => [...prev, createdOffice])
    }
  }, [createdOffice])

  useEffect(() => {
    const office = officeListItems.find((x: Office) => x.id === updatedOffice?.id)
    if (office) setOfficeListItems((prev: Office[]) => [...prev.filter((x: Office) => x.id !== office.id), office])
  }, [updatedOffice])

  useEffect(() => {
    setOfficeListItems((prev: Office[]) => officeListItems.filter((x: Office) => x.id !== deletedOfficeId))
  }, [deletedOfficeId])

  const onDeleteOffice = (id: number) => {
    dispatch(deleteOffice(id))
  }

  return <Offices offices={officeListItems} deleteOffice={onDeleteOffice} />
}
export default OfficeContainer
