import { DataGrid } from '@mui/x-data-grid';
import { ICounter } from '../../redux/counter/interfaces';
import React from 'react'
import { MeasurePointCountersProps } from './interfaces';
const MeasurePointCounters : React.FC<MeasurePointCountersProps> = (props: MeasurePointCountersProps) => {    
    const columns = [
        { field: "id", headerName: "Id", flex: 1 },
        { field: "name", headerName: "Namn", flex: 1 },        
      ];
    const renderGrid = () => {
        if(props.counters === undefined || props.counters.length === 0)
        return <div>Inga mätare kunde hittas för vald mätpunkt!</div>
    
        return (
        <DataGrid style={{ height: 800, width: "100%" }}
          rows={props.counters}
          columns={columns}
          pageSize={13}
          rowsPerPageOptions={[13]}          
          />
        )
        }
    return (
        <div>
             {renderGrid()}
        </div>
    )
}
export default MeasurePointCounters