import {GridInitialState} from '@mui/x-data-grid'
import {Reducer} from 'redux'
import {IReportState} from './interfaces'
import {ActionTypes} from './types'
const initialState: IReportState = {
  isLoading: false,
  errorMessage: '',
  reportList: {
    isLoading: false,
    errorMessage: '',
    reports: [],
  },
  reportResult: {
    isLoading: false,
    errorMessage: '',
    data: undefined
  },
  preview: {
    isLoading: false,
    errorMessage: '',
    preview: undefined
  }
}
const reportReducer: Reducer<IReportState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.GET_REPORTS_LOADING:
      return {...state, isLoading: true, reportList: {...state.reportList, isLoading: true}}
    case ActionTypes.GET_REPORTS_SUCCESS:
      return {...state, isLoading: false, reportList: {...state.reportList, reports: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_REPORTS_FAILED:
      return {...state, isLoading: true, reportList: {...state.reportList, isLoading: false, errorMessage: payload}}
    case ActionTypes.RUN_REPORT_LOADING:
      return {...state, isLoading: true, reportResult: {...state.reportResult, isLoading: true}}
    case ActionTypes.RUN_REPORT_SUCCESS:
      return {...state, isLoading: false, reportResult: {...state.reportResult, data: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.RUN_REPORT_FAILED:
      return {...state, isLoading: true, reportResult: {...state.reportResult, isLoading: false, errorMessage: payload}}
    case ActionTypes.GET_REPORT_PREVIEW_LOADING:
      return {...state, isLoading: true, preview: {...state.preview, isLoading: true}}
    case ActionTypes.GET_REPORT_PREVIEW_SUCCESS:
      return {...state, isLoading: false, preview: {...state.preview, preview: payload, isLoading: false, errorMessage: ''}}
    case ActionTypes.GET_REPORT_PREVIEW_FAILED:
      return {...state, isLoading: true, preview: {...state.preview, isLoading: false, errorMessage: payload}}
    default:
      return {...state}
  }
}
export default reportReducer
