import { Cancel, Save } from '@mui/icons-material'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material'
import React from 'react'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
export interface IAlertDialogProps {
  open: boolean
  title: string
  message: string
  confirmLabel: string
  cancelLabel: string
  onConfirm: () => void
  onCancel: () => void
}
const AlertDialog = (props: IAlertDialogProps) => {
  return (
    <Dialog sx={{style}} open={props.open}>
      <DialogContent>
        <Grid>
          <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
            <Grid item xs={12}>
                {props.message}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} onClick={() => props.onConfirm()}>
            {props.confirmLabel}
          </Button>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onCancel}>
            {props.cancelLabel}
          </Button>
        </DialogActions>
    </Dialog>
  )
}
export default AlertDialog