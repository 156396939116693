import addressService from '../../services/addressService'
import { ActionTypes } from './addressTypes'
import { ActionTypes as TreeActionTypes } from '../tree/treeTypes'
import {Dispatch} from 'redux'
import measurePointService from '../../services/measurepointService'
import { castUnit, processConsumptions, setMeterTypeOrder } from '../../shared/utils/utilities'
import configurationService from '../../services/configurationService'
import { IMeterType } from '../meter/interfaces'
import { IUnit } from '../unit/unitInterfaces'

export const getaddressbyid = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.GET_ADDRESS_LOADING})
      const data: any = await addressService.getAddress(id)
      return dispatch({type: ActionTypes.GET_ADDRESS_OK, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.GET_ADDRESS_ERROR, payload: error})
    }
  }
}

export const createAddress = (name: string, estateId: number, street: string, postalCode: string, city: string, number: string, comment: string, hidden: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.CREATE_ADDRESS_LOADING})
    const data = await addressService.createAddress(name, estateId, street, postalCode, city, number, comment, hidden)
    dispatch({ type: TreeActionTypes.SET_TREE_MODIFIED })
    return dispatch({type: ActionTypes.CREATE_ADDRESS_OK, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_ADDRESS_ERROR, payload: error})
  }
}

export const updateAddress =
  (id: number, name: string, estateId: number, street: string, postalCode: string, city: string, number: string, comment: string, hidden: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.UPDATE_ADDRESS_LOADING})
      const data = await addressService.updateAddress(id, name, estateId, street, postalCode, city, number, comment, hidden)
      dispatch({ type: TreeActionTypes.SET_TREE_MODIFIED })
      return dispatch({type: ActionTypes.UPDATE_ADDRESS_OK, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.UPDATE_ADDRESS_ERROR, payload: error})
    }
  }

export const deleteAddress = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.DELETE_ADDRESS_LOADING})
    const data = await addressService.deleteAddress(id)
    dispatch({ type: TreeActionTypes.SET_TREE_MODIFIED })
    return dispatch({type: ActionTypes.DELETE_ADDRESS_OK, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_ADDRESS_ERROR, payload: error})
  }
}

export const getMeterTypesAtAddress = (addressId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ADDRESS_METER_TYPES_LOADING})
  try {
    let meterTypes = await addressService.getMeterTypesAtAddress(addressId)
    const degreeDayCorrectedMeterTypeIds = await configurationService.getDegreeDayCorrectedMeterTypes()
    meterTypes = meterTypes.map((type: IMeterType) => ({...type, canDegreeDayAdjust: degreeDayCorrectedMeterTypeIds.some((x: number) => x === type.id)}))
    const sorted = setMeterTypeOrder(meterTypes)
    return dispatch({type: ActionTypes.FETCH_ADDRESS_METER_TYPES_SUCCESS, payload: sorted})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ADDRESS_METER_TYPES_ERROR, payload: error})
  }
}
export const getReadingTableLogs = (addressId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ADDRESS_READING_LOGS_LOADING})
  try {
    const data = await addressService.getReadingTableLogs(addressId, meterTypeId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_ADDRESS_READING_LOGS_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ADDRESS_READING_LOGS_FAILED, payload: error})
  }
}
export const getConsumptionTableLogs = (addressId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOGS_LOADING})
  try {
    const data = await addressService.getConsumptionTableLogs(addressId, meterTypeId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOGS_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOGS_FAILED, payload: error})
  }
}
export const getConsumption = (addressId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOADING})
  try {
    const consumptions = await addressService.getConsumption(addressId, meterTypeId, startDate, stopDate, useDegreeDayCorrection)
    return dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_SUCCESS, payload: consumptions})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_ERROR, payload: error})
  }
}
export const getNodeConsumption = (addressId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, shouldEstimate?: boolean, unit?: IUnit, category?: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_LOADING})
  try {
    const consumptions = shouldEstimate
      ? await addressService.getNodeConsumptionAndEstimate(addressId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
      : await addressService.getNodeConsumption(addressId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
    return dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_SUCCESS, payload: processConsumptions(consumptions, unit, category)})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ADDRESS_CONSUMPTION_ERROR, payload: error})
  }
}

export const getMeasurePointByAddressId = (addressId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_MEASUREPOINTS_BY_ADDRESS_ID_LOADING})
      const data = await measurePointService.getMeasurePointByAddressId(addressId)
      return dispatch({type: ActionTypes.FETCH_MEASUREPOINTS_BY_ADDRESS_ID_SUCCESS, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_MEASUREPOINTS_BY_ADDRESS_ID_FAILED, payload: error})
    }
  }
}

