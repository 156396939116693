export enum ActionTypes {
  GET_TREE_LOADING = 'GET_TREE_LOADING',
  GET_TREE_SUCCESS = 'GET_TREE_SUCCESS',
  GET_TREE_FAILED = 'GET_TREE_FAILED',
  SET_SELECTED_METER_TYPE = "SET_SELECTED_METER_TYPE",
  SET_SELECTED_OFFICE = "SET_SELECTED_OFFICE",
  SET_FILTERED_NODES = "SET_FILTERED_NODES",
  SET_KEYWORD = "SET_KEYWORD",
  SET_TREE_MODIFIED = "SET_TREE_MODIFIED",
  FETCH_PATH_LOADING = 'FETCH_PATH_LOADING',
  FETCH_PATH_SUCCESS = 'FETCH_PATH_SUCCESS',
  FETCH_PATH_FAILED = 'FETCH_PATH_FAILED',
  SET_EXPANDED_NODES = 'SET_EXPANDED_NODES'
}
