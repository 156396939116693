import { Action } from '@remix-run/router'
import React from 'react'
import {Reducer} from 'redux'
import {ICounterState, ICounterValueState} from '../counter/interfaces'
import {ActionTypes} from './types'
const initialCounterValueState: ICounterValueState = {
  createdReset: undefined,
  currentReset: undefined,
  createdLog: undefined,
  currentLog: undefined,
  isLoading: false,
  errorMessage: '',
}
export const counterValueReducer: Reducer<ICounterValueState> = (state = initialCounterValueState, {type, payload}) => {
  switch (type) {
    case ActionTypes.SAVE_COUNTER_RESET_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.SAVE_COUNTER_VALUE_SUCCESS: {
      return {...state, isLoading: false, createdReset: payload}
    }
    case ActionTypes.SAVE_COUNTER_RESET_LOADING: {
      return {...state, isLoading: false, errorMessage: payload.message}
    }
    case ActionTypes.SAVE_COUNTER_VALUE_LOG_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.SAVE_COUNTER_VALUE_LOG_SUCCESS: {
      return {...state, isLoading: false, createdLog: payload}
    }
    case ActionTypes.SAVE_COUNTER_VALUE_LOG_LOADING: {
      return {...state, isLoading: false, errorMessage: payload.message}
    }
    case ActionTypes.GET_COUNTER_VALUE_LOG_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.GET_COUNTER_VALUE_LOG_SUCCESS: {
      return {...state, isLoading: false, currentLog: payload}
    }
    case ActionTypes.GET_COUNTER_VALUE_LOG_LOADING: {
      return {...state, isLoading: false, errorMessage: payload.message}
    }
    case ActionTypes.GET_COUNTER_RESET_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.GET_COUNTER_RESET_SUCCESS: {
      return {...state, isLoading: false, currentReset: payload}
    }
    case ActionTypes.GET_COUNTER_RESET_LOADING: {
      return {...state, isLoading: false, errorMessage: payload.message}
    }
    case ActionTypes.SAVE_COUNTER_RESET_LOADING: {
      return {...state, isLoading: true}
    }
    case ActionTypes.SAVE_COUNTER_RESET_SUCCESS: {
      return { ...state, isLoading: false, currentReset: payload }
    }
    case ActionTypes.SAVE_COUNTER_RESET_FAILED: {
      return {...state, isLoading: false, errorMessage: payload.message}
    }
    default: {
      return state
    }
  }
}
