import {Reducer} from 'redux'
import {IEmissionState} from './interfaces'
import {ActionTypes} from './types'

export const initialState: IEmissionState = {
  isLoading: false,
  emissions: [],
  errorMessage: undefined,
  selectedEmission: undefined,
}

export const emissionReducer: Reducer<IEmissionState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_EMISSIONS_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_EMISSIONS_SUCCESS:
      return {...state, isLoading: false, emissions: payload}
    case ActionTypes.FETCH_EMISSIONS_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.FETCH_EMISSION_BY_NAME_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.FETCH_EMISSION_BY_NAME_SUCCESS:
      return {...state, isLoading: false, selectedEmission: payload}
    case ActionTypes.FETCH_EMISSION_BY_NAME_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    default:
      return state
  }
}
