export enum ActionTypes {
    GET_ADDRESS_LOADING = "GET_ADDRESS_LOADING",
    GET_ADDRESS_OK = "GET_ADDRESS_OK",
    GET_ADDRESS_ERROR = "GET_ADDRESS_ERROR",
    CREATE_ADDRESS_LOADING = "CREATE_ADDRESS_LOADING",
    CREATE_ADDRESS_OK = "CREATE_ADDRESS_OK",
    CREATE_ADDRESS_ERROR = "CREATE_ADDRESS_ERROR",
    UPDATE_ADDRESS_LOADING = "UPDATE_ADDRESS_LOADING",
    UPDATE_ADDRESS_OK = "UPDATE_ADDRESS_OK",
    UPDATE_ADDRESS_ERROR = "UPDATE_ADDRESS_ERROR",
    DELETE_ADDRESS_LOADING = "DELETE_ADDRESS_LOADING",
    DELETE_ADDRESS_OK = "DELETE_ADDRESS_OK",
    DELETE_ADDRESS_ERROR = "DELETE_ADDRESS_ERROR",
    EDIT_ADDRESS_LOADING = "EDIT_ADDRESS_LOADING",
    EDIT_ADDRESS_OK = "EDIT_ADDRESS_OK",
    EDIT_ADDRESS_ERROR = "EDIT_ADDRESS_ERROR",
    FETCH_ADDRESS_CONSUMPTION_SUCCESS = "FETCH_ADDRESS_CONSUMPTION_SUCCESS",
    FETCH_ADDRESS_CONSUMPTION_ERROR = "FETCH_ADDRESS_CONSUMPTION_ERROR",
    FETCH_ADDRESS_CONSUMPTION_LOADING = "FETCH_ADDRESS_CONSUMPTION_LOADING",
    FETCH_ADDRESS_METER_TYPES_SUCCESS = "FETCH_ADDRESS_METER_TYPES_SUCCESS",
    FETCH_ADDRESS_METER_TYPES_ERROR = "FETCH_ADDRESS_METER_TYPES_ERROR",
    FETCH_ADDRESS_METER_TYPES_LOADING = "FETCH_ADDRESS_METER_TYPES_LOADING",
    FETCH_ADDRESS_READING_LOGS_LOADING = "FETCH_ADDRESS_READING_LOGS_LOADING",
    FETCH_ADDRESS_READING_LOGS_SUCCESS = "FETCH_ADDRESS_READING_LOGS_SUCCESS",
    FETCH_ADDRESS_READING_LOGS_FAILED = "FETCH_ADDRESS_READING_LOGS_FAILED",
    FETCH_ADDRESS_CONSUMPTION_LOGS_LOADING = "FETCH_ADDRESS_CONSUMPTION_LOGS_LOADING",
    FETCH_ADDRESS_CONSUMPTION_LOGS_SUCCESS = "FETCH_ADDRESS_CONSUMPTION_LOGS_SUCCESS",
    FETCH_ADDRESS_CONSUMPTION_LOGS_FAILED = "FETCH_ADDRESS_CONSUMPTION_LOGS_FAILED",
    FETCH_MEASUREPOINTS_BY_ADDRESS_ID_LOADING = "FETCH_MEASUREPOINTS_BY_ADDRESS_ID_LOADING",
    FETCH_MEASUREPOINTS_BY_ADDRESS_ID_SUCCESS = "FETCH_MEASUREPOINTS_BY_ADDRESS_ID_SUCCESS",
    FETCH_MEASUREPOINTS_BY_ADDRESS_ID_FAILED = "FETCH_MEASUREPOINTS_BY_ADDRESS_ID_FAILED",
  }

