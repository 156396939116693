import React from 'react'
import {IConsumptionWithLog} from '../../../redux/counterValue/interfaces'
import {CONSUMPTION, COST, EMISSION, ATEMP, LOA, MWH, CUBIC_METERS_SWE, WATER_SWE} from '../../../shared/utils/constants'
import {IConsumptionTableValueProps} from './interfaces'
import {RootState} from '../../../redux/rootReducer'
import {useSelector} from 'react-redux'
const ConsumptionTableValue = (props: IConsumptionTableValueProps) => {
  const showVolume = useSelector((state: RootState) => state.configuration.showVolume)
  const selectedUnit = useSelector((state: RootState) => state.unit.selectedUnit)

  const print = (actual: number, estimate: number) => {
    if (props.shouldEstimate && actual === 0 && estimate !== undefined && estimate !== 0)
      return (
        <div>
          {actual} <span style={{'fontStyle': 'italic'}}>[{estimate}]</span>
        </div>
      )
    else return actual
  }

  const renderValue = () => {
    const unitToUse = props.unit !== undefined ? props.unit : selectedUnit

    if (!props.category || props.category === CONSUMPTION) return print(props.value.value, props.value.estimatedValue)
    else if (props.category === COST) return print(props.value.cost, props.value.estimatedCost)
    else if (props.category === EMISSION) return print(props.value.emissionValue, props.value.estimatedEmissionValue)
    else if (props.category === ATEMP)
      return (!showVolume && props.meterType.name === WATER_SWE) || unitToUse?.comment === MWH ? print(props.value.aTempMultiplied, props.value.estimatedATempMultiplied) : print(props.value.aTemp, props.value.estimatedATemp)
    else if (props.category === LOA)
      return (!showVolume && props.meterType.name === WATER_SWE) || unitToUse?.comment === MWH ? print(props.value.loaMultiplied, props.value.estimatedLOAMultiplied) : print(props.value.loa, props.value.estimatedLOA)
    else return 0
  }
  return <React.Fragment>{renderValue()}</React.Fragment>
}
export default ConsumptionTableValue
