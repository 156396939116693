import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DataGrid, GridRowsProp, GridColDef} from '@mui/x-data-grid'
import {Card, CardContent, CardHeader, Container, Divider, Grid, Typography} from '@mui/material'
import PageTitleWrapper from '../shared/PageTitleWrapper'
import Footer from '../shared/Footer'
import {RootState} from '../../redux/rootReducer'
import {getAllArea, deleteArea} from '../../redux/area/actions'
import EditLocationAltOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'
import PageHeader from './PageHeader'
import {useNavigate} from 'react-router-dom'
import Area from '../../redux/area/Interfaces.ts'
////////////////////
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {isAdmin} from '../../shared/utils/utilities'

export default function ListArea() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleOnClick = (rowData: Area) => {
    navigate(`/area/${rowData.id}/edit`)
  }
  //////////////
  const [open, setOpen] = React.useState(false)
  const [areaToDelete, setAreaToDelete] = React.useState<Area | undefined>()
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getAllArea())
    setHasAdminRights(isAdmin())
  }, [])

  const onAreaDeleteClick = () => {
    if (areaToDelete) {
      dispatch(deleteArea(areaToDelete.id))
      handleClose()
    }
  }
  const handleClickOpen = (rowData: Area) => {
    setAreaToDelete(rowData)

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //////////////
  const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id'},
    {field: 'number', headerName: 'Nummer', width: 150},
    {field: 'name', headerName: 'Namn', width: 300},
    {field: 'insDate', headerName: 'Skapades', width: 250},
    {field: 'updDate', headerName: 'Uppdaterades', width: 250},
    {
      field: 'Edit',
      headerName: '',
      renderCell: (params) =>
        hasAdminRights ? (
          <div>
            <IconButton onClick={() => handleOnClick(params.row)}>
              <EditLocationAltOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => handleClickOpen(params.row)}>
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </div>
        ) : (
          ''
        ),
    },
  ]

  const areas: Area[] = useSelector((state: RootState) => state.area.areas)
  const isLoading = useSelector((state: RootState) => state.area.isLoading)

  if (!isLoading) {
    const rows: GridRowsProp = areas
    return (
      <React.Fragment>
        <div>
          <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{'Radera graddagsort'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>Vill du radera graddagsorten {areaToDelete ? areaToDelete.name : ''}?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Avbryt</Button>
              <Button onClick={onAreaDeleteClick} autoFocus>
                Radera
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <PageTitleWrapper>
          <PageHeader />
        </PageTitleWrapper>
        <Container maxWidth='lg'>
          <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title='Alla graddagsorter' />
                <Divider />
                <CardContent>
                  <DataGrid rowsPerPageOptions={[50, 100]} rows={rows} columns={columns} autoHeight onRowDoubleClick={(param: any) => handleOnClick(param.row)} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <PageTitleWrapper>
          <Typography variant='h3' component='h3' gutterBottom>
            Graddagsorter
          </Typography>
          {/* <Typography variant='subtitle2'>home subtitle </Typography> */}
        </PageTitleWrapper>
        <Container maxWidth='lg'>
          <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title='' sx={{background: '#97a7c1'}} />
                <Divider />
                <CardContent>
                  <Skeleton />
                  <Skeleton animation='wave' />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation='wave' />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation='wave' />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation='wave' />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation='wave' />
                  <Skeleton animation={false} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}
