import unitService from '../../services/unitService'
import {ActionTypes} from './unitTypes'
import {Dispatch} from 'redux'
import { IUnit } from './unitInterfaces'

export const getAllUnits = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_UNITS_LOADING})
  const data : IUnit[] = await unitService.getUnits()
  if (data) {
    dispatch({type: ActionTypes.GET_UNITS_SUCCESS, payload: data})
  } else {
    dispatch({type: ActionTypes.GET_UNITS_FAILED})
  }
}

export const getUnitById = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_UNIT_LOADING})
  try {
    const data = await unitService.getUnitById(id)
    return dispatch({type: ActionTypes.GET_UNIT_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_UNIT_FAILED, payload: error})
  }
}

export const editeUnit = (unit: any) => async (dispatch: Dispatch) => {
  const data = await unitService.editeUnit(unit)
  if (data) {
    dispatch({type: ActionTypes.GET_UNIT_SUCCESS, payload: data})
  } else {
    dispatch({type: ActionTypes.GET_UNIT_FAILED})
  }
}

export const deleteUnitById = (id: number) => async (dispatch: Dispatch) => {
  const data = await unitService.deleteUnitById(id)
  if (data) {
    dispatch({type: ActionTypes.GET_UNIT_SUCCESS, payload: data})
  } else {
    dispatch({type: ActionTypes.GET_UNIT_FAILED})
  }
}

export const createUnit = (unit: any) => async (dispatch: Dispatch) => {
  const data = await unitService.createUnit(unit)
  if (data) {
    dispatch({type: ActionTypes.GET_UNIT_SUCCESS, payload: data})
  } else {
    dispatch({type: ActionTypes.GET_UNIT_FAILED})
  }
}
