import { CounterValueLogTag, ICounterConsumptionType, IDeviationState, ILogItem } from '../redux/home/homeInterfaces';
import { htmlDecode, isNullOrWhiteSpace } from '../shared/utils/utilities';
import saveupClient, { handleError, handleResponse } from '../clients/saveupClient';
export const logService = {
    getAllLogs,
    getCounterValueLogs,
    getCounterValueLogsByOffice,
    getDeviationStates,
    updateLogTags
};
async function getAllLogs() {
    const logs = await saveupClient.get("counter/value/log").then(response => {        
        return response.data;
    }).catch(error => {
        console.log(error);
    });
    return logs;
}
async function getCounterValueLogsByOffice(year: number, month: number, office: string) {
    const logs = await saveupClient.get<ILogItem[]>(`counter/getestatecounterlogsbyoffice?office=${office}&year=${year}&month=${month}`, { timeout: 120000 })
        .then(handleResponse)

    if(!logs) return []    
    return logs.filter((x: ILogItem) => !isNullOrWhiteSpace(x.office) && x.office.trim().toLowerCase() === office.trim().toLowerCase()).map((x: ILogItem, i: number) => {
        x.counterValue = +x.counterValue.toFixed(2)
        x.message = htmlDecode(x.message)
        x.counterNumber = isNullOrWhiteSpace(x.counterNumber) ? (isNullOrWhiteSpace(x.counterName) ? x.counterName : `(DB-ID: ${x.counterId})`) : x.counterNumber
        x.office = isNullOrWhiteSpace(x.office) ?  "" : x.office
        x.deviation = ""
        x.category = x.tag
        return x
    })
}

async function getCounterValueLogs(year: number, month: number) {
    const logs = await saveupClient.get<ILogItem[]>(`counter/GetEstateCounterLogs?year=${year}&month=${month}`, { timeout: 120000 })
        .then(handleResponse)
        .catch(error => console.log(error))

    if(!logs) return []
    
    return logs.map((x: ILogItem, i: number) => {
        x.counterValue = +x.counterValue.toFixed(2)
        x.message = htmlDecode(x.message)
        x.counterNumber = isNullOrWhiteSpace(x.counterNumber) ? (isNullOrWhiteSpace(x.counterName) ? x.counterName : `(DB-ID: ${x.counterId})`) : x.counterNumber
        x.office = isNullOrWhiteSpace(x.office) ?  "" : x.office
        x.deviation = ""
        x.category = x.tag
        return x
    })
}
// Log/Deviation/State
async function getDeviationStates(ids:  ICounterConsumptionType[]) {
    const data = {
        list: [...ids]
    }    
    const states = await saveupClient.post<IDeviationState[]>("counter/log/deviation/state", data, {timeout: 120000})
        .then(handleResponse)    
    return states
}

async function updateLogTags (counterValueLogId: number, tags: string[]) {
    const data = {
        counterValueLogId: counterValueLogId,
        tags: tags
    }
    const result = await saveupClient.post<CounterValueLogTag[]>("counter/value/log/tag", data)
        .then(handleResponse)        
    return result
}

export default logService;