import {Alert, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Tab, Typography} from '@mui/material'
import React, {useState} from 'react'
import {UserProps} from './interfaces'
import UserList from './UserList'
import {Create} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import {TabContext, TabList, TabPanel} from '@mui/lab'

const User = (props: UserProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deletedId, setDeletedId] = useState<number>(0)
  const [deletedFullName, setDeletedFullName] = useState<string>('')

  const onDisableClick = (id: number, name: string, isDisabled: boolean) => {
    if(isDisabled) {
      setDeletedId(id)
      setDeletedFullName(name)
      setDeleteModalOpen(true)
    }
    else {
      setDeletedId(0)
      setDeletedFullName('')
      setDeleteModalOpen(false)
    }
  }

  
  const onDeleteModalConfirmClick = () => {
    setDeleteModalOpen(false)
    props.disableUser(deletedId, true)
  }
  const onEnableConfirmClick = (id: number, name: string, isDisabled: boolean) => {
    props.disableUser(id, isDisabled)
  }

  const [selectedTab, setSelectedTab] = useState<string>('enabled')

  const onTabChange = (e: React.SyntheticEvent<Element, Event>, obj: any) => {
    setSelectedTab(obj.toString())
  }

  return (
    <React.Fragment>
      <Grid sx={{margin: '20px'}} justifyContent='center' alignItems='stretch'>
        <Card>
          <Grid item xl={12}>
            <CardHeader title='Användare' sx={{background: '#97a7c1'}} />
            <Divider />
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' columns={12} spacing={3} padding={3}>
              <Grid item xs={2}>
                <Link to='/user/create'>
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='primary' startIcon={<Create fontSize='small' />}>
                    Skapa ny användare
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' columns={12} spacing={3} padding={3}>
              <Grid item xs={12}>
                <TabContext value={selectedTab}>
                  <TabList onChange={onTabChange}>
                    <Tab label='Användare' value={'enabled'} />
                    <Tab label='Borttagna användare' value={'disabled'} />
                  </TabList>
                  <TabPanel value={'enabled'}>
                    <UserList name='users' users={props.users} isLoading={props.isLoading} onDisableClick={onDisableClick} />
                  </TabPanel>
                  <TabPanel value={'disabled'}>
                    <UserList name='removedUsers' users={props.disabledUsers} isLoading={props.isLoading} onDisableClick={onEnableConfirmClick} hideButtons={true} />
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Radera användare?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography>
              Är du säker på att du vill ta bort användaren <strong>{`${deletedFullName}`}</strong> {`med ID: ${deletedId}`}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDeleteModalConfirmClick()} color='error' autoFocus>
            Ta bort
          </Button>
          <Button onClick={() => setDeleteModalOpen(false)}>Avbryt</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default User
