import {Grid, TextField} from '@mui/material'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import React from 'react'
import {Control, Controller} from 'react-hook-form'
import {IDialogStartStopDateProps} from './interfaces'
const DialogStartStopDate = (props: IDialogStartStopDateProps) => {
  return (
    <React.Fragment>
      <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
        <Grid xs={12} item>
          <Controller
            render={({field}) => <DesktopDatePicker label='Från' {...field} renderInput={(params: any) => <TextField {...params} fullWidth views={props.views} />} />}
            name='startDate'
            control={props.control}
            defaultValue={props.defaultValue}
          />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
        <Grid xs={12} item>
          <Controller
            render={({field}) => <DesktopDatePicker label='Till' {...field} renderInput={(params: any) => <TextField {...params} fullWidth views={props.views} />} />}
            name='stopDate'
            control={props.control}
            defaultValue={props.defaultValue}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default DialogStartStopDate
