import React from 'react'
import {hasEditPermissions} from '../../../shared/utils/utilities'
import {Button, Grid} from '@mui/material'
import {Add} from '@mui/icons-material'
import {IAddButtonProps} from './interfaces'

const AddButton = (props: IAddButtonProps) => {
  if (props.isAuthorized) {
    return (
      <Grid item xs={12}>
        <Button id={props.id} onClick={(e: any) => props.onClick(e)} startIcon={<Add fontSize='small' />}>
          {props.label}
        </Button>
      </Grid>
    )
  }
  return <div></div>
}
export default AddButton
