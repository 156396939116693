import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getDeviationTypeForMeter, getMeterTypeById, updateMeterType, getDeviationTypes} from '../../redux/meter/actions'
import TextField from '@mui/material/TextField'
import {RootState} from '../../redux/rootReducer'
import {Grid, Card, CardHeader, CardContent, Container, Divider, Alert, IconButton, Collapse, Select, MenuItem, FormLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from '@mui/material'
import SecButton from '../shared/Inputs/SecButton'
import {ArrowBack, Close, Delete, Edit, Save} from '@mui/icons-material'
import {useParams} from 'react-router'
import {getAllUnits} from '../../redux/unit/unitActions'
import {Controller, useForm} from 'react-hook-form'
import {IUnit} from '../../redux/unit/unitInterfaces'
import {Link} from 'react-router-dom'
import {isAdmin} from '../../shared/utils/utilities'

interface IMeterUpdateForm {
  name: string
  decimals: number
  isConsumption: boolean
  maxValue: number
  multiple: number
  unitId: number
  deviationTypeId: number
}

interface IMeterDetailsProps {
  meterId: number
}

enum SaveResponse {
  SUCCESS,
  FAIL,
  PENDING,
  NOT_SET,
}

const MeterDetails = (props: IMeterDetailsProps) => {
  const dispatch = useDispatch()
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  useEffect(() => {
    dispatch(getAllUnits())
    dispatch(getDeviationTypes())
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() => {
    setMeterId(props.meterId)
    dispatch(getMeterTypeById(props.meterId))
    dispatch(getDeviationTypeForMeter(props.meterId))
  }, [props.meterId])

  const meterType = useSelector((state: RootState) => state.meter.selectedMeterType)
  const units = useSelector((state: RootState) => state.unit.units)
  const deviationTypes = useSelector((state: RootState) => state.meter.deviationTypes)
  const selectedUnit = units.find((unit: IUnit) => unit.id === meterType?.unitId)
  const selectedDeviationType = useSelector((state: RootState) => state.meter.selectedDeviationType)
  const [meterId, setMeterId] = useState<number>(0)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const {register, control, handleSubmit, reset, setValue} = useForm<IMeterUpdateForm>({
    defaultValues: {
      name: meterType ? meterType.name : '',
      decimals: meterType ? meterType.decimals : 0,
      isConsumption: meterType ? meterType.isConsumption : true,
      maxValue: meterType ? meterType.maxValue : 0,
      multiple: meterType ? meterType.multiple : 0,
      unitId: meterType ? meterType.unitId : 0,
      deviationTypeId: 0,
    },
  })

  useEffect(() => {
    if (meterType) {
      reset({
        name: meterType.name,
        decimals: meterType.decimals,
        isConsumption: meterType.isConsumption,
        maxValue: meterType.maxValue,
        multiple: meterType.multiple,
        unitId: meterType.unitId,
        deviationTypeId: selectedDeviationType ? selectedDeviationType.id : 0,
      })
    }
  }, [meterType])
    // When deviationTypes are updated we need to update the selected deviation type using setValue
    useEffect(() => {
      if (selectedDeviationType) {
        setValue('deviationTypeId', selectedDeviationType.id)
      }
    }, [selectedDeviationType])
  const [area, setArea] = useState('')
  const [office, setOffice] = useState('')
  const [isHidden, setIsHidden] = useState(true)

  const onSubmit = (data: IMeterUpdateForm) => {
    if (meterType) {
      dispatch(updateMeterType(meterType.id, data.name, data.maxValue, data.multiple, data.unitId, data.decimals, data.isConsumption, data.deviationTypeId))
      setSaveResult(SaveResponse.SUCCESS)
      setAlertOpen(true)
    }
  }
  const onChangeOwnerClick = (e: any) => alert('Opening owner change modal')
  const onChangeOfficeClick = (e: any) => alert('Opening office add modal')
  const onDeleteMeterClick = (e: any) => setDeleteDialogOpen(true)
  const onNewAddressClick = (e: any) => alert('Skapar ny adress')

  const onAreaChange = (e: any) => setArea(e.target.value)
  const onOfficeChange = (e: any) => setOffice(e.target.value)

  const renderAlert = () => {
    let alert = <div></div>
    switch (saveResult) {
      case SaveResponse.SUCCESS:
        alert = (
          <Alert
            severity='success'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            Mätartypen har sparats
          </Alert>
        )
        break
      case SaveResponse.FAIL:
        alert = (
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            Misslyckades med att spara mätartypen!
          </Alert>
        )
        break
      default:
        break
    }
    return <Collapse in={alertOpen}>{alert}</Collapse>
  }

  const meterTypeName = meterType ? ' ' + meterType.name : ''
  return (
    <div>
      <Container maxWidth='lg'>
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <Card component='form' noValidate autoComplete='off'>
              <CardHeader title={`Redigera mätartyp${meterTypeName}`} />
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                    <Grid item xs={12}>
                      {renderAlert()}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                    <Grid item xs={2}>
                      <TextField value={meterId} fullWidth inputProps={{maxLength: 40}} disabled={true} InputProps={{readOnly: true}} label='ID' />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='name'
                        control={control}
                        render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} InputLabelProps={{shrink: true}} label='Namn' disabled={hasAdminRights ? false : true} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='maxValue'
                        control={control}
                        render={({field}) => (
                          <TextField {...field} fullWidth inputProps={{maxLength: 40}} InputLabelProps={{shrink: true}} label='Max-värde' disabled={hasAdminRights ? false : true} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='multiple'
                        control={control}
                        render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} InputLabelProps={{shrink: true}} label='Multipel' disabled={hasAdminRights ? false : true} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name='decimals'
                        control={control}
                        render={({field}) => (
                          <TextField {...field} fullWidth inputProps={{maxLength: 40}} InputLabelProps={{shrink: true}} label='Decimaler' disabled={hasAdminRights ? false : true} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={3}>
                      <FormLabel>Avvikelsetyp:</FormLabel>
                      <Controller
                        name='deviationTypeId'
                        control={control}
                        render={({field}) => (
                          <Select fullWidth {...field} disabled={hasAdminRights ? false : true}>
                            <MenuItem key={1} value={0}>
                              -- Välj avvikelsetyp ---
                            </MenuItem>
                            {deviationTypes?.map((type, index) => (
                              <MenuItem key={index + 1} value={type.id}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <FormLabel>Enhet:</FormLabel>
                      <Controller
                        name='unitId'
                        control={control}
                        render={({field}) => (
                          <Select fullWidth {...field} disabled={hasAdminRights ? false : true}>
                            <MenuItem key={1} value={0}>
                              --- Välj enhet ---
                            </MenuItem>
                            {units?.map((unit, index) => (
                              <MenuItem key={index + 1} value={unit.id}>
                                {unit.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={10}>
                      {hasAdminRights && (
                        <SecButton startIcon={<Save />} variant='contained' onClick={handleSubmit(onSubmit)}>
                          Spara
                        </SecButton>
                      )}
                      <Link to='/meter'>
                        <SecButton startIcon={<ArrowBack />} variant='contained' style={{'marginLeft': '10px'}}>
                          Tillbaka
                        </SecButton>
                      </Link>
                    </Grid>
                    <Grid item xs={2}>
                      {hasAdminRights && (
                        <SecButton startIcon={<Delete />} variant='contained' onClick={onDeleteMeterClick} color='error'>
                          Radera
                        </SecButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
          <DialogContent>
            <DialogContentText id='alert-dialog-descrption'>Borttagning av mätartyper är inaktiverat. Vill du radera denna mätartyp, vänligt kontakta support.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled autoFocus>
              Radera
            </Button>
            <Button onClick={() => setDeleteDialogOpen(false)}>Avbryt</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}
export default MeterDetails