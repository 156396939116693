import {Reducer} from 'redux'
import {NodeState} from './interfaces'
import { ActionTypes } from './types'
const initialNodeState: NodeState = {
  isLoading: false,
  errorMessage: '',
  costEmissionData: {
    isLoading: false,
    errorMessage: '',
    data: {
      id: 0,
      type: '',
      meterCosts: [],
      energyZoneMeterCosts: [],
      estateMeterCosts: [],
      officeMeterCosts: [],
      meterEmission: [],
      estateMeterEmission: [],
      officeMeterEmission: [],
    },
  },
}
export const nodeReducer: Reducer<NodeState> = (state = initialNodeState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_NODE_COST_EMISSION_DATA_LOADING: {
      return {...state, costEmissionData: {...state.costEmissionData, isLoading: true, errorMessage: '', data: undefined}}
    }
    case ActionTypes.FETCH_NODE_COST_EMISSION_DATA_SUCCESS: {
      return {...state, costEmissionData: {...state.costEmissionData, isLoading: false, errorMessage: '', data: payload}}
    }
    case ActionTypes.FETCH_NODE_COST_EMISSION_DATA_ERROR: {
      return {...state, costEmissionData: {...state.costEmissionData, isLoading: false, errorMessage: payload, data: undefined}}
    }
    default: {
      return state
    }
  }
}
