import SaveAlert from '../../shared/alerts/SaveAlert'
import PageTitleWrapper from '../../shared/PageTitleWrapper'
import {Cancel, Close, Save} from '@mui/icons-material'
import {Alert, Button, Card, CardContent, CardHeader, Collapse, Container, Grid, IconButton, MenuItem, Select, Stack, TextField, Typography} from '@mui/material'
import {getAllArea} from '../../../redux/area/actions'
import {createEstate, getEstateAttributeControls} from '../../../redux/estate/actions'
import {RootState} from '../../../redux/rootReducer'
import React, {useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import { ICreateEstateForm } from './interfaces'
import { SaveResponse } from './enums'
import FormAttributeControlPanel from '../../../components/attributes/controls/FormAttributeControlPanel'



const CreateEstate = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const ownerId: number = id ? parseInt(id) : 0
  const methods = useForm<ICreateEstateForm>({
    defaultValues: {
      ownerId: ownerId,
      name: '',
      rekylId: '',
      atemp: '',
      loa: '',
      office: 'NOT_SET',
      description: '',
      area: 0,
    },
  })
  const navigate = useNavigate()
  const areas = useSelector((state: RootState) => state.area.areas)
  const offices = useSelector((state: RootState) => state.filter.offices)
  const controls = useSelector((state: RootState) => state.estate.estateAttributeControls.controls)
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState(false)
  useEffect(() => {
    dispatch(getAllArea())
    dispatch(getEstateAttributeControls())
  }, [])

  const onSubmit = (form: ICreateEstateForm) => {
    dispatch(createEstate(form.ownerId, form.name, form.description, 0, form.area, form.rekylId, form.office, form.atemp, form.loa, form.category))
    setSaveResult(SaveResponse.SUCCESS)
    setAlertOpen(true)
    navigate(`/owner/${form.ownerId}`)
  }

  return (
    <div>
      <PageTitleWrapper>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h3' component='h3' gutterBottom></Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </PageTitleWrapper>
      <Container>
        <Card>
          <CardHeader title='Ny fastighet' />
          <CardContent>
            <Grid container spacing={2} sx={{marginTop: '20px'}}>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                <Grid item xs={12}>
                  <SaveAlert
                    result={saveResult}
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    successMessage='Fastigheten har skapats.'
                    errorMessage='Misslyckades med att skapa fastigheten.'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                <Grid item xs={2}>
                  <Controller name='name' control={methods.control} render={({field}) => <TextField fullWidth label='Namn' {...field} />} />
                </Grid>
                <Grid item xs={2}>
                  <Controller name='rekylId' control={methods.control} render={({field}) => <TextField fullWidth label='Rekyl-ID' {...field} />} />
                </Grid>
                <Grid item xs={2}>
                  <Controller name='atemp' control={methods.control} render={({field}) => <TextField fullWidth label='Atemp (m²)' {...field} />} />
                </Grid>
                <Grid item xs={2}>
                  <Controller name='loa' control={methods.control} render={({field}) => <TextField fullWidth label='LOA (m²)' {...field} />} />
                </Grid>
                <Grid item xs={3}>
                  <Controller name='description' control={methods.control} render={({field}) => <TextField fullWidth label='Beskrivning' {...field} multiline={true} />} />
                </Grid>
              </Grid>
              <FormAttributeControlPanel useFormReturn={methods} attributeControls={controls} />
              <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                <Grid item xs={2}>
                  <Controller
                    name='office'
                    control={methods.control}
                    render={({field}) => (
                      <Select fullWidth {...field}>
                        <MenuItem value='NOT_SET'>--- Välj kontor ---</MenuItem>
                        {offices.map((office, index) => (
                          <MenuItem key={index} value={office.name}>
                            {office.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name='area'
                    control={methods.control}
                    render={({field}) => (
                      <Select fullWidth {...field}>
                        <MenuItem value={0}>-- Välj graddagsort ---</MenuItem>
                        {areas.map((area, index) => (
                          <MenuItem key={index} value={area.id}>
                            {area.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction='row' spacing={2}>
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<Save fontSize='small' />} onClick={methods.handleSubmit(onSubmit)}>
                    Spara
                  </Button>
                  <Button onClick={() => navigate(`/owner/${id}`)} sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<Cancel fontSize='small' />}>
                    Avbryt
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
export default CreateEstate
