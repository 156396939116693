import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {RootState} from '../../redux/rootReducer'
import {
  getMeterCosts,
  createMeterCost,
  updateMeterCost,
  deleteMeterCost,
  getEnergyZoneMeterCosts,
  updateEnergyZoneMeterCost,
  createEnergyZoneMeterCost,
  deleteEnergyZoneMeterCost,
  getMeterTypeById,
} from '../../redux/meter/actions'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material'
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams} from '@mui/x-data-grid'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import {hasEditPermissions, isAdmin} from '../../shared/utils/utilities'
import {Add, Edit} from '@mui/icons-material'
import MeterCostInputDialog, {IMeterCostInputForm} from './MeterCostInputDialog'
import {ICurrency, IMeterCostCurrency, IMeterCostProps} from '../../redux/meter/interfaces'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import {getCurrencyByName} from '../../redux/currency/actions'
import {CURRENCY_SEK, NOT_SET} from '../../shared/utils/constants'
import ResponseAlert from '../alert/ResponseAlert'
import {IEnergyZone} from '../../redux/energyzone/interfaces'
import LabeledSelect, {ILabeledSelectItem} from '../shared/Inputs/LabeledSelect'
import {getEnergyZones} from '../../redux/energyzone/actions'
import {orderBy} from 'lodash'
import { getMeterTypesWithEnergyZone } from '../../redux/configuration/actions'
import { getUnitById } from '../../redux/unit/unitActions'
import MessageDialog from '../../components/shared/alerts/MessageDialog'
import { isOverlapping } from '../consumption/graph/costEmissionHelper'
const StripedDataGrid = styled(DataGrid)(({theme}) => ({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}))

const MeterCost = (props: IMeterCostProps) => {
  const dispatch = useDispatch()
  
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<IMeterCostCurrency | undefined>()
  const [saveResponse, setSaveResponse] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [energyZone, setEnergyZone] = useState<number>(0)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const [alreadyExistsDialogOpen, setAlreadyExistsDialogOpen] = useState<boolean>(false)
  const meterCosts = useSelector((state: RootState) => orderBy(state.meter.meterCosts, ['startDate', 'stopDate'], ['asc']))
  const defaultCurrency: ICurrency | undefined = useSelector((state: RootState) => state.currency.selectedCurrency)
  const savedMeterCost = useSelector((state: RootState) => state.meter.savedMeterCost)
  const energyZones = useSelector((state: RootState) => state.energyZone.energyZones)
  const meterTypesWithEnergyZone = useSelector((state: RootState) => state.configuration.meterTypesWithEnergyZone)
  const meterType = useSelector((state: RootState) => state.meter.selectedMeterType)
  const unit = useSelector((state: RootState) => state.unit.selectedUnit)

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() => {
    dispatch(getMeterTypeById(props.meterId))
  }, [props.meterId])

  useEffect(() => {
    if (meterType) dispatch(getUnitById(meterType.unitId))
  }, [meterType])

  useEffect(() => {
    // dispatch(getMeterCosts(props.meterId))
    dispatch(getCurrencyByName(CURRENCY_SEK))
    dispatch(getMeterTypesWithEnergyZone())
    dispatch(getEnergyZones())
  }, [])

  function refreshMeterCosts(energyZoneId: number) {
    if (energyZone === 0) dispatch(getMeterCosts(props.meterId))
    else dispatch(getEnergyZoneMeterCosts(props.meterId, energyZoneId))
  }

  useEffect(() => {
    refreshMeterCosts(energyZone)
  }, [energyZone])

  useEffect(() => {
    if (savedMeterCost && saveResponse === SaveResponse.PENDING) {
      refreshMeterCosts(savedMeterCost ? savedMeterCost.energyZoneId : 0)
      setSaveResponse(SaveResponse.SUCCESS)
      setAlertOpen(true)
    } else if (saveResponse === SaveResponse.PENDING) {
      setSaveResponse(SaveResponse.FAIL)
      setAlertOpen(true)
    }
  }, [savedMeterCost])

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'cost', headerName: 'Pris', flex: 1, editable: true},
    {
      field: 'currency',
      headerName: 'Valuta',
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return params.value.name
      },
    },
    {field: 'startDate', headerName: 'Från', flex: 1, editable: true},
    {field: 'stopDate', headerName: 'Till', flex: 1, editable: true},
    {
      width: 10,
      field: 'actions',
      headerName: 'ACtions',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<string>) => {
        if(!hasAdminRights) return ''
        return (
          <div>
            <IconButton
              onClick={() => {
                setSelectedRow(params.row)
                setSaveDialogOpen(true)
              }}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleOnEnergyZoneDeleteClick(params.row)}>
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </div>
        )
      },
      flex: 1,
    },
  ]

  const handleOnEnergyZoneDeleteClick = (cost: IMeterCostCurrency) => {
    if (energyZone === 0) {
      dispatch(deleteMeterCost(cost.id))
    } else {
      if (cost.energyZoneId !== energyZone) {
        alert('Valt objekt har felaktig information gällande elprisomrode och kan ej raderas genom denna vy.')
      } else {
        dispatch(deleteEnergyZoneMeterCost(cost.id))
      }
    }
  }

  const handleSaveNewMeterCostClick = (e: any) => {
    setSelectedRow(undefined)
    setSaveDialogOpen(true)
  }

  const renderInputDialog = () => {
    if (defaultCurrency) {
      return <MeterCostInputDialog defaultCurrency={defaultCurrency} open={saveDialogOpen} item={selectedRow} onClose={() => setSaveDialogOpen(false)} onConfirm={(e: any) => onValueConfirmClick(e)} unit={unit} />
    }
    return ''
  }
  const onValueConfirmClick = (data: IMeterCostInputForm) => {
    if (!hasAdminRights) return

    if (energyZone === 0) {
      if(isOverlapping(data.id, data.startDate, data.stopDate, meterCosts)) {      
        setAlreadyExistsDialogOpen(true)
        return
      }

      if (data.id > 0) {
        dispatch(updateMeterCost(data.id, props.meterId, data.currencyId, data.cost, data.startDate, data.stopDate))
      } else {
        dispatch(createMeterCost(props.meterId, defaultCurrency ? defaultCurrency.id : 0, data.cost, data.startDate, data.stopDate))
      }
    } else {      
      if(isOverlapping(data.id, data.startDate, data.stopDate, meterCosts.filter((x: IMeterCostCurrency) => x.energyZoneId === energyZone))) {      
        setAlreadyExistsDialogOpen(true)
        return
      }

      if (data.id > 0) {
        dispatch(updateEnergyZoneMeterCost(data.id, props.meterId, energyZone, data.currencyId, data.cost, data.startDate, data.stopDate))
      } else {
        dispatch(createEnergyZoneMeterCost(props.meterId, energyZone, defaultCurrency ? defaultCurrency.id : 0, data.cost, data.startDate, data.stopDate))
      }
    }
    setSaveDialogOpen(false)
    setSaveResponse(SaveResponse.PENDING)
  }

  if (!defaultCurrency) {
    return <div></div>
  }
  return (
    <div>
      {meterTypesWithEnergyZone.some((x: number) => x === props.meterId) ? (
        <Grid container direction='row' alignItems='stretch' spacing={1}>
          <Grid item xs={3}>
            <InputLabel>Välj elprisområde:</InputLabel>
            <Select fullWidth value={energyZone} onChange={(e: any) => setEnergyZone(e.target.value)}>
              <MenuItem value={0}>Alla prisområden</MenuItem>
              {energyZones?.map((zone: IEnergyZone, index: number) => (
                <MenuItem key={index} value={zone.id}>
                  {zone.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <ResponseAlert
            response={saveResponse}
            open={alertOpen}
            successMessage={`Prisuppgiften ${savedMeterCost ? savedMeterCost.cost : NOT_SET} registerades.`}
            failureMessage='Det gick inte att registera prisuppgiften.'
          />
        </Grid>
        <Grid item xs={12}>
          {hasAdminRights ? (
            <Grid item xs={12}>
              <Button id='saveNewMeterCost' onClick={handleSaveNewMeterCostClick} startIcon={<Add fontSize='small' />}>
                Ny prisuppgift
              </Button>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={`Registrerade kostnader för mätare`} />
            <Divider />
            <CardContent>
              <StripedDataGrid pageSize={50} rows={meterCosts} columns={columns} autoHeight />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {renderInputDialog()}
      <MessageDialog open={alreadyExistsDialogOpen} setOpen={(value) => setAlreadyExistsDialogOpen(value)} title='Prisuppgift finns redan' message='Det finns redan en prisuppgift registrerad för denna mätartyp inom valt tidsspann.' />
    </div>
  )
}
export default MeterCost
