import React from 'react'
import {Link} from 'react-router-dom'
function Internal() {
  return (
    <div>
      Tyvärr har ett fel uppstått. <Link to='/'>Gå tillbaka.</Link>
    </div>
  )
}
export default Internal
