import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import { Grid } from '@mui/material';
import { ReportLoadingProps } from './interfaces';

const ReportLoading : React.FC<ReportLoadingProps> = (props: ReportLoadingProps) => {
  const [internalProgress, setInternalProgress] = useState(0)
  const [hasDeterministicProgress, setHasDeterministicProgress] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [maxProgress, setMaxProgress] = useState<number>(100)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  useEffect(() => {
    if(props.progress !== undefined && props.maxProgress !== undefined) {
      setHasDeterministicProgress(props.progress > 0 && props.maxProgress > 0)
      setProgress(props.progress)   
    }
    else {
      setHasDeterministicProgress(false)
      setProgress(0)   
    }
  }, [props.progress])

  useEffect(() => {
    if(props.maxProgress !== undefined) setMaxProgress(props.maxProgress)
  }, [props.maxProgress])

  useEffect(() => {
    setIsLoading(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (hasDeterministicProgress) {
      setInternalProgress((progress / maxProgress) * 100)
    } else if (isLoading) {
      timer = setInterval(() => {
        setInternalProgress((oldProgress) => {
          if (oldProgress >= 100) {
            return 0
          }
          return oldProgress + 10
        })
      }, 1000)
    }

    return () => timer && clearInterval(timer);
  }, [isLoading, hasDeterministicProgress, progress, maxProgress]);

  // Reset progress when loading is not active or when switching between modes
  useEffect(() => {
    if (!isLoading || hasDeterministicProgress) {
      setInternalProgress(0)
    }
  }, [isLoading, hasDeterministicProgress])

  return (
    <Grid item xs={12}>
      {isLoading && <LinearProgress
        variant={hasDeterministicProgress ? "determinate" : "indeterminate"}
        value={hasDeterministicProgress ? internalProgress : undefined}
        style={{ width: '100%', height: '12px', borderRadius: '5px' }}
      />}
    </Grid>
  )
}

export default ReportLoading
