import {CoPresentOutlined, Edit} from '@mui/icons-material'
import {Card, CardContent, CardHeader, Divider, IconButton, styled} from '@mui/material'
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams} from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import {OfficeMeterCost} from '../../../redux/officeMeterCost/interfaces'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import {OfficeMeterCostListProps} from './interfaces'
import { hasEditPermissions, isAdmin } from '../../../shared/utils/utilities'

const StripedDataGrid = styled(DataGrid)(({theme}) => ({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}))
const OfficeMeterCostList = (props: OfficeMeterCostListProps) => {
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)

  useEffect(() => {
    setHasAdminRights(isAdmin)
  }, [])
  useEffect(() => {
    console.log(props.officeMeterCosts)
  }, [props.officeMeterCosts])

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'cost', headerName: 'Pris', flex: 1, editable: true},
    {
      field: 'currency',
      headerName: 'Valuta',
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return params.value.name
      },
    },
    {field: 'startDate', headerName: 'Från', flex: 1, editable: true},
    {field: 'stopDate', headerName: 'Till', flex: 1, editable: true},
    {
      width: 10,
      field: 'actions',
      headerName: 'Actions',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<string>) => hasAdminRights ? (
        <div>
          <IconButton onClick={() => props.onOfficeMeterCostEditClick(params.row)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => props.onOfficeMeterCostDeleteClick(params.row)}>
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </div>
      ) : '',
      flex: 1,
    },
  ]
  return (
    <div>
      <Card>
        <CardHeader title={`Registrerade kostnader`} />
        <Divider />
        <CardContent>
          <StripedDataGrid pageSize={50} rows={props.officeMeterCosts} columns={columns} autoHeight />
        </CardContent>
      </Card>
    </div>
  )
}
export default OfficeMeterCostList
