import React, {useEffect, useState} from 'react'
import {IMeterEmissionInputForm, IMeterEmissionProps} from './interfaces'
import {Container, Grid} from '@mui/material'
import ResponseAlert from '../../../components/alert/ResponseAlert'
import AddButton from '../../../components/shared/buttons/AddButton'
import MeterEmissionInputDialog from './MeterEmissionInputDialog'
import {IEmission} from '../../../redux/emission/interfaces'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'
import {EMISSION_KG_CO2} from '../../../shared/utils/constants'
import {IMeterEmission} from '../../../redux/meter/interfaces'
import {isAdmin} from '../../../shared/utils/utilities'
import {createMeterEmission, deleteMeterEmission, updateMeterEmission, getMeterEmissions, getMeterTypeById} from '../../../redux/meter/actions'
import MeterEmissionList from './MeterEmissionList'
import {getEmissions} from '../../../redux/emission/actions'
import {getUnitById} from '../../../redux/unit/unitActions'
import MessageDialog from '../../../components/shared/alerts/MessageDialog'
import { isOverlapping } from '../../../components/consumption/graph/costEmissionHelper'
const MeterEmission = (props: IMeterEmissionProps) => {
  const dispatch = useDispatch()

  const [defaultEmission, setDefaultEmission] = useState<IEmission | undefined>()
  const emissions: IEmission[] = useSelector((state: RootState) => state.emission.emissions)
  const meterEmissions: IMeterEmission[] = useSelector((state: RootState) => state.meter.meterEmissions)
  const savedEmission: IMeterEmission | undefined = useSelector((state: RootState) => state.meter.savedMeterEmission)
  const meterType = useSelector((state: RootState) => state.meter.selectedMeterType)
  const unit = useSelector((state: RootState) => state.unit.selectedUnit)
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<IMeterEmission | undefined>()
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const [alreadyExistsDialogOpen, setAlreadyExistsDialogOpen] = useState<boolean>(false)
  useEffect(() => {
    dispatch(getMeterTypeById(props.meterId))
  }, [props.meterId])

  useEffect(() => {
    if (meterType) dispatch(getUnitById(meterType.unitId))
  }, [meterType])

  useEffect(() => {
    dispatch(getEmissions())
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() => {
    if (savedEmission && defaultEmission) {
      dispatch(getMeterEmissions(props.meterId, defaultEmission.id))
    }
  }, [savedEmission])

  useEffect(() => {
    const co2Emission = emissions.find((x) => x.name === EMISSION_KG_CO2)
    setDefaultEmission(co2Emission)
    if (co2Emission) {
      dispatch(getMeterEmissions(props.meterId, co2Emission.id))
    }
  }, [emissions])

  const onValueConfirmClick = (data: IMeterEmissionInputForm) => {
    if (!hasAdminRights) return
    if (!defaultEmission) return
    
    if(isOverlapping(data.id, data.startDate, data.stopDate, meterEmissions)) {      
      setAlreadyExistsDialogOpen(true)
      return
    }

    if (data.id > 0) {
      dispatch(updateMeterEmission(data.id, props.meterId, defaultEmission.id, data.value, data.startDate, data.stopDate))
    } else {
      dispatch(createMeterEmission(props.meterId, defaultEmission.id, data.value, data.startDate, data.stopDate))
    }

    setSaveDialogOpen(false)
  }

  const onMeterEmissionEditClick = (emissionToEdit: any) => {
    setSelectedRow(emissionToEdit)
    setSaveDialogOpen(true)
  }

  const onMeterEmissionDeleteClick = (emission: IMeterEmission) => {
    dispatch(deleteMeterEmission(emission.id))
  }

  const renderInputDialog = () => {
    if (defaultEmission) {
      return (
        <MeterEmissionInputDialog
          meterId={props.meterId}
          defaultEmission={defaultEmission}
          open={saveDialogOpen}
          item={selectedRow}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={(e: IMeterEmissionInputForm) => onValueConfirmClick(e)}
          unit={unit}
        />
      )
    }
    return ''
  }

  const onNewEmissionClick = (e: any) => {
    setSelectedRow(undefined)
    setSaveDialogOpen(true)
  }

  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <AddButton onClick={onNewEmissionClick} id='addNewEmissionButton' label='Nytt utsläpp' isAuthorized={hasAdminRights} />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <MeterEmissionList meterEmissions={meterEmissions} onMeterEmissionEditClick={onMeterEmissionEditClick} onMeterEmissionDeleteClick={onMeterEmissionDeleteClick} />
        </Grid>
      </Grid>
      {renderInputDialog()}
      <MessageDialog open={alreadyExistsDialogOpen} setOpen={(value) => setAlreadyExistsDialogOpen(value)} title='Utsläppsuppgift finns redan' message='Det finns redan ett utsläpp registrerat för denna mätartyp inom valt tidsspann.' />
    </Container>
  )
}
export default MeterEmission
