import { Dispatch } from "redux"
import { ActionTypes } from "../office/types"
import { OfficeMeterCost } from "./interfaces"
import officeMeterCostService from "../../services/officeMeterCostService"

export const getOfficeMeterCosts = (officeId: number, meterId: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.GET_OFFICE_METER_COSTS_LOADING})
    try {
      const meterCosts: OfficeMeterCost[] = await officeMeterCostService.getOfficeMeterCosts(officeId, meterId)
      return dispatch({type: ActionTypes.GET_OFFICE_METER_COSTS_SUCCESS, payload: meterCosts})
    } catch (error) {
      return dispatch({type: ActionTypes.GET_OFFICE_METER_COSTS_FAILED, payload: error})
    }
  }
  export const createOfficeMeterCost = (officeId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.CREATE_OFFICE_METER_COST_LOADING})
    try {
      const createdMeterCost: OfficeMeterCost = await officeMeterCostService.createOfficeMeterCost(officeId, meterId, currencyId, cost, startDate, stopDate)
      return dispatch({type: ActionTypes.CREATE_OFFICE_METER_COST_SUCCESS, payload: createdMeterCost})
    } catch (error) {
      return dispatch({type: ActionTypes.CREATE_OFFICE_METER_COST_FAILED, payload: error})
    }
  }
  export const updateOfficeMeterCost = (id: number, officeId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.UPDATE_OFFICE_METER_COST_LOADING})
    try {        
      const updatedMeterCost: OfficeMeterCost = await officeMeterCostService.updateOfficeMeterCost(id, officeId, meterId, currencyId, cost, startDate, stopDate)
      return dispatch({type: ActionTypes.UPDATE_OFFICE_METER_COST_SUCCESS, payload: updatedMeterCost})
    } catch (error) {
      return dispatch({type: ActionTypes.UPDATE_OFFICE_METER_COST_FAILED, payload: error})
    }
  }
  export const deleteOfficeMeterCost = (id: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.DELETE_OFFICE_METER_COST_LOADING})
    try {
      await officeMeterCostService.deleteOfficeMeterCost(id)
      return dispatch({type: ActionTypes.DELETE_OFFICE_METER_COST_SUCCESS, payload: id})
    } catch (error) {
      return dispatch({type: ActionTypes.DELETE_OFFICE_METER_COST_FAILED, payload: error})
    }
  }