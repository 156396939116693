import {IConsumption} from '../redux/consumption/interfaces'
import {IMeterType} from '../redux/meter/interfaces'
import {IOwner, IOwnerPathDto} from '../redux/owner/ownerInterfaces'
import saveupClient, {handleResponse} from '../clients/saveupClient'
import { IBreadCrumbPath } from '../redux/tree/treeInterfaces'
import { isNullOrWhiteSpace } from '../shared/utils/utilities'
import { OWNER } from '../shared/utils/constants'

async function getOwners() {
  return await saveupClient.get('/owner').then(handleResponse)
}

async function getOwnersByName(name: string) {
  let owners = await saveupClient.get('/owner').then(handleResponse)
  owners = owners.filter((x: any) => x.name.trim().toLowerCase().indexOf(name.trim().toLowerCase()) !== -1)
  return owners
}

async function getOwnerById(id: number) {
  const OwnerById = await saveupClient
    .get(`/owner/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return OwnerById
}

async function createOwner(name: string, comment: string) {
  return await saveupClient.post<IOwner>(`owner`, {name, comment}).then(handleResponse)
}
async function editOwner(id: number, name: string, comment: string, hidden: boolean) {
  return await saveupClient
    .put(`owner`, {
      id: id,
      name: name,
      comment: comment,
      hidden: hidden,
    })
    .then(handleResponse)
}

async function deleteOwner(id: number) {
  const deleteowner = await saveupClient
    .delete(`owner/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return deleteowner
}

async function getMeterTypesAtOwner(id: number) {
  const types = await saveupClient
    .get<IMeterType[]>(`owner/${id}/meter/type`)
    .then(handleResponse)
    .catch((error) => console.log(error))
  return types
}

async function getConsumption(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {
  const result = await saveupClient
    .get<IConsumption>(
      `Consumption/Summary?id=${id}&type=OWNER&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}`,
      { timeout: 120000 }
    )
    .then(handleResponse)
  return result
}
async function getConsumptionByOffice(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, office: string) {
  const result = await saveupClient
    .get<IConsumption>(
      `Consumption/Office/Summary?id=${id}&type=OWNER&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&office=${office}`,
      { timeout: 120000 }
    )
    .then(handleResponse)
  return result
}
/**
 * 
 * @param id 
 * @param meterTypeId 
 * @param startDate 
 * @param stopDate 
 * @param useDegreeDayCorrection 
 * @returns 
 */
async function getConsumptionCostEmission(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/cost?id=${id}&type=OWNER&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}`,
      { timeout: 120000 }
    )
    .then(handleResponse)
  return result
}
/**
 * 
 * @param id 
 * @param meterTypeId 
 * @param startDate 
 * @param stopDate 
 * @param useDegreeDayCorrection 
 * @param office 
 * @returns 
 */
async function getConsumptionCostEmissionByOffice(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, office: string, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/cost?id=${id}&type=OWNER&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&office=${office}${targetUnit ? `&unit=${targetUnit}` : ''}`,
      { timeout: 120000 }
    )
    .then(handleResponse)
  return result
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getNodeConsumptionAndEstimate(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=OWNER&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&estimate=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @param office
 * @returns IConsumption[]
 */
async function getNodeConsumptionAndEstimateByOffice(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, office: string, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=OWNER&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&office=${office}&estimate=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
async function getPath(id: number) {
  return await saveupClient.get<IOwnerPathDto>(`owner/${id}/path`).then(handleResponse)
}
async function getBreadCrumbPath(id: number) {
  let paths: IBreadCrumbPath[] = []
  const dto: IOwnerPathDto = await getPath(id)
  paths.push({
    id: dto.id,
    label: isNullOrWhiteSpace(dto.name) ? dto.id.toString() : dto.name,
    nodeType: OWNER
  })  
  return paths;
}
const ownerService = {
  getOwners,
  getOwnerById,
  getOwnersByName,
  getMeterTypesAtOwner,
  getConsumption,
  getConsumptionByOffice,
  getConsumptionCostEmission,
  getConsumptionCostEmissionByOffice,
  getNodeConsumptionAndEstimate,
  getNodeConsumptionAndEstimateByOffice,
  getPath,
  getBreadCrumbPath,
  createOwner,
  editOwner,
  deleteOwner,
}

export default ownerService
