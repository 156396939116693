import {IConsumption, IConsumptionNodeDto} from '../redux/consumption/interfaces'
import {ITableLog} from '../redux/counterValue/interfaces'
import {IMeterType} from '../redux/meter/interfaces'
import saveupClient, {handleResponse} from '../clients/saveupClient'
import IAddress, { AddressSearchResultDto, IAddressPathDto } from '../redux/address/interfaces'
import { IBreadCrumbPath } from '../redux/tree/treeInterfaces'
import { isNullOrWhiteSpace } from '../shared/utils/utilities'
import { ADDRESS, ESTATE, OWNER } from '../shared/utils/constants'

/**
 ** Get address by id.
 * @param AddressId
 * @returns Address
 */
async function getAddress(AddressId: number) {
  const Address = await saveupClient
    .get(`address/${AddressId}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return Address
}
/**
 ** Create a new address.
 * @param name
 * @param estateid
 * @param street
 * @param postalCode
 * @param city
 * @param number
 * @param comment
 * @param hidden
 * @returns Address
 */
async function createAddress(name: string, estateId: number, street: string, postalCode: string, city: string, number: string, comment: string, hidden: boolean) {
  return await saveupClient
    .post(`address`, { name, estateId, street, postalCode, city, number, comment, hidden,})
    .then(handleResponse)
    .catch(err => console.log(err))
}

/**
 ** Update an existing address
 * @param name 
 * @param estateId 
 * @param street 
 * @param postalCode 
 * @param city 
 * @param number 
 * @param comment 
 * @param hidden 
 * @returns 
 */
async function updateAddress(id: number, name: string, estateId: number, street: string, postalCode: string, city: string, number: string, comment: string, hidden: boolean) {
  return await saveupClient
    .put(`address`, { id, name, estateId, street, postalCode, city, number, comment, hidden,})
    .then(handleResponse)
}

/**
 * Delete address by id
 * @param id
 * @returns
 */
async function deleteAddress(id: number) {
  return await saveupClient.delete(`address/${id}`).then(handleResponse)  
}

/**
 ** Get all meter types present at child nodes of given address.
 * @param addressId 
 * @returns 
 */
async function getMeterTypesAtAddress(addressId: number) {
  return await saveupClient.get<IMeterType[]>(`address/${addressId}/meter/type`).then(handleResponse)
}

/**
 ** Get periodic consumption within timespan for given address id and meter type id.
 * @param id 
 * @param meterTypeId 
 * @param startDate 
 * @param stopDate 
 * @param useDegreeDayCorrection 
 * @returns 
 */
async function getConsumption(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {
  //const adjustedStartDate = new Date(startDate.getFullYear() - 1, 0, 1, 0, 0, 0)
  return await saveupClient
    .get<IConsumption>(
      `Consumption/Summary?id=${id}&type=ADDRESS&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}`
    )
    .then(handleResponse)
}
/**
 * Get consumption, cost and emissions for address node.
 * @param id 
 * @param meterTypeId 
 * @param startDate 
 * @param stopDate 
 * @param useDegreeDayCorrection 
 * @returns 
 */
async function getConsumptionCostEmission(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {  
  return await saveupClient
  .get<IConsumptionNodeDto>(`consumption/cost?id=${id}&type=ADDRESS&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&round=true`)
  .then(handleResponse)
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @param targetUnit
 * @returns IConsumption[]
 */
async function getNodeConsumption(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=ADDRESS&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&round=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span with estimates.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @param targetUnit
 * @returns IConsumption[]
 */
async function getNodeConsumptionAndEstimate(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=ADDRESS&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&estimate=true&round=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
/**
 ** Get all reading logs within provided periods for given address id.
 * @param addressId 
 * @param meterTypeId 
 * @param fromYear 
 * @param fromMonth 
 * @param toYear 
 * @param toMonth 
 * @returns 
 */
async function getReadingTableLogs(addressId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient
    .get<ITableLog>(`address/counter/value/log/period/meter?addressId=${addressId}&meterTypeId=${meterTypeId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`)
    .then(handleResponse)
}

/**
 * Get all consumption logs within provided periods for given address id.
 * @param addressId 
 * @param meterTypeId 
 * @param fromYear 
 * @param fromMonth 
 * @param toYear 
 * @param toMonth 
 * @returns 
 */
async function getConsumptionTableLogs(addressId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient
    .get<ITableLog>(`address/counter/consumption/log/period/meter?addressId=${addressId}&meterTypeId=${meterTypeId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`)
    .then(handleResponse)
}
async function getPath(id: number) {
  return await saveupClient.get<IAddressPathDto>(`address/${id}/path`).then(handleResponse)
}
async function getBreadCrumbPath(id: number) {
  let paths: IBreadCrumbPath[] = []
  const dto: IAddressPathDto = await getPath(id)
  paths.push({
    id: dto.estate.owner.id,
    label: isNullOrWhiteSpace(dto.estate.owner.name) ? dto.estate.owner.id.toString() : dto.estate.owner.name,
    nodeType: OWNER
  })
  paths.push({
    id: dto.estate.id,
    label: `${isNullOrWhiteSpace(dto.estate.name) ? dto.estate.id.toString() : dto.estate.name}${
      isNullOrWhiteSpace(dto.estate.rekylId) ? '(' + dto.estate.rekylId + ')' : ''
    }`,
    nodeType: ESTATE
  })
  paths.push({id: dto.id, label: isNullOrWhiteSpace(dto.name) ? dto.id.toString() : dto.name, nodeType: ADDRESS})  
  return paths;
}
async function searchAddresses(keyword: string) {
  return await saveupClient.get<AddressSearchResultDto[]>(`/address/search?keyword=${keyword}`).then(handleResponse)
}

const addressService = {
  getAddress,  
  getMeterTypesAtAddress,
  getConsumption,
  getConsumptionCostEmission,
  getNodeConsumption,
  getNodeConsumptionAndEstimate,
  getReadingTableLogs,
  getConsumptionTableLogs,
  getPath,
  getBreadCrumbPath,
  createAddress,
  updateAddress,
  deleteAddress,
  searchAddresses
}

export default addressService
