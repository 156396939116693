import { Dispatch } from "redux"
import { ActionTypes } from "../office/types"
import { OfficeMeterEmission } from "./interfaces"
import officeMeterEmissionService from "../../services/officeMeterEmissionService"

export const getOfficeMeterEmissions = (officeId: number, meterId: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.GET_OFFICE_METER_EMISSIONS_LOADING})
    try {
      const meterEmissions: OfficeMeterEmission[] = await officeMeterEmissionService.getOfficeMeterEmissions(officeId, meterId)
      return dispatch({type: ActionTypes.GET_OFFICE_METER_EMISSIONS_SUCCESS, payload: meterEmissions})
    } catch (error) {
      return dispatch({type: ActionTypes.GET_OFFICE_METER_EMISSIONS_FAILED, payload: error})
    }
  }
  export const createOfficeMeterEmission = (officeId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.CREATE_OFFICE_METER_EMISSION_LOADING})
    try {
      const createdMeterCost: OfficeMeterEmission = await officeMeterEmissionService.createOfficeMeterEmission(officeId, meterId, currencyId, cost, startDate, stopDate)
      return dispatch({type: ActionTypes.CREATE_OFFICE_METER_EMISSION_SUCCESS, payload: createdMeterCost})
    } catch (error) {
      return dispatch({type: ActionTypes.CREATE_OFFICE_METER_EMISSION_FAILED, payload: error})
    }
  }
  export const updateOfficeMeterEmission = (id: number, officeId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.UPDATE_OFFICE_METER_EMISSION_LOADING})
    try {        
      const updatedMeterCost: OfficeMeterEmission = await officeMeterEmissionService.updateOfficeMeterEmission(id, officeId, meterId, currencyId, cost, startDate, stopDate)
      return dispatch({type: ActionTypes.UPDATE_OFFICE_METER_EMISSION_SUCCESS, payload: updatedMeterCost})
    } catch (error) {
      return dispatch({type: ActionTypes.UPDATE_OFFICE_METER_EMISSION_FAILED, payload: error})
    }
  }
  export const deleteOfficeMeterEmission = (id: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.DELETE_OFFICE_METER_EMISSION_LOADING})
    try {
      await officeMeterEmissionService.deleteOfficeMeterEmission(id)
      return dispatch({type: ActionTypes.DELETE_OFFICE_METER_EMISSION_SUCCESS, payload: id})
    } catch (error) {
      return dispatch({type: ActionTypes.DELETE_OFFICE_METER_EMISSION_FAILED, payload: error})
    }
  }