import saveupClient, {handleError, handleResponse} from '../clients/saveupClient'
import {ICounterReset, ICounterValue, ICounterValueLog} from '../redux/counter/interfaces'
import {ICounterValueSaveParameter, ICounterValuesSaveParameter, ICounterValuesUpdateParameter, ICounterValueUpdateParameter} from '../redux/counterValue/interfaces'

async function getCounterValueLog(id: number) {
  const result = await saveupClient.get<ICounterValueLog>(`Counter/Value/${id}/Log`).then(handleResponse)
  return result
}
async function getCounterReset(id: number) {
  const result = await saveupClient.get<ICounterReset>(`Counter/Value/${id}/Reset`).then(handleResponse)
  return result
}
async function saveCounterValue(value: ICounterValueSaveParameter) {
  const result = await saveupClient
    .post<ICounterValue>(`Counter/Value/Save`, value)
    .then(handleResponse)
    .catch((error) => console.log(error))
  return result
}
async function updateCounterValue(value: ICounterValueUpdateParameter) {
  const result = await saveupClient
    .put<ICounterValue>(`Counter/Value`, value)
    .then(handleResponse)
    .catch((error) => console.log(error))
  return result
}
async function saveCounterValues(value: ICounterValuesSaveParameter) {
  const result = await saveupClient
    .post<ICounterValue>(`Counter/Values/Save`, value)
    .then(handleResponse)
    .catch((error) => console.log(error))
  return result
}
async function updateCounterValues(value: ICounterValuesUpdateParameter) {
  const result = await saveupClient
    .put<ICounterValue>(`Counter/Values`, value)
    .then(handleResponse)
    .catch((error) => console.log(error))
  return result
}
async function deleteCounterValue(id: number) {
  return await saveupClient
    .delete(`counter/value/${id}`)
    .then(handleResponse)
    .catch((error) => console.log(error))
}
async function saveCounterReset(counterValueId: number, changed: boolean ,turned: boolean, lastValue: number, startValue: number) {
  return await saveupClient.post("counter/reset", { counterValueId, turned, changed, lastValue, startValue})
  .then(handleResponse)
}
async function saveCounterValueLog(counterValueId: number, text: string, tag: string) {
  return await saveupClient.post<ICounterValueLog>(`counter/value/log?counterValueId=${counterValueId}&text=${text}&tag=${tag}`)
  .then(handleResponse)
}
const counterValueService = {
  getCounterValueLog,
  getCounterReset,
  saveCounterValue,
  saveCounterValues,
  deleteCounterValue,
  updateCounterValue,
  updateCounterValues,
  saveCounterReset,
  saveCounterValueLog
}
export default counterValueService
