import ReportModal from '../../report/ReportModal'
import {Button, FormControl, FormControlLabel, Grid, LinearProgress, MenuItem, Select, Switch, Typography} from '@mui/material'
import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'
import {OwnerReportsProps} from '../../../redux/owner/ownerInterfaces'
import {getEstatesByOwnerId} from '../../../redux/owner/ownerActions'
import {getAllUnits} from '../../../redux/unit/unitActions'
import estateService from '../../../services/estateService'
import {IEstate} from '../../../redux/estate/Interfaces'
import {generateCsvFile, generateTextFile, getOfficeFilterString, isNullOrWhiteSpace, jsonToCsv, parseStopDate, parseStopDateYearly} from '../../../shared/utils/utilities'
import {IMeterType} from '../../../redux/meter/interfaces'
import {isNumber} from 'lodash'
import {AREA, ATEMP, CONSUMPTION, COST, CSV, CURRENCY_SEK, EMISSION, EMISSION_KG_CO2, ESTATE, EXTERNAL, LOA, M_EXP_2, OWNER, PDF} from '../../../shared/utils/constants'
import {getEmissionByName} from '../../../redux/emission/actions'
import {getCurrencyByName} from '../../../redux/currency/actions'
import pdfService from '../../../services/pdfService'
import {IConsumptionNodePDF} from '../../../redux/pdf/interfaces'
import OwnerPdfReport from './pdf/OwnerPdfReport'
import ReactToPrint, {useReactToPrint} from 'react-to-print'
import {clearPdfTableState} from '../../../redux/pdf/actions'
import reportService from '../../../services/reportService'
import { CustomReportParameter, IReportDefinition, ReportPreview } from '../../../redux/report/interfaces'
const OwnerReports = (props: OwnerReportsProps) => {
  const [csvReport, toggleCsvReport] = useState(false)
  const [pdfReport, togglePdfReport] = useState(false)
  const [externalReport, toggleExternalReport] = useState(false)
  const [exportTemplate, setExportTemplate] = useState('mestro')
  const [metertype, setMeterType] = useState<number>(0)
  const [areaReport, toggleAreaReport] = useState(false)
  const pdfRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const types = useSelector((state: RootState) => state.filter.types ?? [])
  const units = useSelector((state: RootState) => state.unit.units)
  const estates = useSelector((state: RootState) => state.owner.selectedOwner?.estates) ?? []
  const ownerName = useSelector((state: RootState) => state.owner.selectedOwner?.owner?.name) ?? ''
  const meterTypes = useSelector((state: RootState) => state.owner.selectedOwner?.meterTypes) ?? []
  const currentlyListedOwners = useSelector((state: RootState) => state.tree.filteredTree)
  const currency = useSelector((state: RootState) => state.currency.selectedCurrency)
  const emission = useSelector((state: RootState) => state.emission.selectedEmission)
  const pdfTableStates = useSelector((state: RootState) => state.pdf.pdfTableStates)
  const selectedOffice = useSelector((state: RootState) => state.tree.selectedOffice)

  const [printFormat, setPrintFormat] = useState<string>('HTML')
  const [pdfData, setPdfData] = useState<IConsumptionNodePDF[] | undefined>([])
  const [isUsingDegreeDayAdjustment, setIsUsingDegreeDayAdjustment] = useState<boolean>(false)
  const [canGeneratePdf, setCanGeneratePdf] = useState<boolean>(false)
  const [reportType, setReportType] = useState<string>(CONSUMPTION)
  // const [allTablesLoaded, setAllTablesLoaded] = useState<boolean>(false)

  const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: always;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }
  `

  const reactToPrint = useReactToPrint({
    content: () => pdfRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      setCanGeneratePdf(false)
      setPdfData(undefined)
    },
  })
  useEffect(() => {
    setPdfData(undefined)
    dispatch(getEstatesByOwnerId(props.id))
    dispatch(getAllUnits())
    dispatch(getEmissionByName(EMISSION_KG_CO2))
    dispatch(getCurrencyByName(CURRENCY_SEK))
  }, [props.id])

  useEffect(() => {
    if (pdfData && pdfData.length > 0) {
      if (printFormat === 'PDF') {
        const generatePdf = async () => {
          const fileName = `${props.name}_${new Date().toLocaleString().substring(0, 10)}.pdf`
          await pdfService.exportChartsByContainerReference(fileName, pdfRef)
        }
        generatePdf().then(() => {
          setCanGeneratePdf(false)
        })
      } else {
        reactToPrint()
      }
      dispatch(clearPdfTableState())
    }
  }, [pdfData])

  // useEffect(() => {
  //   console.log(pdfTableStates)
  //   setAllTablesLoaded(pdfTableStates?.length > 0 && pdfTableStates.every(x => x.isReady))
  // }, [pdfTableStates])

  const renderItem = ({id, name}: any, index: number) => {
    return (
      <MenuItem key={index} value={id}>
        {name}
      </MenuItem>
    )
  }

  const createPdf = async (startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    setIsUsingDegreeDayAdjustment(useDegreeDayCorrection)
    if (units?.length === 0 || !currency || !emission) return
    const parsedStopDate = parseStopDateYearly(stopDate)
    let estatesToProcess = []
    if (applyOfficeFilter) {
      let currentlyListedEstates = currentlyListedOwners.map((x) => x.children).flat()
      estatesToProcess = estates.filter((x) => currentlyListedEstates.map((y) => y.data.id).includes(x.id))
    } else {
      estatesToProcess = estates
    }

    const selectedMeterTypes = metertype > 0 ? meterTypes.filter((type: IMeterType) => type.id == metertype) : meterTypes.filter((x) => x.isConsumption)
    setCanGeneratePdf(true)
    const data: IConsumptionNodePDF[] = await pdfService.getOwnerSummaryPdf(estatesToProcess, selectedMeterTypes, startDate, parsedStopDate, useDegreeDayCorrection, units)
    if (data && data.length > 0) {
      setPdfData(data)
    }
  }

  const generateCsv = async (startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    const headers = [
      'Period',
      'Fastighets-Id',
      'Fastighet',
      'Rekyl-Id',
      'Mätartyp',
      'Mätar-Id',
      'Mätarnamn',
      'Mätarnummer',
      'Avläsning',
      'Avläsningsdatum',
      'Förbrukning',
      'Enhet',
      'Graddagsjusterad',
      'ATemp',
      'ATemp_Enhet',
      'LOA',
      'LOA_Enhet',
      'Kostnad',
      'Valuta',
      'Utsläpp',
      'Utsläppsenhet',
    ]
    let estatesToProcess = []
    if (applyOfficeFilter) {
      let currentlyListedEstates = currentlyListedOwners.map((x) => x.children).flat()
      estatesToProcess = estates.filter((x) => currentlyListedEstates.map((y) => y.data.id).includes(x.id))
    } else {
      estatesToProcess = estates
    }

    const data = await Promise.all(
      estatesToProcess.map(async (estate: IEstate) => {
        return await estateService.getEstateCsv(estate.id, startDate, stopDate, useDegreeDayCorrection)
      })
    )
    var flattenedData: any[] = []
    data.forEach((r: any) => {
      r.forEach((sr: any) => {
        const adjustedRow = (({
          period,
          estateId,
          estateName,
          rekylId,
          meterType,
          counterId,
          counterName,
          counterNumber,
          reading,
          readingDate,
          consumption,
          unit,
          degreeDayAdjusted,
          aTemp,
          aTempUnit,
          loa,
          loaUnit,
          cost,
          currency,
          emissionValue,
          emissionType,
        }) => ({
          period,
          estateId,
          estateName,
          rekylId,
          meterType,
          counterId,
          counterName,
          counterNumber,
          reading,
          readingDate,
          consumption,
          unit,
          degreeDayAdjusted,
          aTemp,
          aTempUnit,
          loa,
          loaUnit,
          cost,
          currency,
          emissionValue,
          emissionType,
        }))(sr)
        flattenedData.push(adjustedRow)
      })
    })
    if (data && data.length > 0) {

      
      const fileName =
        props.name?.length > 0
          ? props.name + '_' + startDate.getFullYear() + '-' + startDate.getMonth() + 1 + '_' + stopDate.getFullYear() + '-' + stopDate.getMonth() + 1 + '_' + new Date().toLocaleString().substring(0, 10) + '.csv'
          : props.id + '_' + startDate.getFullYear() + '-' + startDate.getMonth() + 1 + '_' + stopDate.getFullYear() + '-' + stopDate.getMonth() + 1 + '_' + new Date().toLocaleString().substring(0, 10) + '.csv'
      const csvContent = jsonToCsv(flattenedData, headers)
      generateCsvFile(csvContent, fileName)
    } else {
      alert('Ingen data hittades för vald fastighetsägare.')
    }
  }

  const generateExternalReport = async (eomStartDate: Date, eomStopdate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    const startDate = eomStartDate
    const stopDate = eomStopdate
    let estatesToProcess = []
    if (applyOfficeFilter) {
      let currentlyListedEstates = currentlyListedOwners.map((x) => x.children).flat()
      estatesToProcess = estates.filter((x) => currentlyListedEstates.map((y) => y.data.id).includes(x.id))
    } else {
      estatesToProcess = estates
    }
    if (exportTemplate == 'mestro') {
      const headers = ['Key', 'Avläsningsdatum', 'Mätarställning', 'Förbrukning', 'Enhet', 'Upplösning', 'Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Räkneverk']
      const data = await Promise.all(
        estatesToProcess.map(async (estate: IEstate) => {
          return await estateService.getMestroReport(estate.id, startDate, stopDate, useDegreeDayCorrection)
        })
      )
      if (data && data.length > 0) {
        let currDate = new Date().toLocaleString().substring(0, 10)
        let fileName = 'Fastighetssnabben' + '_' + props.name + '_manual_' + currDate + '.csv'
        let minDate = new Date('0001-01-01')
        const parsedData: any[] = []
        data.forEach((r: any) => {
          if (r !== undefined) {
            r.forEach((fsr: any) => {
              //sr.meterType = getUnitNameByTemplate(sr.meterType, template);
              const sr = (({key, date, reading, consumption, unit, resolution, ownerName, estateName, addressName, measurePointName, counterName}) => ({
                key,
                date,
                reading,
                consumption,
                unit,
                resolution,
                ownerName,
                estateName,
                addressName,
                measurePointName,
                counterName,
              }))(fsr)
              if (sr.unit.toLowerCase() === 'kw/h') {
                sr.unit = 'kWh'
              }
              if (new Date(sr.date) > minDate) {
                parsedData.push(sr)
              }
            })
          }
        })
        let csvContent = jsonToCsv(parsedData, headers, false)
        generateCsvFile(csvContent, fileName)
      } else {
        alert('Ingen data hittades för vald fastighetsägare.')
      }
    } else if (exportTemplate === 'format1') {
      console.log('Building vitec format 1 report')
      const data = await Promise.all(
        estatesToProcess.map(async (estate: IEstate) => {
          return await estateService.getVitecFormat1Report(estate.id, startDate, stopDate)
        })
      )
      if (data && data.length > 0) {
        let currDate = new Date().toLocaleString().substring(0, 10)
        let fileName = 'Fastighetssnabben' + '_' + ownerName + '_manual_' + currDate + '.txt'
        let trimmedRows: any[] = []
        data.forEach((r: any) => {
          const lines = r.split('\r')
          lines.forEach((l: any) => {
            if (l.trim().length > 0) {
              trimmedRows.push(l.trim() + '\r')
            }
          })
        })
        generateTextFile(trimmedRows.join(''), fileName)
      } else {
        alert('Ingen data hittades för vald fastighetsägare.')
      }
    } else if (exportTemplate === 'format6') {
      console.log('Building vitec format 6 report')
      const data = await Promise.all(
        estatesToProcess.map(async (estate: IEstate) => {
          return await estateService.getVitecFormat6Report(estate.id, startDate, stopDate)
        })
      )
      if (data && data.length > 0) {
        let currDate = new Date().toLocaleString().substring(0, 10)
        let fileName = 'Fastighetssnabben' + '_' + ownerName + '_manual_' + currDate + '.txt'
        let trimmedRows: any[] = []
        data.forEach((r: any) => {
          if (!isNullOrWhiteSpace(r)) {
            const lines = r.split('\r')
            lines.forEach((l: any) => {
              if (l.trim().length > 0) {
                trimmedRows.push(l.trim() + '\r')
              }
            })
          }
        })
        generateTextFile(trimmedRows.join(''), fileName)
      } else {
        alert('Ingen data hittades för vald fastighetsägare.')
      }
    }
  }
  const generateAreaCsv = async (startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    const reports = await reportService.getReports()
    const selectedReport = reports.find((x: IReportDefinition) => x.name.toLocaleUpperCase() === 'ESTATE_AREAS_CONSUMPTION')
    let estatesToProcess : number[] = []
    let reportParameter: CustomReportParameter = {name: '', parameters: [], filter: {key: '', value: ''}}
    if (selectedReport) {
      // If owner estates should be filtered on selected office
      if (applyOfficeFilter) {
        // Get the id of estates that are currently listed in the tree
        let currentlyListedEstates = currentlyListedOwners.map((x) => x.children).flat()
        // Get distinct 'id' of estates that are currently listed in the tree
        estatesToProcess = estates.filter((x) => currentlyListedEstates.map((y) => y.data.id).includes(x.id)).map((x) => x.id)
      }
      else {
        // Get distinct 'id' of estates.
        estatesToProcess = estates.map((x) => x.id)
      }


      reportParameter = {
        name: selectedReport.name,
        parameters: [
          {name: 'fromYear', value: startDate.getFullYear().toString()},
          {name: 'fromMonth', value: (startDate.getMonth() + 1).toString()},
          {name: 'toYear', value: stopDate.getFullYear().toString()},
          {name: 'toMonth', value: (stopDate.getMonth() + 1).toString()},
        ],
        filter: {key: 'OFFICE', value: getOfficeFilterString(selectedOffice)}, // value: useOfficeFilter ? officeFilter : 'ALL'},
      }


      
      const preview: ReportPreview = {
        name: 'ESTATE_AREAS_CONSUMPTION',
        numberOfReportObjects: estatesToProcess.length,
        uniqueObjectType: ESTATE,
        uniqueObjectIds: estatesToProcess
      }
      if (preview) {
        await reportService.generateBatchReports(selectedReport, preview, reportParameter, startDate, stopDate)
      }
      
    }
  }
  return (
    <div>
      <Grid container direction='row' justifyContent='left' spacing={3}>
        <Grid item>
          <Button variant='contained' onClick={() => toggleCsvReport(true)}>
            CSV - Mätarställning per period
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => togglePdfReport(true)}>
            PDF - Graf & Tabell per fastighet
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => toggleExternalReport(true)}>
            CSV/XML - Externt system
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => toggleAreaReport(true)}>
            CSV - Fastighetsytor
          </Button>
        </Grid>
      </Grid>
      <ReportModal id={props.id} open={csvReport} onClose={() => toggleCsvReport(false)} title='Export till CSV' actions={{run: generateCsv}} reportType={CSV} />
      <ReportModal id={props.id} open={pdfReport} onClose={() => togglePdfReport(false)} title='Export till PDF' actions={{run: createPdf}} reportType={PDF} isLoading={canGeneratePdf}>
        <Grid container direction='row' justifyContent='left' spacing={3}>
          <Grid item>
            <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
              <Select
                sx={{flexGrow: 1, width: '180px', color: '#3E5060', margin: '5px'}}
                label='Mätartyp'
                className='type-select'
                value={metertype}
                defaultValue={0}
                onChange={(item, index) => {
                  if (isNumber(item.target.value)) {
                    setMeterType(item.target.value)
                  }
                }}>
                <MenuItem value={0} key={0}>
                  Alla mätartyper
                </MenuItem>
                {types.map((t, i) => renderItem(t, i + 1))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
              <Select
                sx={{flexGrow: 1, width: '180px', color: '#3E5060', margin: '5px'}}
                label='Typ av rapport'
                className='type-select'
                value={reportType}
                onChange={(item, index) => {
                  setReportType(item.target.value)
                }}>
                <MenuItem value={CONSUMPTION} key={CONSUMPTION}>
                  Förbrukning
                </MenuItem>
                <MenuItem value={COST} key={COST}>
                  Kostnad
                </MenuItem>
                <MenuItem value={EMISSION} key={EMISSION}>
                  Utsläpp
                </MenuItem>
                <MenuItem value={ATEMP} key={ATEMP}>
                  ATemp ({M_EXP_2})
                </MenuItem>
                <MenuItem value={LOA} key={LOA}>
                  LOA ({M_EXP_2})
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
              <Select
                sx={{flexGrow: 1, width: '180px', color: '#3E5060', margin: '5px'}}
                label='Utskriftsformat'
                className='type-select'
                value={printFormat}
                onChange={(item, index) => {
                  setPrintFormat(item.target.value)
                }}>
                <MenuItem value='PDF' key={0}>
                  PDF (långsam)
                </MenuItem>
                <MenuItem value='HTML' key={1}>
                  Utskriftsvy
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </ReportModal>
      <ReportModal id={props.id} open={externalReport} onClose={() => toggleExternalReport(false)} actions={{run: generateExternalReport}} title='Externa rapporter' reportType={EXTERNAL}>
        <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
          <Select
            sx={{flexGrow: 1, width: '250px', color: '#3E5060', margin: '5px'}}
            label='Export-mall'
            className='type-select'
            value={exportTemplate}
            defaultValue='mestro'
            onChange={(e) => setExportTemplate(e.target.value)}>
            <MenuItem value='mestro' key={1}>
              Mestro
            </MenuItem>
            <MenuItem value='format1' key={2}>
              Vitec Format 1 - Mätarställningar
            </MenuItem>
            <MenuItem value='format6' key={3}>
              Vitec Format 6 - Förbrukningar
            </MenuItem>
          </Select>
        </FormControl>
      </ReportModal>
      <ReportModal id={props.id} open={areaReport} onClose={() => toggleAreaReport(false)} title='Export till CSV' actions={{run: generateAreaCsv}} reportType={AREA} />
      <div ref={pdfRef} style={{display: 'block'}}>
        {canGeneratePdf && pdfData && emission && currency && (
          <OwnerPdfReport
            data={pdfData}
            ownerId={props.id}
            ownerName={props.name}
            units={units}
            emissions={[emission]}
            currencies={[currency]}
            isUsingDegreeDayAdjustment={isUsingDegreeDayAdjustment}
            category={reportType}
          />
        )}
      </div>
    </div>
  )
}
export default OwnerReports
