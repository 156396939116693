import {useState, ChangeEvent, useContext, useEffect} from 'react'
import {Card, Grid, ListItem, List, ListItemText, Divider, Switch} from '@mui/material'
import {ThemeContext} from '../../shared/theme/ThemeProvider'

function NotificationsTab() {
  const {themeName, setThemeName} = useContext(ThemeContext)
  const [state, setState] = useState({
    hideGraphs: false,
    showVolume: false,
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const settings = { ...state,  [event.target.name]: event.target.checked}
    setState(settings)
    const storedJson = localStorage.getItem('localSettings')
    if (!storedJson || JSON.stringify(settings) !== storedJson) {
      localStorage.setItem('localSettings', JSON.stringify(settings))
    }
  }

  useEffect(() => {
    const storedJson = localStorage.getItem('localSettings')
    if (storedJson) {
      setState(JSON.parse(storedJson))
    }
  }, [])

  const changeTheme = () => {
    themeName === 'DarkTheme' ? setThemeName('DefaultsTheme') : setThemeName('DarkTheme')
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <List>
            <ListItem sx={{p: 3}}>
              <ListItemText
                primaryTypographyProps={{variant: 'h5', gutterBottom: true}}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary='Förbrukningsgrafer'
                secondary='Dölj förbrukningsgrafer'
              />
              <Switch color='primary' checked={state.hideGraphs} onChange={handleChange} name='hideGraphs' />
            </ListItem>
            <Divider component='li' />
            <ListItem sx={{p: 3}}>
              <ListItemText
                primaryTypographyProps={{variant: 'h5', gutterBottom: true}}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary='Enhetstyp för vatten'
                secondary='Visa enhetstyp för vatten som m3 istället för liter vid visning av LOA och ATEMP.'
              />
              <Switch color='primary' checked={state.showVolume} onChange={handleChange} name='showVolume' />
            </ListItem>
            <Divider component='li' />
            <ListItem sx={{p: 3}}>
              <ListItemText
                primaryTypographyProps={{variant: 'h5', gutterBottom: true}}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1,
                }}
                primary='Mörk/ljusläge'
                secondary='Ställ in appens utseende i mörkt eller ljust läge'
              />
              <Switch color='primary' defaultChecked={themeName === 'DarkTheme' ? true : false} onChange={changeTheme} />
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  )
}

export default NotificationsTab
