import {Dispatch} from 'redux'
import logService from '../../services/logService'
import {ActionTypes} from './homeTypes'
import { isNullOrWhiteSpace } from '../../shared/utils/utilities'
import { CounterValueLogTag, ICounterConsumptionType } from './homeInterfaces'
import { Action } from '@remix-run/router'

export const getLogs = (year: number, month: number, office: string | null | undefined) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_ALL_LOGS_LOADING })
  const result = isNullOrWhiteSpace(office) ? await logService.getCounterValueLogs(year, month) : await logService.getCounterValueLogsByOffice(year, month, office!)
  if (result) {
    return dispatch({type: ActionTypes.GET_ALL_LOGS_SUCCESS, payload: result})
  } else {
    return dispatch({type: ActionTypes.GET_ALL_LOGS_FAILED})
  }
}
export const getDeviationStates = (ids: ICounterConsumptionType[]) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_DEVIATIONS_LOADING })
  const result = await logService.getDeviationStates(ids)  
  if (result) {
    return dispatch({type: ActionTypes.GET_DEVIATIONS_SUCCESS, payload: result})
  } else {
    return dispatch({type: ActionTypes.GET_DEVIATIONS_FAILED})
  }
}
export const loadDeviationFilterState = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionTypes.GET_DEVIATION_FILTER_STATE_LOADING})
  try {
    const storedFilter = JSON.parse(localStorage.getItem("deviationFilter") ?? '')
    return dispatch({ type: ActionTypes.GET_DEVIATION_FILTER_STATE_SUCCESS, payload: storedFilter })
  }
  catch {
    return dispatch({ type: ActionTypes.GET_DEVIATION_FILTER_STATE_FAILED })
  }
}
export const updateLogTags = (logId: number, tags: string[]) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.UPDATE_LOG_TAGS_LOADING})
  try {
    const result: CounterValueLogTag[] = await logService.updateLogTags(logId, tags)
    return dispatch({type: ActionTypes.UPDATE_LOG_TAGS_SUCCESS, payload: { logId, tags}})
  } catch (error) {
    return dispatch({type: ActionTypes.UPDATE_LOG_TAGS_FAILED, payload: error})
  }
}
