import {Cancel, NumbersRounded, Save} from '@mui/icons-material'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {hasEditPermissions, isAdmin} from '../../shared/utils/utilities'
import {ICurrency, IMeterCostCurrency} from '../../redux/meter/interfaces'
import {Controller, useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import { DesktopDatePicker} from '@mui/x-date-pickers'
import { IUnit } from '../../redux/unit/unitInterfaces'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export interface IMeterCostInputDialog {
  item?: IMeterCostCurrency | undefined
  defaultCurrency: ICurrency
  open: boolean
  unit: IUnit | undefined
  onConfirm: (data: IMeterCostInputForm) => void
  onClose: () => void
}

export interface IMeterCostInputForm {
  id: number
  cost: number
  currencyId: number
  startDate: Date
  stopDate: Date
}

const MeterCostInputDialog = (props: IMeterCostInputDialog) => {
  const {register, control, handleSubmit, reset} = useForm<IMeterCostInputForm>({
    defaultValues: {
      id: props.item ? props.item.id : 0,
      cost: props.item ? props.item.cost : 0,
      currencyId: props.item ? props.item.currencyId : props.defaultCurrency.id,
      startDate: props.item ? props.item.startDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      stopDate: props.item ? props.item.stopDate : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
    },
  })
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() =>{
    if(props.item) {
      reset({
        id: props.item ? props.item.id : 0,
        cost: props.item ? props.item.cost : 0,
        currencyId: props.item ? props.item.currencyId : props.defaultCurrency.id,
        startDate: props.item ? props.item.startDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        stopDate: props.item ? props.item.stopDate : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
      })
    }
  }, [props.item])
  
  const renderTitle = () => {
    return <Typography variant='h3'>{props.item?.id === 0 ? 'Ny prisuppgift' : 'Redigera prisuppgift'}</Typography>
  }
  const onSubmit = (data: IMeterCostInputForm) => {
    const cost = parseFloat(data.cost.toString())
    const startDate = new Date(data.startDate)
    const stopDate = new Date(data.stopDate)
    props.onConfirm({...data, id: props.item ? props.item.id : 0, cost, startDate, stopDate })
  }
  return (
    <div>
      <form>
        <Dialog sx={{style}} open={props.open}>
          <DialogTitle>{renderTitle()}</DialogTitle>
          <DialogContent>
            <Grid>
              <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                <Grid xs={12} item>
                  <Controller
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        id='cost'
                        label={`Pris (SEK / ${props.unit?.comment})`}
                        type='number'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                    name='cost'
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                <Grid xs={12} item>
                  <Controller
                    render={({field}) => <DesktopDatePicker label='Från' {...field} renderInput={(params: any) => <TextField {...params} fullWidth views={['year', 'month']} />} />}
                    name='startDate'
                    control={control}
                    defaultValue={new Date()}
                  />
                </Grid>
              </Grid>
              <Grid container direction='row' alignItems='stretch' spacing={3} sx={{marginTop: '2px'}}>
                <Grid xs={12} item>
                  <Controller
                    render={({field}) => <DesktopDatePicker label='Till' {...field} renderInput={(params: any) => <TextField {...params} fullWidth views={['year', 'month']} />} />}
                    name='stopDate'                    
                    control={control}
                    defaultValue={new Date()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} disabled={!hasAdminRights} onClick={handleSubmit(onSubmit)}>
              Spara
            </Button>
            <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
              Avbryt
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  )
}
export default MeterCostInputDialog
