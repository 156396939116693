export enum ActionTypes {
  FETCH_CASE_LOADING = 'FETCH_CASE_LOADING',
  FETCH_CASE_SUCCESS = 'FETCH_CASE_SUCCESS',
  FETCH_CASE_FAILED = 'FETCH_CASE_FAILED',
  FETCH_CASESTATUS_LOADING = 'FETCH_CASESTATUS_LOADING',
  FETCH_CASESTATUS_SUCCESS = 'FETCH_CASESTATUS_SUCCESS',
  FETCH_CASESTATUS_FAILED = 'FETCH_CASESTATUS_FAILED',
  FETCH_CASETYPES_LOADING = 'FETCH_CASETYPES_LOADING',
  FETCH_CASETYPES_SUCCESS = 'FETCH_CASETYPES_SUCCESS',
  FETCH_CASETYPES_FAILED = 'FETCH_CASETYPES_FAILED',
  UPDATE_CASE_LOADING = 'UPDATE_CASE_LOADING',
  UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS',
  UPDATE_CASE_FAILED = 'UPDATE_CASE_FAILED',
  CREATE_CASE_LOADING = 'CREATE_CASE_LOADING',
  CREATE_CASE_SUCCESS = 'CREATE_CASE_SUCCESS',
  CREATE_CASE_FAILED = 'CREATE_CASE_FAILED',
  CREATE_POSTS_LOADING = 'CREATE_POSTS_LOADING',
  CREATE_POSTS_SUCCESS = 'CREATE_POSTS_SUCCESS',
  CREATE_POSTS_FAILED = 'CREATE_POSTS_FAILED',
}
