import {IUnitState, IUnit} from './unitInterfaces'
import {Reducer} from 'redux'
import {ActionTypes} from './unitTypes'

export const initialState: IUnitState = {
  isLoading: false,
  selectedUnit: undefined,
  units: [],
  errorMessage: undefined,
}
export const initUnit: IUnit = {
  id: 0,
  name: '',
  comment: '',
  insDate: new Date(),
  updDate: new Date(),
}

export const unitReducer: Reducer<IUnitState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.GET_UNIT_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_UNIT_SUCCESS:
      return {...state, isLoading: false, selectedUnit: payload}
    case ActionTypes.GET_UNIT_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.GET_UNITS_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_UNITS_SUCCESS:
      return {...state, isLoading: false, units: payload}
    case ActionTypes.GET_UNITS_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.EDIT_UNIT_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.EDIT_UNIT_SUCCESS:
      return {...state, isLoading: false, data: payload}
    case ActionTypes.EDIT_UNIT_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.CREATE_UNIT_LOADING:
      return {...state, isLoading: true, selectedUnit: payload}
    case ActionTypes.CREATE_UNIT_SUCCESS:
      return {...state, isLoading: false, selectedUnit: payload}
    case ActionTypes.CREATE_UNIT_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.DELETE_UNIT_SUCCESS:
      return {...state, isLoading: true, errorMessage: payload.errorMessage}
    default:
      return state
  }
}
