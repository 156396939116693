import { Reducer } from "redux";
import { ICurrencyState } from "./interfaces";
import { ActionTypes } from "./types";

const initialCurrencyState: ICurrencyState = {
    isLoading: false,
    selectedCurrency: undefined
}
export const currencyReducer: Reducer<ICurrencyState> = (state = initialCurrencyState, {type, payload}) =>{
    switch(type) {
        case ActionTypes.FETCH_CURRENCY_LOADING:
            return { ...state, isLoading: true}
        case ActionTypes.FETCH_CURRENCY_SUCCESS:
            return { ...state, isLoading: false, selectedCurrency: payload }
        case ActionTypes.FETCH_CURRENCY_FAILED:
            return { ...state, isLoading: false }
        default:
            return state
    }
}
