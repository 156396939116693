import { Dispatch } from "redux"
import { ActionTypes } from "../estate/types"
import { IEstateMeterCost } from "./interface"
import estateMeterCostService from "../../services/estateMeterCostService"

export const getEstateMeterCosts = (estateId: number, meterId: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.GET_ESTATE_METER_COST_LOADING})
    try {
      const meterCosts: IEstateMeterCost[] = await estateMeterCostService.getEstateMeterCosts(estateId, meterId)
      return dispatch({type: ActionTypes.GET_ESTATE_METER_COST_SUCCESS, payload: meterCosts})
    } catch (error) {
      return dispatch({type: ActionTypes.GET_ESTATE_METER_COST_FAILED, payload: error})
    }
  }
  export const createEstateMeterCost = (estateId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.CREATE_ESTATE_METER_COST_LOADING})
    try {
      const createdMeterCost: IEstateMeterCost = await estateMeterCostService.createEstateMeterCost(estateId, meterId, currencyId, cost, startDate, stopDate)
      return dispatch({type: ActionTypes.CREATE_ESTATE_METER_COST_SUCCESS, payload: createdMeterCost})
    } catch (error) {
      return dispatch({type: ActionTypes.CREATE_ESTATE_METER_COST_FAILED, payload: error})
    }
  }
  export const updateEstateMeterCost = (id: number, estateId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.UPDATE_ESTATE_METER_COST_LOADING})
    try {        
      const updatedMeterCost: IEstateMeterCost = await estateMeterCostService.updateEstateMeterCost(id, estateId, meterId, currencyId, cost, startDate, stopDate)
      return dispatch({type: ActionTypes.UPDATE_ESTATE_METER_COST_SUCCESS, payload: updatedMeterCost})
    } catch (error) {
      return dispatch({type: ActionTypes.UPDATE_ESTATE_METER_COST_FAILED, payload: error})
    }
  }
  export const deleteEstateMeterCost = (id: number) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.DELETE_ESTATE_METER_COST_LOADING})
    try {
      await estateMeterCostService.deleteEstateMeterCost(id)
      return dispatch({type: ActionTypes.DELETE_ESTATE_METER_COST_SUCCESS, payload: id})
    } catch (error) {
      return dispatch({type: ActionTypes.DELETE_ESTATE_METER_COST_FAILED, payload: error})
    }
  }