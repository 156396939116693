import styled from '@emotion/styled'
import {Add, Edit} from '@mui/icons-material'
import {Button, Card, CardContent, CardHeader, Grid } from '@mui/material'
import {DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid'
import {getOwners} from '../../redux/owner/ownerActions'
import { IOwner } from '../../redux/owner/ownerInterfaces'
import {RootState} from '../../redux/rootReducer'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link } from 'react-router-dom'
import { isAdmin } from '../../shared/utils/utilities'

const columns: GridColDef[] = [
  {field: 'id', headerName: 'Id', flex: 1},
  {field: 'name', headerName: 'Namn', flex: 1},
  {field: 'comment', headerName: 'Kommentar', flex: 1},
  {field: 'insDate', headerName: 'Ändrades', flex: 1},
  {field: 'updDate', headerName: 'Uppdaterades', flex: 1},
  {
    width: 10,
    field: 'edit',
    headerName: 'Redigera',
    renderHeader: (params: GridColumnHeaderParams) => <div></div>,
    renderCell: (params: GridRenderCellParams<string>) => (
      <Link to={`/owner/${params.row.id}`}>
        <Edit fontSize='small' />
      </Link>
    ),
  },
]

const OwnerList = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOwners())
  }, [])
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const owners = useSelector((state: RootState) => state.owner.owners)

  const onEditClick = (row: any) => {
    console.log(row)
  }

  const sortOwners = (x: IOwner, y: IOwner) => {
    if(x.name > y.name) return 1
    else if(x.name < y.name) return -1
    else return 0
  }

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  return (
    <div>
      <Card>
        <CardHeader title='Alla fastighetsägare' />
        <CardContent>
          <Grid container>
          <Grid direction='row' container justifyContent='flex-start' >
              {hasAdminRights && <Grid xs={1} item sx={{marginLeft: 'auto'}}>
                <Link to='/owner/create'>
                  <Button variant='contained' color='primary'>
                    <Add />
                    Ny fastighetsägare
                  </Button>
                </Link>
                </Grid>
              }              
            </Grid>
            <Grid direction='row' container sx={{marginTop: '20px'}}>
              <Grid xs={12} item>
                <DataGrid rows={owners} columns={columns} autoHeight onRowClick={(param: any) => onEditClick(param.row)}></DataGrid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}
export default OwnerList
