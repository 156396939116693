import React, {useEffect, useState} from 'react'
import {OfficeEmissionProps} from './interfaces'
import {OfficeMeterEmission} from '../../../redux/officeMeterEmission/interfaces'
import {hasEditPermissions, isAdmin} from '../../../shared/utils/utilities'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'
import {IEmission} from '../../../redux/emission/interfaces'
import {orderBy} from 'lodash'
import {SaveResponse} from '../../../shared/enums/SaveResponse'
import {getEmissionByName} from '../../../redux/emission/actions'
import {EMISSION_KG_CO2, NOT_SET} from '../../../shared/utils/constants'
import {getAllUnits} from '../../../redux/unit/unitActions'
import {OfficeMeterEmissionInputForm} from './officeMeterEmissionInputDialog/interfaces'
import {createOfficeMeterEmission, deleteOfficeMeterEmission, updateOfficeMeterEmission} from '../../../redux/officeMeterEmission/actions'
import {IUnit} from '../../../redux/unit/unitInterfaces'
import OfficeMeterEmissionInputDialog from './officeMeterEmissionInputDialog/OfficeMeterEmissionInputDialog'
import {IMeterType} from '../../../redux/meter/interfaces'
import {Container, Grid} from '@mui/material'
import ResponseAlert from '../../../components/alert/ResponseAlert'
import AddButton from '../../../components/shared/buttons/AddButton'
import OfficeMeterEmissionTabContext from './OfficeMeterEmissionTabContext'
import { getOfficeMeterEmissions } from '../../../redux/officeMeterEmission/actions'
import MessageDialog from '../../../components/shared/alerts/MessageDialog'
import { isOverlapping } from '../../../components/consumption/graph/costEmissionHelper'
const OfficeEmission = (props: OfficeEmissionProps) => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<OfficeMeterEmission | undefined>()
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)
  const [saveResponse, setSaveResponse] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('0')
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const [alreadyExistsDialogOpen, setAlreadyExistsDialogOpen] = useState<boolean>(false)
  const meterTypes = useSelector((state: RootState) => state.filter.types ?? [])
  const units = useSelector((State: RootState) => State.unit.units ?? [])
  let Emissions = useSelector((state: RootState) => {
    const sorted: OfficeMeterEmission[] = orderBy(state.office.officeMeterEmissions.officeMeterEmissions, ['startDate', 'stopDate'], ['asc'])
    return sorted
  })
  const defaultEmission: IEmission | undefined = useSelector((state: RootState) => state.emission.selectedEmission)
  const deletedOfficeMeterEmission = useSelector((state: RootState) => state.office.deleteOfficeMeterEmission.id)
  const createdOfficeMeterEmission = useSelector((state: RootState) => state.office.createOfficeMeterEmission.officeMeterEmission)
  const updatedOfficeMeterEmission = useSelector((state: RootState) => state.office.updateOfficeMeterEmission.officeMeterEmission)

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  useEffect(() => {
    dispatch(getEmissionByName(EMISSION_KG_CO2))
    if (units.length === 0) {
      dispatch(getAllUnits())
    }
  }, [props.officeId])

  useEffect(() => {
    if (selectedTab !== '' && parseInt(selectedTab) !== 0) dispatch(getOfficeMeterEmissions(props.officeId, parseInt(selectedTab)))
  }, [props.officeId, selectedTab])

  useEffect(() => {
    if (meterTypes.length > 0) {
      const firstMeterType: IMeterType = meterTypes[0]
      setSelectedTab(`${firstMeterType.id}`)
    }
  }, [meterTypes])

  const onHandleSaveNewMeterEmissionClick = (event: React.MouseEventHandler<HTMLButtonElement>) => {
    setSelectedRow(undefined)
    setSaveDialogOpen(true)
  }

  const onOfficeMeterEmissionEditClick = (Emission: OfficeMeterEmission) => {
    setSelectedRow(Emission)
    setSaveDialogOpen(true)
  }

  const onOfficeMeterEmissionDeleteClick = (Emission: OfficeMeterEmission) => {
    dispatch(deleteOfficeMeterEmission(Emission.id))
  }

  const onValueConfirmClick = (data: OfficeMeterEmissionInputForm) => {
    if (!hasAdminRights) return

    if(isOverlapping(data.id, data.startDate, data.stopDate, Emissions)) {      
      setAlreadyExistsDialogOpen(true)
      return
    }

    if (data.id > 0) {
      dispatch(updateOfficeMeterEmission(data.id, props.officeId, parseInt(selectedTab), data.emissionId, data.value, data.startDate, data.stopDate))
    } else {
      dispatch(createOfficeMeterEmission(props.officeId, parseInt(selectedTab), defaultEmission ? defaultEmission.id : 0, data.value, data.startDate, data.stopDate))
    }
    setSaveDialogOpen(false)
    setSaveResponse(SaveResponse.PENDING)
  }

  useEffect(() => {
    if (selectedTab !== '' && parseInt(selectedTab) !== 0) dispatch(getOfficeMeterEmissions(props.officeId, parseInt(selectedTab)))
  }, [createdOfficeMeterEmission, updatedOfficeMeterEmission, deletedOfficeMeterEmission])

  const getCreatedOrUpdatedOfficeMeterEmission = () => {
    if (createdOfficeMeterEmission) {
      return `${createdOfficeMeterEmission.id}`
    }
    if (updatedOfficeMeterEmission) {
      return `${updatedOfficeMeterEmission.id}`
    }
    return NOT_SET
  }

  const renderInputDialog = () => {
    if (defaultEmission) {
      const currentMeter = meterTypes.find((x: IMeterType) => x.id === parseInt(selectedTab))
      const unit: IUnit | undefined = units.find((x: IUnit) => x.id === currentMeter?.unitId)
      return (
        <OfficeMeterEmissionInputDialog
          defaultEmission={defaultEmission}
          open={saveDialogOpen}
          item={selectedRow}
          meterId={parseInt(selectedTab)}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={(e: OfficeMeterEmissionInputForm) => onValueConfirmClick(e)}
          unit={unit}
        />
      )
    }
    return ''
  }
  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <ResponseAlert
            response={saveResponse}
            open={alertOpen}
            successMessage={`Prisuppgiften med Id: ${getCreatedOrUpdatedOfficeMeterEmission()} registerades.`}
            failureMessage='Det gick inte att registera prisuppgiften.'
          />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <AddButton onClick={onHandleSaveNewMeterEmissionClick} id='addMeterEmission' label='Nytt utläpp' isAuthorized={hasAdminRights} />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <OfficeMeterEmissionTabContext
            officeId={props.officeId}
            meterTypes={meterTypes}
            units={units}
            officeMeterEmissions={Emissions}
            onOfficeMeterEmissionEditClick={onOfficeMeterEmissionEditClick}
            onOfficeMeterEmissionDeleteClick={onOfficeMeterEmissionDeleteClick}
            onTabChange={(value: string) => setSelectedTab(value)}
          />
        </Grid>
      </Grid>
      {renderInputDialog()}
      <MessageDialog open={alreadyExistsDialogOpen} setOpen={(value) => setAlreadyExistsDialogOpen(value)} title='Utsläppsuppgift finns redan' message='Det finns redan ett utsläpp registrerat för detta kontor inom valt tidsspann.' />
    </Container>
  )
}
export default OfficeEmission
