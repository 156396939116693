import ownerService from '../../services/ownerService'
import {ActionTypes} from './ownerTypes'
import {ActionTypes as TreeActionTypes} from '../tree/treeTypes'
import {Dispatch} from 'redux'
import estateService from '../../services/estateService'
import {IMeterType} from '../meter/interfaces'
import {castUnit, processConsumptions, setMeterTypeOrder} from '../../shared/utils/utilities'
import IEstateAttribute, {IOffice} from '../estateAttribute/interfaces'
import {ALL_OFFICES_SWE} from '../../shared/utils/constants'
import configurationService from '../../services/configurationService'
import IAttribute from '../attribute/interfaces'
import attributeService from '../../services/attributeService'
import {IEstate, IEstateListItem} from '../estate/Interfaces'
import { IUnit } from '../unit/unitInterfaces'

export const getOwners = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_ALL_OWNERS_LOADING})
      const data = await ownerService.getOwners()
      return dispatch({type: ActionTypes.FETCH_ALL_OWNERS_OK, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_ALL_OWNERS_ERROR, payload: error})
    }
  }
}

export const createOwner = (name: string, comment: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.CREATE_OWNER_LOADING})
      const createdOwner = await ownerService.createOwner(name, comment)
      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({type: ActionTypes.CREATE_OWNER_OK, payload: createdOwner})
    } catch (error) {
      return dispatch({type: ActionTypes.CREATE_OWNER_ERROR, payload: error})
    }
  }
}

export const editOwner = (id: number, name: string, comment: string, hidden: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.EDIT_OWNER_LOADING})
      const updatedOwner = await ownerService.editOwner(id, name, comment, hidden)
      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({type: ActionTypes.EDIT_OWNER_OK, payload: updatedOwner})
    } catch (error) {
      return dispatch({type: ActionTypes.EDIT_OWNER_ERROR, payload: error})
    }
  }
}

export const getOwnerById = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.GET_SELECTED_OWNER_LOADING})
      const data = await ownerService.getOwnerById(id)
      return dispatch({type: ActionTypes.GET_SELECTED_OWNER_SUCCESS, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.GET_SELECTED_OWNER_ERROR, payload: error})
    }
  }
}

export const deleteOwner = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.DELETE_OWNER_LOADING})
      const data = await ownerService.deleteOwner(id)
      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({type: ActionTypes.DELETE_OWNER_OK, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.DELETE_OWNER_ERROR, payload: error})
    }
  }
}

export const getEstatesByOwnerId = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.GET_OWNER_ESTATES_LOADING})
  try {
    const estates: IEstate[] = await estateService.getEstateByOwnerId(id)
    const estateListItems: IEstateListItem[] = estates.map((estate: IEstate) => ({...estate, category: ''}))
    const attributes: IAttribute[] = await attributeService.getAttributes()
    const estateCategoryAttribute = attributes.find((x: IAttribute) => x.name === 'CATEGORY' && x.class === 'ESTATE')
    if (estateCategoryAttribute) {
      if (estateListItems.length > 3) {
        var allEstateAttributes: IEstateAttribute[] = await estateService.getEstateAttributesByAttributeId(estateCategoryAttribute.id)
        // Append value from each IEstateAttribute in allEstateAttributes as 'category' to each object in estates if the attributeId on IEstateAttribute matches the id of estateCategoryAttribute and the estateId matches the id of the estate
        estateListItems.forEach((estate: any) => {
          var categoryEstateAttribute = allEstateAttributes.find((x: IEstateAttribute) => x.attributeId === estateCategoryAttribute.id && x.estateId === estate.id)
          if (categoryEstateAttribute) estate.category = categoryEstateAttribute.value
        })
      } else {
        // For each object in estates, fetch IEstateAttributes from getEstateAttributes and append the value from the IEstateAttribute with the attributeId of estateCategoryAttribute.id to the object in estates as 'category'
        estateListItems.forEach(async (estate: any) => {
          var estateAttributes = await estateService.getEstateAttributes(estate.id)
          estateAttributes.forEach((x: IEstateAttribute) => {
            if (x.attributeId === estateCategoryAttribute.id) estate.category = x.value
          })
        })
      }
    }
    return dispatch({type: ActionTypes.GET_OWNER_ESTATES_SUCCESS, payload: estateListItems})
  } catch (error) {
    return dispatch({type: ActionTypes.GET_OWNER_ESTATES_ERROR, payload: error})
  }
}

export const getMeterTypesAtOwner = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_OWNER_METER_TYPES_LOADING})
  try {
    let meterTypes = await ownerService.getMeterTypesAtOwner(id)
    const degreeDayCorrectedMeterTypeIds = await configurationService.getDegreeDayCorrectedMeterTypes()
    meterTypes = meterTypes.map((type: IMeterType) => ({...type, canDegreeDayAdjust: degreeDayCorrectedMeterTypeIds.some((x: number) => x === type.id)}))
    const sorted = setMeterTypeOrder(meterTypes)
    return dispatch({type: ActionTypes.FETCH_OWNER_METER_TYPES_SUCCESS, payload: sorted})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_OWNER_METER_TYPES_ERROR, payload: error})
  }
}
export const getConsumption =
  (ownerId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, office: string | undefined = undefined) =>
  async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.FETCH_OWNER_CONSUMPTION_LOADING})
    try {
      let consumptions: any = []
      if (office && office.toUpperCase() !== ALL_OFFICES_SWE) consumptions = await ownerService.getConsumptionByOffice(ownerId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, office)
      else consumptions = await ownerService.getConsumption(ownerId, meterTypeId, startDate, stopDate, useDegreeDayCorrection)
      return dispatch({type: ActionTypes.FETCH_OWNER_CONSUMPTION_SUCCESS, payload: consumptions})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_OWNER_CONSUMPTION_ERROR, payload: error})
    }
}
export const getNodeConsumption =
  (ownerId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, office?: string, shouldEstimate?: boolean, unit?: IUnit, category?: string) => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.FETCH_OWNER_CONSUMPTION_LOADING})
    try {
      let consumptions: any = []
      if (office && office.toUpperCase() !== ALL_OFFICES_SWE)
        consumptions = shouldEstimate
          ? await ownerService.getNodeConsumptionAndEstimateByOffice(ownerId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, office, castUnit(unit))
          : await ownerService.getConsumptionCostEmissionByOffice(ownerId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, office, castUnit(unit))
      else consumptions = await ownerService.getNodeConsumptionAndEstimate(ownerId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
      return dispatch({type: ActionTypes.FETCH_OWNER_CONSUMPTION_SUCCESS, payload: processConsumptions(consumptions, unit, category)})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_OWNER_CONSUMPTION_ERROR, payload: error})
    }
}
