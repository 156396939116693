import IAttribute from '../redux/attribute/interfaces'
import {IEstate, IAreaEstate, IEstatePathDto, IEstateReportRow, EstateCsvRow, IGroup, IMestroConsumptionAndReading} from '../redux/estate/Interfaces'
import IEstateAttribute, {IOffice} from '../redux/estateAttribute/interfaces'
import {getCounterDisplayName, getMeasurePointDisplayName, getUnitByValueCategory, isNullOrWhiteSpace, parseStopDateMonthly, parseStopDateYearly, processConsumptions} from '../shared/utils/utilities'
import saveupClient, {handleError, handleResponse} from '../clients/saveupClient'
import _ from 'lodash'
import {IConsumption} from '../redux/consumption/interfaces'
import {IMeterType} from '../redux/meter/interfaces'
import {ITableLog} from '../redux/counterValue/interfaces'
import {IUser} from '../redux/user/interfaces'
import {IBreadCrumbPath} from '../redux/tree/treeInterfaces'
import {ATEMP, ESTATE, KWH, LITER, LOA, OWNER} from '../shared/utils/constants'
import Area from '../redux/area/Interfaces.ts'

async function getEstates() {
  return await saveupClient.get<IEstate[]>(`/estate`).then(handleResponse)
}

async function getEstatesByName(name: string) {
  return await saveupClient.get<IEstate[]>(`/estate/search/name?name=${name}`).then(handleResponse)
}

/**
 * Get all estates found at owner for current user.
 * @returns IEstate[]
 */
async function getOwnerEstate() {
  const response = await saveupClient.get<IEstate>(`/User/Owner`).then(handleResponse)

  const response2 = await saveupClient.get<IEstate>(`/Owner/${response.data[0].id}/Estate`).then(handleResponse)
  return response2
}

async function getEstateByOwnerId(id: number) {
  const response = await saveupClient.get<IEstate>(`/Owner/${id}/Estate`).then(handleResponse)
  return response
}
/**
 * Fetch all valid offices present at any estate
 * @returns IOffice[]
 */
async function getOffices() {
  const attributes = await saveupClient.get<IAttribute>(`/Attribute`).then(handleResponse)
  const officeAttribute = attributes.filter((x: IAttribute) => x.name.toUpperCase() === 'OFFICE' && x.class.toUpperCase() === 'ESTATE')
  const estateAttribute: IEstateAttribute[] = await saveupClient.get<IEstateAttribute>(`/estate/attribute/${officeAttribute[0].id}`).then(handleResponse)
  const filtered: IEstateAttribute[] = _.uniqBy(
    estateAttribute.filter((x: IEstateAttribute) => !isNullOrWhiteSpace(x.value)),
    'value'
  )
  const offices: IOffice[] = filtered.map((x: IEstateAttribute) => {
    return <IOffice>{id: x.value, name: x.value}
  })
  return offices
}

/**
 * Fetch all monthly consumptions in given date span.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getConsumption(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {
  //adjustedStartDate.setFullYear(adjustedStartDate.getFullYear()-1)
  //const adjustedStartDate = new Date(startDate.getFullYear() - 1, 0, 1, 0, 0, 0)
  const result = await saveupClient
    .get<IConsumption>(
      `Consumption/Summary?id=${id}&type=ESTATE&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}`
    )
    .then(handleResponse)
  return result
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getNodeConsumption(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  //adjustedStartDate.setFullYear(adjustedStartDate.getFullYear()-1)
  //const adjustedStartDate = new Date(startDate.getFullYear() - 1, 0, 1, 0, 0, 0)
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=ESTATE&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span.
 * @param id
 * @param meterTypeId
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getNodeConsumptionAndEstimate(id: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  //adjustedStartDate.setFullYear(adjustedStartDate.getFullYear()-1)
  //const adjustedStartDate = new Date(startDate.getFullYear() - 1, 0, 1, 0, 0, 0)
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=ESTATE&meterTypeId=${meterTypeId}&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&estimate=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
/**
 * Get all meter types used at given estate.
 * @param estateId
 * @returns
 */
async function getMeterTypesAtEstate(estateId: number) {
  return await saveupClient.get<IMeterType[]>(`estate/${estateId}/meter/type`).then(handleResponse)
}

/**
 * Get estate by id.
 * @param id
 * @returns
 */
async function getEstate(id: number) {
  return await saveupClient.get<IEstate>(`estate/${id}`).then(handleResponse)
}

async function getEstateAttributes(estateId: number) {
  return await saveupClient.get<IEstateAttribute>(`estate/${estateId}/attribute`).then(handleResponse)
}

async function getEstateArea(estateId: number) {
  return await saveupClient.get<Area>(`estate/${estateId}/area`).then(handleResponse)
}

async function getEstateCsv(estateId: number, fromDate: Date, toDate: Date, degreeDayAdjusted: boolean) {
  let csvRows = await saveupClient
    .get<EstateCsvRow[]>(
      `estate/${estateId}/report/period/csv?fromDate=${fromDate.toLocaleString().substring(0, 10)}&toDate=${toDate.toLocaleString().substring(0, 10)}&degreeDayAdjusted=${degreeDayAdjusted}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  let modifiedCsvRows = csvRows.map((x: EstateCsvRow) => {
    let row = x

    const loaUnit = getUnitByValueCategory(x.unit, LOA)
    row.loaUnit = `${loaUnit}/m²`                                                                                                                                                                                                                                                                                                       
    if(loaUnit === KWH) row.loa = parseFloat((x.loa * 1000).toFixed())
    else if(loaUnit === LITER) row.loa = parseFloat((x.loa * 1000).toFixed())

    const aTempUnit = getUnitByValueCategory(x.unit, ATEMP)
    row.aTempUnit = `${aTempUnit}/m²`
    if(aTempUnit === KWH) row.aTemp = parseFloat((x.aTemp * 1000).toFixed())
    else if(aTempUnit === LITER) row.aTemp = parseFloat((x.aTemp * 1000).toFixed())

    row.currency = 'SEK' 
    row.emissionType = 'KG CO2e'
    
    return row
  })
  return modifiedCsvRows
}

async function getMestroReport(estateId: number, fromDate: Date, toDate: Date, degreeDayAdjusted: boolean) {
  const data = await saveupClient
    .get<IMestroConsumptionAndReading[]>(
      `estate/mestro/export?estateId=${estateId}&fromDate=${fromDate.toLocaleString().substring(0, 10)}&toDate=${toDate.toLocaleString().substring(0, 10)}&degreeDayAdjusted=${degreeDayAdjusted}`,
      {timeout: 120000}
    )
    .then(handleResponse)

  const parsed = data.map((x: IMestroConsumptionAndReading) => {
    const {readingDate, resolution, ...rest} = x
    const counterName = getCounterDisplayName(x)
    const measurePointName = getMeasurePointDisplayName(x)
    const newResolution = isNullOrWhiteSpace(resolution) ? 'month' : resolution
    const unit = x.unit.toLocaleLowerCase() === 'kw/h' ? 'kWh' : x.unit
    return {...rest, date: readingDate, resolution: newResolution, counterName, measurePointName, unit, key: x.externalId}
  })
  console.log(parsed)
  return parsed
}

async function getVitecFormat1Report(estateId: number, fromDate: Date, toDate: Date) {
  return await saveupClient
    .get<string>(`estate/vitec/export?id=${estateId}&Type=ESTATE&StartDate=${fromDate.toLocaleString().substring(0, 10)}&StopDate=${toDate.toLocaleString().substring(0, 10)}`, {timeout: 120000})
    .then(handleResponse)
}
async function getVitecFormat6Report(estateId: number, startDate: Date, stopDate: Date) {
  const parsedStopDate = parseStopDateMonthly(stopDate)
  const res = await saveupClient //Estate/Vitec/Export/Format?id=" + estateId + "&Type=ESTATE&StartDate=" + fromDate + "&StopDate=" + toDate + "&integration=" + integration + "&format=" + format
    .get<string>(`estate/vitec/export/format?id=${estateId}&Type=ESTATE&StartDate=${startDate.toLocaleString()}&StopDate=${parsedStopDate.toLocaleString()}&integration=&format=6`, {timeout: 120000})
    .then(handleResponse)
  return res
}

async function getReadingTableLogs(estateId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient
    .get<ITableLog>(`Estate/Counter/Value/Log/Period/Meter?estateId=${estateId}&meterTypeId=${meterTypeId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`)
    .then(handleResponse)
}

async function getConsumptionTableLogs(estateId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient
    .get<ITableLog>(`Estate/Counter/Consumption/Log/Period/Meter?estateId=${estateId}&meterTypeId=${meterTypeId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`)
    .then(handleResponse)
}

async function createEstate(ownerId: number, name: string, description: string, hidden: boolean) {
  return await saveupClient.post<IEstate>(`estate`, {ownerId, name, description, hidden}).then(handleResponse)
}

async function createEstateAttribute(estateId: number, attributeId: number, value: string) {
  return await saveupClient.post<IEstateAttribute>(`estate/attribute`, {estateId: estateId, attributeId: attributeId, value: value}).then(handleResponse)
}

async function deleteEstateAttribute(id: number) {  
  return await saveupClient.delete(`estate/attribute/${id}`,).then(handleResponse)
}
async function createEstateArea(estateId: number, areaId: number) {
  return await saveupClient.post<IAreaEstate>(`area/estate/add`, {estateId, areaId}).then(handleResponse)
}

async function createEstateUser(estateId: number, userId: number) {
  return await saveupClient.post<IGroup>(`estate/assign`, {estateId, userId}).then(handleResponse)
}

async function updateEstate(id: number, ownerId: number, name: string, description: string, hidden: boolean) {
  return await saveupClient.put<IEstate>(`estate`, {id, ownerId, name, description, hidden}).then(handleResponse)
}

async function deleteEstate(id: number) {
  return await saveupClient.delete(`estate/${id}`).then(handleResponse)
}

async function updateEstateArea(areaId: number, estateId: number) {
  return await saveupClient.put<IAreaEstate>(`estate/area`, {areaId, estateId}).then(handleResponse)
}

async function deleteEstateArea(areaId: number, estateId: number) {
  return await saveupClient.delete(`area/estate/remove?areaId=${areaId}&estateId=${estateId}`).then(handleResponse)
}
async function getEstateUsers(estateId: number) {
  return await saveupClient.get<IUser>(`estate/${estateId}/users`).then(handleResponse)
}
async function deleteEstateUser(userId: number, estateId: number) {
  const response = await saveupClient
    .post('estate/RemoveAssignment', {userId, estateId})
    .then((response) => response)
    .catch((err) => console.log(err))
  return response && response.status == 200
}
async function getEstateAddresses(estateId: number) {
  return await saveupClient.get(`estate/${estateId}/address`).then(handleResponse)
}
async function getPath(id: number) {
  return await saveupClient.get<IEstatePathDto>(`estate/${id}/path`).then(handleResponse)
}
async function getBreadCrumbPath(id: number) {
  let paths: IBreadCrumbPath[] = []
  const dto: IEstatePathDto = await getPath(id)
  paths.push({
    id: dto.owner.id,
    label: isNullOrWhiteSpace(dto.owner.name) ? dto.owner.id.toString() : dto.owner.name,
    nodeType: OWNER,
  })
  paths.push({
    id: dto.id,
    label: `${isNullOrWhiteSpace(dto.name) ? dto.id.toString() : dto.name}${isNullOrWhiteSpace(dto.rekylId) ? '(' + dto.rekylId + ')' : ''}`,
    nodeType: ESTATE,
  })
  return paths
}

async function getEstateAttributesByAttributeId(attributeId: number) {
  return await saveupClient.get<IEstateAttribute>(`estate/attribute/${attributeId}`).then(handleResponse)
}

const estateService = {
  getEstates,
  getEstatesByName,
  getOwnerEstate,
  getEstateByOwnerId,
  getOffices,
  getConsumption,
  getNodeConsumption,
  getNodeConsumptionAndEstimate,
  getMeterTypesAtEstate,
  getEstate,
  getEstateCsv,
  getMestroReport,
  getVitecFormat1Report,
  getVitecFormat6Report,
  getPath,
  getBreadCrumbPath,
  getEstateAttributesByAttributeId,
  createEstate,
  updateEstate,
  deleteEstate,
  getReadingTableLogs,
  getConsumptionTableLogs,
  getEstateAttributes,
  getEstateArea,
  getEstateUsers,
  getEstateAddresses,
  createEstateAttribute,
  createEstateArea,
  createEstateUser,
  updateEstateArea,
  deleteEstateArea,
  deleteEstateUser,
  deleteEstateAttribute
}

export default estateService
