import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {Controller, FieldValues, DeepPartial, useForm, Control} from 'react-hook-form'

type ArbitraryFormValues = {
  fieldName: string
}

type ControlledDropwDownProps = {
  label: string  
  items: any[]
  name: string
  control: Control<any, any>
  defaultValue?: string,
  onChange?: (value: any) => void
  disabled?: boolean
}

const ControlledDropDown = (props: ControlledDropwDownProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Controller
        render={({field}) => (
          <Select inputProps={{maxLength: 40}} label={props.label} {...field} onChange={(e: SelectChangeEvent) => {
            field.onChange(e);
            if(props.onChange !== undefined) props.onChange(e.target.value)
          }}>
            {props.items.map((item: any, index: number) => (
              <MenuItem value={item.value} key={item.key ? item.key : index}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        )}
        name={props.name}
        control={props.control}      
        defaultValue={props.defaultValue ? props.defaultValue : ''}  
      />
    </FormControl>
  )
}

export default ControlledDropDown