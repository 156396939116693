import {Reducer} from 'redux'
import {ICounterDeviation, IDeviationType} from '../counter/interfaces'
import {IMeterTypeState} from './interfaces'
import {ActionTypes} from './types'

export const initialState: IMeterTypeState = {
  isLoading: false,
  errorMessage: undefined,
  responseCode: undefined,
  meterTypes: [],
  selectedMeterType: undefined,
  selectedUnit: undefined,
  selectedDeviationType: undefined,
  deviationTypes: [],
  deviationValueTypes: [],
  meterCosts: [],
  savedMeterCost: undefined,
  meterEmissions: [],
  savedMeterEmission: undefined
}
const meterTypeReducer: Reducer<IMeterTypeState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.GET_METER_TYPE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_METER_TYPE_SUCCESS:
      return {...state, isLoading: false, selectedMeterType: payload}
    case ActionTypes.GET_METER_TYPE_FAILED:
      return {...state, isLoading: false, errorMessage: ActionTypes.UPDATE_METER_TYPE_FAILED}
    case ActionTypes.GET_METER_DEVIATION_TYPE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_METER_DEVIATION_TYPE_SUCCESS:
      return {...state, isLoading: false, selectedDeviationType: payload}
    case ActionTypes.GET_METER_DEVIATION_TYPE_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.GET_DEVIATION_TYPES_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_DEVIATION_TYPES_SUCCESS:
      return {...state, isLoading: false, deviationTypes: payload}
    case ActionTypes.GET_DEVIATION_TYPES_FAILED:
      return {...state, isLoading: false, errorMessage: ActionTypes.GET_DEVIATION_TYPES_FAILED}
    case ActionTypes.GET_DEVIATION_VALUE_TYPES_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_DEVIATION_VALUE_TYPES_SUCCESS:
      return {...state, isLoading: false, deviationValueTypes: payload}
    case ActionTypes.GET_DEVIATION_VALUE_TYPES_FAILED:
      return {...state, isLoading: false, errorMessage: ActionTypes.GET_DEVIATION_VALUE_TYPES_FAILED}
    case ActionTypes.UPDATE_METER_TYPE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.UPDATE_METER_TYPE_SUCCESS:
      return {...state, isLoading: false, selectedMeterType: payload}
    case ActionTypes.UPDATE_METER_TYPE_FAILED:
      return {...state, isLoading: false, errorMessage: ActionTypes.UPDATE_METER_TYPE_FAILED}
    case ActionTypes.DELETE_METER_TYPE_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.DELETE_METER_TYPE_SUCCESS:
      return {...state, isLoading: false, errorMessage: payload.responseMessage, responseCode: payload.responseCode}
    case ActionTypes.DELETE_METER_TYPE_FAILED:
      return {...state, isLoading: false, errorMessage: ActionTypes.UPDATE_METER_TYPE_FAILED, responseCode: payload.responseCode}
    case ActionTypes.GET_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_METER_COST_SUCCESS:
      return {...state, isLoading: false, meterCosts: payload}
    case ActionTypes.GET_METER_COST_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.CREATE_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.CREATE_METER_COST_SUCCESS:
      return {...state, isLoading: false, savedMeterCost: payload}
    case ActionTypes.CREATE_METER_COST_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.UPDATE_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.UPDATE_METER_COST_SUCCESS:
      return {...state, isLoading: false, savedMeterCost: payload}
    case ActionTypes.UPDATE_METER_COST_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.DELETE_METER_COST_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.DELETE_METER_COST_SUCCESS:
      const costs = state.meterCosts.filter((x) => x.id !== payload)
      return {...state, meterCosts: costs, isLoading: false}
    case ActionTypes.DELETE_METER_COST_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
      case ActionTypes.GET_METER_EMISSION_LOADING:
        return {...state, isLoading: true}
      case ActionTypes.GET_METER_EMISSION_SUCCESS:
        return {...state, isLoading: false, meterEmissions: payload}
      case ActionTypes.GET_METER_EMISSION_FAILED:
        return {...state, isLoading: false, errorMessage: payload}
      case ActionTypes.CREATE_METER_EMISSION_LOADING:
        return {...state, isLoading: true}
      case ActionTypes.CREATE_METER_EMISSION_SUCCESS:
        return {...state, isLoading: false, savedMeterEmission: payload}
      case ActionTypes.CREATE_METER_EMISSION_FAILED:
        return {...state, isLoading: false, errorMessage: payload}
      case ActionTypes.UPDATE_METER_EMISSION_LOADING:
        return {...state, isLoading: true}
      case ActionTypes.UPDATE_METER_EMISSION_SUCCESS:
        return {...state, isLoading: false, savedMeterEmission: payload}
      case ActionTypes.UPDATE_METER_EMISSION_FAILED:
        return {...state, isLoading: false, errorMessage: payload}
      case ActionTypes.DELETE_METER_EMISSION_LOADING:
        return {...state, isLoading: true}
      case ActionTypes.DELETE_METER_EMISSION_SUCCESS:
        const emissions = state.meterEmissions.filter((x) => x.id !== payload)
        return {...state, meterEmissions: emissions, isLoading: false}
      case ActionTypes.DELETE_METER_EMISSION_FAILED:
        return {...state, isLoading: false, errorMessage: payload}
    default:
      return {...state}
  }
}
export default meterTypeReducer
