import {Grid, IconButton} from '@mui/material'
import {head} from 'lodash'
import React, {useEffect, useState} from 'react'
import {IExternalSystem} from '../../redux/externalSystem/interfaces'
import {ICounterIntegration, ICounterIntegrationProps} from '../../redux/counter/interfaces'
import LabeledSelect from '../shared/Inputs/LabeledSelect'
import SecTextField from '../shared/Inputs/SecTextField'
import {useDispatch} from 'react-redux'
import {addNewIntegration, deleteIntegration, modifyIntegration} from '../../redux/counter/actions'
import {CounterIntegrationType} from '../../shared/enums/CounterIntegrationType'
import {Delete} from '@mui/icons-material'
import {isAdmin} from '../../shared/utils/utilities'
const integrationOptions = [
  {value: CounterIntegrationType.Export, text: 'Export', key: 'export'},
  {value: CounterIntegrationType.Import, text: 'Import', key: 'import'},
  {value: CounterIntegrationType.None, text: 'Inaktiv', key: 'none'},
]
const defaultExternalSystems = [{value: 'NOT_SET', text: 'Alla', key: 'NOT_SET'}]
const CounterIntegration = (props: ICounterIntegrationProps) => {
  const [integrationType, setIntegrationType] = useState<CounterIntegrationType>(
    props.integration.integrationType === CounterIntegrationType.Both ? CounterIntegrationType.Import : props.integration.integrationType
  )
  const defaultSystem = head(props.externalSystems.filter((x) => x.id === props.integration.externalSystemId)) ?? props.externalSystems[0]
  const [selectedSystem, setSelectedSystem] = useState<number>(defaultSystem ? defaultSystem.id : 0)
  const [externalId, setExternalId] = useState<string>(props.integration.externalId)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (props.integration.externalSystemId !== selectedSystem || props.integration.integrationType !== integrationType || props.integration.externalId !== externalId) {
      const modifiedIntegration: ICounterIntegration = {...props.integration, externalSystemId: selectedSystem, externalId: externalId, integrationType: integrationType}
      dispatch(modifyIntegration(modifiedIntegration))
    }
  }, [selectedSystem, integrationType, externalId])

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  const onDeleteButtonClick = () => {
    dispatch(deleteIntegration(props.integration))
  }

  const handlePaste = (e: any) => {
    e.preventDefault()
    let content = e.clipboardData.getData('Text')
    if (content !== undefined || content !== null) {
      setExternalId(content)
    }
  }

  return (
    <Grid container spacing={5} key={props.index} sx={{marginTop: '1px'}}>
      <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
        <Grid item xs={3}>
          <LabeledSelect
            id='externalSystemSelect'
            label='System'
            className='system-select'
            items={props.externalSystems.map((x) => ({value: x.id, text: x.name, key: x.id}))}
            value={selectedSystem}
            onChange={(e: any) => setSelectedSystem(e.target.value)}
            disabled={hasAdminRights ? false: true}
          />
        </Grid>
        <Grid item xs={3}>
          <LabeledSelect
            id='integrationSelect'
            label='Integrationstyp'
            className='integration-type-select'
            items={integrationOptions}
            value={integrationType}
            onChange={(e: any) => setIntegrationType(e.target.value)}
            disabled={hasAdminRights ? false: true}
          />
        </Grid>
        <Grid item xs={4}>
          <SecTextField id='externalId' label='Externt ID' className='integration-external-id' value={externalId} onChange={(e: any) => setExternalId(e.target.value)} onPaste={handlePaste} disabled={hasAdminRights ? false: true} />
          {hasAdminRights && (
            <IconButton color='primary' onClick={onDeleteButtonClick} title='Ta bort' sx={{marginLeft: '5px'}}>
              <Delete />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
export default CounterIntegration
