import React, {useEffect} from 'react'
import PageTitleWrapper from '../shared/PageTitleWrapper'
import {Container, Grid, Card, CardHeader, CardContent, Divider, Typography, Stack, Button, Select, MenuItem} from '@mui/material'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {useNavigate} from 'react-router-dom'
import Area, {ICreateAreaForm} from '../../redux/area/Interfaces.ts'
import {createArea, createAreaEnergyZone} from '../../redux/area/actions'
import {ArrowBack} from '@mui/icons-material'
import {getEnergyZones} from '../../redux/energyzone/actions'
import {RootState} from '../../redux/rootReducer'
import {CardActions} from '@material-ui/core'

function CreateArea() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSubmit = (form: ICreateAreaForm) => {
    dispatch(createArea(form.number, form.name, form.energyZoneId))
    navigate('/area')
  }
  const energyzones = useSelector((state: RootState) => state.energyZone.energyZones)
  useEffect(() => {
    if (!energyzones || energyzones.length === 0) {
      dispatch(getEnergyZones())
    }
  }, [])

  const defaultValues: ICreateAreaForm = {
    name: '',
    number: 0,
    energyZoneId: 0,
  }
  const {
    handleSubmit,
    register,
    control,
    formState: {errors, isDirty, isValid},
  } = useForm<ICreateAreaForm>({defaultValues})

  return (
    <>
      <PageTitleWrapper>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h3' component='h3' gutterBottom></Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </PageTitleWrapper>
      <Container maxWidth='lg'>
        <Card component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title='Skapa en ny graddagsort' />
          <Divider />
          <CardContent>
            <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
              <Grid item xs={4}>
                <Typography variant='body1'>Områdesnummer:</Typography>
                <TextField
                  {...register('number', {
                    required: 'required',
                  })}
                  placeholder='number'
                  type='number'
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body2'>Namn:</Typography>
                <TextField
                  {...register('name', {
                    required: 'required',
                  })}
                  placeholder='name'
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body1'>Elprisområde:</Typography>
                <Controller
                  name='energyZoneId'
                  control={control}
                  render={({field}) => (
                    <Select fullWidth {...field}>
                      <MenuItem key={1} value={0}>
                        --- Välj elprisområde ---
                      </MenuItem>
                      {energyzones?.map((zone, index) => (
                        <MenuItem key={index + 1} value={zone.id}>
                          {zone.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid item xs={12}>
              <Stack direction='row' spacing={2}>
                <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<SaveOutlinedIcon fontSize='small' />}>
                  Spara
                </Button>
                <Button onClick={() => navigate('/area')} sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<ArrowBack fontSize='small' />}>
                  Avbryt
                </Button>
              </Stack>
            </Grid>
          </CardActions>
        </Card>
      </Container>
    </>
  )
}

export default CreateArea
