import {Reducer} from 'redux'
import {ActionTypes} from './types'
import { CounterPeriodConsumptionState } from './interfaces'
const initialCounterPeriodConsumptionState: CounterPeriodConsumptionState = {
  isLoading: false,
  errorMessage: '',
  lastActionType: undefined,
  selectedConsumption: {
    consumption: null,
    isBasedOnReading: false
  },
  counterPeriodConsumptions: [],
  counterPeriodConsumptionListItems: {
    counterPeriodConsumptionListItems: [],
    isLoading: false,
    errorMessage: ''
  },
  savedConsumption: null,
  deletedConsumption: 0,
  getCounterPeriodConsumptionLogById: {
    counterPeriodConsumptionLog: undefined,
    isLoading: false,
    errorMessage: ''
  },
  createdCounterPeriodConsumptionLog:  {
    counterPeriodConsumptionLog: undefined,
    isLoading: false,
    errorMessage: ''
  },
  updatedCounterPeriodConsumptionLog:  {
    counterPeriodConsumptionLog: undefined,
    isLoading: false,
    errorMessage: ''
  },
  deleteCounterPeriodConsumptionLog:  {
    id: 0,
    isLoading: false,
    errorMessage: ''
  },  
}
export const counterPeriodConsumptionReducer: Reducer<CounterPeriodConsumptionState> = (state = initialCounterPeriodConsumptionState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_SUCCESS: {
      return {...state, isLoading: false, selectedConsumption: {consumption: payload.consumption, isBasedOnReading: payload.isConsumptionBasedOnReading}, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_SUCCESS: {
      return {...state, isLoading: false, counterPeriodConsumptions: payload, lastActionType: type}
    }
    case ActionTypes.FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_LOADING: {      
      return {...state, counterPeriodConsumptionListItems: {...state.counterPeriodConsumptionListItems, counterPeriodConsumptionListItems: [], isLoading: true}}
    }
    case ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_SUCCESS: {      
      return {...state, counterPeriodConsumptionListItems: {...state.counterPeriodConsumptionListItems, isLoading: false, counterPeriodConsumptionListItems: payload}}
    }
    case ActionTypes.FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_ERROR: {      
      return {...state, counterPeriodConsumptionListItems: {...state.counterPeriodConsumptionListItems, counterPeriodConsumptionListItems: [], isLoading: false, errorMessage: payload.status}}
    }
    case ActionTypes.SAVE_COUNTER_PERIOD_CONSUMPTION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.SAVE_COUNTER_PERIOD_CONSUMPTION_SUCCESS: {
      return {...state, isLoading: false, savedConsumption: payload, lastActionType: type}
    }
    case ActionTypes.SAVE_COUNTER_PERIOD_CONSUMPTION_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_SUCCESS: {
      return {...state, isLoading: false, deletedConsumption: payload, lastActionType: type}
    }
    case ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOADING: {
      return {...state, isLoading: true, lastActionType: type}
    }
    case ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_SUCCESS: {
      return {...state, isLoading: false, savedConsumption: payload, lastActionType: type}
    }
    case ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_ERROR: {
      return {...state, isLoading: false, errorMessage: payload.status, lastActionType: type}
    }
    case ActionTypes.GET_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING: {
      return {...state, getCounterPeriodConsumptionLogById: { ...state.getCounterPeriodConsumptionLogById, isLoading: true, counterPeriodConsumptionLog: undefined, errorMessage: ''}}
    }
    case ActionTypes.GET_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS: {
      return {...state, getCounterPeriodConsumptionLogById: { ...state.getCounterPeriodConsumptionLogById, isLoading: false, counterPeriodConsumptionLog: payload, errorMessage: '' } }
    }
    case ActionTypes.GET_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR: {
      return {...state, getCounterPeriodConsumptionLogById: {...state.getCounterPeriodConsumptionLogById,  isLoading: false, counterPeriodConsumptionLog: undefined, errorMessage: payload.status } }
    }
    case ActionTypes.CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING: {
      return {...state, createdCounterPeriodConsumptionLog: { ...state.createdCounterPeriodConsumptionLog, isLoading: true, counterPeriodConsumptionLog: undefined, errorMessage: ''}}
    }
    case ActionTypes.CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS: {
      return {...state, createdCounterPeriodConsumptionLog: { ...state.createdCounterPeriodConsumptionLog, isLoading: false, counterPeriodConsumptionLog: payload, errorMessage: '' } }
    }
    case ActionTypes.CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR: {
      return {...state, createdCounterPeriodConsumptionLog: { ...state.createdCounterPeriodConsumptionLog, isLoading: false, counterPeriodConsumptionLog: undefined, errorMessage: payload.status } }
    }
    case ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING: {
      return {...state, updatedCounterPeriodConsumptionLog: { ...state.updatedCounterPeriodConsumptionLog, isLoading: true, counterPeriodConsumptionLog: undefined, errorMessage: ''}}
    }
    case ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS: {
      return {...state, updatedCounterPeriodConsumptionLog: { ...state.updatedCounterPeriodConsumptionLog,isLoading: false, counterPeriodConsumptionLog: payload, errorMessage: '' } }
    }
    case ActionTypes.UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR: {
      return {...state, updatedCounterPeriodConsumptionLog: { ...state.updatedCounterPeriodConsumptionLog,isLoading: false, counterPeriodConsumptionLog: undefined, errorMessage: payload.status } }
    }
    case ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING: {
      return {...state, deleteCounterPeriodConsumptionLog: { ...state.deleteCounterPeriodConsumptionLog, isLoading: true, id: 0, errorMessage: ''}}
    }
    case ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS: {
      return {...state, deleteCounterPeriodConsumptionLog: { ...state.deleteCounterPeriodConsumptionLog, isLoading: false, id: payload, errorMessage: '' } }
    }
    case ActionTypes.DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR: {
      return {...state, deleteCounterPeriodConsumptionLog: { ...state.deleteCounterPeriodConsumptionLog, isLoading: false, id: 0, errorMessage: payload.status } }
    }
    default: {
      return state
    }
  }
}
