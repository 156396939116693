import {Box, Typography} from '@mui/material'
import React from 'react'

const Start: React.FC = () => {
  return (
    <React.Fragment>
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='600px'>
        <Typography variant='h5'>
          Välkommen till SaveUp! Använd menyn till vänster för att navigera.
          <br />
          <br />
          Vänligen notera att sidan är under konstruktion, och innehåll kan komma att ändras.
        </Typography>
      </Box>
    </React.Fragment>
  )
}
export default Start
