import React, {useEffect, useState} from 'react'
import {IEstatePdfReportProps} from './interaces'
import {Container, Grid, ThemeProvider, Typography, createTheme} from '@mui/material'
import {head, last, orderBy, tail} from 'lodash'
import ConsumptionTablePDF from '../../../../components/consumption/pdf/ConsumptionTablePDF'
import ConsumptionGraphGroupedPdf from '../../../../components/consumption/pdf/ConsumptionGraphGroupedPDF'
import {CONSUMPTION} from '../../../../shared/utils/constants'

const theme = createTheme()
theme.typography.h4 = {
  color: '#3f51b5',
}

const EstatePdfReport = (props: IEstatePdfReportProps) => {
  const [pdfStartDate, setPdfStartDate] = useState<Date>(new Date())
  const [pdfStopDate, setPdfStopDate] = useState<Date>(new Date())
  const [pdfConsumptionHeader, setPdfConsumptionHeader] = useState<string>('')
  useEffect(() => {
    const sortedConsumptions = orderBy(props.consumptions, ['year', 'month'])
    const firstConsumption = head(sortedConsumptions)
    const lastConsumption = last(sortedConsumptions)

    if (firstConsumption && lastConsumption) {
      setPdfStartDate(new Date(firstConsumption.year, firstConsumption.month, 1))
      setPdfStopDate(new Date(lastConsumption.year, lastConsumption.month, 1))
    }
    if (props.meterType.canDegreeDayAdjust && props.isUsingDegreeDayAdjustment == true) {
      setPdfConsumptionHeader(' - Normalårskorrigerad förbrukning (' + props.unit?.comment + ')')
    } else {
      setPdfConsumptionHeader(' - Förbrukning (' + props.unit?.comment + ')')
    }
  }, [props.consumptions])

  return (
    <div className='custom-chart' key={props.index} style={{'pageBreakInside': 'avoid'}}>
      <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom style={{backgroundColor: 'white', color: '#3f51b5'}}>
            {props.ownerName}
            <br />
            {props.estate.name}
            <br />
            {props.meterType.name}
            {pdfConsumptionHeader}
            <br />
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='left' alignItems='stretch' spacing={3} style={{width: '80%'}}>
        <Grid item xs={12}>
          <ConsumptionGraphGroupedPdf
            data={props.consumptions}
            startDate={pdfStartDate}
            stopDate={pdfStopDate}
            meterType={props.meterType}
            emission={props.emission}
            currency={props.currency}
            unit={props.unit}
            category={props.category}
            index={props.index}
          />
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
        <Grid item xs={12}>
          <ConsumptionTablePDF
            data={props.consumptions}
            startDate={pdfStartDate}
            stopDate={pdfStopDate}
            meterType={props.meterType}
            deviations={null}
            emission={props.emission}
            currency={props.currency}
            unit={props.unit}
            category={props.category}
            index={props.index}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default EstatePdfReport
