import {styled} from '@mui/material/styles'
import {ToggleButtonGroup} from '@mui/material'

const MenuBottonGroup = styled("div")(
  ({theme}) =>
    `border-radius: 0;
      display: flex;
      height:  48px
      align-items: center;
      margin: 0 ;
      justify-content: space-between;
      border-bottom: ${theme.sidebar.borderColor};     
`
)

export default MenuBottonGroup
