import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridRowClassNameParams,
  GridSortModel,
  GridFilterModel,
  GridSortModelParams,
  GridSortDirection,
} from '@mui/x-data-grid'
import {Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, Link, styled, TextField} from '@mui/material'
import Footer from '../shared/Footer'
import {RootState} from '../../redux/rootReducer'
import {getLogs} from '../../redux/home/homeActions'
import {setListColumnFilter, setListColumnSorting} from '../../redux/filter/actions'
import {isNullOrWhiteSpace, getCurrentPeriodAsDate} from '../../shared/utils/utilities'
import {Typography} from '@mui/material'
import {Add, CheckBox, Edit, NewReleases, Restore} from '@mui/icons-material'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import {ICounterConsumptionType, IDeviationFilter, IDeviationState, ILogItem} from '../../redux/home/homeInterfaces'
import {head, update} from 'lodash'
import {ICaseModalProps} from '../../redux/case/interfaces'
import CaseModal from '../case/CaseModal'
import LabeledSelect from '../shared/Inputs/LabeledSelect'
import {IOffice} from '../../redux/estateAttribute/interfaces'
import {DatePicker} from '@mui/x-date-pickers'
import {useLocation} from 'react-router-dom'
import {categoryConstants} from '../../shared/utils/constants'
import logService from '../../services/logService'
const currentPeriod = getCurrentPeriodAsDate()
const defaultFilter: IDeviationFilter = {
  selectedLogOffice: 'ALL',
  selectedCategory: 'ALL',
  selectedCaseStatus: 'ALL',
  startDate: currentPeriod,
}

const StyledDataGrid: any = styled(DataGrid)(({theme}) => ({
  '& .history': {
    color: 'gray',
  },
}))

export default function Index(): React.ReactElement {
  const dispatch = useDispatch()
  const location = useLocation()

  const [logItems, setLogItems] = useState<ILogItem[]>()
  const [rows, setRows] = useState<GridRowsProp | null>(null)
  const [modifiedRows, setModifiedRows] = useState<GridRowsProp | null>(null)
  const [updatedLogs, setUpdatedLogs] = useState<ILogItem[]>([])

  const {data, isLoading} = useSelector((state: RootState) => state.home)
  const offices = useSelector((state: RootState) => state.filter.offices)

  const [selectedLogOffice, setSelectedLogOffice] = useState(defaultFilter.selectedLogOffice)
  const [selectedCategory, setSelectedCategory] = useState(defaultFilter.selectedCategory)
  const [selectedCaseStatus, setSelectedCaseStatus] = useState(defaultFilter.selectedCaseStatus)
  const [startDate, setStartDate] = useState<Date>(defaultFilter.startDate)

  const homeListFilter = useSelector((state: RootState) => state.filter.listFilters['home'])

  const [filterModel, setFilterModel] = useState<GridFilterModel>({items: []})
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const onCaseModalConfirm = (logId: number, isArchived: boolean, caseStatus: string, isReading: boolean) => {
    setCaseModalProps({...caseModalProps, open: false})

    let logItem = logItems?.find((obj, index) => ((!isArchived && obj.id === logId) || (isArchived && obj.historyId === logId)) && obj.isConsumption === !isReading)
    if (logItem) {
      logItem.caseState = caseStatus
      logItem.hasCase = true
      // Det logUpdDate to current time
      logItem.logUpdDate = new Date()
      if (updatedLogs) setUpdatedLogs([...updatedLogs, logItem])
      else setUpdatedLogs([logItem])
    }

    // After the caseModal is closed update the caseState property on the item in the DataGrid
    setModifiedRows((previous) => {
      if (!previous) return previous
      const newValue = previous.map((obj, idx) => {
        if (((!isArchived && obj.id === logId) || (isArchived && obj.historyId === logId)) && obj.isConsumption === !isReading) {
          obj.caseState = caseStatus
          obj.hasCase = true
          obj.logUpdDate = new Date()
        }
        return obj
      })
      return newValue
    })
  }

  const onCaseModalCancel = () => setCaseModalProps({...caseModalProps, open: false})

  const defaultCaseModalProps: ICaseModalProps = {
    id: 0,
    logMessage: '',
    isArchived: false,
    isReading: false,
    open: false,
    type: 'OTHER',
    status: 'OPEN',
    category: 'OTHER',
    onConfirm: onCaseModalConfirm,
    onClose: onCaseModalCancel,
  }

  const [caseModalProps, setCaseModalProps] = useState<ICaseModalProps>(defaultCaseModalProps)

  useEffect(() => {
    setLogItems(data)
  }, [data])

  useEffect(() => {
    setFilter()
  }, [])

  const setFilter = () => {
    const jsonData: string | null = localStorage.getItem('deviationFilter')
    if (jsonData != null && !isNullOrWhiteSpace(jsonData)) {
      const storedFilter: IDeviationFilter = JSON.parse(jsonData)
      setSelectedLogOffice(storedFilter.selectedLogOffice)
      setSelectedCategory(storedFilter.selectedCategory)
      setSelectedCaseStatus(storedFilter.selectedCaseStatus)
      if (startDate !== storedFilter.startDate) {
        setStartDate(new Date(storedFilter.startDate))
      }
    }

    // Update the filter model for the datagrid by the key and value in homeListFilter
    if (homeListFilter && homeListFilter.model) {
      setFilterModel(homeListFilter.model)
    }
    // Update the sorting model for the datagrid by the homeListSorting
    if (homeListFilter && homeListFilter.sorting) {
      const sortModel: GridSortModel = []
      if (homeListFilter.sorting.columnName) {
        sortModel.push({field: homeListFilter.sorting.columnName, sort: (homeListFilter.sorting.order === 'asc' ? 'asc' : 'desc') as GridSortDirection})
      }
      setSortModel(sortModel)
    }
  }

  useEffect(() => {
    const dateSearchTimer = setTimeout(() => {
      fetchCounterValueLogs()
    }, 4000)
    return () => {
      clearTimeout(dateSearchTimer)
    }
  }, [startDate, selectedLogOffice])

  useEffect(() => {
    filterRows()
  }, [selectedCategory, selectedCaseStatus])

  useEffect(() => {
    filterRows()
  }, [logItems])

  const deviationStateUpdate = async (ids: ICounterConsumptionType[]) => {
    if (rows) {
      const deviations: IDeviationState[] = await logService.getDeviationStates(ids)

      // If the deviation state is a reading, we check counterValueLogId, if it is not a reading, we check counterValueConsumptionId
      const rowsWithDeviationState = [...rows]
        .filter((row) => deviations.some((dev) => (!dev.isReading && row.isConsumption ? dev.counterPeriodConsumptionLogId === row.Id : dev.counterValueLogId === row.id)))
        .map((row) => {
          const deviation = head(deviations.filter((dev) => (!dev.isReading && row.isConsumption ? dev.counterPeriodConsumptionLogId === row.Id : dev.counterValueLogId === row.id)))
          if (deviation) {
            switch (deviation?.type.toLowerCase()) {
              case 'förbrukning':
                row.deviation = `${deviation.currentDeviation.toFixed(2)}%`
                break
              case 'effektivitet':
                row.deviation = `${deviation.currentDiff.toFixed(2)}%`
                break
              case 'temperaturgräns':
                if (deviation.currentDiff > 0) row.deviation = `+${deviation.currentDiff.toFixed(2)}\xB0C`
                else row.deviation = `${deviation.currentDiff.toFixed(2)}\xB0C`
                break
              default:
                row.deviation = deviation ? `${deviation.currentDiff.toFixed(2)}%` : ''
                break
            }
          }
          return row
        })
      // setModifiedRows and replace rows without deviation with rows from rowsWithDeviationState
      // We add a check for isConsumption to ensure we compare consumptions with consumptions and readings with readings
      setModifiedRows((previous) => {
        if (!previous) return previous
        const newValue = previous.map((obj, idx) => {
          const row = rowsWithDeviationState.find((x) => x.id === obj.id && x.isConsumption === obj.isConsumption)
          return row ? row : obj
        })
        return newValue
      })
    }
  }

  useEffect(() => {
    // We set modified rows to the current rows. Afterwards we update deviation states for all rows
    setModifiedRows(rows?.map((row, index) => ({...row, logId: row.id})) ?? null)
    const updateRowsDeviationState = async () => {
      if (rows) {
        const batchSize = 20
        const totalBatches = Math.ceil(rows.length / batchSize)
        for (let i = 0; i < totalBatches; i++) {
          const batch = rows.slice(i * batchSize, (i + 1) * batchSize)
          const batchIds: ICounterConsumptionType[] = batch.map((item) => ({id: item.id, isConsumption: item.isConsumption}))

          await deviationStateUpdate(batchIds)
        }

        // Update modifiedRows and set the currentDiff to empty value for all rows that doesn't have a deviation
        setModifiedRows((previous) => {
          if (!previous) return previous
          const newValue = previous.map((obj, idx) => {
            const row = rows.find((x) => x.id === obj.id && x.isConsumption === obj.isConsumption)
            if (!row?.deviation) {
              obj.deviation = 'n/a'
            }
            return obj
          })
          return newValue
        })
      }
    }
    updateRowsDeviationState()
  }, [rows])

  useEffect(() => {
    saveFilterState()
  }, [location])

  const fetchCounterValueLogs = useCallback(() => {
    const office = selectedLogOffice === 'ALL' ? '' : selectedLogOffice
    const year = startDate.getFullYear()
    const month = startDate.getMonth() + 1
    dispatch(getLogs(year, month, office))
  }, [dispatch, selectedLogOffice, startDate])

  const filterRows = () => {
    let updatedLogItems = logItems?.map((log, index) => {
      const updatedLogItem = updatedLogs?.find((updated) => (log.historyId > 0 ? updated.historyId === log.historyId : updated.id === log.id) && updated.isConsumption === log.isConsumption)
      if (updatedLogItem && updatedLogItem?.logUpdDate && updatedLogItem.logUpdDate > log.logUpdDate) {
        log.caseState = updatedLogItem.caseState
        log.hasCase = updatedLogItem.hasCase
        log.logUpdDate = updatedLogItem.logUpdDate
      }
      return log
    })

    let filteredRows = updatedLogItems?.filter((x) => selectedLogOffice === 'ALL' || x.office === selectedLogOffice) ?? []
    filteredRows = filteredRows.filter((x) => selectedCategory === 'ALL' || isCategoryFilterPassed(x.category)) ?? []
    filteredRows = filteredRows.filter((x) => selectedCaseStatus === 'ALL' || isStatusFilterPassed(x)) ?? []

    saveFilterState()
    setRows(filteredRows)
  }

  const resetFilters = () => {
    localStorage.removeItem('deviationFilter')
    setSelectedLogOffice('ALL')
    setSelectedCategory('ALL')
    setSelectedCaseStatus('ALL')
    setStartDate(getCurrentPeriodAsDate())
    fetchCounterValueLogs()
  }

  const isCategoryFilterPassed = (category: string | undefined) => {
    if (selectedCategory === 'OPEN') return isNullOrWhiteSpace(category) || category === selectedCategory
    else return category === selectedCategory
  }

  const isStatusFilterPassed = (log: ILogItem) => {
    if (selectedCaseStatus === 'ALL') return true
    else if (selectedCaseStatus === 'NEW' && !log.hasCase) return true
    else if (log.caseState === selectedCaseStatus) return true
    else return false
  }

  const saveFilterState = () => {
    localStorage.setItem('deviationFilter', JSON.stringify({selectedLogOffice: selectedLogOffice, selectedCategory: selectedCategory, selectedCaseStatus: selectedCaseStatus, startDate: startDate}))
  }

  const onCellClick = (cell: any) => {
    if (cell.field === 'caseState' || cell.field === 'hasCase') {
      // open={caseModalOpen} onConfirm={generateExternalReport}
      const selectedCaseModalProps: ICaseModalProps = {
        id: cell.row.id > 0 ? cell.row.id : cell.row.historyId,
        logMessage: cell.row.message,
        isArchived: cell.row.historyId > 0,
        isReading: !cell.row.isConsumption,
        open: true,
        type: cell.row.category,
        status: cell.row.caseState,
        category: cell.row.category,
        onConfirm: onCaseModalConfirm,
        onClose: onCaseModalCancel,
      }
      setCaseModalProps(selectedCaseModalProps)
    }
  }

  const logTableColumnHeaders: GridColDef[] = [
    {field: 'date', headerName: 'Datum', minWidth: 120},
    {field: 'estateName', headerName: 'Fastighet', width: 150, minWidth: 80},
    {
      field: 'counterNumber',
      headerName: 'Räkneverk',
      flex: 1,
      width: 150,
      minWidth: 80,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <Link href={`/counter/${params.row.counterId}`}>{params.value}</Link>
      },
    },
    {field: 'userName', headerName: 'Avläsare', width: 200},
    {
      field: 'message',
      headerName: 'Meddelande',
      minWidth: 100,
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => {
        ;<div style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{params.value}</div>
      },
      flex: 1,
    },
    {
      field: 'deviation',
      headerName: 'Avvikelse',
      renderCell: (params: GridRenderCellParams<string>) => (!params.value ? <CircularProgress sx={{fontSize: '12px', padding: '4px'}} /> : params.value === 'n/a' ? <div></div> : params.value),
    },
    {field: 'counterValue', headerName: 'Värde'},
    {field: 'office', headerName: 'Kontor', width: 160},
    {
      field: 'category',
      headerName: 'Kategori',
      width: 160,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.value == 'null') return ''
        else if (params.value == 'RESET') return 'Mätarbyte/Runtslagning'
        else if (params.value == 'OTHER') return 'Avvikelse'
        else if (params.value == 'LOWER_CONSUMPTION') return 'Minskad förbrukning'
        else if (params.value == 'EXTERNAL') return 'Extern'
      },
    },
    {
      width: 10,
      field: 'caseState',
      headerName: 'Status',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <IconButton>
            {isNullOrWhiteSpace(params.value) ? (
              ''
            ) : params.value !== 'CLOSED' ? (
              params.value !== 'NEW' ? (
                <ConfirmationNumberIcon fontSize='small' />
              ) : (
                <NewReleases fontSize='small' />
              )
            ) : (
              <CheckBox fontSize='small' />
            )}
          </IconButton>
        )
      },
    },
    {
      width: 10,
      field: 'hasCase',
      headerName: 'ikoner',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<boolean>) => <IconButton>{params.value ? <Edit fontSize='small' /> : <Add fontSize='small' />}</IconButton>,
    },
  ]

  const getRowClassName = (params: GridRowClassNameParams) => {
    const className = params.row.historyId > 0 ? 'history' : ''
    return className
  }

  const onRefreshListClick = () => {
    resetFilters()
  }

  const renderOfficeSelectItems = (items: IOffice[]) => {
    const arr = [{value: 'ALL', text: 'Alla kontor'}, ...items?.map((x: IOffice) => ({value: x.name, text: x.name}))]
    return arr
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortModel(sortModel)
    if (sortModel.length > 0) {
      const {field, sort} = sortModel[0]
      dispatch(setListColumnSorting('home', field, sort === 'asc' ? 'asc' : 'desc'))
    }
  }

  const handleFilterModelChange = (filterModel: GridFilterModel) => {
    setFilterModel(filterModel)
    dispatch(setListColumnFilter('home', filterModel))
  }

  return (
    <React.Fragment>
      <Grid sx={{margin: '20px'}} justifyContent='center' alignItems='stretch'>
        <Card>
          <Grid item xl={12}>
            <CardHeader title='Avvikelser' sx={{background: '#97a7c1'}} />
            <Divider />
            <Grid sx={{margin: '20px', gap: '15px'}} container direction='row' justifyContent='flex-start' alignItems='flex-start' columns={12} spacing={3}>
              <Grid item xl={2}>
                <LabeledSelect
                  id='logOffices'
                  label='Kontor'
                  className='log-office-select'
                  items={renderOfficeSelectItems(offices)}
                  value={selectedLogOffice}
                  onChange={(x: any) => setSelectedLogOffice(x.target.value)}
                  canEdit={true}
                />
              </Grid>
              <Grid item xl={2}>
                <LabeledSelect
                  id='logCategories'
                  label='Kategori'
                  className='log-category-select'
                  items={categoryConstants}
                  value={selectedCategory}
                  onChange={(x: any) => setSelectedCategory(x.target.value)}
                  canEdit={true}
                />
              </Grid>
              <Grid item xl={2}>
                <LabeledSelect
                  id='logStatuses'
                  label='Status'
                  className='log-status-select'
                  items={[
                    {value: 'ALL', text: 'Alla'},
                    {value: 'NEW', text: 'Ny'},
                    {value: 'OPEN', text: 'Pågående'},
                    {value: 'CLOSED', text: 'Avslutad'},
                  ]}
                  value={selectedCaseStatus}
                  onChange={(x: any) => setSelectedCaseStatus(x.target.value)}
                  canEdit={true}
                />
              </Grid>
              <Grid item xl={2}>
                <DatePicker
                  views={['year', 'month']}
                  label='Period'
                  mask='____-__'
                  inputFormat='yyyy-MM'
                  value={startDate}
                  onChange={(date: Date | null) => {
                    if (date) setStartDate(date)
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xl={2}>
                <IconButton color='primary' onClick={onRefreshListClick} size='large' title='Återställ filter'>
                  <Restore fontSize='large' />
                  <Typography variant='button' sx={{color: '#5569ff'}}>
                    Återställ filter
                  </Typography>
                </IconButton>
              </Grid>
            </Grid>
            <Grid sx={{margin: '20px', width: 'inherit'}} justifyContent='center' alignItems='stretch'>
              <Grid item xl={12}>
                <CardContent>
                  {' '}
                  {isLoading ? (
                    <React.Fragment>
                      <Box display='flex' justifyContent='center' alignItems='center' minHeight='600px'>
                        <CircularProgress sx={{color: '#223354'}} size={256} />
                      </Box>
                    </React.Fragment>
                  ) : (
                    // Append styled datagrid that will rerender when modifiedRows change
                    <StyledDataGrid
                      pageSize={100}
                      rows={modifiedRows ?? []}
                      columns={logTableColumnHeaders}
                      autoHeight
                      getRowHeight={() => 'auto'}
                      onCellClick={onCellClick}
                      getRowClassName={getRowClassName}
                      onSortModelChange={handleSortModelChange}
                      onFilterModelChange={handleFilterModelChange}
                      filterModel={filterModel}
                      sortModel={sortModel}
                    />
                  )}
                </CardContent>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Footer />
      <CaseModal {...caseModalProps} />
    </React.Fragment>
  )
}
