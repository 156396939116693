import saveupClient from '../clients/saveupClient'
async function getUnits() {
  const data = await saveupClient
    .get('/Unit')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return data
}

async function getUnitById(id: number) {
  const data = await saveupClient
    .get(`/Unit/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return data
}

async function deleteUnitById(id: number) {
  const data = await saveupClient
    .delete(`/Unit/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return data
}

async function editeUnit(unit: any) {
  const data = await saveupClient
    .put(`/Unit`, unit)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return data
}

async function createUnit(unit: any) {
  const data = await saveupClient
    .post(`/Unit`, unit)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
  return data
}
const unitService = {
  getUnits,
  getUnitById,
  deleteUnitById,
  editeUnit,
  createUnit,
}

export default unitService
