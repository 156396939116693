// Component which renders a Card which displays the title, description and textinputs matching the parameters of the selected report.
import {Card, CardContent} from '@mui/material'
import React from 'react'
import {IReportDefinition} from '../../redux/report/interfaces'
import { ReportDetailsProps } from './interfaces'
const ReportDetails : React.FC<ReportDetailsProps> = ({ report }) => {    
  if(report === undefined) return (<></>)
  return (
      <div>
        <h2>{report.title}</h2>
        <p>{report.description}</p>
        {/* {report.parameters ? report.parameters.map((parameter) => {
          return (
            <div key={parameter.name}>
              <label><strong>{parameter.title}</strong></label>
            </div>
          )
        }) : ''} */}
      </div>
  )
}
export default ReportDetails
