import {Add} from '@mui/icons-material'
import {Typography, IconButton, Divider} from '@mui/material'
import {DataGrid, GridColDef, GridRowsProp} from '@mui/x-data-grid'
import {createEstateUser, getEstateUsers} from '../../../../redux/estate/actions'
import {IReaderViewProps} from './interfaces'
import {IUser} from '../../../../redux/user/interfaces'
import {RootState} from '../../../../redux/rootReducer'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReaderSearch from './ReaderSearch'
import ReaderTable from './ReaderTable'

const ReaderView = (props: IReaderViewProps) => {
  const dispatch = useDispatch()
  const [searchResultUsers, setSearchResultUsers] = useState<IUser[]>([])
  const [keyword, setKeyword] = useState('')
  useEffect(() => {
    dispatch(getEstateUsers(props.estateId))
  }, [props.estateId])

  const onSearchCompleted = (users: IUser[]) => {
    setKeyword(keyword)
    setSearchResultUsers(users)
  }
  const readers = useSelector((state: RootState) => state.estate.readers)

  const handleAddClick = (user: IUser) => dispatch(createEstateUser(props.estateId, user.id))

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id', width: 60},
    {field: 'mail', headerName: 'Användarnamn', flex: 1},
    {field: 'fullName', headerName: 'Namn', flex: 1},
    {
      field: 'Add',
      headerName: '',
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleAddClick(params.row)}>
            <Add />
          </IconButton>
        </div>
      ),
    },
  ]

  const rows: GridRowsProp = searchResultUsers
  return (
    <div>
      <ReaderSearch onSearchCompleted={onSearchCompleted} />
      {rows && rows.length > 0 ? <DataGrid style={{height: '400px', width: '100%'}} rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} /> : ''}
      <Divider style={{marginTop: '20px', marginBottom: '20px'}} />
      <ReaderTable estateId={props.estateId} readers={readers} />
    </div>
  )
}
export default ReaderView
