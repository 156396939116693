import ReportModal from '../../report/ReportModal'
import {Button, FormControl, FormControlLabel, Grid, LinearProgress, MenuItem, Select, Switch, Typography} from '@mui/material'
import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/rootReducer'
import {getAllUnits} from '../../../redux/unit/unitActions'
import estateService from '../../../services/estateService'
import {IEstate} from '../../../redux/estate/Interfaces'
import {applyTransformations, generateCsvFile, generateTextFile, isNullOrWhiteSpace, jsonToCsv, parseStopDate, parseStopDateMonthly, parseStopDateYearly} from '../../../shared/utils/utilities'
import {IMeterType} from '../../../redux/meter/interfaces'
import {isNumber} from 'lodash'
import {AREA, ATEMP, CONSUMPTION, COST, CSV, CURRENCY_SEK, EMISSION, EMISSION_KG_CO2, ESTATE, EXTERNAL, LOA, OWNER, PDF} from '../../../shared/utils/constants'
import {getEmissionByName} from '../../../redux/emission/actions'
import {getCurrencyByName} from '../../../redux/currency/actions'
import pdfService from '../../../services/pdfService'
import {IConsumptionNodePDF} from '../../../redux/pdf/interfaces'
import ReactToPrint, {useReactToPrint} from 'react-to-print'
import {IEstateReportsProps} from './interfaces'
import EstatePdfReport from './pdf/EstatePdfReport'
import {getEstate} from '../../../redux/estate/actions'
import reportService from '../../../services/reportService'
import {CustomReportParameter, IReportDefinition, ReportPreview} from '../../../redux/report/interfaces'
const EstateReports = (props: IEstateReportsProps) => {
  const [csvReport, toggleCsvReport] = useState(false)
  const [pdfReport, togglePdfReport] = useState(false)
  const [externalReport, toggleExternalReport] = useState(false)
  const [areaReport, toggleAreaReport] = useState(false)
  const [exportTemplate, setExportTemplate] = useState('mestro')
  const [metertype, setMeterType] = useState<number>(0)
  const pdfRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const types = useSelector((state: RootState) => state.filter.types ?? [])
  const units = useSelector((state: RootState) => state.unit.units)
  const currency = useSelector((state: RootState) => state.currency.selectedCurrency)
  const emission = useSelector((state: RootState) => state.emission.selectedEmission)

  const meterTypes = useSelector((state: RootState) => state.estate.meterTypes) ?? []
  const estate = useSelector((state: RootState) => state.estate.selectedEstate)

  const [printFormat, setPrintFormat] = useState<string>('HTML')
  const [reportType, setReportType] = useState<string>(CONSUMPTION)
  const [pdfData, setPdfData] = useState<IConsumptionNodePDF[] | undefined>([])
  const [isUsingDegreeDayAdjustment, setIsUsingDegreeDayAdjustment] = useState<boolean>(false)
  const [canGeneratePdf, setCanGeneratePdf] = useState<boolean>(false)

  const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }
  `

  const reactToPrint = useReactToPrint({
    content: () => pdfRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      setCanGeneratePdf(false)
      setPdfData(undefined)
    },
  })
  useEffect(() => {
    setPdfData(undefined)
    dispatch(getEstate(props.id))
    dispatch(getAllUnits())
    dispatch(getEmissionByName(EMISSION_KG_CO2))
    dispatch(getCurrencyByName(CURRENCY_SEK))
  }, [props.id])

  useEffect(() => {
    if (pdfData && pdfData.length > 0) {
      if (printFormat === 'PDF') {
        const generatePdf = async () => {
          const fileName = `${props.name}_${new Date().toLocaleString().substring(0, 10)}.pdf`
          await pdfService.exportChartsByContainerReference(fileName, pdfRef)
        }
        generatePdf().then(() => {
          setCanGeneratePdf(false)
        })
      } else {
        reactToPrint()
      }
    }
  }, [pdfData])

  const renderItem = ({id, name}: any, index: number) => {
    return (
      <MenuItem key={index} value={id}>
        {name}
      </MenuItem>
    )
  }

  const createPdf = async (startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    if (estate === undefined) return

    setIsUsingDegreeDayAdjustment(useDegreeDayCorrection)
    if (units?.length === 0 || !currency || !emission) return
    const parsedStopDate = parseStopDateMonthly(stopDate)

    const selectedMeterTypes = metertype > 0 ? meterTypes.filter((type: IMeterType) => type.id == metertype) : meterTypes.filter((x) => x.isConsumption)
    if(selectedMeterTypes?.length > 0 ) {
      const selectedUnit = units.find((x) => x.id === selectedMeterTypes[0].unitId)
      setCanGeneratePdf(true)
      const data: IConsumptionNodePDF[] = await pdfService.getEstateSummaryPdf(estate, selectedMeterTypes, startDate, parsedStopDate, useDegreeDayCorrection, selectedUnit)
      if (data && data.length > 0) {
        setPdfData(data)
      }
    }
  }

  const renderEstateReport = (item: IConsumptionNodePDF, index: number) => {
    const unit = units.find((x) => x.id === item.meterType.unitId)
    if (unit) {
      if (item.consumptions && item.consumptions.length > 0) {
        return (
          <EstatePdfReport
            meterType={item.meterType}
            ownerName={''}
            estate={item.estate}
            emission={emission}
            currency={currency}
            unit={unit}
            consumptions={item.consumptions}
            key={index}
            isUsingDegreeDayAdjustment={isUsingDegreeDayAdjustment}
            index={index}
            category={reportType}
          />
        )
      } else {
        return (
          <EstatePdfReport
            meterType={item.meterType}
            ownerName={''}
            estate={item.estate}
            emission={undefined}
            currency={undefined}
            unit={unit}
            consumptions={[]}
            key={index}
            isUsingDegreeDayAdjustment={isUsingDegreeDayAdjustment}
            index={index}
            category={reportType}
          />
        )
      }
    }
    return <div></div>
  }

  const generateCsv = async (startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    if (estate === undefined) return

    const headers = [
      'Period',
      'Fastighets-Id',
      'Fastighet',
      'Rekyl-Id',
      'Mätartyp',
      'Mätar-Id',
      'Mätarnamn',
      'Mätarnummer',
      'Avläsning',
      'Avläsningsdatum',
      'Förbrukning',
      'Enhet',
      'Graddagsjusterad',
      'ATemp',
      'ATemp_Enhet',
      'LOA',
      'LOA_Enhet',
      'Kostnad',
      'Valuta',
      'Utsläpp',
      'Utsläppsenhet',
    ]

    const data = await estateService.getEstateCsv(estate.id, startDate, stopDate, useDegreeDayCorrection)

    var flattenedData: any[] = []
    data.forEach((r: any) => {
      const adjustedRow = (({
        period,
        estateId,
        estateName,
        rekylId,
        meterType,
        counterId,
        counterName,
        counterNumber,
        reading,
        readingDate,
        consumption,
        unit,
        degreeDayAdjusted,
        aTemp,
        aTempUnit,
        loa,
        loaUnit,
        cost,
        currency,
        emissionValue,
        emissionType,
      }) => ({
        period,
        estateId,
        estateName,
        rekylId,
        meterType,
        counterId,
        counterName,
        counterNumber,
        reading,
        readingDate,
        consumption,
        unit,
        degreeDayAdjusted,
        aTemp,
        aTempUnit,
        loa,
        loaUnit,
        cost,
        currency,
        emissionValue,
        emissionType,
      }))(r)
      flattenedData.push(adjustedRow)
    })
    if (data && data.length > 0) {
      const fileName =
        estate.name +
        '_' +
        startDate.getFullYear() +
        '-' +
        startDate.getMonth() +
        1 +
        '_' +
        stopDate.getFullYear() +
        '-' +
        stopDate.getMonth() +
        1 +
        '_' +
        new Date().toLocaleString().substring(0, 10) +
        '.csv'
      const csvContent = jsonToCsv(flattenedData, headers)
      generateCsvFile(csvContent, fileName)
    } else {
      alert('Ingen data hittades för vald fastighetsägare.')
    }
  }

  const generateExternalReport = async (eomStartDate: Date, eomStopdate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    if (estate === undefined) return

    const startDate = eomStartDate
    const stopDate = eomStopdate

    if (exportTemplate == 'mestro') {
      const headers = ['Key', 'Avläsningsdatum', 'Mätarställning', 'Förbrukning', 'Enhet', 'Upplösning', 'Fastighetsägare', 'Fastighet', 'Adress', 'Mätpunkt', 'Räkneverk']
      const data = await estateService.getMestroReport(estate.id, startDate, stopDate, useDegreeDayCorrection)

      if (data && data.length > 0) {
        let currDate = new Date().toLocaleString().substring(0, 10)
        let fileName = 'Fastighetssnabben' + '_' + props.name + '_manual_' + currDate + '.csv'
        let minDate = new Date('0001-01-01')
        let allRows: any = []
        data.forEach((r: any) => {
          const sr = (({key, date, reading, consumption, unit, resolution, ownerName, estateName, addressName, measurePointName, counterName}) => ({
            key,
            date,
            reading,
            consumption,
            unit,
            resolution,
            ownerName,
            estateName,
            addressName,
            measurePointName,
            counterName,
          }))(r)
          if (sr.unit.toLowerCase() === 'kw/h') {
            sr.unit = 'kWh'
          }
          if (new Date(sr.date) > minDate) {
            allRows.push(sr)
          }
        })
        let csvContent = jsonToCsv(allRows, headers, false)
        generateCsvFile(csvContent, fileName)
      } else {
        alert('Ingen data hittades för vald fastighetsägare.')
      }
    } else if (exportTemplate === 'format1') {
      var rows: any[] = []
      const data: any[] = await estateService.getVitecFormat1Report(estate.id, startDate, stopDate)
      if (data && data.length > 0) {
        rows.push(data)
        let currDate = new Date().toLocaleString().substring(0, 10)
        let fileName = 'Fastighetssnabben' + '_' + estate.name + '_manual_' + currDate + '.txt'
        let trimmedRows: any[] = []
        rows.forEach((r: any) => {
          const lines = r.split('\r')
          lines.forEach((l: any) => {
            if (l.trim().length > 0) {
              trimmedRows.push(l.trim() + '\r')
            }
          })
        })
        generateTextFile(trimmedRows.join(''), fileName)
      } else {
        alert('Ingen data hittades för vald fastighetsägare.')
      }
    } else if (exportTemplate === 'format6') {
      var rows: any[] = []
      const data: any[] = await estateService.getVitecFormat6Report(estate.id, startDate, stopDate)
      if (data && data.length > 0) {
        rows.push(data)
        let currDate = new Date().toLocaleString().substring(0, 10)
        let fileName = 'Fastighetssnabben' + '_' + estate.name + '_manual_' + currDate + '.txt'
        let trimmedRows: any[] = []
        rows.forEach((r: any) => {
          if (!isNullOrWhiteSpace(r)) {
            const lines = r.split('\r')
            lines.forEach((l: any) => {
              if (l.trim().length > 0) {
                trimmedRows.push(l.trim() + '\r')
              }
            })
          }
        })
        generateTextFile(trimmedRows.join(''), fileName)
      } else {
        alert('Ingen data hittades för vald fastighetsägare.')
      }
    }
  }

  const generateAreaCsv = async (startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, applyOfficeFilter: boolean) => {
    const reports = await reportService.getReports()
    const selectedReport = reports.find((x: IReportDefinition) => x.name.toLocaleUpperCase() === 'ESTATE_AREAS_CONSUMPTION')
    if (selectedReport) {
      // const officeFilter = getFilterString(selectedOffice)
      const reportParameter: CustomReportParameter = {
        name: selectedReport.name,
        parameters: [
          {name: 'fromYear', value: startDate.getFullYear().toString()},
          {name: 'fromMonth', value: (startDate.getMonth() + 1).toString()},
          {name: 'toYear', value: stopDate.getFullYear().toString()},
          {name: 'toMonth', value: (stopDate.getMonth() + 1).toString()},
        ],
        filter: {key: 'OFFICE', value: 'ALL'}, // value: useOfficeFilter ? officeFilter : 'ALL'},
      }

      const preview: ReportPreview = {
        name: 'ESTATE_AREAS_CONSUMPTION',
        numberOfReportObjects: 1,
        uniqueObjectType: ESTATE,
        uniqueObjectIds: [props.id]
      }
      if (preview) {
        await reportService.generateBatchReports(selectedReport, preview, reportParameter, startDate, stopDate)
      }
    }
  }

  return (
    <div>
      <Grid container direction='row' justifyContent='left' spacing={3}>
        <Grid item>
          <Button variant='contained' onClick={() => toggleCsvReport(true)}>
            CSV - Mätarställning per period
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => togglePdfReport(true)}>
            PDF - Graf & Tabell per fastighet
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => toggleExternalReport(true)}>
            CSV/XML - Externt system
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => toggleAreaReport(true)}>
            CSV - Fastighetsytor
          </Button>
        </Grid>
      </Grid>
      <ReportModal id={props.id} open={csvReport} onClose={() => toggleCsvReport(false)} title='Export till CSV' actions={{run: generateCsv}} reportType={CSV} />
      <ReportModal id={props.id} open={pdfReport} onClose={() => togglePdfReport(false)} title='Export till PDF' actions={{run: createPdf}} reportType={PDF} isLoading={canGeneratePdf}>
        <Grid container direction='row' justifyContent='left' spacing={3}>
          <Grid item>
            <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
              <Select
                sx={{flexGrow: 1, width: '180px', color: '#3E5060', margin: '5px'}}
                label='Mätartyp'
                className='type-select'
                value={metertype}
                defaultValue={0}
                onChange={(item, index) => {
                  if (isNumber(item.target.value)) {
                    setMeterType(item.target.value)
                  }
                }}>
                <MenuItem value={0} key={0}>
                  Alla mätartyper
                </MenuItem>
                {types.map((t, i) => renderItem(t, i + 1))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
              <Select
                sx={{flexGrow: 1, width: '180px', color: '#3E5060', margin: '5px'}}
                label='Typ av rapport'
                className='type-select'
                value={reportType}
                onChange={(item, index) => {
                  setReportType(item.target.value)
                }}>
                <MenuItem value={CONSUMPTION} key={CONSUMPTION}>
                  Förbrukning
                </MenuItem>
                <MenuItem value={COST} key={COST}>
                  Kostnad
                </MenuItem>
                <MenuItem value={EMISSION} key={EMISSION}>
                  Utsläpp
                </MenuItem>
                <MenuItem value={ATEMP} key={ATEMP}>
                  ATemp (m²)
                </MenuItem>
                <MenuItem value={LOA} key={LOA}>
                  LOA (m²)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
              <Select
                sx={{flexGrow: 1, width: '180px', color: '#3E5060', margin: '5px'}}
                label='Utskriftsformat'
                className='type-select'
                value={printFormat}
                onChange={(item, index) => {
                  setPrintFormat(item.target.value)
                }}>
                <MenuItem value='PDF' key={0}>
                  PDF (långsam)
                </MenuItem>
                <MenuItem value='HTML' key={1}>
                  Utskriftsvy
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </ReportModal>
      <ReportModal id={props.id} open={externalReport} onClose={() => toggleExternalReport(false)} actions={{run: generateExternalReport}} title='Externa rapporter' reportType={EXTERNAL}>
        <FormControl variant='standard' sx={{m: 1, minWidth: 120}}>
          <Select
            sx={{flexGrow: 1, width: '250px', color: '#3E5060', margin: '5px'}}
            label='Export-mall'
            className='type-select'
            value={exportTemplate}
            defaultValue='mestro'
            onChange={(e) => setExportTemplate(e.target.value)}>
            <MenuItem value='mestro' key={1}>
              Mestro
            </MenuItem>
            <MenuItem value='format1' key={2}>
              Vitec Format 1 - Mätarställningar
            </MenuItem>
            <MenuItem value='format6' key={3}>
              Vitec Format 6 - Förbrukningar
            </MenuItem>
          </Select>
        </FormControl>
      </ReportModal>
      <ReportModal id={props.id} open={areaReport} onClose={() => toggleAreaReport(false)} title='Export till CSV' actions={{run: generateAreaCsv}} reportType={AREA} />
      <div ref={pdfRef} style={{display: 'block'}}>
        {canGeneratePdf && pdfData && emission && currency && (
          <div style={{margin: '0', padding: '0', display: 'block'}}>
            {pdfData
              .sort((x: IConsumptionNodePDF, y: IConsumptionNodePDF) => x.estate.name.localeCompare(y.estate.name))
              .map((obj: IConsumptionNodePDF, index: number) => {
                return (
                  <div key={index}>
                    {renderEstateReport(obj, index)}
                    <div className='page-break' />
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}
export default EstateReports
