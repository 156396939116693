import {isNullOrWhiteSpace} from '../../../shared/utils/utilities'
import {NodeCostEmissionDataDto} from '../../../redux/node/interfaces'
import {EMISSION_KG_CO2e} from '../../../shared/utils/constants'
import {PeriodObject} from '../../../shared/interfaces/interfaces'

export function getCostString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  if (data.estateMeterCosts) {
    const estateMeterCosts = data.estateMeterCosts.find(
      (x) =>
        x.meterId === meterId &&
        new Date(x.startDate).getFullYear() === year &&
        new Date(x.stopDate).getFullYear() === year &&
        new Date(x.startDate).getMonth() === month &&
        new Date(x.stopDate).getMonth() === month
    )
    if (estateMeterCosts) return `Fastighet: ${estateMeterCosts.cost} ${estateMeterCosts.currency.name}`
    const approxEstateMeterCostString = getApproxEstateMeterCostString(data, meterId, year, month)
    if (!isNullOrWhiteSpace(approxEstateMeterCostString)) return approxEstateMeterCostString
  }
  if (data.officeMeterCosts) {
    const officeMeterCosts = data.officeMeterCosts.find(
      (x) =>
        x.meterId === meterId &&
        new Date(x.startDate).getFullYear() === year &&
        new Date(x.stopDate).getFullYear() === year &&
        new Date(x.startDate).getMonth() === month &&
        new Date(x.stopDate).getMonth() === month
    )
    if (officeMeterCosts) return `Kontor: ${officeMeterCosts.cost} ${officeMeterCosts.currency.name}`
    const approxOfficeMeterCostString = getApproxOfficeMeterCostString(data, meterId, year, month)
    if (!isNullOrWhiteSpace(approxOfficeMeterCostString)) return approxOfficeMeterCostString
  }
  if (data.energyZoneMeterCosts) {
    const energyZoneMeterCost = data.energyZoneMeterCosts.find(
      (x) =>
        x.meterId === meterId &&
        new Date(x.startDate).getFullYear() === year &&
        new Date(x.stopDate).getFullYear() === year &&
        new Date(x.startDate).getMonth() === month &&
        new Date(x.stopDate).getMonth() === month
    )
    if (energyZoneMeterCost) return `Elprisområde: ${energyZoneMeterCost.cost} ${energyZoneMeterCost.currency.name}`
    const approxEnergyZoneMeterCostString = getApproxEnergyZoneMeterCostString(data, meterId, year, month)
    if (!isNullOrWhiteSpace(approxEnergyZoneMeterCostString)) return approxEnergyZoneMeterCostString
  }
  if (data.meterCosts) {
    const meterCost = data.meterCosts.find(
      (x) =>
        x.meterId === meterId &&
        new Date(x.startDate).getFullYear() === year &&
        new Date(x.stopDate).getFullYear() === year &&
        new Date(x.startDate).getMonth() === month &&
        new Date(x.stopDate).getMonth() === month
    )
    if (meterCost) return `Mätartyp: ${meterCost.cost} ${meterCost.currency.name}`
    const approxMeterCostsString = getApproxMeterCostsString(data, meterId, year, month)
    if (!isNullOrWhiteSpace(approxMeterCostsString)) return approxMeterCostsString
  }
  return ''
}
export function getEmissionString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  if (data.estateMeterEmission) {
    const estateMeterEmission = data.estateMeterEmission.find(
      (x) =>
        x.meterId === meterId &&
        new Date(x.startDate).getFullYear() === year &&
        new Date(x.stopDate).getFullYear() === year &&
        new Date(x.startDate).getMonth() === month &&
        new Date(x.stopDate).getMonth() === month
    )
    if (estateMeterEmission) return `Fastighet: ${estateMeterEmission.value} ${EMISSION_KG_CO2e}`
    const approxEstateMeterEmissionString = getApproxEstateMeterEmissionString(data, meterId, year, month)
    if (!isNullOrWhiteSpace(approxEstateMeterEmissionString)) return approxEstateMeterEmissionString
  }
  if (data.officeMeterEmission) {
    const officeMeterEmission = data.officeMeterEmission.find(
      (x) =>
        x.meterId === meterId &&
        new Date(x.startDate).getFullYear() === year &&
        new Date(x.stopDate).getFullYear() === year &&
        new Date(x.startDate).getMonth() === month &&
        new Date(x.stopDate).getMonth() === month
    )
    if (officeMeterEmission) return `Kontor: ${officeMeterEmission.value} ${EMISSION_KG_CO2e}`
    const approxOfficeMeterEmissionString = getApproxOfficeMeterEmissionString(data, meterId, year, month)
    if (!isNullOrWhiteSpace(approxOfficeMeterEmissionString)) return approxOfficeMeterEmissionString
  }
  if (data.meterEmission) {
    const meterEmission = data.meterEmission.find(
      (x) =>
        x.meterId === meterId &&
        new Date(x.startDate).getFullYear() === year &&
        new Date(x.stopDate).getFullYear() === year &&
        new Date(x.startDate).getMonth() === month &&
        new Date(x.stopDate).getMonth() === month
    )
    if (meterEmission) return `Mätartyp: ${meterEmission.value} ${EMISSION_KG_CO2e}`
    const approxMeterEmissionString = getApproxMeterEmissionString(data, meterId, year, month)
    if (!isNullOrWhiteSpace(approxMeterEmissionString)) return approxMeterEmissionString
  }
  return ''
}
export function getApproxEstateMeterCostString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.estateMeterCosts) {
    const estateMeterCosts = data.estateMeterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (estateMeterCosts) return `Fastighet: ${estateMeterCosts.cost} ${estateMeterCosts.currency.name}`
  }
  return ''
}
export function getApproxOfficeMeterCostString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.officeMeterCosts) {
    const officeMeterCosts = data.officeMeterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (officeMeterCosts) return `Kontor: ${officeMeterCosts.cost} ${officeMeterCosts.currency.name}`
  }
  return ''
}
export function getApproxEnergyZoneMeterCostString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.energyZoneMeterCosts) {
    const energyZoneMeterCost = data.energyZoneMeterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (energyZoneMeterCost) return `Elprisområde: ${energyZoneMeterCost.cost} ${energyZoneMeterCost.currency.name}`
  }
  return ''
}
export function getApproxMeterCostsString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.meterCosts) {
    const meterCost = data.meterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (meterCost) return `Mätartyp: ${meterCost.cost} ${meterCost.currency.name}`
  }
  return ''
}
export function getApproxEstateMeterEmissionString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.estateMeterEmission) {
    const estateMeterEmission = data.estateMeterEmission.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (estateMeterEmission) return `Fastighet: ${estateMeterEmission.value} ${EMISSION_KG_CO2e}`
  }
  return ''
}
export function getApproxOfficeMeterEmissionString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.officeMeterEmission) {
    const officeMeterEmission = data.officeMeterEmission.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (officeMeterEmission) return `Kontor: ${officeMeterEmission.value} ${EMISSION_KG_CO2e}`
  }
  return ''
}
export function getApproxMeterEmissionString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.meterEmission) {
    const meterEmission = data.meterEmission.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (meterEmission) return `Mätartyp: ${meterEmission.value} ${EMISSION_KG_CO2e}`
  }
  return ''
}

export function getApproxCostString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.estateMeterCosts) {
    const estateMeterCosts = data.estateMeterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (estateMeterCosts) return `Fastighet: ${estateMeterCosts.cost} ${estateMeterCosts.currency.name}`
  }
  if (data.officeMeterCosts) {
    const officeMeterCosts = data.officeMeterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (officeMeterCosts) return `Kontor: ${officeMeterCosts.cost} ${officeMeterCosts.currency.name}`
  }
  if (data.energyZoneMeterCosts) {
    const energyZoneMeterCost = data.energyZoneMeterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (energyZoneMeterCost) return `Elprisområde: ${energyZoneMeterCost.cost} ${energyZoneMeterCost.currency.name}`
  }
  if (data.meterCosts) {
    const meterCost = data.meterCosts.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (meterCost) return `Mätartyp: ${meterCost.cost} ${meterCost.currency.name}`
  }
  return ''
}
export function getApproxEmissionString(data: NodeCostEmissionDataDto, meterId: number, year: number, month: number) {
  const approxDate = new Date(year, month, 1)
  if (data.estateMeterEmission) {
    const estateMeterEmission = data.estateMeterEmission.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (estateMeterEmission) return `Fastighet: ${estateMeterEmission.value} ${EMISSION_KG_CO2e}`
  }
  if (data.officeMeterEmission) {
    const officeMeterEmission = data.officeMeterEmission.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (officeMeterEmission) return `Kontor: ${officeMeterEmission.value} ${EMISSION_KG_CO2e}`
  }
  if (data.meterEmission) {
    const meterEmission = data.meterEmission.find((x) => new Date(x.startDate) <= approxDate && new Date(x.stopDate) >= approxDate && x.meterId === meterId)
    if (meterEmission) return `Mätartyp: ${meterEmission.value} ${EMISSION_KG_CO2e}`
  }
  return ''
}
export function isOverlapping(id: number, startDate: Date, stopDate: Date, existingObjects: PeriodObject[]): boolean {
  for (const existing of existingObjects) {
    if (existing.id === id) continue

    if (startDate >= new Date(existing.startDate) && startDate < new Date(existing.stopDate)) {
      console.log('startDate', startDate, 'existing.startDate', new Date(existing.startDate), 'existing.stopDate', new Date(existing.stopDate))
      return true
    }

    if (stopDate > new Date(existing.startDate) && stopDate <= new Date(existing.stopDate)) {
      console.log('stopDate', stopDate, 'existing.startDate', new Date(existing.startDate), 'existing.stopDate', new Date(existing.stopDate))
      return true
    }

    if(startDate <= new Date(existing.startDate) && stopDate >= new Date(existing.stopDate)) {
      console.log('startDate', startDate, 'stopDate', stopDate, 'existing.startDate', new Date(existing.startDate), 'existing.stopDate', new Date(existing.stopDate))
      return true
    }
  }

  return false
}
