import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {deleteMeasurePoint, getCountersByMeasurePointId, getMeasurePointById, updateMeasurePoint} from '../../redux/Measurepoint/measurepointActions'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import {RootState} from '../../redux/rootReducer'
import PageTitleWrapper from '../shared/PageTitleWrapper'
import {Grid, Typography, Divider, Card, CardHeader, CardContent, Container, MenuItem, DialogTitle, Dialog, DialogContent, Button, DialogActions, DialogContentText} from '@mui/material'
import SecTextField from '../shared/Inputs/SecTextField'
import {IInactivePeriod} from '../../redux/counter/interfaces'
import SecSwitch from '../shared/Inputs/SecSwitch'
import SecButton from '../shared/Inputs/SecButton'
import {Delete, Edit, Save} from '@mui/icons-material'
import {IMeasurePointDetailsProps} from '../../redux/Measurepoint/measurepointInterfaces'
import {useNavigate, useParams} from 'react-router'
import {SaveResponse} from '../../shared/enums/SaveResponse'
import {Controller, useForm} from 'react-hook-form'
import SaveAlert from '../shared/alerts/SaveAlert'
import {Link} from 'react-router-dom'
import {hasPermissions, isAdmin, isNullOrWhiteSpace, sortSelectItems} from '../../shared/utils/utilities'
import {getaddressbyid} from '../../redux/address/addressActions'
import SearchSelectDialog, {ISelectItem} from '../shared/dialogs/SearchSelectDialog'
import IAddress, { AddressSearchResultDto } from '../../redux/address/interfaces'
import addressService from '../../services/addressService'
import {NODE_WRITE} from '../../shared/utils/constants'
import MeasurePointCounters from './MeasurePointCounters'
let defaultInactivePeriods: IInactivePeriod[] = [
  {period: 1, isInactive: false, label: 'Jan'},
  {period: 2, isInactive: false, label: 'Feb'},
  {period: 3, isInactive: false, label: 'Mar'},
  {period: 4, isInactive: false, label: 'Apr'},
  {period: 5, isInactive: false, label: 'Maj'},
  {period: 6, isInactive: false, label: 'Jun'},
  {period: 7, isInactive: false, label: 'Jul'},
  {period: 8, isInactive: false, label: 'Aug'},
  {period: 9, isInactive: false, label: 'Sep'},
  {period: 10, isInactive: false, label: 'Okt'},
  {period: 11, isInactive: false, label: 'Nov'},
  {period: 12, isInactive: false, label: 'Dec'},
]

interface IUpdateMeasurePointFormProps {
  name: string
  measurePointNumber: string
  description: string
  hidden: boolean
}

const MeasurePointDetails = (props: IMeasurePointDetailsProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [saveResult, setSaveResult] = useState<SaveResponse>(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [inactivePeriods, setInactivePeriods] = useState<IInactivePeriod[]>(defaultInactivePeriods)
  const [deleteMeasurePointDialogOpen, setDeleteMeasurePointDialogOpen] = useState<boolean>(false)
  const [selectAddressDialogOpen, setSelectAddressDialogOpen] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)  

  const measurepoint = useSelector((state: RootState) => state.measurepoint.selectedMeasurePoint)
  const address = useSelector((state: RootState) => state.address.selectedAddress)
  const measurePointCounters = useSelector((state: RootState) => state.measurepoint.counters)
  const {control, handleSubmit, reset} = useForm<IUpdateMeasurePointFormProps>({
    defaultValues: {
      name: '',
      measurePointNumber: '',
      description: '',
      hidden: false,
    },
  })

  useEffect(() => {
    if (props.measurePointId > 0) {
      dispatch(getMeasurePointById(props.measurePointId))
      dispatch(getCountersByMeasurePointId(props.measurePointId))
    }
  }, [props.measurePointId])

  useEffect(() => {
    if (measurepoint) {
      dispatch(getaddressbyid(measurepoint.addressId))
      const inactivePeriods: IInactivePeriod[] = defaultInactivePeriods.map((x: IInactivePeriod) => ({
        period: x.period,
        isInactive: measurepoint.inactivePeriods?.some((i) => i === x.period) ? true : false,
        label: x.label,
      }))
      setInactivePeriods(inactivePeriods)
      reset({
        name: measurepoint.name,
        measurePointNumber: isNullOrWhiteSpace(measurepoint.number) ? '' : measurepoint.number,
        description: measurepoint.description ?? '',
        hidden: measurepoint.hidden,
      })
    }
  }, [measurepoint])

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
    setHasAdminRights(isAdmin())
  }, [])

  const modifyInactivePeriods = (value: string, index: number) => {
    const modifiedPeriods = [...inactivePeriods]
    modifiedPeriods[index].isInactive = !modifiedPeriods[index].isInactive
    setInactivePeriods(modifiedPeriods)
  }

  const renderPeriodSwitch = (obj: any, index: number) => {
    return (
      <Grid item xs={1} key={index}>
        <SecSwitch label={obj.label}>
          <Switch checked={obj.isInactive} onChange={(e) => modifyInactivePeriods(e.target.value, index)} title='Avläses ej' />
        </SecSwitch>
      </Grid>
    )
  }
  const onChangeAddressClick = (e: any) => setSelectAddressDialogOpen(true)
  const onChangeAddressConfirmClick = (value: any) => {
    setSelectAddressDialogOpen(false)
    if (value) {
      dispatch(getaddressbyid(value))
    }
  }
  const onAddressSearchPerformed = async (keyword: string) => {
    let result: ISelectItem[] = []
    if (keyword && keyword.trim().length >= 3) {
      let addresses = await addressService.searchAddresses(keyword)
      result = addresses.map((x: AddressSearchResultDto) => {
        let addressPart = isNullOrWhiteSpace(x.name) ? x.id.toLocaleString() : x.name
        let estatePart = ''
        if(x.estate !== undefined) {          
          estatePart =  ` ${isNullOrWhiteSpace(x.estate.name) ? `ID: ${x.estate.id.toLocaleString()}` : x.estate.name}`
          addressPart = `${estatePart} / ${addressPart}`
        }

        const item: ISelectItem = {id: x.id, text: addressPart }
        return item
      })
      result = sortSelectItems(result)
    }
    return result
  }

  const onNewCounterClick = (e: any) => console.log('Skapar nytt räkneverk.')
  const onSaveMeasurePointClick = (form: IUpdateMeasurePointFormProps) => {
    if (measurepoint) {
      const addressId = address ? address.id : measurepoint.addressId
      const inactivePeriodsToUpdate = inactivePeriods.filter((modified) => modified.isInactive || measurepoint.inactivePeriods?.some((existing) => existing == modified.period))
      dispatch(updateMeasurePoint(measurepoint.id, addressId, form.name, form.description, form.hidden, form.measurePointNumber, inactivePeriodsToUpdate))
      setSaveResult(SaveResponse.SUCCESS)
      setAlertOpen(true)
    }
  }

  const onDeleteMeasurePointClick = () => {
    if (measurepoint) {
      setDeleteMeasurePointDialogOpen(false)
      dispatch(deleteMeasurePoint(measurepoint?.id))
      navigate(`/address/${measurepoint?.addressId}`)
    }
  }

  const renderEditButton = () =>
    canEdit && (
      <Grid item xs={10}>
        <SecButton startIcon={<Save />} variant='contained' onClick={handleSubmit(onSaveMeasurePointClick)}>
          Spara
        </SecButton>
      </Grid>
    )

  const renderDeleteButton = () =>
    hasAdminRights && (
      <Grid item xs={2}>
        <SecButton startIcon={<Delete />} variant='contained' onClick={(e: React.MouseEvent<HTMLButtonElement>) => setDeleteMeasurePointDialogOpen(true)} color='error' sx={{float: 'right'}}>
          Radera
        </SecButton>
      </Grid>
    )

  const renderModifyButtons = () => (
    <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
      {renderEditButton()}
      {renderDeleteButton()}
    </Grid>
  )
  return (
    <div>
      <PageTitleWrapper>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h3' component='h3' gutterBottom></Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </PageTitleWrapper>
      <Container maxWidth='lg'>
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <Card component='form' noValidate autoComplete='off'>
              <CardHeader title='Redigera mätpunkt' />
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                    <Grid item xs={12}>
                      <SaveAlert
                        result={saveResult}
                        open={alertOpen}
                        onClose={() => setAlertOpen(false)}
                        successMessage='Mätpunkten har sparats.'
                        errorMessage='Misslyckades med att spara mätpunkten.'
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={2}>
                      <SecTextField value={measurepoint ? measurepoint.id : 0} type='number' fullWidth disabled={true} InputProps={{readOnly: true}} label='Id' InputLabelProps={{shrink: true}} />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name='name'
                        control={control}
                        render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} label='Namn' InputLabelProps={{shrink: true}} disabled={!canEdit} />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name='measurePointNumber'
                        control={control}
                        render={({field}) => <TextField {...field} fullWidth inputProps={{maxLength: 40}} label='Nummer' InputLabelProps={{shrink: true}} disabled={!canEdit} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={6}>
                      <Controller
                        name='description'
                        control={control}
                        render={({field}) => <TextField {...field} fullWidth InputLabelProps={{shrink: true}} minRows={3} label='Kommentar' multiline disabled={!canEdit} />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    {hasAdminRights && (
                      <Grid item xs={3}>
                        <SecTextField
                          value={address ? address.name : ''}
                          fullWidth
                          inputProps={{maxLength: 40}}
                          disabled={true}
                          label='Address'
                          InputProps={{
                            readOnly: !canEdit,
                            endAdornment: (
                              <SecButton startIcon={<Edit />} onClick={onChangeAddressClick}>
                                Ändra
                              </SecButton>
                            ),
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <SecTextField
                        fullWidth
                        inputProps={{maxLength: 40}}
                        value={measurepoint ? measurepoint.insDate : ''}
                        label='Skapades'
                        InputLabelProps={{shrink: true}}
                        InputProps={{readOnly: true}}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SecTextField
                        fullWidth
                        inputProps={{maxLength: 40}}
                        value={measurepoint ? measurepoint.updDate : ''}
                        label='Uppdaterades'
                        InputLabelProps={{shrink: true}}
                        InputProps={{readOnly: true}}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        sx={{marginTop: '10px'}}
                        control={
                          <Controller
                            name='hidden'
                            control={control}
                            render={({field}) => {
                              return <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} title='Avläses ej' disabled={!canEdit} />
                            }}
                          />
                        }
                        label='Avläses ej'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardHeader title='Inaktiva perioder' />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                    {inactivePeriods.map((obj, index) => {
                      return renderPeriodSwitch(obj, index)
                    })}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>{renderModifyButtons()}</CardContent>
            </Card>
            <Card component='form' noValidate autoComplete='off' sx={{marginTop: '20px'}}>
              <CardHeader title='Räkneverk' />
              <Divider />
              <CardContent>
                <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                  <Grid item xs={12}>
                    <MeasurePointCounters counters={measurePointCounters.counters} isLoading={measurePointCounters.isLoading} errorMessage={measurePointCounters.errorMessage} />
                  </Grid>
                  {hasAdminRights ? (
                    <Grid item xs={12}>
                      <Link to={`/measurepoint/${measurepoint?.id}/counter/create`}>
                        <SecButton startIcon={<Save />} variant='contained' onClick={onNewCounterClick}>
                          Nytt räkneverk
                        </SecButton>
                      </Link>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {hasAdminRights && (
          <div>
            <Dialog open={deleteMeasurePointDialogOpen} onClose={() => setDeleteMeasurePointDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
              <DialogTitle id='alert-dialog-title'>{'Radera mätpunkt'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Vill du radera mätpunkten {isNullOrWhiteSpace(measurepoint?.name) ? `${measurepoint?.name}(${measurepoint?.id})` : `med ID: ${measurepoint?.id}`}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={onDeleteMeasurePointClick} autoFocus>
                  Radera
                </Button>
                <Button onClick={() => setDeleteMeasurePointDialogOpen(false)}>Avbryt</Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        <SearchSelectDialog
          open={selectAddressDialogOpen}
          title='Flytta mätpunkt'
          defaultItemLabel='-- Välj address --'
          text='Välj den address till vilken du vill flytta mätpunkten.'
          onConfirm={onChangeAddressConfirmClick}
          onCancel={() => setSelectAddressDialogOpen(false)}
          onSearch={onAddressSearchPerformed}
        />
      </Container>
    </div>
  )
}
export default MeasurePointDetails
