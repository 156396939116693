import {Add, Close, Delete, Help, RotateRight, SyncAlt} from '@mui/icons-material'
import {Alert, Button, Card, CardContent, Collapse, Grid, IconButton, styled, Typography} from '@mui/material'
import {DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deletePeriodicConsumption, getAllPeriodicConsumption, getPeriodConsumptionListItems, getPeriodicConsumption, savePeriodicConsumption, updateCounterPeriodConsumption } from '../../redux/counterPeriodConsumption/actions'
import {hasEditPermissions, hasPermissions, htmlDecode, isNullOrWhiteSpace} from '../../shared/utils/utilities'
import {ICounterPeriodConsumption, ICounterPeriodConsumptionInputDialogForm, ICounterPeriodConsumptionListItem, ICounterPeriodConsumptionLog, ICounterPeriodConsumptionProps } from '../../redux/counterPeriodConsumption/interfaces'
import { RootState } from '../../redux/rootReducer'
import { ResetType } from '../../shared/enums/ResetType'
import CounterPeriodConsumptionInputDialog from './CounterPeriodConsumptionInputDialog'
import { NODE_WRITE } from '../../shared/utils/constants'

enum SaveResponse {
  SUCCESS,
  FAIL,
  PENDING,
  NOT_SET,
}

const StripedDataGrid = styled(DataGrid)(({theme}) => ({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}))
const getResetIcon = (type: ResetType) => {
    if(type === ResetType.TURNED)
      return <RotateRight sx={{fontSize: 'small', marginTop: '4px'}}/>
    else if(type === ResetType.CHANGED)
      return <SyncAlt sx={{fontSize: 'small', marginTop: '4px'}}/>
    else
      return <Help sx={{fontSize: 'small', marginTop: '4px'}}/>
}

const CounterPeriodConsumptions = (props: ICounterPeriodConsumptionProps) => {

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState<ICounterPeriodConsumptionListItem | null>(null)
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)

  const savedConsumption: ICounterPeriodConsumption | null = useSelector((state: RootState) => state.counterPeriodConsumption.savedConsumption)
  const deletedConsumption: number = useSelector((state: RootState) => state.counter.deletedConsumption)
  const consumptions = useSelector((state: RootState) => state.counterPeriodConsumption.counterPeriodConsumptionListItems.counterPeriodConsumptionListItems)
  const createdCounterConsumptionPeriodLog = useSelector((state: RootState) => state.counterPeriodConsumption.createdCounterPeriodConsumptionLog.counterPeriodConsumptionLog)

  useEffect(() => {
    dispatch(getPeriodConsumptionListItems(props.counter.id))
    if (savedConsumption && saveResult === SaveResponse.PENDING) {
      setSaveResult(SaveResponse.SUCCESS)
      setAlertOpen(true)
      dispatch(getPeriodConsumptionListItems(props.counter.id))
    } else if (saveResult === SaveResponse.PENDING) {
      setSaveResult(SaveResponse.FAIL)
      setAlertOpen(true)
    }
  }, [savedConsumption])

  useEffect(() => {
    if(createdCounterConsumptionPeriodLog) {
      dispatch(getPeriodConsumptionListItems(props.counter.id))
    }
  }, [createdCounterConsumptionPeriodLog])

  useEffect(() => {    
    if(deletedConsumption > 0) {
      dispatch(getPeriodConsumptionListItems(props.counter.id))
    }
  }, [deletedConsumption])

  useEffect(() => {
    dispatch(getPeriodConsumptionListItems(props.counter.id))
  }, [props.counter.id])

  useEffect(() => {
    if(selectedRow) {
      setOpen(true)
    }
  }, [selectedRow])

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [])

  const deleteColumn: GridActionsColDef = {
    field: 'actions',
    type: 'actions',
    width: 100,
    cellClassName: 'actions',
    getActions: (params: GridRowParams) => {
      return canEdit
        ? [
            <GridActionsCellItem
              icon={<Delete />}
              label='Radera'
              onClick={() => handleDeleteClick(params.row)}
              color='inherit'              
              onResize={() => {}}
              onResizeCapture={() => {}}
              showInMenu={true}
            />,
          ]
        : []
    },
  }
  const columns: GridColDef[] = canEdit ? [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'value', headerName: 'Värde', flex: 1},
    {field: 'year', headerName: 'År', flex: 1},
    {field: 'month', headerName: 'Månad', flex: 1},
    {
      field: 'priority',
      headerName: 'Källa',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ICounterPeriodConsumptionLog[]>) => {
        return params.value ? "Extern" : "Inmatad"
      },
    },
    {
      field: 'counterPeriodConsumptionLogs',
      headerName: 'Meddelande',
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<ICounterPeriodConsumptionLog[]>) => {
        const logMessage = params.value && params.value.length > 0 ? htmlDecode(params.value[0].text) : ''
        const messageElement = <Typography>{logMessage}</Typography>
        return (
          messageElement // params.row.hasReset ? (<div style={{display: 'flex'}}>{getResetIcon(params.row)}&nbsp;{messageElement}</div>) : messageElement
        )
      }
    },
    deleteColumn
  ] :
  [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'value', headerName: 'Värde', flex: 1},
    {field: 'year', headerName: 'År', flex: 1},
    {field: 'month', headerName: 'Månad', flex: 1},
    {
      field: 'priority',
      headerName: 'Källa',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ICounterPeriodConsumptionLog[]>) => {
        return params.value ? "Extern" : "Inmatad"
      }
    },
    {
      field: 'counterPeriodConsumptionLogs',
      headerName: 'Meddelande',
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<ICounterPeriodConsumptionLog[]>) => {
        const logMessage = params.value && params.value.length > 0 ? htmlDecode(params.value[0].text) : ''
        const messageElement = <Typography>{logMessage}</Typography>
        return (
          messageElement //params.row.hasReset ? (<div style={{display: 'flex'}}>{getResetIcon(params.row)}&nbsp;{messageElement}</div>) : messageElement
        )
      }
    }
  ]
  
  const handleOnClick = (row: ICounterPeriodConsumptionListItem) => {        
    if(!canEdit) return
    setSelectedRow(row)
  }
  const handleDeleteClick = (row: ICounterPeriodConsumptionListItem) => {
    dispatch(deletePeriodicConsumption(row.id))
  } 
  
  const renderConsumptions = () => {
    const consumptionsWithKey = consumptions ? consumptions.map((consumption: ICounterPeriodConsumptionListItem) => ({ ...consumption, key: consumption.id})) : []
    return (
      <Card>
        <CardContent>
          <StripedDataGrid rows={consumptionsWithKey} columns={columns} autoHeight onRowClick={(param: any) => handleOnClick(param.row)}></StripedDataGrid>
        </CardContent>
      </Card>
    )
  }
  const renderAlert = () => {
    let alert = <div></div>
    switch (saveResult) {
      case SaveResponse.SUCCESS:
        alert = (
          <Alert
            severity='success'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            Förbrukningen {savedConsumption?.value} sparades för mätaren {savedConsumption?.counterId}
          </Alert>
        )
        break
      case SaveResponse.FAIL:
        alert = (
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setAlertOpen(false)
                }}>
                <Close fontSize='inherit' />
              </IconButton>
            }
            sx={{mb: 2}}>
            Misslyckades med att spara förbrukningen!
          </Alert>
        )
        break
      default:
        break
    }
    return <Collapse in={alertOpen}>{alert}</Collapse>
  }
  
  const onValueConfirmClick = (data: ICounterPeriodConsumptionInputDialogForm) => {
    if(!canEdit) return;
    if(data.id && data.id > 0) {
      dispatch(updateCounterPeriodConsumption(data.id, props.counter.id, data.value, data.year, data.month))
    }
    else {
      dispatch(savePeriodicConsumption(props.counter.id, data.value, data.year, data.month))
    }
    setOpen(false)
    setSaveResult(SaveResponse.PENDING)
  }
  const renderInputDialog = () => {
      const value = selectedRow ? selectedRow.value : 0
      return (
        <CounterPeriodConsumptionInputDialog
          open={open}
          counterId={props.counter.id}
          counterPeriodConsumptionListItem={selectedRow}
          onClose={() => setOpen(false)}
          onConfirm={(e: any) => onValueConfirmClick(e)}
        />
      )
  }
  const handleNewConsumptionSaveClick = (e: any) => {
    setSelectedRow(null)
    setOpen(true)
  }

  return (
    <div>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          {renderAlert()}
        </Grid>
        {canEdit ? (
             <Grid item xs={12}>
             <Button id='saveNewConsumption' onClick={handleNewConsumptionSaveClick} startIcon={<Add fontSize='small' />}>
               Ny förbrukning
             </Button>
           </Grid>
        ) : ''}
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          {renderConsumptions()}
        </Grid>
      </Grid>
      {renderInputDialog()}
    </div>
  )
}
export default CounterPeriodConsumptions