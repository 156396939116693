import {Typography, Button, Grid} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined'
import {isAdmin} from '../../shared/utils/utilities'
import {useState, useEffect} from 'react'

function PageHeader() {
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Typography variant='h3' component='h3' gutterBottom>
          Graddagsorter
        </Typography>
      </Grid>
      {hasAdminRights && (
        <Grid item>
          <Button onClick={() => navigate('/area/create')} sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<AddLocationAltOutlinedIcon fontSize='small' />}>
            Ny graddagsort
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default PageHeader
