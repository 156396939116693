export enum ActionTypes {    
    FETCH_COUNTER_PERIOD_CONSUMPTION_LOADING = 'FETCH_COUNTER_PERIOD_CONSUMPTION_LOADING',
    FETCH_COUNTER_PERIOD_CONSUMPTION_SUCCESS = 'FETCH_COUNTER_PERIOD_CONSUMPTION_SUCCESS',
    FETCH_COUNTER_PERIOD_CONSUMPTION_ERROR = 'FETCH_COUNTER_PERIOD_CONSUMPTION_ERROR',
    FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_LOADING = 'FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_LOADING',
    FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_SUCCESS = 'FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_SUCCESS',
    FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_ERROR = 'FETCH_ALL_COUNTER_PERIOD_CONSUMPTION_ERROR',
    FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_LOADING = 'FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_LOADING',
    FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_SUCCESS = 'FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_SUCCESS',
    FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_ERROR = 'FETCH_COUNTER_PERIOD_CONSUMPTION_LIST_ITEMS_ERROR',
    SAVE_COUNTER_PERIOD_CONSUMPTION_LOADING = 'SAVE_COUNTER_PERIOD_CONSUMPTION_LOADING',
    SAVE_COUNTER_PERIOD_CONSUMPTION_SUCCESS = 'SAVE_COUNTER_PERIOD_CONSUMPTION_SUCCESS',
    SAVE_COUNTER_PERIOD_CONSUMPTION_ERROR = 'SAVE_COUNTER_PERIOD_CONSUMPTION_ERROR',
    UPDATE_COUNTER_PERIOD_CONSUMPTION_LOADING = 'UPDATE_COUNTER_PERIOD_CONSUMPTION_LOADING',
    UPDATE_COUNTER_PERIOD_CONSUMPTION_SUCCESS = 'UPDATE_COUNTER_PERIOD_CONSUMPTION_SUCCESS',
    UPDATE_COUNTER_PERIOD_CONSUMPTION_ERROR = 'UPDATE_COUNTER_PERIOD_CONSUMPTION_ERROR',
    DELETE_COUNTER_PERIOD_CONSUMPTION_LOADING = 'DELETE_COUNTER_PERIOD_CONSUMPTION_LOADING',
    DELETE_COUNTER_PERIOD_CONSUMPTION_SUCCESS = 'DELETE_COUNTER_PERIOD_CONSUMPTION_SUCCESS',
    DELETE_COUNTER_PERIOD_CONSUMPTION_ERROR = 'DELETE_COUNTER_PERIOD_CONSUMPTION_ERROR',

    GET_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING = 'GET_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING',
    GET_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS = 'GET_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS',
    GET_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR = 'GET_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR',
    CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING = 'CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING',
    CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS = 'CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS',
    CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR = 'CREATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR',
    DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING = 'DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING',
    DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS = 'DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS',
    DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR = 'DELETE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR',
    UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING = 'UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_LOADING',
    UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS = 'UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_SUCCESS',
    UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR = 'UPDATE_COUNTER_PERIOD_CONSUMPTION_LOG_ERROR', 
}