export enum ActionTypes {
    GET_MEASUREPOINT_LOADING = "GET_MEASUREPOINT_LOADING",
    GET_MEASUREPOINT_SUCCESS = "GET_MEASUREPOINT_SUCCESS",
    GET_MEASUREPOINT_FAILED = "GET_MEASUREPOINT_FAILED",

    GET_MEASUREPOINTS_LOADING = "GET_MEASUREPOINTS_LOADING",
    GET_MEASUREPOINTS_SUCCESS = "GET_MEASUREPOINTS_SUCCESS",
    GET_MEASUREPOINTS_FAILED = "GET_MEASUREPOINTS_FAILED",

    CREATE_MEASUREPOINT_BY_ID_LOADING = "CREATE_MEASUREPOINT_BY_ID_LOADING",
    CREATE_MEASUREPOINT_BY_ID_SUCCESS = "CREATE_MEASUREPOINT_BY_ID_SUCCESS",
    CREATE_MEASUREPOINT_BY_ID_FAILED = "CREATE_ADDRESSMEASUREPOINT_BY_ID_FAILED",

    CREATE_MEASUREPOINT_LOADING = "CREATE_MEASUREPOINT_LOADING",
    CREATE_MEASUREPOINT_SUCCESS = "CREATE_MEASUREPOINT_SUCCESS",
    CREATE_MEASUREPOINT_FAILED = "CREATE_ADDRESSMEASUREPOINT_FAILED",

    UPDATE_MEASUREPOINT_LOADING = "UPDATE_MEASUREPOINT_LOADING",
    UPDATE_MEASUREPOINT_SUCCESS = "UPDATE_MEASUREPOINT_SUCCESS",
    UPDATE_MEASUREPOINT_FAILED = "UPDATE_ADDRESSMEASUREPOINT_FAILED",

    DELETE_MEASUREPOINT_LOADING = "DELETE_MEASUREPOINT_LOADING",
    DELETE_MEASUREPOINT_SUCCESS = "DELETE_MEASUREPOINT_SUCCESS",
    DELETE_MEASUREPOINT_FAILED = "DELETE_MEASUREPOINT_FAILED",

    CREATE_MEASUREPOINTNUMBER_LOADING = "CREATE_MEASUREPOINTNUMBER_LOADING",
    CREATE_MEASUREPOINTNUMBER_SUCCESS = "CREATE_MEASUREPOINTNUMBER_SUCCESS",
    CREATE_MEASUREPOINTNUMBER_FAILED = "CREATE_ADDRESSMEASUREPOINTNUMBER_FAILED",

    FETCH_MEASUREPOINT_METER_TYPES_SUCCESS = "FETCH_MEASUREPOINT_METER_TYPES_SUCCESS",
    FETCH_MEASUREPOINT_METER_TYPES_FAILED = "FETCH_MEASUREPOINT_METER_TYPES_ERROR",
    FETCH_MEASUREPOINT_METER_TYPES_LOADING = "FETCH_MEASUREPOINT_METER_TYPES_LOADING",

    FETCH_MEASUREPOINT_CONSUMPTION_LOADING = "FETCH_MEASUREPOINT_CONSUMPTION_LOADING",
    FETCH_MEASUREPOINT_CONSUMPTION_SUCCESS = "FETCH_MEASUREPOINT_CONSUMPTION_SUCCESS",
    FETCH_MEASUREPOINT_CONSUMPTION_FAILED = "FETCH_MEASUREPOINT_CONSUMPTION_FAILED",

    FETCH_MEASUREPOINT_READING_LOGS_LOADING="FETCH_MEASUREPOINT_READING_LOGS_LOADING",
    FETCH_MEASUREPOINT_READING_LOGS_SUCCESS="FETCH_MEASUREPOINT_READING_LOGS_SUCCESS",
    FETCH_MEASUREPOINT_READING_LOGS_FAILED="FETCH_MEASUREPOINT_READING_LOGS_FAILED",

    FETCH_MEASUREPOINT_CONSUMPTION_LOGS_LOADING="FETCH_MEASUREPOINT_CONSUMPTION_LOGS_LOADING",
    FETCH_MEASUREPOINT_CONSUMPTION_LOGS_SUCCESS="FETCH_MEASUREPOINT_CONSUMPTION_LOGS_SUCCESS",
    FETCH_MEASUREPOINT_CONSUMPTION_LOGS_FAILED="FETCH_MEASUREPOINT_CONSUMPTION_LOGS_FAILED",

    FETCH_COUNTERS_BY_MEASUREPOINT_ID_FAILED="FETCH_COUNTERS_BY_MEASUREPOINT_ID_FAILED",
    FETCH_COUNTERS_BY_MEASUREPOINT_ID_LOADING="FETCH_COUNTERS_BY_MEASUREPOINT_ID_LOADING",
    FETCH_COUNTERS_BY_MEASUREPOINT_ID_SUCCESS="FETCH_COUNTERS_BY_MEASUREPOINT_ID_SUCCESS",
  }