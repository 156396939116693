
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import externalSystemService from "../../services/externalSystemService";
export const getCounterExternalSystems = () => async (dispatch: Dispatch) => {
    dispatch({type: ActionTypes.FETCH_EXTERNAL_SYSTEMS_LOADING})
    try {
      const externalSystems = await externalSystemService.getExternalSystems()
      return dispatch({type: ActionTypes.FETCH_EXTERNAL_SYSTEMS_SUCCESS, payload: externalSystems})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_EXTERNAL_SYSTEMS_ERROR, payload: error})
    }
  }