import React from 'react'
import {IConsumptionWithLog, ITableLog} from '../../../redux/counterValue/interfaces'
import {IConsumptionTableCellProps} from './interfaces'
import {Grid, Tooltip} from '@mui/material'
import {hasEditPermissions, hasPermissions} from '../../../shared/utils/utilities'
import CaseTooltip from '../case/CaseTooltip'
import CaseIconButton from '../case/CaseIconButton'
import ConsumptionTableValue from './ConsumptionTableValue'
import {COUNTER, DEVIATION_READ} from '..//../../shared/utils/constants'
const ConsumptionTableCell = (props: IConsumptionTableCellProps) => {
  const canEdit = hasPermissions([DEVIATION_READ])
  return (
    <Grid container direction='row' alignItems='center'>
      <ConsumptionTableValue value={props.data} category={props.category} shouldEstimate={props.shouldEstimate} meterType={props.meterType} unit={props.unit}/>
      {canEdit && props.data.logs && props.data.logs.length > 0 ? (
        props.data.logs ? (
          <Tooltip title={<CaseTooltip logs={props.data.logs} />}>
            <div>
              <CaseIconButton isSummarized={props.nodeType !== COUNTER} logs={props.data.logs} onClick={(x: ITableLog) => props.onTooltipButtonClick(x)} />
            </div>
          </Tooltip>
        ) : (
          <React.Fragment />
        )
      ) : (
        <React.Fragment />
      )}
    </Grid>
  )
}
export default ConsumptionTableCell
