import React, {useEffect, useState} from 'react'
import {IEstateMeterEmissionProps} from './interfaces'
import {Container, Grid} from '@mui/material'
import ResponseAlert from '../../../components/alert/ResponseAlert'
import AddButton from '../../shared/buttons/AddButton'
import {IEstateMeterEmission} from '../../../redux/estateMeterEmission/interfaces'
import {SaveResponse} from '../../../shared/enums/SaveResponse'
import {IEmission} from '../../../redux/emission/interfaces'
import {RootState} from '../../../redux/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import {CURRENCY_SEK, EMISSION_KG_CO2, NODE_WRITE, NOT_SET} from '../../../shared/utils/constants'
import {orderBy} from 'lodash'
import EstateMeterEmissionsInputDialog from './estateMeterEmissionInputDialog/EstateMeterEmissionInputDialog'
import {IEstateMeterEmissionInputForm} from './estateMeterEmissionInputDialog/interfaces'
import {hasEditPermissions, hasPermissions} from '../../../shared/utils/utilities'
import {createEstateMeterEmission, deleteEstateMeterEmission, getEstateMeterEmissions, updateEstateMeterEmission} from '../../../redux/estateMeterEmission/actions'
import {getEmissionByName} from '../../../redux/emission/actions'
import {getMeterTypesAtEstate} from '../../../redux/estate/actions'
import EstateMeterEmissionsTabContext from './EstateMeterEmissionTabContext'
import {getAllUnits} from '../../../redux/unit/unitActions'
import { IUnit } from '../../../redux/unit/unitInterfaces'
import { IMeterType } from '../../../redux/meter/interfaces'
import MessageDialog from '../../../components/shared/alerts/MessageDialog'
import { isOverlapping } from '../../../components/consumption/graph/costEmissionHelper'

const EstateMeterEmissions = (props: IEstateMeterEmissionProps) => {
  const dispatch = useDispatch()

  const [selectedRow, setSelectedRow] = useState<IEstateMeterEmission | undefined>()
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false)
  const [saveResponse, setSaveResponse] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('0')
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [alreadyExistsDialogOpen, setAlreadyExistsDialogOpen] = useState<boolean>(false)

  const meterTypes = useSelector((state: RootState) => state.estate.meterTypes ?? [])
  const units = useSelector((State: RootState) => State.unit.units ?? [])
  let emissions = useSelector((state: RootState) => {
    const sorted = orderBy(state.estate.estateMeterEmissions, ['startDate', 'stopDate'], ['asc'])
    return sorted
  })

  const defaultEmission = useSelector((state: RootState) => state.emission.selectedEmission)
  const savedMeterEmissions = useSelector((state: RootState) => state.estate.selectedEstateMeterEmission)
  const deletedEstateMeterEmissions = useSelector((state: RootState) => state.estate.deletedEstateMeterEmission)
  const selectedEstateMeterEmissions = useSelector((state: RootState) => state.estate.selectedEstateMeterEmission)

  
  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [])

  useEffect(() => {
    if (selectedEstateMeterEmissions && selectedTab !== '' && parseInt(selectedTab) !== 0) {
      dispatch(getEstateMeterEmissions(props.estateId, parseInt(selectedTab)))
    }
  }, [selectedEstateMeterEmissions])

  useEffect(() => {
    if (deletedEstateMeterEmissions > 0 && selectedTab !== '' && parseInt(selectedTab) !== 0) {
      dispatch(getEstateMeterEmissions(props.estateId, parseInt(selectedTab)))
    }
  }, [deletedEstateMeterEmissions])

  useEffect(() => {
    dispatch(getEmissionByName(EMISSION_KG_CO2))
    dispatch(getMeterTypesAtEstate(props.estateId))
    if (units.length === 0) {
      dispatch(getAllUnits())
    }
  }, [props.estateId])

  useEffect(() => {
    if (meterTypes?.length > 0) setSelectedTab(meterTypes ? meterTypes[0].id.toString() : '0')
  }, [meterTypes])

  useEffect(() => {
    if (selectedTab !== '' && parseInt(selectedTab) !== 0) dispatch(getEstateMeterEmissions(props.estateId, parseInt(selectedTab)))
  }, [props.estateId, selectedTab])



  const onHandleSaveNewMeterEmissionClick = (event: React.MouseEventHandler<HTMLButtonElement>) => {
    setSelectedRow(undefined)
    setSaveDialogOpen(true)
  }

  const onEstateMeterEmissionEditClick = (emission: IEstateMeterEmission) => {
    setSelectedRow(emission)
    setSaveDialogOpen(true)
  }

  const onEstateMeterEmissionDeleteClick = (emission: IEstateMeterEmission) => {
    dispatch(deleteEstateMeterEmission(emission.id))
  }

  const onValueConfirmClick = (data: IEstateMeterEmissionInputForm) => {
    if (!canEdit) return

    if(isOverlapping(data.id, data.startDate, data.stopDate, emissions)) {      
      setAlreadyExistsDialogOpen(true)
      return
    }

    if (data.id > 0) {
      dispatch(updateEstateMeterEmission(data.id, props.estateId, data.emissionId, parseInt(selectedTab), data.value, data.startDate, data.stopDate))
    } else {
      dispatch(createEstateMeterEmission(props.estateId, data.emissionId, parseInt(selectedTab), data.value, data.startDate, data.stopDate))
    }
    setSaveDialogOpen(false)
    setSaveResponse(SaveResponse.PENDING)
  }

  const renderInputDialog = () => {
    if (defaultEmission) {
      const currentMeter = meterTypes.find((x: IMeterType) => x.id === parseInt(selectedTab))
      const unit: IUnit | undefined = units.find((x: IUnit) => x.id === currentMeter?.unitId)
      return (
        <EstateMeterEmissionsInputDialog
          defaultEmission={defaultEmission}
          open={saveDialogOpen}
          item={selectedRow}
          onClose={() => setSaveDialogOpen(false)}
          onConfirm={(e: IEstateMeterEmissionInputForm) => onValueConfirmClick(e)}
          unit={unit}
        />
      )
    }
    return ''
  }

  return (
    <Container maxWidth='lg'>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <ResponseAlert
            response={saveResponse}
            open={alertOpen}
            successMessage={`Utsläppet ${savedMeterEmissions ? savedMeterEmissions.value : NOT_SET} registerades.`}
            failureMessage='Det gick inte att registera utsläpp.'
          />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <AddButton onClick={onHandleSaveNewMeterEmissionClick} id='addMeterEmissions' label='Nytt utsläpp' isAuthorized={canEdit} />
        </Grid>
      </Grid>
      <Grid container direction='row' alignItems='stretch' spacing={1}>
        <Grid item xs={12}>
          <EstateMeterEmissionsTabContext
            estateId={props.estateId}
            meterTypes={meterTypes}
            units={units}
            estateMeterEmissions={emissions}
            onEstateMeterEmissionEditClick={onEstateMeterEmissionEditClick}
            onEstateMeterEmissionDeleteClick={onEstateMeterEmissionDeleteClick}
            onTabChange={(value: string) => setSelectedTab(value)}
          />
        </Grid>
      </Grid>
      {renderInputDialog()}
      <MessageDialog open={alreadyExistsDialogOpen} setOpen={(value) => setAlreadyExistsDialogOpen(value)} title='Utsläppsuppgift finns redan' message='Det finns redan ett utsläpp registrerat för denna fastighet inom valt tidsspann.' />
    </Container>
  )
}
export default EstateMeterEmissions
