export enum ActionTypes {
    GET_OFFICES_LOADING = "GET_OFFICES_LOADING",
    GET_OFFICES_SUCCESS = "GET_OFFICES_SUCCESS",
    GET_OFFICES_FAILED = "GET_OFFICES_FAILED",
    GET_OFFICE_LOADING = "GET_OFFICE_LOADING",
    GET_OFFICE_SUCCESS = "GET_OFFICE_SUCCESS",
    GET_OFFICE_FAILED = "GET_OFFICE_FAILED",
    CREATE_OFFICE_LOADING = "CREATE_OFFICE_LOADING",
    CREATE_OFFICE_SUCCESS = "CREATE_OFFICE_SUCCESS",
    CREATE_OFFICE_FAILED = "CREATE_OFFICE_FAILED",
    DELETE_OFFICE_LOADING = "DELETE_OFFICE_LOADING",
    DELETE_OFFICE_SUCCESS = "DELETE_OFFICE_SUCCESS",
    DELETE_OFFICE_FAILED = "DELETE_OFFICE_FAILED",
    UPDATE_OFFICE_LOADING = "UPDATE_OFFICE_LOADING",
    UPDATE_OFFICE_SUCCESS = "UPDATE_OFFICE_SUCCESS",
    UPDATE_OFFICE_FAILED = "UPDATE_OFFICE_FAILED",  
    GET_OFFICE_METER_COSTS_LOADING = "GET_OFFICE_METER_COSTS_LOADING",
    GET_OFFICE_METER_COSTS_SUCCESS = "GET_OFFICE_METER_COSTS_SUCCESS",
    GET_OFFICE_METER_COSTS_FAILED = "GET_OFFICE_METER_COSTS_FAILED",
    GET_OFFICE_METER_COST_LOADING = "GET_OFFICE_METER_COST_LOADING",
    GET_OFFICE_METER_COST_SUCCESS = "GET_OFFICE_METER_COST_SUCCESS",
    GET_OFFICE_METER_COST_FAILED = "GET_OFFICE_METER_COST_FAILED",
    CREATE_OFFICE_METER_COST_LOADING = "CREATE_OFFICE_METER_COST_LOADING",
    CREATE_OFFICE_METER_COST_SUCCESS = "CREATE_OFFICE_METER_COST_SUCCESS",
    CREATE_OFFICE_METER_COST_FAILED = "CREATE_OFFICE_METER_COST_FAILED",
    UPDATE_OFFICE_METER_COST_LOADING = "UPDATE_OFFICE_METER_COST_LOADING",
    UPDATE_OFFICE_METER_COST_SUCCESS = "UPDATE_OFFICE_METER_COST_SUCCESS",
    UPDATE_OFFICE_METER_COST_FAILED = "UPDATE_OFFICE_METER_COST_FAILED",
    DELETE_OFFICE_METER_COST_LOADING = "DELETE_OFFICE_METER_COST_LOADING",
    DELETE_OFFICE_METER_COST_SUCCESS = "DELETE_OFFICE_METER_COST_SUCCESS",
    DELETE_OFFICE_METER_COST_FAILED = "DELETE_OFFICE_METER_COST_FAILED",
    GET_OFFICE_METER_EMISSIONS_LOADING = "GET_OFFICE_METER_EMISSIONS_LOADING",
    GET_OFFICE_METER_EMISSIONS_SUCCESS = "GET_OFFICE_METER_EMISSIONS_SUCCESS",
    GET_OFFICE_METER_EMISSIONS_FAILED = "GET_OFFICE_METER_EMISSIONS_FAILED",
    GET_OFFICE_METER_EMISSION_LOADING = "GET_OFFICE_METER_EMISSION_LOADING",
    GET_OFFICE_METER_EMISSION_SUCCESS = "GET_OFFICE_METER_EMISSION_SUCCESS",
    GET_OFFICE_METER_EMISSION_FAILED = "GET_OFFICE_METER_EMISSION_FAILED",
    CREATE_OFFICE_METER_EMISSION_LOADING = "CREATE_OFFICE_METER_EMISSION_LOADING",
    CREATE_OFFICE_METER_EMISSION_SUCCESS = "CREATE_OFFICE_METER_EMISSION_SUCCESS",
    CREATE_OFFICE_METER_EMISSION_FAILED = "CREATE_OFFICE_METER_EMISSION_FAILED",
    UPDATE_OFFICE_METER_EMISSION_LOADING = "UPDATE_OFFICE_METER_EMISSION_LOADING",
    UPDATE_OFFICE_METER_EMISSION_SUCCESS = "UPDATE_OFFICE_METER_EMISSION_SUCCESS",
    UPDATE_OFFICE_METER_EMISSION_FAILED = "UPDATE_OFFICE_METER_EMISSION_FAILED",
    DELETE_OFFICE_METER_EMISSION_LOADING = "DELETE_OFFICE_METER_EMISSION_LOADING",
    DELETE_OFFICE_METER_EMISSION_SUCCESS = "DELETE_OFFICE_METER_EMISSION_SUCCESS",
    DELETE_OFFICE_METER_EMISSION_FAILED = "DELETE_OFFICE_METER_EMISSION_FAILED",
}