import { Reducer } from 'redux'
import { ActionTypes } from './types'
import { FilterState } from './interfaces'
import { CONSUMPTION } from '../../shared/utils/constants'

export const initialState: FilterState = {
  isLoading: false,
  types: [],
  offices: [],
  consumptionCategory: CONSUMPTION,
  errorMessage: undefined,
  listFilters: {}
}
export const filterReducer: Reducer<FilterState> = (state = initialState, {type, payload}) => {
    switch (type) {
      case ActionTypes.FETCH_FILTER_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.FETCH_FILTER_SUCCESS: {
        const {types, offices} = payload
        return {...state, isLoading: false, types: types, offices: offices}
      }
      case ActionTypes.FETCH_FILTER_FAILED: {
        return {...state, isLoading: false, errorMessage: payload.response.status}
      }
      case ActionTypes.SET_SELECTED_CONSUMPTION_CATEGORY_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.SET_SELECTED_CONSUMPTION_CATEGORY_SUCCESS: {     
        return {...state, isLoading: false, consumptionCategory: payload}
      }
      case ActionTypes.SET_SELECTED_CONSUMPTION_CATEGORY_FAILED: {
        return {...state, isLoading: false, errorMessage: payload.response.status}
      }
      case ActionTypes.SET_COLUMN_FILTER:          
          return { ...state, listFilters: { ...state.listFilters, [payload.listName] :  { ...state.listFilters[payload.listName], model: payload.model }}}
      case ActionTypes.SET_COLUMN_SORT:     
        return { ...state, listFilters: { ...state.listFilters, [payload.listName] : { ...state.listFilters[payload.listName], sorting: { columnName: payload.columnName, order: payload.value } }}}    
      default: {
        return state
      }
    }
  }
  