import {Dispatch} from 'redux'
import estateService from '../../services/estateService'
import {ActionTypes} from './types'
import {ActionTypes as TreeActionTypes} from '../tree/treeTypes'
import attributeService from '../../services/attributeService'
import IAttribute, { IAttributeControl } from '../../redux/attribute/interfaces'
import {castUnit, isNullOrWhiteSpace, processConsumptions, setMeterTypeOrder} from '../../shared/utils/utilities'
import {IAreaEstate, IEstateAttribute} from './Interfaces'
import userService from '../../services/userService'
import {IMeterType} from '../meter/interfaces'
import configurationService from '../../services/configurationService'
import { ESTATE } from '../../shared/utils/constants'
import Area from '../area/Interfaces.ts'
import { IUnit } from '../unit/unitInterfaces'

export const getEstates = () => async (dispatch: Dispatch) => {
  try {
    dispatch({type: ActionTypes.FETCH_ESTATES_LOADING})
    let estates = await estateService.getEstates()
    estates = estates.sort((x: any, y: any) => {
      if (x.name.trim().toLower() > y.name.trim().toLower()) return 1
      else if (y.name.trim().toLower() > x.name.trim().toLower()) return -1
      else return 0
    })
    return dispatch({type: ActionTypes.FETCH_ESTATES_SUCCESS, payload: estates})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATES_FAILED, payload: error})
  }
}

export const fetchEstateByOwnerId = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_OWNERESTATE_LOADING})
      const {data} = await estateService.getEstateByOwnerId(id)
      return dispatch({type: ActionTypes.FETCH_OWNERESTATE_SUCCESS, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_OWNERESTATE_FAILED, payload: error})
    }
  }
}
export const getConsumption = (estateId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_LOADING})
  try {
    const consumptions = await estateService.getConsumption(estateId, meterTypeId, startDate, stopDate, useDegreeDayCorrection)
    return dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_SUCCESS, payload: consumptions})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_ERROR, payload: error})
  }
}
export const getNodeConsumption = (estateId: number, meterTypeId: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, shouldEstimate?: boolean, unit?: IUnit, category?: string) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_LOADING})
  try {
    const consumptions = shouldEstimate
      ? await estateService.getNodeConsumptionAndEstimate(estateId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
      : await estateService.getNodeConsumption(estateId, meterTypeId, startDate, stopDate, useDegreeDayCorrection, castUnit(unit))
    return dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_SUCCESS, payload: processConsumptions(consumptions, unit, category)})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_ERROR, payload: error})
  }
}

export const getMeterTypesAtEstate = (estateId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_METER_TYPES_LOADING})
  try {
    let meterTypes = await estateService.getMeterTypesAtEstate(estateId)
    const degreeDayCorrectedMeterTypeIds = await configurationService.getDegreeDayCorrectedMeterTypes()
    meterTypes = meterTypes.map((type: IMeterType) => ({...type, canDegreeDayAdjust: degreeDayCorrectedMeterTypeIds.some((x: number) => x === type.id)}))
    const sorted = setMeterTypeOrder(meterTypes)
    return dispatch({type: ActionTypes.FETCH_ESTATE_METER_TYPES_SUCCESS, payload: sorted})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATE_METER_TYPES_ERROR, payload: error})
  }
}
export const getEstate = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_BY_ID_LOADING})
  try {
    const estate = await estateService.getEstate(id)
    const estateAttributes = await estateService.getEstateAttributes(id)
    const attributes = await attributeService.getAttributes()
    let estateArea : Area | undefined = undefined
    try {
      estateArea = await estateService.getEstateArea(id)
    }
    catch(error) {
      console.log(error)
    }
    const rekylAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'REKYL_ID' && attribute.class === 'ESTATE')
    const officeAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'OFFICE' && attribute.class === 'ESTATE')
    const atempAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'ATEMP' && attribute.class === 'ESTATE')
    const loaAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'LOA' && attribute.class === 'ESTATE')
    const categoryAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'CATEGORY' && attribute.class === 'ESTATE')

    const estateRekylIdAttribute = estateAttributes.find((estateAttribute: IEstateAttribute) => estateAttribute.attributeId === rekylAttribute.id)
    const estateOfficeAttribute = estateAttributes.find((estateAttribute: IEstateAttribute) => estateAttribute.attributeId === officeAttribute.id)
    const estateAtempAttribute = estateAttributes.find((estateAttribute: IEstateAttribute) => estateAttribute.attributeId === atempAttribute.id)
    const estateLoaAttribute = estateAttributes.find((estateAttribute: IEstateAttribute) => estateAttribute.attributeId === loaAttribute.id)
    const estateCategoryAttribute = estateAttributes.find((estateAttribute: IEstateAttribute) => estateAttribute.attributeId === categoryAttribute.id)
    return dispatch({
      type: ActionTypes.FETCH_ESTATE_BY_ID_SUCCESS,
      payload: {
        ...estate,
        office: estateOfficeAttribute ? estateOfficeAttribute.value : 'NOT_SET',
        category: estateCategoryAttribute ? estateCategoryAttribute.value : 'NOT_SET',
        rekylId: estateRekylIdAttribute ? estateRekylIdAttribute.value : '',
        atemp: estateAtempAttribute ? estateAtempAttribute.value : '',
        loa: estateLoaAttribute ? estateLoaAttribute.value : '',
        areaId: estateArea ? estateArea.id : 0,
      },
    })
  } catch (error) {
    console.log(error)
    return dispatch({type: ActionTypes.FETCH_ESTATE_BY_ID_FAILED, payload: error})
  }
}

export const createEstate =
  (ownerId: number, name: string, description: string, readerId: number, areaId: number, rekylId: string, office: string, atemp: string, loa: string, category: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.CREATE_ESTATE_LOADING})
      const createdEstate = await estateService.createEstate(ownerId, name, description, false)
      const attributes = await attributeService.getAttributes()
      const rekylAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'REKYL_ID' && attribute.class === 'ESTATE')
      const officeAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'OFFICE' && attribute.class === 'ESTATE')
      const atempAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'ATEMP' && attribute.class === 'ESTATE')
      const loaAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'LOA' && attribute.class === 'ESTATE')
      const categoryAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'CATEGORY' && attribute.class === 'ESTATE')
      if (!isNullOrWhiteSpace(rekylId)) if (rekylAttribute) await estateService.createEstateAttribute(createdEstate.id, rekylAttribute.id, rekylId)
      if (!isNullOrWhiteSpace(atemp)) if (atempAttribute) await estateService.createEstateAttribute(createdEstate.id, atempAttribute.id, atemp)
      if (!isNullOrWhiteSpace(loa)) if (loaAttribute) await estateService.createEstateAttribute(createdEstate.id, loaAttribute.id, loa)
      if (office !== 'NOT_SET') if (officeAttribute) await estateService.createEstateAttribute(createdEstate.id, officeAttribute.id, office)
      if (category !== 'NOT_SET') if (categoryAttribute) await estateService.createEstateAttribute(createdEstate.id, categoryAttribute.id, category)
      if (areaId > 0) await estateService.createEstateArea(createdEstate.id, areaId)
      if (areaId > 0) await estateService.createEstateArea(createdEstate.id, areaId)
      if (readerId > 0) await estateService.createEstateUser(createdEstate.id, readerId)
      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({type: ActionTypes.CREATE_ESTATE_SUCCESS, payload: createdEstate})
    } catch (error) {
      return dispatch({type: ActionTypes.CREATE_ESTATE_FAILED, payload: error})
    }
  }

export const updateEstate =
  (id: number, ownerId: number, name: string, description: string, hidden: boolean, areaId: number, rekylId: string, office: string, atemp: string, loa: string, category: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.UPDATE_ESTATE_LOADING})
      const updatedEstate = await estateService.updateEstate(id, ownerId, name, description, hidden)
      const attributes = await attributeService.getAttributes()
      const estateAttributes: IEstateAttribute[] = await estateService.getEstateAttributes(id)
      const rekylAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'REKYL_ID' && attribute.class === 'ESTATE')
      const officeAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'OFFICE' && attribute.class === 'ESTATE')
      const atempAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'ATEMP' && attribute.class === 'ESTATE')
      const loaAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'LOA' && attribute.class === 'ESTATE')
      const categoryAttribute = attributes.find((attribute: IAttribute) => attribute.name === 'CATEGORY' && attribute.class === 'ESTATE')
      let estateAttribute: IEstateAttribute | undefined = undefined
      const updatedRekylAttribute = !isNullOrWhiteSpace(rekylId) ? (rekylAttribute ? await estateService.createEstateAttribute(updatedEstate.id, rekylAttribute.id, rekylId) : null) : null
      if (!updatedRekylAttribute) {
        estateAttribute = estateAttributes.find((x) => x.attributeId === rekylAttribute.id)
        if (estateAttribute) {
          await estateService.deleteEstateAttribute(estateAttribute.id)
        }
      }

      const updatedAtempAttribute = !isNullOrWhiteSpace(atemp) ? (atempAttribute ? await estateService.createEstateAttribute(updatedEstate.id, atempAttribute.id, atemp) : null) : null
      if (!updatedAtempAttribute) {
        estateAttribute = estateAttributes.find((x) => x.attributeId === atempAttribute.id)
        if (estateAttribute) {
          await estateService.deleteEstateAttribute(estateAttribute.id)
        }
      }

      const updatedLoaAttribute = !isNullOrWhiteSpace(loa) ? (loaAttribute ? await estateService.createEstateAttribute(updatedEstate.id, loaAttribute.id, loa) : null) : null
      if (!updatedLoaAttribute) {
        estateAttribute = estateAttributes.find((x) => x.attributeId === loaAttribute.id)
        if (estateAttribute) {
          await estateService.deleteEstateAttribute(estateAttribute.id)
        }
      }

      const updatedOfficeAttribute = office !== 'NOT_SET' ? (officeAttribute ? await estateService.createEstateAttribute(updatedEstate.id, officeAttribute.id, office) : null) : null
      if (!updatedOfficeAttribute) {
        estateAttribute = estateAttributes.find((x) => x.attributeId === officeAttribute.id)
        if (estateAttribute) {
          await estateService.deleteEstateAttribute(estateAttribute.id)
        }
      }

      const updatedCategoryAttribute = category !== 'NOT_SET' ? (categoryAttribute ? await estateService.createEstateAttribute(updatedEstate.id, categoryAttribute.id, category) : null) : null
      if (!updatedCategoryAttribute) {
        estateAttribute = estateAttributes.find((x) => x.attributeId === categoryAttribute.id)
        if (estateAttribute) {
          await estateService.deleteEstateAttribute(estateAttribute.id)
        }
      }

      let updatedAreaEstate: IAreaEstate | null = null
      if (areaId > 0) {
        updatedAreaEstate = await estateService.updateEstateArea(areaId, updatedEstate.id)
      }
      dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
      return dispatch({
        type: ActionTypes.CREATE_ESTATE_SUCCESS,
        payload: {
          ...updatedEstate,
          office: updatedOfficeAttribute ? updatedOfficeAttribute.value : 'NOT_SET',
          category: updatedCategoryAttribute ? updatedCategoryAttribute.value : 'NOT_SET',
          rekylId: updatedRekylAttribute ? updatedRekylAttribute.value : '',
          atemp: updatedAtempAttribute ? updatedAtempAttribute.value : '',
          loa: updatedLoaAttribute ? updatedLoaAttribute.value : '',
          areaId: updatedAreaEstate ? updatedAreaEstate.areaId : 0,
        },
      })
    } catch (error) {
      console.log(error)
      dispatch({type: ActionTypes.UPDATE_ESTATE_FAILED, payload: error})
    }
  }

export const deleteEstate = (id: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_ESTATE_LOADING})
  try {
    const result = await estateService.deleteEstate(id)
    dispatch({type: TreeActionTypes.SET_TREE_MODIFIED})
    return dispatch({type: ActionTypes.DELETE_ESTATE_SUCCESS})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_ESTATE_FAILED, payload: error})
  }
}

export const getReadingTableLogs = (estateId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_READING_PROPERTIES_LOADING})
  try {
    const data = await estateService.getReadingTableLogs(estateId, meterTypeId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_ESTATE_READING_PROPERTIES_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATE_READING_PROPERTIES_FAILED, payload: error})
  }
}
export const getConsumptionTableLogs = (estateId: number, meterTypeId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_PROPERTIES_LOADING})
  try {
    const data = await estateService.getConsumptionTableLogs(estateId, meterTypeId, fromYear, fromMonth, toYear, toMonth)
    return dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_PROPERTIES_SUCCESS, payload: data})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATE_CONSUMPTION_PROPERTIES_FAILED, payload: error})
  }
}
export const getEstateUsers = (estateId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_USERS_LOADING})
  try {
    const users = await estateService.getEstateUsers(estateId)
    return dispatch({type: ActionTypes.FETCH_ESTATE_USERS_SUCCESS, payload: users})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATE_USERS_FAILED, payload: error})
  }
}
export const createEstateUser = (estateId: number, userId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.CREATE_ESTATE_USER_LOADING, isLoading: true})
  try {
    const assignment = await estateService.createEstateUser(estateId, userId)
    const modifiedUsers = await estateService.getEstateUsers(estateId)
    return dispatch({type: ActionTypes.CREATE_ESTATE_USER_SUCCESS, isLoading: false, payload: modifiedUsers})
  } catch (error) {
    return dispatch({type: ActionTypes.CREATE_ESTATE_USER_FAILED, isLoading: false, payload: error})
  }
}
export const deleteEstateUser = (userId: number, estateId: number) => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.DELETE_ESTATE_USER_LOADING})
  try {
    const response = await estateService.deleteEstateUser(userId, estateId)
    if (response) return dispatch({type: ActionTypes.DELETE_ESTATE_USER_SUCCESS, payload: userId})
    else return dispatch({type: ActionTypes.DELETE_ESTATE_USER_FAILED, payload: userId})
  } catch (error) {
    return dispatch({type: ActionTypes.DELETE_ESTATE_USER_FAILED, payload: userId})
  }
}
export const getEstateAddresses = (estateId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({type: ActionTypes.FETCH_ESTATE_ADDRESSES_LOADING})
      const data = await estateService.getEstateAddresses(estateId)
      return dispatch({type: ActionTypes.FETCH_ESTATE_ADDRESSES_OK, payload: data})
    } catch (error) {
      return dispatch({type: ActionTypes.FETCH_ESTATE_ADDRESSES_ERROR, payload: error})
    }
  }
}
export const getEstateAttributeControls = () => async (dispatch: Dispatch) => {
  dispatch({type: ActionTypes.FETCH_ESTATE_ATTRIBUTE_CONTROLS_LOADING})
  try {
    const attributes : IAttribute[] = await attributeService.getAttributes()
    const estateAttributes : IAttribute[] = attributes?.filter(x => x.class === ESTATE)
    let attributeControls: IAttributeControl[] = [] 
    for(let i = 0; i < estateAttributes.length; i++) {
      const attribute = estateAttributes[i]      
      const controls : IAttributeControl[] = await attributeService.getAttributeControls(attribute.id)
      if(controls) {        
        attributeControls = [...attributeControls, ...controls]
      }
    }
    return dispatch({type: ActionTypes.FETCH_ESTATE_ATTRIBUTE_CONTROLS_SUCCESS, payload: attributeControls})
  } catch (error) {
    return dispatch({type: ActionTypes.FETCH_ESTATE_ATTRIBUTE_CONTROLS_FAILED, payload: error})
  }
}