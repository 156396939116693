export enum ActionTypes {
  FETCH_ESTATE_LOADING = 'FETCH_ESTATE_LOADING',
  FETCH_ESTATE_SUCCESS = 'FETCH_ESTATE_SUCCES',
  FETCH_ESTATE_FAILED = 'FETCH_ESTATE_FAILED',
  FETCH_ESTATES_LOADING = 'FETCH_ESTATES_LOADING',
  FETCH_ESTATES_SUCCESS = 'FETCH_ESTATES_SUCCES',
  FETCH_ESTATES_FAILED = 'FETCH_ESTATES_FAILED',
  FETCH_ESTATE_BY_ID_LOADING = 'FETCH_ESTATE_BY_ID_LOADING',
  FETCH_ESTATE_BY_ID_SUCCESS = 'FETCH_ESTATE_BY_ID_SUCCESS',
  FETCH_ESTATE_BY_ID_FAILED = 'FETCH_ESTATE_BY_ID_FAILED',
  FETCH_OWNERESTATE_LOADING = 'FETCH_ESTATE_LOADING',
  FETCH_OWNERESTATE_SUCCESS = 'FETCH_ESTATE_SUCCES',
  FETCH_OWNERESTATE_FAILED = 'FETCH_ESTATE_FAILED',
  FETCH_ESTATE_CONSUMPTION_SUCCESS = "FETCH_ESTATE_CONSUMPTION_SUCCESS",
  FETCH_ESTATE_CONSUMPTION_ERROR = "FETCH_ESTATE_CONSUMPTION_ERROR",
  FETCH_ESTATE_CONSUMPTION_LOADING = "FETCH_ESTATE_CONSUMPTION_LOADING",
  FETCH_ESTATE_METER_TYPES_SUCCESS = "FETCH_ESTATE_METER_TYPES_SUCCESS",
  FETCH_ESTATE_METER_TYPES_ERROR = "FETCH_ESTATE_METER_TYPES_ERROR",
  FETCH_ESTATE_METER_TYPES_LOADING = "FETCH_ESTATE_METER_TYPES_LOADING",
  FETCH_ESTATE_READING_PROPERTIES_LOADING = "FETCH_ESTATE_READING_PROPERTIES_LOADING",
  FETCH_ESTATE_READING_PROPERTIES_SUCCESS = "FETCH_ESTATE_READING_PROPERTIES_SUCCESS",
  FETCH_ESTATE_READING_PROPERTIES_FAILED = "FETCH_ESTATE_READING_PROPERTIES_FAILED",
  FETCH_ESTATE_CONSUMPTION_PROPERTIES_LOADING = "FETCH_ESTATE_CONSUMPTION_PROPERTIES_LOADING",
  FETCH_ESTATE_CONSUMPTION_PROPERTIES_SUCCESS = "FETCH_ESTATE_CONSUMPTION_PROPERTIES_SUCCESS",
  FETCH_ESTATE_CONSUMPTION_PROPERTIES_FAILED = "FETCH_ESTATE_CONSUMPTION_PROPERTIES_FAILED",
  FETCH_ESTATE_ADDRESSES_LOADING = "FETCH_ESTATE_ADDRESSES_LOADING",
  FETCH_ESTATE_ADDRESSES_OK = "FETCH_ESTATE_ADDRESSES_OK",
  FETCH_ESTATE_ADDRESSES_ERROR = "FETCH_ESTATE_ADDRESSES_ERROR", 
  CREATE_ESTATE_LOADING = 'CREATE_ESTATE_LOADING',
  CREATE_ESTATE_SUCCESS = 'CREATE_ESTATE_SUCCES',
  CREATE_ESTATE_FAILED = 'CREATE_ESTATE_FAILED',
  UPDATE_ESTATE_LOADING = 'UPDATE_ESTATE_LOADING',
  UPDATE_ESTATE_SUCCESS = 'UPDATE_ESTATE_SUCCES',
  UPDATE_ESTATE_FAILED = 'UPDATE_ESTATE_FAILED',
  DELETE_ESTATE_LOADING = 'DELETE_ESTATE_LOADING',
  DELETE_ESTATE_SUCCESS = 'DELETE_ESTATE_SUCCES',
  DELETE_ESTATE_FAILED = 'DELETE_ESTATE_FAILED',
  FETCH_ESTATE_ATTRIBUTES_LOADING = 'FETCH_ESTATE_ATTRIBUTES_LOADING',
  FETCH_ESTATE_ATTRIBUTES_SUCCESS = 'FETCH_ESTATE_ATTRIBUTES_SUCCESS',
  FETCH_ESTATE_ATTRIBUTES_FAILED = 'FETCH_ESTATE_ATTRIBUTES_FAILED',
  FETCH_ESTATE_USERS_LOADING = 'FETCH_ESTATE_USERS_LOADING',
  FETCH_ESTATE_USERS_SUCCESS = 'FETCH_ESTATE_USERS_SUCCESS',
  FETCH_ESTATE_USERS_FAILED = 'FETCH_ESTATE_USERS_FAILED',
  CREATE_ESTATE_USER_LOADING = 'CREATE_ESTATE_USER_LOADING',
  CREATE_ESTATE_USER_SUCCESS = 'CREATE_ESTATE_USER_SUCCESS',
  CREATE_ESTATE_USER_FAILED = 'CREATE_ESTATE_USER_FAILED',
  DELETE_ESTATE_USER_LOADING = 'DELETE_ESTATE_USER_LOADING',
  DELETE_ESTATE_USER_SUCCESS = 'DELETE_ESTATE_USER_SUCCESS',
  DELETE_ESTATE_USER_FAILED = 'DELETE_ESTATE_USER_FAILED',
  GET_ESTATE_METER_COST_LOADING = "GET_ESTATE_METER_COST_LOADING",
  GET_ESTATE_METER_COST_SUCCESS = "GET_ESTATE_METER_COST_SUCCESS",
  GET_ESTATE_METER_COST_FAILED = "GET_ESTATE_METER_COST_FAILED",
  CREATE_ESTATE_METER_COST_LOADING = "CREATE_ESTATE_METER_COST_LOADING",
  CREATE_ESTATE_METER_COST_SUCCESS = "CREATE_ESTATE_METER_COST_SUCCESS",
  CREATE_ESTATE_METER_COST_FAILED = "CREATE_ESTATE_METER_COST_FAILED",
  UPDATE_ESTATE_METER_COST_LOADING = "UPDATE_ESTATE_METER_COST_LOADING",
  UPDATE_ESTATE_METER_COST_SUCCESS = "UPDATE_ESTATE_METER_COST_SUCCESS",
  UPDATE_ESTATE_METER_COST_FAILED = "UPDATE_ESTATE_METER_COST_FAILED",
  DELETE_ESTATE_METER_COST_LOADING = "DELETE_ESTATE_METER_COST_LOADING",
  DELETE_ESTATE_METER_COST_SUCCESS = "DELETE_ESTATE_METER_COST_SUCCESS",
  DELETE_ESTATE_METER_COST_FAILED = "DELETE_ESTATE_METER_COST_FAILED",
  GET_ESTATE_METER_EMISSION_LOADING = "GET_ESTATE_METER_EMISSION_LOADING",
  GET_ESTATE_METER_EMISSION_SUCCESS = "GET_ESTATE_METER_EMISSION_SUCCESS",
  GET_ESTATE_METER_EMISSION_FAILED = "GET_ESTATE_METER_EMISSION_FAILED",
  CREATE_ESTATE_METER_EMISSION_LOADING = "CREATE_ESTATE_METER_EMISSION_LOADING",
  CREATE_ESTATE_METER_EMISSION_SUCCESS = "CREATE_ESTATE_METER_EMISSION_SUCCESS",
  CREATE_ESTATE_METER_EMISSION_FAILED = "CREATE_ESTATE_METER_EMISSION_FAILED",
  UPDATE_ESTATE_METER_EMISSION_LOADING = "UPDATE_ESTATE_METER_EMISSION_LOADING",
  UPDATE_ESTATE_METER_EMISSION_SUCCESS = "UPDATE_ESTATE_METER_EMISSION_SUCCESS",
  UPDATE_ESTATE_METER_EMISSION_FAILED = "UPDATE_ESTATE_METER_EMISSION_FAILED",
  DELETE_ESTATE_METER_EMISSION_LOADING = "DELETE_ESTATE_METER_EMISSION_LOADING",
  DELETE_ESTATE_METER_EMISSION_SUCCESS = "DELETE_ESTATE_METER_EMISSION_SUCCESS",
  DELETE_ESTATE_METER_EMISSION_FAILED = "DELETE_ESTATE_METER_EMISSION_FAILED",
  FETCH_ESTATE_ATTRIBUTE_CONTROLS_LOADING = "FETCH_ESTATE_ATTRIBUTE_CONTROLS_LOADING",
  FETCH_ESTATE_ATTRIBUTE_CONTROLS_SUCCESS = "FETCH_ESTATE_ATTRIBUTE_CONTROLS_SUCCESS",
  FETCH_ESTATE_ATTRIBUTE_CONTROLS_FAILED = "FETCH_ESTATE_ATTRIBUTE_CONTROLS_FAILED",
}
