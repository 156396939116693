export enum ActionTypes {
    GET_REPORTS_LOADING = "GET_REPORTS_LOADING",
    GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS",
    GET_REPORTS_FAILED = "GET_REPORTS_FAILED",
    RUN_REPORT_LOADING = "RUN_REPORT_LOADING",
    RUN_REPORT_SUCCESS = "RUN_REPORT_SUCCESS",
    RUN_REPORT_FAILED = "RUN_REPORT_FAILED",
    GET_REPORT_PREVIEW_LOADING = "GET_REPORT_PREVIEW_LOADING",
    GET_REPORT_PREVIEW_SUCCESS = "GET_REPORT_PREVIEW_SUCCESS",
    GET_REPORT_PREVIEW_FAILED = "GET_REPORT_PREVIEW_FAILED",
    CSV = "CSV",
    PDF = "PDF",
    EXTERNAL = "EXTERNAL"
}