import { ICase, ICaseCreateParameter, ICaseStatus, ICaseType, ICaseUpdateParameter, IPost, IPostCreateParameter } from "../redux/case/interfaces"
import saveupClient, { handleResponse } from '../clients/saveupClient'
async function getReadingCaseByLogId(id: number) {
    return await saveupClient.get<ICase[]>(`Counter/Value/Log/${id}/Case`)
    .then(handleResponse)
    .catch(error => console.log(error))
}
async function getArchivedReadingCaseByLogId(id: number) {
    return await saveupClient.get<ICase[]>(`Counter/Value/Log/History/${id}/Case`)
    .then(handleResponse)
    .catch(error => console.log(error))
}
async function getConsumptionCaseByLogId(id: number) {
    return await saveupClient.get<ICase[]>(`Consumption/Period/Log/${id}/Case`)
    .then(handleResponse)
    .catch(error => console.log(error))
}
async function getArchivedConsumptionCaseByLogId(id: number) {
    return await saveupClient.get<ICase[]>(`Consumption/Period/Log/History/${id}/Case`)
    .then(handleResponse)
    .catch(error => console.log(error))
}
async function getCaseStatus() {    
    return await saveupClient.get<ICaseStatus[]>(`Case/State`)
    .then(handleResponse)
    .catch(error => console.log(error))
}
async function getCaseTypes() {    
    let types = await saveupClient.get<ICaseType[]>(`Case/Type`)
    .then(handleResponse)
    .catch(error => console.log(error))    
    return types
}
async function createCase(logCase: ICaseCreateParameter) {
    const response =  await saveupClient.post<ICase>(`Case/Log`, logCase)
    .then(handleResponse)
    .catch(error => console.log(error))        
    return response
}
async function createArchivedCase(logCase: ICaseCreateParameter) {
    return await saveupClient.post<ICase>(`Case/Log/History`, logCase)
    .then(handleResponse)
    .catch(error => console.log(error))    
}
async function createConsumptionCase(logCase: ICaseCreateParameter) {
    return await saveupClient.post<ICase>(`Case/Consumption/Log`, logCase)
    .then(handleResponse)
    .catch(error => console.log(error))    
}
async function createArchivedConsumptionCase(logCase: ICaseCreateParameter) {
    return await saveupClient.post<ICase>(`Case/Consumption/Log/History`, logCase)
    .then(handleResponse)
    .catch(error => console.log(error))    
}
async function createPost(logCase: IPostCreateParameter) {
    return await saveupClient.post<IPost>(`post`, logCase)
    .then(handleResponse)
    .catch(error => console.log(error))    
}
async function updateCase(logCase: ICaseUpdateParameter) {
    const updateCaseParameter = {
        id: logCase.id,
        caseTypeId: logCase.caseTypeId,
        caseStateId: logCase.caseStateId,
        name: logCase.name,
        description: logCase.description,
        isActive: logCase.isActive,
    }    
    return await saveupClient.put<ICase>(`Case`, updateCaseParameter)
    .then(handleResponse)
    .catch(error => console.log(error))           
}
const caseService = {
    getReadingCaseByLogId,
    getArchivedReadingCaseByLogId,
    getConsumptionCaseByLogId,
    getArchivedConsumptionCaseByLogId,
    getCaseStatus,
    getCaseTypes,
    createCase,
    createConsumptionCase,
    createArchivedCase,
    createArchivedConsumptionCase,
    updateCase,
    createPost
}
export default caseService 