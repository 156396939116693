import React, {useEffect, useState} from 'react'
import {OfficesProps} from './interfaces'
import {Alert, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Tab, Typography, styled} from '@mui/material'
import {Link} from 'react-router-dom'
import OfficeList from './OfficeList'
import {Create} from '@mui/icons-material'
import {isAdmin} from '../../shared/utils/utilities'
const Offices = (props: OfficesProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deletedId, setDeletedId] = useState<number>(0)
  const [deletedName, setDeletedName] = useState<string>('')
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const onDeleteClick = (id: number, name: string) => {
    setDeletedId(id)
    setDeletedName(name)
    setDeleteModalOpen(true)
  }

  const onDeleteModalConfirmClick = () => {
    setDeleteModalOpen(false)
    if (deletedId > 0) props.deleteOffice(deletedId)
  }

  useEffect(() => {
    console.log(props.offices)
  }, [props.offices])

  useEffect(() => {    
    setHasAdminRights(isAdmin())
  }, [])

  return (
    <React.Fragment>
      <Grid sx={{margin: '20px'}} justifyContent='center' alignItems='stretch'>
        <Card>
          <Grid item xl={12}>
            <CardHeader title='Kontor' sx={{background: '#97a7c1'}} />
            <Divider />
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' columns={12} spacing={3} padding={3}>
              {hasAdminRights && (<Grid item xs={2}>
                <Link to='/office/create'>
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='primary' startIcon={<Create fontSize='small' />}>
                    Skapa nytt kontor
                  </Button>
                </Link>
              </Grid>)}
            </Grid>
            {!props.offices || props.offices.length === 0 ? (
              <Alert severity='info'>Inga kontor hittades.</Alert>
            ) : (
              <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' columns={12} spacing={3} padding={3}>
                <Grid item xs={12}>
                  <OfficeList offices={props.offices} onDeleteClick={onDeleteClick} isLoading={false} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Radera kontor?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Är du säker på att du vill ta bort kontoret <strong>{`${deletedName}`}</strong> {`med ID: ${deletedId}`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDeleteModalConfirmClick()} color='error' autoFocus>
            Ta bort
          </Button>
          <Button onClick={() => setDeleteModalOpen(false)}>Avbryt</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default Offices
