import ConsumptionTable from '../../consumption/table/ConsumptionTable'
import ConsumptionView from '../../consumption/ConsumptionView'
import {HourglassFullOutlined, SyncDisabled, UpdateOutlined} from '@mui/icons-material'
import {TabContext, TabList} from '@mui/lab'
import {Box, Button, Card, CardContent, CircularProgress, Container, Divider, FormControlLabel, Grid, Switch, Tab, TextField, Typography} from '@mui/material'
import ConsumptionNodeDto from '../../../redux/consumption/ConsumptionNodeDto'
import {getMeterType} from '../../../redux/counter/actions'
import {ITableLog} from '../../../redux/counterValue/interfaces'
import {getNodeConsumption, getConsumptionTableLogs, getMeterTypesAtEstate, getReadingTableLogs} from '../../../redux/estate/actions'
import {IEstateSummaryProps} from './interfaces'
import {IMeterType} from '../../../redux/meter/interfaces'
import {RootState} from '../../../redux/rootReducer'
import {isNullOrWhiteSpace, parseStopDateYearly} from '../../../shared/utils/utilities'
import {head} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {DesktopDatePicker} from '@mui/x-date-pickers'
import {getAllUnits} from '../../../redux/unit/unitActions'
import ConsumptionCostEmissionSelect from '../../consumption/ConsumptionCostEmissionSelect'
import {ESTATE} from '../../../shared/utils/constants'
import EstateReports from '../reports/EstateReports'
import ConsumptionOverview from '../../../components/consumption/overview/ConsumptionOverview'
import { IUnit } from '../../../redux/unit/unitInterfaces'
import { getShouldShowVolume } from '../../../redux/configuration/actions'
const EstateSummary = (props: IEstateSummaryProps) => {
  const dispatch = useDispatch()
  const isConsumptionLoading = useSelector((state: RootState) => state.estate.isConsumptionLoading)
  const selectedMeterType = useSelector((state: RootState) => state.tree.selectedMeterType)
  const meterTypes = useSelector((state: RootState) => (selectedMeterType > 0 ? state.estate.meterTypes?.filter((type) => type.id == selectedMeterType) : state.estate.meterTypes))
  const consumptionsFromStore = useSelector((state: RootState) => state.estate.consumptions)
  const readingPropertiesFromStore = useSelector((state: RootState) => state.estate.readingProperties)
  const consumptionPropertiesFromStore = useSelector((state: RootState) => state.estate.consumptionProperties)
  const estate = useSelector((state: RootState) => state.estate.selectedEstate)
  const units = useSelector((state: RootState) => state.unit.units)
  const category = useSelector((state: RootState) => state.filter.consumptionCategory)

  const [selectedConsumptionTab, setSelectedConsumptionTab] = useState(meterTypes && meterTypes.length > 0 ? meterTypes[0].id.toString() : '')
  const [useDegreeDayCorrection, setUseDegreeDayCorrection] = useState(true)
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear() - 2, 0, 1, 0, 0, 0))
  const [stopDate, setStopDate] = useState(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59))
  const [lastLoadedStartDate, setLastLoadedStartDate] = useState(new Date(new Date().getFullYear() - 2, 0, 1, 0, 0, 0))
  const [lastLoadedStopDate, setLastLoadedStopDate] = useState(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59))
  const [showReportModal, setShowReportModal] = useState<boolean>(false)
  const [canEstimate, setCanEstimate] = useState<boolean>(false)
  const [shouldEstimate, setShouldEstimate] = useState<boolean>(true)
  const [consumptions, setConsumptions] = useState<ConsumptionNodeDto[]>([])
  const [consumptionProperties, setConsumptionsProperties] = useState<ITableLog[]>([])
  const [readingProperties, setReadingProperties] = useState<ITableLog[]>([])
  const onConsumptionTabChange = (e: any, obj: any) => {
    setSelectedConsumptionTab(obj.toString())
  }
  useEffect(() => {
    setConsumptions([])
    setConsumptionsProperties([])
    setReadingProperties([])
    setSelectedConsumptionTab('')
    dispatch(getMeterTypesAtEstate(props.estateId))
    if (!units || units.length === 0) {
      dispatch(getAllUnits())
    }
    dispatch(getShouldShowVolume())
  }, [props.estateId])

  useEffect(() => {
    setConsumptions(consumptionsFromStore)
  }, [consumptionsFromStore])

  useEffect(() => {
    setConsumptionsProperties(consumptionPropertiesFromStore)
  }, [consumptionPropertiesFromStore])

  useEffect(() => {
    setReadingProperties(readingPropertiesFromStore)
  }, [readingPropertiesFromStore])

  useEffect(() => {
    if (meterTypes !== undefined && meterTypes?.length > 0) {
      setSelectedConsumptionTab(meterTypes[0].id.toString())
    }
  }, [meterTypes])

  const getConsumptionForTab = () => {
    if (!isNullOrWhiteSpace(selectedConsumptionTab)) {
      const selectedId = parseInt(selectedConsumptionTab)
      if (selectedId > 0) {
        let parsedStopDate = parseStopDateYearly(stopDate)
        const currentMeterType = meterTypes.find((x: IMeterType) => x.id === selectedId)
        const currentUnit = units?.find((x: IUnit) => x.id === currentMeterType?.unitId)
        const canDegreeDayAdjustCurrentMeterType = currentMeterType?.canDegreeDayAdjust === true
        setLastLoadedStartDate(startDate)
        setLastLoadedStopDate(parsedStopDate)
        setCanEstimate(currentMeterType?.isConsumption ?? false)
        setShouldEstimate(currentMeterType?.isConsumption ?? false)
        dispatch(getNodeConsumption(props.estateId, selectedId, startDate, parsedStopDate, canDegreeDayAdjustCurrentMeterType && useDegreeDayCorrection, currentMeterType?.isConsumption ?? false, currentUnit, category))
        dispatch(getReadingTableLogs(props.estateId, selectedId, startDate.getFullYear(), startDate.getMonth() + 1, parsedStopDate.getFullYear(), parsedStopDate.getMonth() + 1))
        dispatch(getConsumptionTableLogs(props.estateId, selectedId, startDate.getFullYear(), startDate.getMonth() + 1, parsedStopDate.getFullYear(), parsedStopDate.getMonth() + 1))
      }
    }
  }

  useEffect(() => getConsumptionForTab(), [selectedConsumptionTab])

  const update = () => getConsumptionForTab()
  const renderTabLabel = (type: IMeterType) => {
    if (type && units.length > 0) {
      const tabUnit = units.find((x) => x.id === type.unitId)
      return tabUnit ? `${type.name} (${tabUnit.comment})` : ''
    } else if (type) {
      return type.name
    } else {
      return ''
    }
  }
  const renderTab = (type: IMeterType) => {
    return <Tab label={renderTabLabel(type)} value={type.id.toString()} key={type.id} />
  }

  const renderGraphs = (
    consumption: ConsumptionNodeDto[],
    valueProperties: ITableLog[],
    typeIdString: string,
    deviations: {[key: string]: number} | null,
    startDate: Date,
    stopDate: Date,
    category: string
  ) => {
    if (!isNullOrWhiteSpace(typeIdString) && consumption) {
      const typeId = parseInt(typeIdString)
      const type = head(meterTypes.filter((x) => x.id === typeId))
      if (!type)
        return (
          <div>
            <Typography variant='h5'>Inga mätartyper hittades på denna fastighet!</Typography>{' '}
          </div>
        )

      return (
        <div>
          <ConsumptionView data={consumption} meterType={type} deviations={deviations} startDate={startDate} stopDate={stopDate} category={category} nodeType={ESTATE} />
          <Divider sx={{'marginTop': '10px', 'marginBottom': '10px'}} />
          <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
            <Grid item xs={12}>
              <EstateReports id={props.estateId} name={estate ? estate.name : ''} show={showReportModal} onFinished={() => setShowReportModal(false)} />
            </Grid>
          </Grid>
          <Divider sx={{'marginTop': '10px', 'marginBottom': '10px'}} />
          <ConsumptionTable data={consumption} logs={valueProperties} meterType={type} deviations={deviations} startDate={startDate} stopDate={stopDate} category={category} shouldEstimate={shouldEstimate} />
        </div>
      )
    } else {
      return (
        <div>
          <Box sx={{'margin': '10px'}}>
            <Grid container direction='row' alignItems='stretch' spacing={1}>
              <Grid item xs={12}>
                <Typography variant='h5'>Ingen data hittades för denna fastighet!</Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      )
    }
  }

  const renderTabList = () => {
    if (meterTypes && meterTypes.length > 0) {
      return (
        <TabList onChange={onConsumptionTabChange} variant='scrollable' scrollButtons='auto'>
          {meterTypes.map((x) => renderTab(x))}
        </TabList>
      )
    } else return <div></div>
  }
  if (isConsumptionLoading || selectedConsumptionTab === '' || !meterTypes?.some((x) => x.id.toString() === selectedConsumptionTab)) {
    return (
      <Box sx={{'margin': '10px'}}>
        <Grid container direction='row' alignItems='stretch' spacing={1}>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='600px'>
              {props.estateId === estate?.id && !isConsumptionLoading ? (
                <div>
                  <SyncDisabled sx={{color: '#223354', fontSize: 256}} />
                  <Typography variant='h5'>Ingen data finns tillgänglig för denna nod.</Typography>
                </div>
              ) : (
                <CircularProgress sx={{color: '#223354'}} size={256} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  } else {
    const meterType = meterTypes.find((x: IMeterType) => x.id == parseInt(selectedConsumptionTab))
    const canDegreeDayAdjustMeterType = meterType?.canDegreeDayAdjust === true
    return (
      <Container maxWidth='lg'>
        <Card>
          <CardContent>
            <TabContext value={selectedConsumptionTab}>
              <Box>{renderTabList()}</Box>
              <Box sx={{'marginTop': '10px'}}>
                <Grid>
                  <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={2}>
                      <DesktopDatePicker
                        views={['year']}
                        label='Från'
                        value={startDate}
                        onChange={(date: Date | null) => {
                          if (date) setStartDate(date)
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DesktopDatePicker
                        views={['year']}
                        label='Till'
                        value={stopDate}
                        onChange={(date: Date | null) => {
                          if (date) setStopDate(date)
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ConsumptionCostEmissionSelect meterType={meterType} />
                    </Grid>
                    {canDegreeDayAdjustMeterType ? (
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={<Switch checked={useDegreeDayCorrection} onChange={() => setUseDegreeDayCorrection(useDegreeDayCorrection ? false : true)} title='Använd graddagsjustering' />}
                          label='Använd graddagsjustering'
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                    {canEstimate ? (
                      <Grid item xs={3}>
                        <FormControlLabel control={<Switch checked={shouldEstimate} onChange={() => setShouldEstimate((estimate) => !estimate)} title='Visa estimat' />} label='Visa estimat' />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item xs={2} alignSelf='right'>
                      <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<UpdateOutlined fontSize='small' />} onClick={update}>
                        Uppdatera
                      </Button>
                    </Grid>
                  </Grid>
                  {shouldEstimate && meterType && units?.length > 0 ? <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={12}>
                        <ConsumptionOverview data={consumptions} meterType={meterType} startDate={lastLoadedStartDate} stopDate={lastLoadedStopDate} category={category} unit={units.find(x => x.id == meterType.unitId)}  />
                    </Grid>
                  </Grid> : ''}
                  <Grid container direction='row' alignItems='stretch' spacing={1}>
                    <Grid item xs={12}>
                      {renderGraphs(consumptions, readingProperties?.concat(consumptionProperties), selectedConsumptionTab, null, lastLoadedStartDate, lastLoadedStopDate, category)}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </TabContext>
          </CardContent>
        </Card>
      </Container>
    )
  }
}
export default EstateSummary
