import {
  ICounter,
  ICounterAttribute,
  ICounterCreateParameter,
  ICounterIntegration,
  ICounterIntegrationDto,
  ICounterLog,
  ICounterNumber,
  ICounterPathDto,
  ICounterValueListItem,
  IDisabledPeriodParameter,
  IInactivePeriod,
  IPeriodParameter,
  IsCounterExcluded 
} from '../redux/counter/interfaces'
import {htmlDecode, isNullOrWhiteSpace, htmlDecodeIfExists, getIntegrationType} from '../shared/utils/utilities'
import saveupClient, {handleError, handleResponse} from '../clients/saveupClient'
import _, {xor} from 'lodash'
import {IConsumption, IConsumptionNodeDto} from '../redux/consumption/interfaces'
import {ICounterDeviation, IDeviationType, IDeviationValue, IDeviationValueType} from '../redux/counter/interfaces'
import {DEFAULT_SWE, DEVIATION_TYPE_EFFICIENCY} from '../shared/language/swe'
import {responseInterceptor} from 'http-proxy-middleware'
import {ConstructionOutlined, Yard} from '@mui/icons-material'
import {ITableLog} from '../redux/counterValue/interfaces'
import {CounterIntegrationType} from '../shared/enums/CounterIntegrationType'
import {IBreadCrumbPath} from '../redux/tree/treeInterfaces'
import {ADDRESS, COUNTER, ESTATE, MEASUREPOINT, OWNER} from '../shared/utils/constants'
import {IAttributeControl} from '../redux/attribute/interfaces'
import { ICounterPeriodConsumption } from '../redux/counterPeriodConsumption/interfaces'

async function getCounter(id: number) {
  const result = await saveupClient.get<ICounter>(`Counter/${id}`).then(handleResponse)
  return result
}
async function getCounterNumbers(id: number) {
  return await saveupClient.get<string[]>(`counter/${id}/number`).then(handleResponse)
}

async function getConsumption(id: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {
  const result = await saveupClient
    .get<IConsumption>(
      `Consumption/Summary?id=${id}&type=COUNTER&meterTypeId=0&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}`
    )
    .then(handleResponse)
  return result
}

async function getConsumptionCostEmission(id: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean) {
  return await saveupClient
    .get<IConsumptionNodeDto>(
      `consumption/cost?id=${id}&type=COUNTER&meterTypeId=0&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&round=true`
    )
    .then(handleResponse)
}

/**
 * Fetch all monthly consumptions, cost and emissions in given date span.
 * @param id
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getNodeConsumption(id: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=COUNTER&meterTypeId=0&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&round=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}
/**
 * Fetch all monthly consumptions, cost and emissions in given date span with estimates.
 * @param id
 * @param startDate
 * @param stopDate
 * @param useDegreeDayCorrection
 * @returns IConsumption[]
 */
async function getNodeConsumptionAndEstimate(id: number, startDate: Date, stopDate: Date, useDegreeDayCorrection: boolean, targetUnit?: string | undefined) {
  const result = await saveupClient
    .get<IConsumption>(
      `consumption/node?id=${id}&type=COUNTER&meterTypeId=0&startDate=${startDate.toLocaleDateString()}&stopDate=${stopDate.toLocaleDateString()}&useDegreeDayCorrection=${useDegreeDayCorrection}&estimate=true&round=true${targetUnit ? `&unit=${targetUnit}` : ''}`,
      {timeout: 120000}
    )
    .then(handleResponse)
  return result
}

async function getValueLogsForCounter(id: number, fromYear: Date, fromMonth: Date, toYear: Date, toMonth: Date) {
  const logs = await saveupClient.get<ICounterLog>(`Counter/Value/Log/Period?counterId=${id}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`).then(handleResponse)

  logs.forEach((l: ICounterLog) => {
    l.counterValue = +(l.counterValue * l.multiple).toFixed(2)
    l.message = htmlDecode(l.message).replace('<', '').replace('>', '')
  })

  return logs
}
async function getConsumptionLogsForCounter(id: number, fromYear: Date, fromMonth: Date, toYear: Date, toMonth: Date) {
  const logs = await saveupClient.get<ICounterLog>(`Counter/Consumption/Log/Period=${id}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`).then(handleResponse)

  logs.forEach((l: ICounterLog) => {
    l.counterValue = +(l.counterValue * l.multiple).toFixed(2)
    l.message = htmlDecode(l.message).replace('<', '').replace('>', '')
  })

  return logs
}
async function getLogsForCounter(id: number, fromYear: Date, fromMonth: Date, toYear: Date, toMonth: Date) {
  const valueLogs: ICounterLog[] = await getValueLogsForCounter(id, fromYear, fromMonth, toYear, toMonth)
  const consumptionLogs: ICounterLog[] = await getConsumptionLogsForCounter(id, fromYear, fromMonth, toYear, toMonth)
  valueLogs.concat(consumptionLogs)
  return valueLogs
}

async function getCounterIntegrations(id: number) {
  const dtos = await saveupClient.get<ICounterIntegrationDto>(`counter/${id}/integration`).then(handleResponse)
  const integrations: ICounterIntegration[] = dtos.map((dto: ICounterIntegrationDto) => ({
    id: dto.id,
    counterId: dto.counterId,
    externalSystemId: dto.externalSystemId,
    integrationType: getIntegrationType(dto),
    externalId: dto.externalId,
    updDate: dto.updDate,
    insDate: dto.insDate,
  }))
  return integrations
}
async function getCounterNumber(counterId: number) {
  return saveupClient.get(`counter/${counterId}/number`).then(handleResponse)
}
async function createCounter(measurePointId: number, meterId: number, name: string, constant: number, hidden: boolean) {
  return await saveupClient.post<ICounter>(`counter`, {measurePointId, meterId, name, constant, hidden}).then(handleResponse)
}
async function getCounterValueListItems(counterId: number) {
  return await saveupClient
    .get<ICounterValueListItem[]>(`counter/${counterId}/list`)
    .then(handleResponse)
    .catch((error) => console.log(error))
}
async function getIsCounterMultiValue(counterId: number) {
  return await saveupClient
    .get<boolean>(`Counter/${counterId}/IsMultiValue`)
    .then(handleResponse)
    .catch((error) => console.log(error))
}
async function getCounterExcluded(counterId: number) {
  const defaultExcludedState: IsCounterExcluded = {
    id: 0,
    counterId: counterId,
    isExcludedFromAddress: false,
    isExcludedFromEstate: false,
    isExcludedFromOwner: false,
    isExcludedFromMeasurePoint: false,
    insDate: new Date(),
    updDate: new Date(),
  }

  const result = await saveupClient
    .get<IsCounterExcluded>(`counter/${counterId}/consumption/exclude`)
    .then(handleResponse)
    .catch((error: any) => {
      if (error.response.status === 404) return defaultExcludedState
      else throw error
    })

  if (result === undefined) {
    return defaultExcludedState
  }
  return result
}
async function getReadingTableLogs(counterId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient.get<ITableLog>(`Counter/Value/Log/Period?counterId=${counterId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`).then(handleResponse)
}

async function getConsumptionTableLogs(counterId: number, fromYear: number, fromMonth: number, toYear: number, toMonth: number) {
  return await saveupClient.get<ITableLog>(`Counter/Consumption/Log/Period?counterId=${counterId}&fromYear=${fromYear}&fromMonth=${fromMonth}&toYear=${toYear}&toMonth=${toMonth}`).then(handleResponse)
}

async function getCounterDisabledPeriods(id: number) {
  return await saveupClient.get<IDisabledPeriodParameter>(`counter/${id}/period/disabled`).then(handleResponse)
}

async function getPath(id: number) {
  return await saveupClient.get<ICounterPathDto>(`counter/${id}/path`).then(handleResponse)
}

async function getBreadCrumbPath(id: number) {
  let paths: IBreadCrumbPath[] = []
  const dto: ICounterPathDto = await getPath(id)
  paths.push({
    id: dto.measurePoint.address.estate.owner.id,
    label: isNullOrWhiteSpace(dto.measurePoint.address.estate.owner.name) ? dto.measurePoint.address.estate.owner.id.toString() : dto.measurePoint.address.estate.owner.name,
    nodeType: OWNER,
  })
  paths.push({
    id: dto.measurePoint.address.estate.id,
    label: `${isNullOrWhiteSpace(dto.measurePoint.address.estate.name) ? dto.measurePoint.address.estate.id.toString() : dto.measurePoint.address.estate.name}${
      isNullOrWhiteSpace(dto.measurePoint.address.estate.rekylId) ? '(' + dto.measurePoint.address.estate.rekylId + ')' : ''
    }`,
    nodeType: ESTATE,
  })
  paths.push({id: dto.measurePoint.address.id, label: isNullOrWhiteSpace(dto.measurePoint.address.name) ? dto.measurePoint.address.id.toString() : dto.measurePoint.address.name, nodeType: ADDRESS})
  paths.push({
    id: dto.measurePoint.id,
    label: isNullOrWhiteSpace(dto.measurePoint.number) ? (isNullOrWhiteSpace(dto.measurePoint.name) ? dto.measurePoint.id.toString() : dto.measurePoint.name) : dto.measurePoint.number.toString(),
    nodeType: MEASUREPOINT,
  })
  paths.push({id: dto.id, label: isNullOrWhiteSpace(dto.number) ? (isNullOrWhiteSpace(dto.name) ? dto.id.toString() : dto.name) : dto.number.toString(), nodeType: COUNTER})
  return paths
}

async function saveCounterNumber(counterId: number, numberString: string) {
  let counterNumbers = await getCounterNumbers(counterId)
  let inputNumbers = numberString
    .split(',')
    .map((n) => n.trim())
    .filter((n) => !isNullOrWhiteSpace(n))

  counterNumbers.filter((n1: string) => !inputNumbers.some((n2: string) => n1.trim() === n2.trim())).forEach(async (n: string) => await deleteCounterNumber(counterId, n))

  let createCounterNumberPromises: Promise<ICounterNumber>[] = inputNumbers
    .filter((n1: string) => !counterNumbers.some((n2: string) => n1.trim() === n2.trim()))
    .map(async (number: string) => {
      if (!isNullOrWhiteSpace(number)) {
        console.log('creating ' + number)
        return createCounterNumber(counterId, number)
      }
      return ''
    })
  let result: ICounterNumber[] = await Promise.all<ICounterNumber>(createCounterNumberPromises)
  return result
}

async function saveDisabledPeriods<IDisabledPeriodParameter>(counterId: number, periods: IInactivePeriod[]) {
  const disabledPeriods: IPeriodParameter[] = periods
    .filter((p: IInactivePeriod) => p.isInactive)
    .map((p: IInactivePeriod) => {
      const parsedPeriod: IPeriodParameter = {
        startDate: new Date(Date.UTC(new Date().getFullYear(), p.period - 1, 15)),
        stopDate: p.period + 1 > 12 ? new Date(Date.UTC(new Date().getFullYear() + 1, 0, 14, 23, 59, 59)) : new Date(Date.UTC(new Date().getFullYear(), p.period, 14, 23, 59, 59)),
        recurring: true,
      }
      return parsedPeriod
    })
  const periodParameter = {
    id: counterId,
    periods: disabledPeriods,
  }
  return await saveupClient.post<IDisabledPeriodParameter>('counter/period/disabled', periodParameter).then(handleResponse)
}

async function updateCounter(id: number, measurePointId: number, meterId: number, name: string, turned: boolean, constant: number, hidden: boolean) {
  const body = {id, measurePointId, meterId, name, turned, constant, hidden}
  return await saveupClient.put<ICounter>('counter', body).then(handleResponse)
}

async function createCounterIntegration(counterId: number, externalSystemId: number, integration: CounterIntegrationType, externalId: string) {
  return await saveupClient.post<ICounterIntegrationDto>('counter/integration', {counterId, externalSystemId, integration, externalId}).then(handleResponse)
}

async function createCounterNumber<ICounterNumber>(counterId: number, number: string) {
  let counterNumberCreateParameter: ICounterCreateParameter = {
    counterId: counterId,
    number: number,
  }
  return await saveupClient.post<ICounterNumber>(`counter/number`, counterNumberCreateParameter).then(handleResponse)
}

async function updateCounterIntegration(id: number, counterId: number, externalSystemId: number, integration: CounterIntegrationType, externalId: string) {
  return await saveupClient.put<ICounterIntegrationDto>('counter/integration', {id, counterId, externalSystemId, integration, externalId}).then(handleResponse)
}

async function updateExternalMestroData(counterId: number) {
  return await saveupClient.post<ICounterPeriodConsumption[]>(`counter/value/import/mestro`, {counterId: counterId, resolution: 'month', RawKind: true}).then(handleResponse)
}
async function updateExternalMetryData(counterId: number) {
  return await saveupClient.post<ICounterPeriodConsumption[]>(`counter/value/import/metry`, {counterId: counterId, resolution: 'month', RawKind: true}).then(handleResponse)
}
async function getCounterDeviation(counterId: number) {
  try {
    return await saveupClient.get<ICounterDeviation>(`counter/${counterId}/deviation`).then(handleResponse)
  } catch (error) {
    throw error
  }
}

async function getCounterDeviationDictionary(id: number, meterId: number, isConsumption: boolean) {
  let deviations: {[key: string]: number} = {}

  // Fetch the counter.
  // let counter: ICounter = await getCounter(id)

  // Get registered deviation for the counter.
  let counterDeviation: ICounterDeviation = await saveupClient.get<ICounterDeviation>(`counter/${id}/deviation`).then(handleResponse)

  let valueTypes: IDeviationValueType[]
  let deviationTypeForMeter: IDeviationType | undefined = undefined
  if (!isConsumption) {
    // Get the deviation type for this counter meter type.

    try {
      deviationTypeForMeter = await saveupClient.get<IDeviationType>(`meter/${meterId}/Deviation/Type`).then(handleResponse)
    } catch (error) {}
    // Get deviation value types.
    valueTypes = await saveupClient.get<IDeviationValueType>(`counter/deviation/value/type`).then(handleResponse)

    // If we have a deviation type for this meter, set the value types
    if (deviationTypeForMeter) {
      if (valueTypes && valueTypes.length > 0) {
        const connectedValueTypeIds = deviationTypeForMeter.deviationTypeBindings.map((x) => x.deviationValueTypeId)
        const valueTypesForThisDeviationType = valueTypes.filter((x) => connectedValueTypeIds.includes(x.id))
        deviationTypeForMeter.valueTypes = valueTypesForThisDeviationType
      } else {
        deviationTypeForMeter.valueTypes = []
      }
    }
  }

  // If we do not have a deviation type for this meter, or the type is DEFAULT, set standard deviation
  if (deviationTypeForMeter == undefined || deviationTypeForMeter == null || deviationTypeForMeter.name.toLowerCase() == DEFAULT_SWE.toLowerCase()) {
    deviations[DEFAULT_SWE] = counterDeviation ? counterDeviation.deviation : 10
  } else if (deviationTypeForMeter.name.toLowerCase() == DEVIATION_TYPE_EFFICIENCY.toLowerCase()) {
    // If the deviation type is efficiency
    deviations[DEVIATION_TYPE_EFFICIENCY] = counterDeviation ? counterDeviation.deviation : 50
  } else {
    // If the deviation type is one consisting of several dynamic values
    // If we have one or more dynamic values for this deviation type
    if (counterDeviation && counterDeviation.deviationValues && counterDeviation.deviationValues.length > 0) {
      counterDeviation.deviationValues.forEach((deviationValue) => {
        deviationValue.deviationValueType = valueTypes.find((x: IDeviationValueType) => x.id == deviationValue.deviationValueTypeId)
        deviations[deviationValue.deviationValueType ? deviationValue.deviationValueType.name : ''] = deviationValue.value
      })
    } else {
      // No values found, set each value to the default
      deviationTypeForMeter.valueTypes.forEach((valueType) => {
        deviations[valueType.name] = valueType.defaultValue
      })
    }
  }
  console.log(deviations)
  return deviations
}
async function createCounterDeviation(counterId: number, deviation: number) {
  return await saveupClient.post<ICounterDeviation>(`counter/deviation`, {counterId, deviation}).then(handleResponse)
}
async function updateCounterDeviation(id: number, counterId: number, deviation: number) {
  return await saveupClient.put<ICounterDeviation>(`counter/deviation`, {id, counterId, deviation}).then(handleResponse)
}

async function updateDeviationSubValue(id: number, counterDeviationId: number, deviationValueTypeId: number, value: number) {
  return await saveupClient.put<IDeviationValue>(`counter/deviation/value`, {id, counterDeviationId, deviationValueTypeId, value}).then(handleResponse)
}
async function createCounterDeviationSubValue(counterDeviationId: number, deviationValueTypeId: number, value: number) {
  return await saveupClient.post<IDeviationValue>(`counter/deviation/value`, {counterDeviationId, deviationValueTypeId, value}).then(handleResponse)
}
async function deleteDeviationSubValue(counterId: number) {
  await saveupClient.delete(`counter/${counterId}/deviation/value`).then(handleResponse)
}

async function deleteCounter(id: number) {
  return await saveupClient.delete(`counter/${id}`).then(handleResponse)
}
async function deleteCounterIntegration(id: number) {
  return await saveupClient.delete(`counter/integration/${id}`).then(handleResponse)
}
async function deleteCounterNumber(counterId: number, counterNumber: string) {
  return await saveupClient.delete(`counter/number?counterId=${counterId}&number=${counterNumber}`).then(handleResponse)
}
async function deleteDisabledPeriods(counterId: number) {
  return await saveupClient.delete(`counter/${counterId}/period/disabled`).then(handleResponse)
}
async function saveCounterConsumtionExcluded(counterId: number, isExcluded: boolean) {
  const currentState: IsCounterExcluded | undefined = await getCounterExcluded(counterId)
  // If we find no state, and isExcluded is set to be false, we do not need to take any action
  if ((!currentState || currentState.id === 0) && !isExcluded) {
    return currentState
  }
  const data = {
    counterId: counterId,
    isExcludedFromOwner: isExcluded,
    isExcludedFromEstate: isExcluded,
    isExcludedFromAddress: isExcluded,
    isExcludedFromMeasurePoint: isExcluded,
  }
  var result = await saveupClient.post<IsCounterExcluded>(`counter/consumption/exclude`, data).then(handleResponse).catch(handleError)
  return result
}

async function getDeviationSubValueTypes() {
  return await saveupClient.get<IDeviationValueType[]>(`counter/deviation/value/type`).then(handleResponse)
}

async function getCounterAttributes(counterId: number) {
  return await saveupClient.get<ICounterAttribute[]>(`counter/${counterId}/attribute`).then(handleResponse)
}

async function createOrUpdateCounterAttribute(counterId: number, attributeId: number, value: string) {
  return await saveupClient.post<ICounterAttribute>(`counter/attribute`, {counterId, attributeId, value}).then(handleResponse)
}

const counterService = {
  getCounter,
  getConsumption,
  getConsumptionCostEmission,
  getNodeConsumption,
  getNodeConsumptionAndEstimate,
  getValueLogsForCounter,
  getConsumptionLogsForCounter,
  getLogsForCounter,
  getCounterDeviation,
  getCounterDeviationDictionary,
  getCounterIntegrations,
  getCounterNumbers,
  getCounterValueListItems,
  getIsCounterMultiValue,
  getCounterExcluded,
  getReadingTableLogs,
  getConsumptionTableLogs,
  getCounterDisabledPeriods,
  getPath,
  getBreadCrumbPath,  
  getDeviationSubValueTypes,
  getCounterAttributes,
  createCounter,
  createCounterIntegration,
  createCounterDeviation,
  createCounterDeviationSubValue,
  createOrUpdateCounterAttribute,
  updateCounterIntegration,
  updateCounter,
  updateExternalMestroData,
  updateExternalMetryData,
  updateCounterDeviation,
  updateDeviationSubValue,
  deleteCounter,  
  deleteCounterIntegration,
  deleteDisabledPeriods,
  deleteDeviationSubValue,
  saveCounterNumber,
  saveDisabledPeriods,
  saveCounterConsumtionExcluded,
}
export default counterService
