import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PageTitleWrapper from '../shared/PageTitleWrapper'
import {Container, Grid, Card, CardHeader, CardContent, Divider, Typography, Button, Stack, Skeleton, MenuItem, Select, CardActions, Alert, IconButton} from '@mui/material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {RootState} from '../../redux/rootReducer'
import {createAreaEnergyZone, editArea, getAreaById, getEnergyZoneForArea} from '../../redux/area/actions'
import TextField from '@mui/material/TextField'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import {getEnergyZones} from '../../redux/energyzone/actions'
import Area, {IAreaForm} from '../../redux/area/Interfaces.ts'
import {SaveResponse} from '../estate/create/enums'
import {Close} from '@mui/icons-material'
import {ActionTypes} from '../../redux/area/types'
export default function UpdateArea() {
  const {id} = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [saveResult, setSaveResult] = useState(SaveResponse.NOT_SET)
  const [alertOpen, setAlertOpen] = useState(false)
  const onSubmit = (data: any) => {
    dispatch(editArea(data))
    if (data.energyZoneId > 0) {
      dispatch(createAreaEnergyZone(data.id, data.energyZoneId))
    }
  }
  const energyzones = useSelector((state: RootState) => state.energyZone.energyZones)
  const currentEnergyZone = useSelector((state: RootState) => state.area.energyZone)
  const area: Area | undefined = useSelector((state: RootState) => state.area.area)
  const lastAction = useSelector((state: RootState) => state.area.lastAction)
  const defaultValues: IAreaForm = {
    id: 0,
    name: '',
    number: 0,
    energyZoneId: 0,
    insDate: new Date(),
    updDate: new Date(),
  }
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: {errors, isDirty, isValid},
  } = useForm({defaultValues})

  useEffect(() => {
    dispatch(getAreaById(Number(id)))
    dispatch(getEnergyZoneForArea(Number(id)))
    if (!energyzones || energyzones.length === 0) {
      dispatch(getEnergyZones())
    }
  }, [id])

  useEffect(() => {
    reset({
      id: area?.id,
      name: area?.name,
      number: area?.number,
      insDate: area?.insDate,
      updDate: area?.updDate
    })
    if (lastAction === ActionTypes.EDIT_AREA_SUCCESS) {
      setAlertOpen(true)
    }
  }, [area])

  useEffect(() => {
    reset({energyZoneId: currentEnergyZone?.id})
  }, [currentEnergyZone])

  return (
    <>
      <PageTitleWrapper>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h3' component='h3' gutterBottom></Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </PageTitleWrapper>
      <Container maxWidth='lg'>
        {/* <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
          <Grid item xs={12}>
            {alertOpen ? (
              <Alert
                severity='success'
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                    <Close fontSize='inherit' />
                  </IconButton>
                }
                sx={{mb: 2}}>
                Graddagsorten har uppdaterats
              </Alert>
            ) : (
              ''
            )}
          </Grid>
        </Grid> */}
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <Card component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
              <CardHeader title='Redigera graddagsort' />
              <Divider />
              <CardContent>
                <Grid container direction='row' alignItems='stretch' spacing={3}>
                  <Grid item xs={4}>
                    <Typography variant='body1'>id:</Typography>
                    <TextField
                      {...register('id', {
                        required: 'required',
                      })}
                      aria-readonly='true'
                      placeholder='id'
                      type='number'
                      fullWidth
                      InputProps={{readOnly: true}}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body2'>Nummer:</Typography>
                    <TextField
                      {...register('number', {
                        required: 'required',
                      })}
                      placeholder='number'
                      type='number'
                      fullWidth
                      inputProps={{maxLength: 9}}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Namn:</Typography>
                    <TextField
                      {...register('name', {
                        required: 'required',
                      })}
                      placeholder='name'
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='stretch' spacing={3}>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Elprisområde:</Typography>
                    <Controller
                      name='energyZoneId'
                      control={control}
                      render={({field}) => (
                        <Select fullWidth {...field}>
                          <MenuItem key={1} value={0}>
                            --- Välj elprisområde ---
                          </MenuItem>
                          {energyzones?.map((zone, index) => (
                            <MenuItem key={index + 1} value={zone.id}>
                              {zone.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Skapades:</Typography>
                    <TextField {...register('insDate')} placeholder='insDate' InputProps={{readOnly: true}} fullWidth />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body1'>Uppdaterades:</Typography>
                    <TextField {...register('updDate')} placeholder='updDate' InputProps={{readOnly: true}} fullWidth />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Stack direction='row' spacing={2}>
                  <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' type='submit' color='success' startIcon={<SaveOutlinedIcon fontSize='small' />}>
                    Spara
                  </Button>
                  <Button onClick={() => navigate('/area')} sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<HighlightOffIcon fontSize='small' />}>
                    Avbryt
                  </Button>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
