import {IConsumptionNodeDto, IConsumptionGraphGroupedPDFProps, IConsumptionGraphGroupedProps, IConsumptionYearData} from '../../../redux/consumption/interfaces'
import ConsumptionNodeDto from '../../../redux/consumption/ConsumptionNodeDto'

import React, {useEffect, useRef} from 'react'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import {ATEMP, CONSUMPTION, COST, CUBIC_METERS_SWE, EMISSION, LOA, MWH, READING, WATER_SWE} from '../../../shared/utils/constants'
import {IAxesLabels} from '../table/interfaces'
import {getDesc, getUnit} from '../graph/consumptionGraphHelper'
import {Grid} from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/rootReducer'
const oldPlugin = {
  id: 'backgroundSetter',
  afterDraw: (chart: any, args: any, options: any) => {
    const {ctx} = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = options.color
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore();
  },
  defaults: {
    color: 'white'
  }
}

const backgroundColor = {
  id: 'backgroundColor',
  beforeDraw: (chart: any, options: any) => {
    const { ctx, width, height} = chart
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, width, height)
    ctx.restore()
  }
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, backgroundColor)

const labels = ['test1', 'test2', 'test3']
export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => 3000),
      backgroundColor: 'rgb(255, 99, 132)',
      stack: 'Stack 0',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => 3000),
      backgroundColor: 'rgb(75, 192, 192)',
      stack: 'Stack 0',
    },
    {
      label: 'Dataset 3',
      data: labels.map(() => 3000),
      backgroundColor: 'rgb(53, 162, 235)',
      stack: 'Stack 1',
    },
  ],
}

const barColors = ['#C30009', '#00791C', '#0067FF', '#9D5900']

const axesLabels = {
  'CONSUMPTION': 'Förbrukning',
  'READING': 'Avläsning',
  'COST': 'Kostnad',
  'EMISSION': 'Utsläpp',
} as IAxesLabels

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
const ConsumptionGraphGroupedPDF = (props: IConsumptionGraphGroupedPDFProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const showVolume = useSelector((state: RootState) => state.configuration.showVolume)

  const getYearlyConsumptionData = (consumptions: ConsumptionNodeDto[], year: number) => {
    var consumptionsByYear: number[] = []

    for (let i = 1; i <= 12; i++) {
      var monthlyConsumption = consumptions.filter((x) => x.year == year && x.month == i).map((x) => x)[0]
      if (monthlyConsumption) {
        if (!props.category || props.category === CONSUMPTION) consumptionsByYear.push(monthlyConsumption.value)
        else if (props.category === COST) consumptionsByYear.push(monthlyConsumption.cost)
        else if (props.category === EMISSION) consumptionsByYear.push(monthlyConsumption.emissionValue)
        else if (props.category === ATEMP) consumptionsByYear.push((!showVolume && props.meterType.name === WATER_SWE) || props.unit.comment === MWH ? monthlyConsumption.aTempMultiplied : monthlyConsumption.aTemp)
        else if (props.category === LOA) consumptionsByYear.push((!showVolume && props.meterType.name === WATER_SWE) || props.unit.comment === MWH ? monthlyConsumption.loaMultiplied : monthlyConsumption.loa)
      } else {
        consumptionsByYear.push(0)
      }
    }

    let yearData: IConsumptionYearData = {
      label: `${year}`,
      backgroundColor: '#000000',
      data: consumptionsByYear,
      fill: true,
    }
    return yearData
  }

  const getConsumptionYearData = (consumptions: ConsumptionNodeDto[], startDate: Date) => {
    var years = consumptions
      .filter((x) => x.year >= startDate.getFullYear() && !x.hidden)
      .map((x) => x.year)
      .filter((v, i, arr) => arr.indexOf(v) === i)
    var consumptionsByYear: IConsumptionYearData[] = []
    years.forEach((year) => {
      consumptionsByYear.push(getYearlyConsumptionData(consumptions, year))
    })
    return {
      months: months,
      data: consumptionsByYear,
    }
  }

  let groupedData = getConsumptionYearData(props.data, props.startDate)

  let labels = months.map((x) => x).filter((v, i, arr) => arr.indexOf(v) === i)
  groupedData.data.forEach((year, index) => {
    if (barColors[index] !== undefined) {
      year.backgroundColor = barColors[index]
    }
  })

  const chartData = {
    datasets: groupedData.data.map((x: IConsumptionYearData) => {
      return {
        label: x.label,
        data: x.data,
        backgroundColor: x.backgroundColor,
        fill: x.fill,
      }
    }),
    labels: labels,
  }

  return (
    <Bar
      data={chartData}
      options={{
        animation: {duration: 0},
        scales: {
          y: {title: {display: true, text: `${getDesc(props.category, props.meterType)} (${getUnit(props.category, props.meterType, props.unit, props.currency, props.emission)})`}},
          x: {title: {display: true, text: 'Period'}},
        },
      }}
    />

    //</div>
  )
}
export default ConsumptionGraphGroupedPDF
