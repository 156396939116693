import React, {useEffect, useState} from 'react'
import {FormControl, InputAdornment, InputLabel, MenuItem, Select} from '@mui/material'
import {CONSUMPTION, COST, EMISSION, ATEMP, LOA} from '../../shared/utils/constants'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {isNullOrWhiteSpace} from '../../shared/utils/utilities'
import {setSelectedConsumptionCategory} from '../../redux/filter/actions'
import {Toll, Co2, SquareFoot, BarChart, Timeline} from '@mui/icons-material'
import {ConsumptionCostEmissionSelectProps} from './table/interfaces'
import { getSelectedCategoryValueLabel } from './graph/consumptionGraphHelper'
export interface IConsumptionCostEmissionSelectProps {
  defaultValue: string
}
const ConsumptionCostEmissionSelect = (props: ConsumptionCostEmissionSelectProps) => {
  const dispatch = useDispatch()
  const currentlySetCategory = useSelector((state: RootState) => state.filter.consumptionCategory)
  const [category, setCategory] = useState<string>(currentlySetCategory ?? CONSUMPTION)

  useEffect(() => {
    if (!isNullOrWhiteSpace(category)) {
      dispatch(setSelectedConsumptionCategory(category))
    }
  }, [category])

  const renderIcon = (category: string) => {
    switch (category) {
      case CONSUMPTION:
        return !props.meterType?.isConsumption ? <Timeline /> : <BarChart />
      case COST:
        return <Toll />
      case EMISSION:
        return <Co2 />
      case ATEMP:
        return <SquareFoot />
      case LOA:
        return <SquareFoot />
      default:
        return <BarChart />
    }
  }
  return (
    <FormControl variant='outlined' sx={{minWidth: 130}}>
      <InputLabel id={`typeSelectLabel`} variant='outlined' htmlFor='typeSelect'>
        Kategori
      </InputLabel>
      <Select
        sx={{flexGrow: 1, color: '#3E5060'}}
        id='typeSelect'
        label='Kategori'
        labelId={`typeSelectLabel`}
        className='type-select'
        value={category}
        startAdornment={<InputAdornment position='start'>{renderIcon(category)}</InputAdornment>}
        onChange={(e) => setCategory(e.target.value)}>
        <MenuItem value={CONSUMPTION}>{getSelectedCategoryValueLabel(CONSUMPTION, props.meterType)}</MenuItem>
        {props.meterType?.isConsumption && <MenuItem value={COST}>{getSelectedCategoryValueLabel(COST, props.meterType)}</MenuItem>}
        {props.meterType?.isConsumption && <MenuItem value={EMISSION}>{getSelectedCategoryValueLabel(EMISSION, props.meterType)}</MenuItem>}
        {props.meterType?.isConsumption && <MenuItem value={ATEMP}>{getSelectedCategoryValueLabel(ATEMP, props.meterType)}</MenuItem>}
        {props.meterType?.isConsumption && <MenuItem value={LOA}>{getSelectedCategoryValueLabel(LOA, props.meterType)}</MenuItem>}
      </Select>
    </FormControl>
  )
}
export default ConsumptionCostEmissionSelect
