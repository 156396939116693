import {AccessTimeFilled, Assignment, MapsHomeWork, Room, PeopleAlt, BusinessCenter} from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import MenuBottonGroup from './MenuBottonGroupStyles'
import MenuBotton from './MenuBottonStyles'
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {isAdmin} from '../../../shared/utils/utilities'
function TopSectionMenu() {
  const [shouldDisplayAdminButtons, setShouldDisplayAdminButtons] = useState<boolean>(false)
  useEffect(() => {
    setShouldDisplayAdminButtons(isAdmin())
  }, [])

  return (
    <MenuBottonGroup>
      <Tooltip title='Rapporter'>
        <Link to={'Report'}>
          <MenuBotton value='Rapporter'>
            <Assignment />
          </MenuBotton>
        </Link>
      </Tooltip>
      <Tooltip title='Fastighetsägare'>
        <Link to={'Owner'}>
          <MenuBotton value='Fastighetsägare'>
            <MapsHomeWork />
          </MenuBotton>
        </Link>
      </Tooltip>
      <Tooltip title='Graddagsorter'>
        <Link to={'Area'}>
          <MenuBotton value='Graddagsorter'>
            <Room />
          </MenuBotton>
        </Link>
      </Tooltip>
      <Tooltip title='Mätartyper'>
        <Link to={'Meter'}>
          <MenuBotton value='Mätartyper'>
            <AccessTimeFilled />
          </MenuBotton>
        </Link>
      </Tooltip>
      {shouldDisplayAdminButtons && (
        <>
          <Tooltip title='Användare'>
            <Link to={'User'}>
              <MenuBotton value='Användare'>
                <PeopleAlt />
              </MenuBotton>
            </Link>
          </Tooltip>
        </>
      )}
      <Tooltip title='Kontor'>
        <Link to={'Office'}>
          <MenuBotton value='Kontor'>
            <BusinessCenter />
          </MenuBotton>
        </Link>
      </Tooltip>
    </MenuBottonGroup>
  )
}

export default TopSectionMenu
