import {Edit} from '@mui/icons-material'
import {Card, CardContent, CardHeader, Divider, IconButton, styled} from '@mui/material'
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {IEstateMeterEmission} from '../../../redux/estateMeterEmission/interfaces'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import {IEstateMeterEmissionListProps} from './interfaces'
import {EMISSION_KG_CO2e, NODE_WRITE} from '../../../shared/utils/constants'
import {hasEditPermissions, hasPermissions} from '../../../shared/utils/utilities'
const StripedDataGrid = styled(DataGrid)(({theme}) => ({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}))
const EstateMeterEmissionList = (props: IEstateMeterEmissionListProps) => {
  const [canEdit, setCanEdit] = useState<boolean>(false)

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
  }, [])

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'value', headerName: 'Värde', flex: 1, editable: true},
    {
      field: 'emissionType',
      headerName: 'Utsläpp',
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return EMISSION_KG_CO2e
      },
    },
    {field: 'startDate', headerName: 'Från', flex: 1, editable: true},
    {field: 'stopDate', headerName: 'Till', flex: 1, editable: true},
    {
      width: 10,
      field: 'actions',
      headerName: 'Actions',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<string>) =>
        canEdit ? (
          <div>
            <IconButton onClick={() => props.onEstateMeterEmissionEditClick(params.row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => props.onEstateMeterEmissionDeleteClick(params.row)}>
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </div>
        ) : (
          ''
        ),
      flex: 1,
    },
  ]
  return (
    <div>
      <Card>
        <CardHeader title={`Registrerade utsläpp`} />
        <Divider />
        <CardContent>
          <StripedDataGrid pageSize={50} rows={props.estateMeterEmissions} columns={columns} autoHeight />
        </CardContent>
      </Card>
    </div>
  )
}
export default EstateMeterEmissionList
