import { Link } from 'react-router-dom'
import {DrawerLogo} from './LogoStyle'
const Logo = () => {
  return (
    <DrawerLogo>
      <Link to="/"><img src='/assets/logo/saveup.logo.svg' alt='' style={{width:'90%', height: '90%', marginLeft: '5px', marginTop: '5px'}}/></Link>
    </DrawerLogo>
  )
}
export default Logo
