import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {IMeterEmissionInputDialogProps, IMeterEmissionInputForm} from './interfaces'
import DialogStartStopDate from '../../../components/shared/dialogs/DialogStartStopDate'
import ControlledTextField from '../../shared/Inputs/ControlledTextField'
import DialogOKCancelButtons from '../../../components/shared/dialogs/DialogOKCancelButtons'
import { isAdmin } from '../../../shared/utils/utilities'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const EstateMeterCostInputDialog = (props: IMeterEmissionInputDialogProps) => {
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)
  const {register, control, handleSubmit, reset} = useForm<IMeterEmissionInputForm>({
    defaultValues: {
      id: props.item ? props.item.id : 0,
      meterId: props.item ? props.item.meterId : 0,
      emissionId: props.item ? props.item.emissionId : props.defaultEmission.id,
      value: props.item ? props.item.value : 0,
      startDate: props.item ? props.item.startDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      stopDate: props.item ? props.item.stopDate : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    },
  })
  useEffect(() => {
    if (props.item) {
      reset({
        id: props.item ? props.item.id : 0,
        meterId: props.item ? props.item.meterId : 0,
        emissionId: props.item ? props.item.emissionId : props.defaultEmission.id,
        value: props.item ? props.item.value : 0,
        startDate: props.item ? props.item.startDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        stopDate: props.item ? props.item.stopDate : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
      })
    }
  }, [props.item])
  useEffect(() => {
    setHasAdminRights(isAdmin())
  }, [])

  const renderTitle = () => {
    return <Typography variant='h3'>{props.item?.id === 0 ? 'Nytt utsläpp' : 'Redigera utsläpp'}</Typography>
  }

  const onSubmit = (data: IMeterEmissionInputForm) => {
    const value = parseFloat(data.value.toString())
    const meterId = data.meterId
    const startDate = new Date(data.startDate)
    const stopDate = new Date(data.stopDate)
    props.onConfirm({...data, id: props.item ? props.item.id : 0, meterId, value, startDate, stopDate})
  }

  return (
    <div>
      <form>
        <Dialog sx={{style}} open={props.open}>
          <DialogTitle>{renderTitle()}</DialogTitle>
          <DialogContent>
            <Grid>
              <ControlledTextField control={control} id='value' name='value' label={`Utsläpp (KG CO2e / ${props.unit?.comment})`} type='number' />
              <DialogStartStopDate control={control} defaultValue={new Date()} views={['year', 'month']} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogOKCancelButtons onConfirmClick={handleSubmit(onSubmit)} onCancelClick={props.onClose} isAuthorized={hasAdminRights} />
          </DialogActions>
        </Dialog>
      </form>
    </div>
  )
}
export default EstateMeterCostInputDialog
