import {Box, Container, Link, Typography} from '@mui/material'
import {FooterWrapper} from './FooterStyles'

function Footer() {
  return (
    <FooterWrapper>
      <Container maxWidth='lg'>
        <Box py={3} display={{xs: 'block', md: 'flex'}} alignItems='center' textAlign={{xs: 'center', md: 'left'}} justifyContent='space-between'>
          <Box>
            <Typography variant='subtitle1'>&copy; 2023 - SaveUp admin portal</Typography>
          </Box>
          <Typography sx={{pt: {xs: 2, md: 0}}} variant='subtitle1'>
            Skapad av{' '}
            <Link href='#' target='_blank' rel='noopener noreferrer'>
              Nordlo Södra Norrland
            </Link>
          </Typography>
        </Box>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
