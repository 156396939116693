import { Cancel, Save } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

interface IConfirmDialogProps {
    open: boolean,
    onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void,
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void,
    title?: string | undefined,
    content?: string | undefined,
    okButtonLabel?: string | undefined,
    cancelButtonLabel?: string | undefined
}

const ConfirmDialog = (props: IConfirmDialogProps) => {    
    return (
    <Dialog open={props.open} onClose={props.onClose}>
    { props.title && (<DialogTitle>{props.title}</DialogTitle>) }
    { props.content && ( <DialogContent>{props.content}</DialogContent>)}
    <DialogActions>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' color='primary' startIcon={<Save fontSize='small' />} onClick={props.onConfirm}>
            {props.okButtonLabel ?? "OK"}
          </Button>
          <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<Cancel fontSize='small' />} onClick={props.onClose}>
            {props.cancelButtonLabel ?? "Avbryt"}
          </Button>
        </DialogActions>
    </Dialog>
    )
}
export default ConfirmDialog
