import {Delete} from '@mui/icons-material'
import {IconButton} from '@mui/material'
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams} from '@mui/x-data-grid'
import { IReaderTableProps } from './interfaces'
import {IUser} from '../../../../redux/user/interfaces'
import {deleteEstateUser} from '../../../../redux/estate/actions'
import React from 'react'
import {useDispatch} from 'react-redux'
const ReaderTable = (props: IReaderTableProps) => {
  const dispatch = useDispatch()
  const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id', flex: 1},
    {field: 'fullName', headerName: 'Namn', flex: 1},
    {field: 'mail', headerName: 'Beskrivning', flex: 1},
    {
      field: 'edit',
      headerName: 'Redigera',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <div>
            <IconButton onClick={() => onRemoveReaderClick(params.row)}>
              <Delete />
            </IconButton>
          </div>
        )
      },
      flex: 1,
    },
  ]

  const onRemoveReaderClick = (row: IUser) => {
    dispatch(deleteEstateUser(row.id, props.estateId))
  }
  const renderGrid = () => {
    if (props.readers === undefined || props.readers.length === 0) {
      return <div>Inga avläsare kunde hittas för vald fastighet!</div>
    } else {
      return <DataGrid style={{height: '400px', width: '100%'}} rows={props.readers} columns={columns} pageSize={10} rowsPerPageOptions={[10]} />
    }
  }
  return <div>{renderGrid()}</div>
}
export default ReaderTable
