import ReadingGraphLine from '../reading/ReadingGraphLine'
import {WarningAmber} from '@mui/icons-material'
import {FormControlLabel, Grid, Switch, Typography} from '@mui/material'
import {IConsumptionViewProps} from '../../redux/consumption/interfaces'
import React, {useEffect, useState} from 'react'
import ConsumptionGraphGrouped from './graph/ConsumptionGraphGrouped'
import ConsumptionGraphPlain from './graph/ConsumptionGraphPlain'
import { COUNTER, CURRENCY_SEK, EMISSION_KG_CO2 } from '../../shared/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/rootReducer'
import { getUnitById } from '../../redux/unit/unitActions'
import { getCurrencyByName } from '../../redux/currency/actions'
import { getEmissionByName } from '../../redux/emission/actions'
import { getNodeCostEmissionData } from '../../redux/node/actions'
import { shouldHideGraphs } from '../../shared/utils/utilities'
const ConsumptionView = (props: IConsumptionViewProps) => {
  const dispatch = useDispatch()
  const [isGroupedGraph, setIsGroupedGraph] = useState(props ? props.meterType.isConsumption : false)
  const selectedUnit = useSelector((state: RootState) => state.unit.selectedUnit)
  const selectedEmission = useSelector((state: RootState) => state.emission.selectedEmission)
  const selectedCurrency = useSelector((state: RootState) => state.currency.selectedCurrency)
  const nodeCostEmissionData = useSelector((state: RootState) => state.node.costEmissionData?.data)
  const [hideGraph, setHideGraph] = useState<boolean>(false)

  useEffect(() => {
    setHideGraph(shouldHideGraphs())
  }, [])

  useEffect(() => {
      dispatch(getUnitById(props.meterType.unitId))
      dispatch(getCurrencyByName(CURRENCY_SEK))
      dispatch(getEmissionByName(EMISSION_KG_CO2))        
  }, [props.meterType.id])

  useEffect(() => {
    if(props.data && props.data.length > 0 && ((nodeCostEmissionData === undefined || nodeCostEmissionData.id !== props.data[0].id))) {
      let nodeId = props.data[0].id

      // We fetch a large span to allow for overarching costs and emission data.
      const costEmissionFetchStartDate = new Date(2001, 0, 1)
      const costEmissionStopDate = new Date(2099, 11, 31)
      
      dispatch(getNodeCostEmissionData(nodeId, props.data[0].type, costEmissionFetchStartDate, costEmissionStopDate))
    }
  }, [props.data])


  const onToggleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGroupedGraph(event.target.checked)
  }  

  const getGraphByType = () => {
    if (props.meterType.isConsumption) {
      if (isGroupedGraph) {
        return (
          <ConsumptionGraphGrouped
            data={props.data}
            meterType={props.meterType}
            startDate={props.startDate}
            stopDate={props.stopDate}
            category={props.category}
            unit={selectedUnit}
            currency={selectedCurrency}
            emission={selectedEmission}
            deviations={props.deviations}
            costEmissionData={nodeCostEmissionData}
          />
        )
      } else {
        return (
          <ConsumptionGraphPlain
            data={props.data}
            meterType={props.meterType}
            deviations={props.deviations}
            startDate={props.startDate}
            stopDate={props.stopDate}
            category={props.category}
            unit={selectedUnit}
            currency={selectedCurrency}
            emission={selectedEmission}
            costEmissionData={nodeCostEmissionData}
          />
        )
      }
    } else {
      if (props.data.find((d) => d.type === 'COUNTER') || (props.nodeType && props.nodeType === COUNTER)) {
        return (
          <ReadingGraphLine
            data={props.data}
            meterType={props.meterType}
            deviations={props.deviations}
            startDate={props.startDate}
            stopDate={props.stopDate}
            category={props.category}
            unit={selectedUnit}
            currency={selectedCurrency}
            emission={selectedEmission}
          />
        )
      } else {
        return (
          <div style={{textAlign: 'center'}}>
            <WarningAmber sx={{fontSize: '128px'}} />
            <Typography variant='h5'>Denna mätartyp rapporterar ej förbrukningar! Vänliga kontrollera enskilda räkneverk.</Typography>
          </div>
        )
      }
    }
  }
  if(hideGraph) return <React.Fragment></React.Fragment>
  return (
    <div>
      {getGraphByType()}
      <Grid container direction='row' spacing={1}>
        <Grid item xs={2}>
          <FormControlLabel control={<Switch checked={isGroupedGraph} onChange={onToggleChanged} title='Grupperad graf' />} label='Grupperad' />
        </Grid>
      </Grid>
    </div>
  )
}
export default ConsumptionView