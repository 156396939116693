import {Reducer} from 'redux'
import {ActionTypes} from './measurepointTypes'
import { IMeasurePointState } from './measurepointInterfaces'

export const initialState: IMeasurePointState = {
  isLoading: false,  
  errorMessage: undefined,
  meterTypes: [],
  selectedMeasurePoint: undefined,
  consumptions: [],
  readingLogs: [],
  consumptionLogs: [],
  counters: {
    isLoading: false,
    counters: [],
    errorMessage: ''
  }
}

  export const measurePointReducer: Reducer<IMeasurePointState> = (state = initialState, {type, payload}) => {
    switch (type) {
      case ActionTypes.GET_MEASUREPOINT_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.GET_MEASUREPOINT_SUCCESS: {
        return {...state, isLoading: false, selectedMeasurePoint: payload}
      }
      case ActionTypes.GET_MEASUREPOINT_FAILED: {
        return {...state, isLoading: false, errorMessage: payload}
      }
      case ActionTypes.GET_MEASUREPOINTS_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.GET_MEASUREPOINTS_SUCCESS: {
        return {...state, isLoading: false, selectedMeasurePoint: payload}
      }
      case ActionTypes.GET_MEASUREPOINTS_FAILED: {
        return {...state, isLoading: false, errorMessage: payload.response.status}
      }
      case ActionTypes.CREATE_MEASUREPOINT_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.CREATE_MEASUREPOINT_SUCCESS: {
        return {...state, isLoading: false, selectedMeasurePoint: payload}
      }
      case ActionTypes.CREATE_MEASUREPOINT_FAILED: {
        return {...state, isLoading: false, errorMessage: payload.response.status}
      }
      case ActionTypes.UPDATE_MEASUREPOINT_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.UPDATE_MEASUREPOINT_SUCCESS: {
        return {...state, isLoading: false }
      }
      case ActionTypes.UPDATE_MEASUREPOINT_FAILED: {
        return {...state, isLoading: false, errorMessage: payload.response.status}
      }
      case ActionTypes.DELETE_MEASUREPOINT_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.DELETE_MEASUREPOINT_SUCCESS: {
        return {...state, isLoading: false, selectedMeasurePoint: payload}
      }
      case ActionTypes.DELETE_MEASUREPOINT_FAILED: {
        return {...state, isLoading: false, errorMessage: payload.response.status}
      }
      case ActionTypes.FETCH_MEASUREPOINT_METER_TYPES_LOADING: {
        return {...state, isLoading: true}
      }
      case ActionTypes.FETCH_MEASUREPOINT_METER_TYPES_SUCCESS: {
        return {...state, isLoading: false, meterTypes: payload}
      }
      case ActionTypes.FETCH_MEASUREPOINT_METER_TYPES_FAILED: {
        return {...state, isLoading: false, errorMessage: payload.response.status}
      }
      case ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOADING: {
        return { ...state, isLoading: true }
      }
      case ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_SUCCESS: {
        return { ...state, isLoading: false, consumptions: payload }
      }
      case ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_FAILED: {
        return { ...state, isLoading: false, errorMessage: payload.response.status }
      }
      case ActionTypes.FETCH_MEASUREPOINT_READING_LOGS_LOADING: {
        return { ...state, isLoading: true }
      }
      case ActionTypes.FETCH_MEASUREPOINT_READING_LOGS_SUCCESS: {
        return { ...state, isLoading: false, readingLogs: payload }
      }
      case ActionTypes.FETCH_MEASUREPOINT_READING_LOGS_FAILED: {
        return { ...state, isLoading: false, errorMessage: payload.response.status }
      }
      case ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOGS_LOADING: {
        return { ...state, isLoading: true }
      }
      case ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOGS_SUCCESS: {
        return { ...state, isLoading: false, consumptionLogs: payload }
      }
      case ActionTypes.FETCH_MEASUREPOINT_CONSUMPTION_LOGS_FAILED: {
        return { ...state, isLoading: false, errorMessage: payload.response.status }
      }
      case ActionTypes.FETCH_COUNTERS_BY_MEASUREPOINT_ID_LOADING: {
        return {...state, counters: {...state.counters, counters: [], isLoading: true}}
      }
      case ActionTypes.FETCH_COUNTERS_BY_MEASUREPOINT_ID_SUCCESS: {
        return {...state, counters: {...state.counters, isLoading: false, counters: payload}}
      }
      case ActionTypes.FETCH_COUNTERS_BY_MEASUREPOINT_ID_FAILED: {
        return {...state, counters: {...state.counters, isLoading: false, errorMessage: payload.response.status}}
      }
      default: {
        return state
      }
    }
  }
