import {Reducer} from 'redux'
import {ActionTypes} from './homeTypes'
import {CounterValueLogTag, IDeviationFilter, ILogState} from './homeInterfaces'

const defaultDeviationFilter: IDeviationFilter = {
  selectedCaseStatus: 'ALL',
  selectedLogOffice: 'ALL',
  selectedCategory: 'ALL',
  startDate: new Date(),
}

export const initialState: ILogState = {
  isLoading: false,
  isDeviationsLoading: false,
  data: [],
  errorMessage: undefined,
  deviations: [],
  deviationFilter: defaultDeviationFilter
}

export const homeReducer: Reducer<ILogState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.GET_ALL_LOGS_LOADING:
      return {...state, isLoading: true}
    case ActionTypes.GET_ALL_LOGS_SUCCESS:
      return {...state, isLoading: false, data: payload}
    case ActionTypes.GET_ALL_LOGS_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.GET_DEVIATIONS_LOADING:
      return {...state, isLoading: false, isDeviationsLoading: true}
    case ActionTypes.GET_DEVIATIONS_SUCCESS:
      return {...state, isLoading: false, isDeviationsLoading: false, deviations: payload}
    case ActionTypes.GET_DEVIATIONS_FAILED:
      return {...state, isLoading: false, isDeviationsLoading: false, errorMessage: payload}
    case ActionTypes.GET_DEVIATION_FILTER_STATE_LOADING:
      return {...state, isLoading: false}
    case ActionTypes.GET_DEVIATION_FILTER_STATE_SUCCESS:
      return {...state, isLoading: false, deviationFilter: payload}
    case ActionTypes.GET_DEVIATION_FILTER_STATE_FAILED:
      return {...state, isLoading: false, errorMessage: payload}
    case ActionTypes.UPDATE_LOG_TAGS_LOADING:
      return state
    case ActionTypes.UPDATE_LOG_TAGS_SUCCESS:
      const logs = state.data
      const logIndex = logs.findIndex((log) => log.id === payload.logId)
      if(logIndex > -1 && payload.tags.length > 0) {
        logs[logIndex].category = payload.tags[0]
      }
      return {...state, data: [...logs]}
    default:
      return state
  }
}

export default homeReducer
