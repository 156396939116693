import {Typography} from '@mui/material'
interface Props {
  user: any
}

function PageHeader({user}: Props) {
  return (
    <>
      <Typography variant='h3' component='h3' gutterBottom>
        Användarinställningar
      </Typography>
      <Typography variant='subtitle2'>Hej, {user}. Nedan listas användarspecifika inställningar du kan göra för att anpassa SaveUp.</Typography>
    </>
  )
}

export default PageHeader
