import { OfficeMeterCost } from "../redux/officeMeterCost/interfaces"
import saveupClient, { handleResponse } from "../clients/saveupClient"

async function getOfficeMeterCosts(officeId: number, meterId: number)  {
    return await saveupClient.get<OfficeMeterCost[]>(`office/${officeId}/meter/${meterId}/cost`).then(handleResponse)    
}
async function createOfficeMeterCost(officeId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
    return await saveupClient.post<OfficeMeterCost>(`office/meter/cost`, {officeId, meterId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
}
async function updateOfficeMeterCost(id: number, officeId: number, meterId: number, currencyId: number, cost: number, startDate: Date, stopDate: Date) {
    return await saveupClient.put<OfficeMeterCost>(`office/meter/cost`, {id, officeId, meterId, currencyId, cost, startDate: startDate.toLocaleDateString(), stopDate: stopDate.toLocaleDateString()}).then(handleResponse)
} 
async function deleteOfficeMeterCost(id: number) {
    return await saveupClient.delete(`office/meter/cost/${id}`).then(handleResponse)
} 
const OfficeMeterCostService = {
    getOfficeMeterCosts,
    createOfficeMeterCost,
    updateOfficeMeterCost,
    deleteOfficeMeterCost
}
  
  export default OfficeMeterCostService
  