import React, {ChangeEvent, useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search'
import {styled, alpha} from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import {useDispatch, useSelector} from 'react-redux'
import {debounce} from 'lodash'
import {IUser} from '../../../../redux/user/interfaces'
import {RootState} from '../../../../redux/rootReducer'
import {searchUsers} from '../../../../redux/user/actions'
import {IReaderSearchProps} from './interfaces'
import {Button, Grid} from '@mui/material'
import { PlayArrow } from '@mui/icons-material'
const Search = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))
const ReaderSearch = (props: IReaderSearchProps) => {
  const [keyword, setKeyword] = useState<string>('')
  const [debouncedKeyword, setDebouncedKeyword] = useState<string>('')
  const dispatch = useDispatch()
  useEffect(() => {
    const keywordTimer = setTimeout(() => {
      setDebouncedKeyword(keyword)
    }, 2000)
    return () => {
      clearTimeout(keywordTimer)
    }
  }, [keyword])

  useEffect(() => {
    if (debouncedKeyword?.length > 3) {      
      dispatch(searchUsers(debouncedKeyword))
    }
  }, [debouncedKeyword])

  const users = useSelector((state: RootState) => state.user.searchResult)
  useEffect(() => {
    props.onSearchCompleted(users)
  }, [users])

  return (
    <Grid>
      <Grid direction='row' container>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder='Sök...' inputProps={{'aria-label': 'search'}} value={keyword} onChange={(e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)} />
        </Search>
      </Grid>
    </Grid>
  )
}
export default ReaderSearch
