import IAttribute, { IAttributeControl } from '../redux/attribute/interfaces'
import saveupClient, { handleResponse } from '../clients/saveupClient'

async function getAttributes() {
    return await saveupClient.get<IAttribute[]>(`attribute`)
    .then(handleResponse)
    .catch(error => console.log(error))
}
async function getAttributeControls(attributeId: number) {
    return await saveupClient.get<IAttributeControl[]>(`attribute/${attributeId}/control`)
    .then(handleResponse)
}
const attributeService = {
    getAttributes,
    getAttributeControls
}
export default attributeService